import './style.scss'
import React, {CSSProperties, useEffect} from "react";

interface IMdButtonProps {
    className?: string;
    type?: "reset" | "submit" | "button";
    icon?: any;
    tooltip?: string;
    style?: { [selector: string]: CSSProperties }
    children?: any;
    disabled?: boolean;
    onClick?: (event: any) => void;
}

export function MdButton({ className = "", type = "button", icon = null, tooltip = "", style = {}, children = null, disabled = false, onClick = () => {},  }: IMdButtonProps) {

    const onMouseDown = (event: React.MouseEvent) => {

        if (event.button != 0) return;

        let button = event.target as HTMLButtonElement;

        let rect = button.getBoundingClientRect();

        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        let inkNode = button.querySelector('.md-button-ink') as HTMLElement;

        if (inkNode != null && !inkNode.classList.contains('--ink-active')) {
            inkNode.style.left = (x - Math.max(rect.width, rect.height)) + 'px';
            inkNode.style.top = (y - Math.max(rect.width, rect.height)) + 'px';
            inkNode.style.width = (Math.max(rect.width, rect.height) * 2) + 'px';
            inkNode.style.height = (Math.max(rect.width, rect.height) * 2) + 'px';
            inkNode.classList.add('--ink-active');
            setTimeout(() => {
                inkNode.classList.remove('--ink-active');
            }, 400);
        }
    }


    useEffect(() => { }, []);


    return (
        <button
            type={ type }
            title={tooltip}
            className={"md-button" + " " + className}
            style={ style["button"] }
            onMouseDown={ (e) => onMouseDown(e) }
            onClick={ (e) => onClick(e) }
            disabled={ disabled }>
            {
                icon != null && (
                    <i className="md-button-icon" style={style["icon"]}>{icon}</i>
                )
            }

            {
                children != null && (
                    <span className="md-button-text" style={style["text"]}>{children}</span>
                )
            }
            <span className="md-button-ink" style={ style["ink"] }></span>
        </button>
    );
}