import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_GET_CLIENTS_STRUCTURE,
    ROUTE_GETCLIENTS_Sales,
    ROUTE_GET_ALLOWED_PERSONS,
    ROUTE_GET_CLIENTS_DETAILS,
    ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE,
    ROUTE_SEARCH_CLIENT_FEES,
    ROUTE_GET_CLIENT_INFO_BY_NAME,
    ROUTE_GET_COMPANY_BY_CODE,
    ROUTE_ADD_COMPANY,
    ROUTE_GET_ALL_COMPANIES, ROUTE_SEARCH_COMPANY_BY_NAME, ROUTE_IMPORT_COMPANY,
} from "../constants/routeConstants/ApiRouteConstants";
import {UserApi} from "./UserApi";
import {SearchClientFees} from "../responces/SearchClientFees";
import {IFalconResponse} from "../interfaces/IFalconGenericResponce";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {ICLientSale} from "../interfaces/companyInfoInterfaces/IClientSale";
import {ICompanyInfo} from "../interfaces/companyInfoInterfaces/ICompanyInfo";
import {
    ICompanyInfoRequest,
    IClientDetailsRequest,
    IClientStructureRequest,
    ICompanyInfoByNameRequest, ICompanyRequest, ISearchCompanyRequest
} from '../interfaces/requestsInterfaces/reportRequests';
import {IClientDetail} from '../interfaces/companyInfoInterfaces/IClientDetail';
import {ICompanyResponse} from "../responces/ICompanyResponse";
import {IAddCompanyRequest} from "../Requests/IAddCompanyRequest";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompany";

export function CompanyApi() {
    const searchPaxInfo = UserApi();


    //  Route: /searchAllowedPersons get allowed persons from api
    const searchAllowedPersons = async (request: ICompanyInfoRequest) => {
        const result = await axiosInstance.get<IFalconResponse<IAllowedPerson>>(
            ROUTE_GET_ALLOWED_PERSONS, {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {clientCode: request.clientCode}
            }
        );

        return result.data;
    }

    // route: /searchClients (WPF)
    //ToDo need to test
    const searchClient = async (request: ICompanyInfoRequest) => {

        const result = await axiosInstance.get<IFalconResponse<ICompanyInfo>>(
            ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE, {
                headers: {
                    Authorization: BEARER + request.token,
                    // 'Access-Control-Allow-Origin': '*'
                },
                params: {clientCode: request.clientCode}
            }
        );
        return result.data;
    };

    const searchClientByName = async (request: ICompanyInfoByNameRequest) => {

        const result = await axiosInstance.get<IFalconResponse<ICompanyInfo>>(
            ROUTE_GET_CLIENT_INFO_BY_NAME, {
                headers: {
                    Authorization: BEARER + request.token,
                    // 'Access-Control-Allow-Origin': '*'
                },
                params: {nameLike: request.nameLike}
            }
        );
        return result.data;
    };

    //route: "clientDetails"
    const getClientDetails = async (request: IClientDetailsRequest) => {
        const result = await axiosInstance.get<IFalconResponse<IClientDetail>>(
            ROUTE_GET_CLIENTS_DETAILS,
            {
                headers: {
                    // 'Access-Control-Allow-Origin': '*',
                    Authorization: BEARER + request.token,
                },
                params: {clientCode: request.clientCode, dateStart: request.dateStart}
            }
        );
        return result.data;
    };

    //route: /searchClientFees
    //! endpoint not work
    const searchClientFees = async (request: ICompanyInfoRequest) => {
        const result = await axiosInstance.get<SearchClientFees>(
            ROUTE_SEARCH_CLIENT_FEES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    clientCode: request.clientCode,
                },
            }
        );
        if ((await result).status === 200) {
            return result.data;
        }
    };

    const getClientsSales = async (request: ICompanyInfoRequest) => {
        const result = await axiosInstance.get<IFalconResponse<ICLientSale>>(ROUTE_GETCLIENTS_Sales, {
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                Authorization: BEARER + request.token,
            },
            params: {
                clientCode: request.clientCode,
            }
        })
        return result.data;
    };

    const getClientStructure = async (request: IClientStructureRequest) => {
        const response = await axiosInstance.get<IFalconResponse<IClientStructure>>(
            ROUTE_GET_CLIENTS_STRUCTURE,
            {
                headers: {
                    //  'Access-Control-Allow-Origin': '*',
                    Authorization: BEARER + request.token,
                },
                params: {
                    clientCode: request.clientCode,
                    status: request.status,
                    monthNum: request.monthNum,
                    quarter: request.quarter,
                    year: request.year,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd
                }
            }
        )

        return response.data;
    }


    const addUserToCompany = async () => {
        const clientCode = "Ф0009867";
        const result = await axiosInstance.post<SearchClientFees>(
            "/api/company/addUser/" + clientCode,
            {
                email: "Some@email.com",
                firstName: "Mike",
                lastName: "Vasovski",
                patronymic: "Petrovich",
                position: "Sale Manageer",
                roleName: "sf",
            }
        );
        if ((await result).status === 200) {
            //return result.status;
        }
        return result.status;
    };


    const getAllCompanies = async (token: string) => {
        const result = await axiosInstance.get<ICompanyResponse[]>(
            ROUTE_GET_ALL_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    }


    const importCompany = async (company: ICompany, token: string) => {
        const result = await axiosInstance.put(
            ROUTE_IMPORT_COMPANY,
            company,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    };


    const addCompany = async (company: ICompany, token: string) => {
        const request: IAddCompanyRequest = {
            code: company.clientCode,
            agreement: {
                no: company.agreement.no,
                dateFrom: company.agreement.dateFrom,
                dateTill: company.agreement.dateTill,
                code: ""
            },
            fullName: company.fullName,
            address: company.address,
            postalAddress: company.postalAddress,
            gkCode: company.gkCode,
            gkName: company.gkName,
            inn: company.inn,
            kpp: company.kpp,
            ogrn: company.ogrn,
            vatUse: company.vatUse,
            isActive: company.isActive
        };
        const result = await axiosInstance.put(
            ROUTE_ADD_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    };


    const getCompany = async (request: ICompanyRequest) => {
        const result = await axiosInstance.get<ICompanyResponse>(
            ROUTE_GET_COMPANY_BY_CODE,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    code: request.code,
                },
            }
        );
        if ((await result).status === 200) {
            return result.data;
        }
    };


    const searchcompany = async (request: ISearchCompanyRequest) => {
        const result = await axiosInstance.get<ICompanyResponse[]>(
            process.env.REACT_APP_INTEROP_BASE_ROUTE + ROUTE_SEARCH_COMPANY_BY_NAME,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    name: request.name,
                },
            }
        );
        if ((await result).status === 200) {
            console.log(result);
            return result.data;
        }
    };


    return {
        addUserToCompany,
        searchClient,
        searchPaxInfo,
        getClientsSales,
        getClientDetails,
        searchClientFees,
        searchAllowedPersons,
        getClientStructure,
        searchClientByName,
        importCompany,
        addCompany,
        getCompany,
        getAllCompanies,
        searchcompany
    };
}
