import {send} from "process";
import {SendAccountantDocumentRequest} from "../Requests/customerRequests/SendAccountantDocumentsRequest";
import {CustomerApi} from "../api/CustomerApi";
import {
    CURRENT_NODE_ENVIROMENT,
    CURRENT_REACT_ENVIROMENT,
    DATE_FORMAT_YYYYMMDD,
    ENVIROMENT_PRODUCTION
} from "../constants/ServiceCostants";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {DateService} from "./DateService";
import {
    IGetCustomerBuhDocsRequest,
    ISendBuhDocsToEmailByEdmRequest
} from "../interfaces/requestsInterfaces/reportRequests";
import {IError} from '../interfaces/dataInterfaces/IError';
import {doc} from "prettier";
import {
    getCustomerBuhDocsThunk,
    reportsState,
    setAvailableEdmBuhDocs,
    setCustomerBuhDocs,
    setSentToEdmBuhDocs
} from "../redux/reportsSlice";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {IReadyToDownloadDoc} from '../interfaces/reportInterfaces/accountantPageInterfaces';
import {link} from "fs";
import {availableParallelism} from "os";
import {AttachmentFormat, IDocAttachments} from "../Requests/ISendDocsToEdm";
import {IAccountantDocument} from "../interfaces/reportInterfaces/customerInterfaces";

export function AccountantDocumentService() {
    const {getCustomerBuhDocs,} = CustomerApi();
    const {dateFromDatePickerToCustomFormat} = DateService();
    const authState = useAppSelector((state) => state.auth);
    const reportsState = useAppSelector((state) => state.reports);

    const [cookies] = useCookies([ACCESS_TOKEN]);
    const dispatch = useAppDispatch();

    const {getUploadsBuhDocs, sendBuhDocsToEdm, getUploadsBuchDocs} = CustomerApi();

    const getBuchDocs = async (
        dateStart: string,
        dateEnd: string,
        docNumber: string,
        direction: string,
        name: string,
        clientCode?: string
    ) => {
        let dateStartFalconFormat = dateFromDatePickerToCustomFormat(
            dateStart,
            DATE_FORMAT_YYYYMMDD
        );
        let dateEndFalconFormat = dateFromDatePickerToCustomFormat(
            dateEnd,
            DATE_FORMAT_YYYYMMDD
        );
        const request: IGetCustomerBuhDocsRequest = {
            dateStart: dateStart, //dateStartFalconFormat,
            dateEnd: dateEnd, //dateEndFalconFormat,
            docNo: docNumber,
            route: direction,
            gkCode: authState.currentGkCode ?? "",
            clientCode: clientCode ?? authState.currentClientCode,
            name: name ?? "",
            token: cookies[ACCESS_TOKEN]
        }

        try {
            const response = await getCustomerBuhDocs(
                request
            );

            if (response.status === 200) {
                dispatch(setCustomerBuhDocs(response.data))
                return response.data;
            }
            return response.data;

        } catch (error) {
            return []
        }

        // const response = await getCustomerBuhDocs(
        //   request
        // );


        // if (response.status === 200) {
        //   dispatch(setCustomerBuhDocs(response.data.media))
        //   return response.data.media;
        // }

        // if (response.status === 500) {
        //   return new Error("")
        // }

        // return response.data.media;
        // //dispatch(getCustomerBuhDocsThunk(request))
    };

    // const checkSendingBuhDocs = (buchDocs: IAccountantDocument[]) => {

    // }


    const sendSelectedBuchDocsToEdm = async (
        documents: IDocAttachments,
        emails: string[],
        format: AttachmentFormat,
        needEdmUpload: boolean,
        ConnectionId: string) => {
        try {
            const response = await sendBuhDocsToEdm(documents, emails,  format, needEdmUpload, ConnectionId, cookies[ACCESS_TOKEN]);
            return response;
        } catch (ex) {
            console.error(ex);
        }
        return null;
    }

    // const getReadyToUploadDocs = async () => {
    //   const result = await getUploadsBuchDocs(cookies[ACCESS_TOKEN]);
    //   const keys = Object.keys(result);
    //   if (keys.length === 0) { return {} }
    //   let readyDocs = new Array<IReadyToUploadDoc>();
    // }

    const getDocNumberFromUploadKey = (uploadKey: string) => {
        const arr = uploadKey.split('_');
        return (arr[1])
    }

    // const sendBuchDocsToEmail = async (buhDocs: IAccountantDocument[], emails?: string[]) => {

    //   if (buhDocs.length === 0) return new Array<string>();

    //   if (emails === undefined) { emails = [authState.email] }

    //   let request: ISendBuhDocsToEmailByEdmRequest = {
    //     documents: getBuchDocNumbers(buhDocs),
    //     emails: emails,
    //     isTest: CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION
    //   }

    //   const response = await sendBuchDocsToEmailByEdm(request)

    //   if (Array.isArray(response)) return response;

    //   return new Array<string>();
    // }
    const getAllReadyForDownloadDocs = async (buhDocs: IAccountantDocument[]) => {
        const availableDocs = await getReadyForDownloadBuhDocs(buhDocs);
        if (availableDocs.length === 0) return (availableDocs);
        dispatch(setAvailableEdmBuhDocs(availableDocs))
        return availableDocs
    }

    const addReadyForDownloadDocs = async (buhDocs: IAccountantDocument[]) => {
        if (buhDocs.length === 0) return [];
        let availableDocs = await getReadyForDownloadBuhDocs(buhDocs);
        if (availableDocs.length === 0) return (availableDocs);
        let existDocs = reportsState.availableEdmBuhDocs;
        // availableDocs = availableDocs.filter(availableDoc => existDocs.find(number => number.docNumber === availableDoc.docNumber) !== undefined)
        if (availableDocs.length === 0) return (availableDocs);
        //UPDATE AVAILABLE DOCS
        existDocs = [...existDocs, ...availableDocs];
        dispatch(setAvailableEdmBuhDocs(existDocs))
        //UODATE SENT DOCS
        let sentDocs = reportsState.sentToEdmBuhDocs;
        sentDocs = sentDocs.filter(buhdoc => availableDocs.find(number => number.docNumber === buhdoc.docNo) !== undefined)
        dispatch(setSentToEdmBuhDocs(sentDocs))
        return availableDocs
    }

    const getReadyForDownloadBuhDocs = async (buhDocs: IAccountantDocument[]) => {
        const buchDocsNumbers = getBuchDocNumbers(buhDocs);
        const result = await getUploadsBuchDocs(buchDocsNumbers, authState.currentClientCode, cookies[ACCESS_TOKEN]);

        let readyDocs = new Array<IReadyToDownloadDoc>();

        if(!result) {
            return readyDocs;
        }

        const keys = Object.keys(result);
        if (keys.length === 0) {
            return readyDocs
        }
        keys.forEach(key => {
            readyDocs.push({
                docNumber: getDocNumberFromUploadKey(key),
                docLink: key,
                docName: result[key]
            })

        })
        // dispatch(setAvailableEdmBuhDocs(readyDocs))
        return readyDocs;
    }

    const getBuchDocNumbers = (documents: IAccountantDocument[]): string[] => {
        if (documents.length === 0) return new Array<string>();
        return documents.map(doc => doc.docNo)
    }

    const mapAccDocumentsToSendDocRequest = (
        documents: IAccountantDocument[]
    ): SendAccountantDocumentRequest[] => {
        return documents.map((doc) => ({
            ducumentNumber: doc.docNo,
            addStamp: false,
            docsToSend: [true, true, true],
        }));
    };

    return {
        getBuchDocs,
        mapAccDocumentsToSendDocRequest,
        //sendBuchDocsToEmail,
        sendSelectedBuchDocsToEdm,
        getReadyForDownloadBuhDocs,
        getAllReadyForDownloadDocs,
        addReadyForDownloadDocs
        // getReadyToUploadDocs,
    };
}
