import {ITrainVariant} from "../../../../interfaces/orderInterfaces/TrainOrderInterfaces";
import {DataConvertService} from "../../../../services/DataConvertService";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import "./style.scss";
import {InfoIcon} from "../../../../icons/InfoIcon";
import {LevelSliderIcon} from "../../../../icons/LevelSliderIcon";
import {SeatIcon} from "../../../../icons/SeatIcon";

type TrainInfoProps = {
    isCollapsed: boolean;
    trainVariant: ITrainVariant
}

export function TrainInfoComponent(props: TrainInfoProps) {
    const {t} = useTranslation();
    const [activeTariff, setActiveTariff] = useState(-1);

    const {useMoneyRubFormat} = DataConvertService();

    const tariffs = props.trainVariant.categoryPrice

    return (<>
        <div className="train-tariffs-container">
            <div><p>{t("orderPage.tariffs")}: {tariffs.length}</p></div>
            {tariffs && tariffs.map((tariff, index) => {
                return (
                    <div className="train-tariff-item">

                        {/*Category name*/}
                        <div className="train-tariff-item-name">{tariff.categoryName}</div>


                        <div className="avia-tariff-tooltip">
                            <label> {t("orderPage.seatResidue")} </label>
                            <div><LevelSliderIcon/></div>
                        </div>
                        <div> {tariff.seatsLeft} </div>  `

                        {/*Founded ticket information */}
                        <div className="avia-tariff-tooltip">
                            <label> {t("orderPage.trainTicketInfo")} </label>
                            <div><InfoIcon/></div>
                        </div>
                        <div> {tariff.foundInfo}</div>


                        <div className="avia-tariff-tooltip">
                            <label> {t("orderPage.baggage")} </label>
                            <div><SeatIcon/></div>
                        </div>
                        <div> {tariff.position}</div>

                        <div className="avia-tariff-item-price-block">
                            <div>{useMoneyRubFormat.format(tariff.price)}</div>
                            &nbsp;&nbsp;
                            <div className="avia-info-price">
                                <input type="radio" id="radioTarrifAvia"
                                       checked={index === activeTariff}
                                       onChange={() => {
                                           setActiveTariff(index)
                                       }}/>
                            </div>
                        </div>
                    </div>)
            })}
        </div>
    </>)
}