import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../../redux/hooks";
import {EMAIL_FIELD, PASSWORD_FIELD} from "../../../constants/FieldsConstants";
import {AuthService} from "../../../services/AuthService";
import {ITokenRequest} from "../../../Requests/ITokenRequest";
import {LoginRequestValidator} from "../../../validators/LoginRequestValidator";
import {ValidationErrors} from "fluentvalidation-ts";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import "./style.scss";

export function SignInPage() {
    //login data  and effects hooks
    const [searchParams] = useSearchParams();

    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [validationError, setValidationError] = useState<ValidationErrors<ITokenRequest>>();
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    //import function and servics
    const {login} = AuthService();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const userValidationStatus = useAppSelector((state) => state.auth.isValidUser);
    const loginRequestValidator = new LoginRequestValidator(t);



    const parseQueryString = async () => {
        const params = Object.fromEntries(searchParams.entries());

        if(params.email != null) {
            setInputEmail(params.email);
        }
    }

    const changeHandler = (inputField: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginError("");
        if (inputField === PASSWORD_FIELD) {
            setInputPassword(event.target.value);
        }
        if (inputField === EMAIL_FIELD) {
            setInputEmail(event.target.value);
        }
    };

    //TODO investigate for using other event
    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); //

        let authResult = false;

        setValidationError({email: "", password: ""});

        const error = loginRequestValidator.validate({
            email: inputEmail,
            password: inputPassword,
        });

        if (error.email || error.password) {
            setLoadingSpinner(false);
            setValidationError(error);
        } else {
            setLoadingSpinner(true);
            setLoginError("");
            authResult = await login({
                email: inputEmail,
                password: inputPassword,
            });
            setLoadingSpinner(false);
            if (authResult) {
                navigate("/cabinet");
            } else {
                setLoginError(t("invalidPasswordOrEmail"));
            }
        }
    };


    useEffect(() => {
        parseQueryString();
    }, []);


    return (
        <>
            <form className="login-form">
                <div className="login-form-header">Вход в систему</div>

                <div className="login-form-body">

                    <div className="login-form-error-message">
                        {loginError && <ErrorMessage error={loginError}/>}
                    </div>

                    <MdTextField type="email"
                                 label={t("email")}
                                 value={inputEmail}
                                 invalid={(validationError != null && validationError.email != null && validationError.email.length > 0)}
                                 errorMessage={(validationError?.email)}
                                 onChange={(event) => {
                                     changeHandler(EMAIL_FIELD, event);
                                 }}/>

                    <MdTextField type="password"
                                 label={t("password")}
                                 value={inputPassword}
                                 invalid={(validationError != null && validationError.password != null && validationError.password.length > 0)}
                                 errorMessage={(validationError?.password)}
                                 onChange={(event) => {
                                     changeHandler(PASSWORD_FIELD, event);
                                 }}/>

                    {
                        !loadingSpinner
                            ? (
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            width: "100%",
                                            height: "40px",
                                            fontSize: "14px",
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF"
                                        }
                                    }}
                                    onClick={(event) => submitHandler(event)}
                                >
                                    {t("description.login")}
                                </MdButton>
                            )
                            : (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "20px 0 0 0 "
                                }}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                </div>
                            )
                    }
                </div>

                <Link className="forgot-password-link" to="/recovery">{t("description.forgot-password")}</Link>

                <p className="sign-up-link">
                    {t("description.dont-have-acc") + " "}
                    <Link to="/sign-up">{t("description.register-here")}</Link>
                </p>
            </form>
        </>
    );
}
