import { ICustomerFlight } from "../../../../interfaces/reportInterfaces/customerInterfaces";
import { DataConvertService } from "../../../../services/DataConvertService";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

type CustomerFlightProps = {
  customerFlights: ICustomerFlight[];
};
export function CustomerFlightsComponent({ customerFlights }: CustomerFlightProps) {
  //const testData = customerFlightsTestData;
  const { useMoneyRubFormat } = DataConvertService();
  const { t } = useTranslation();
  return (
    <>
      <table className="table common-document-stripped-table table-bordered ">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{`${t("statisticPage.route")}`}</th>
            <th scope="col">{`${t("statisticPage.serviceType")}`}</th>
            <th scope="col">{`${t("statisticPage.totalCharge")}`}</th>
            <th scope="col">{`${t("statisticPage.totalCount")}`}</th>
          </tr>
        </thead>
        <tbody>
          {customerFlights.map((item, i) => {
            return (
              <tr>
                <td>{i}</td>
                <td>{item.route}</td>
                <td>{item.ticketClass}</td>
                <td>{useMoneyRubFormat.format(item.totalCharge)}</td>
                <td>{item.totalCount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
