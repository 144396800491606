import {axiosInstance} from "./AxiosInstance";
import {
    BASE_URL,
    BEARER,
    ROUTE_EDM_GENERATE_REPORTS,
    ROUTE_GET_CLIENTS_STRUCTURE,
    ROUTE_GET_STATEMENT_CLOSE_BALANCE,
    ROUTE_GET_STATEMENT_INCOMES,
    ROUTE_GET_STATEMENT_OPEN_BALANCE,
    ROUTE_GET_STATEMENT_SALES,
} from "../constants/routeConstants/ApiRouteConstants";
import {GetClientStructureResponse} from "../responces/companyInfoResponses/GetClientStructureResponse";
import {GetStatementOpenBalanceResponse} from "../responces/accountInfoResponses/GetStatementOpenBalanceResponse";
import {GetStatementCloseBalanceResponce} from "../responces/accountInfoResponses/GetStatementCloseBalanceResponse";
import {IStatementSale} from "../interfaces/reportInterfaces/IStatementSale";
import {IFalconResponse} from "../interfaces/IFalconGenericResponce";
import {IStatementIncome} from "../interfaces/reportInterfaces/IStatementIncome";
import {ICustomerOpeningMonthlyBalance} from "../interfaces/reportInterfaces/customerInterfaces";
import axios from "axios";
//import { CookiesProvider, Cookies } from 'react-cookie'
import {IClientStructureRequest, ICommonReportRequest} from "../interfaces/requestsInterfaces/reportRequests";
import {Exception} from "sass";
import {IGenerateReportsRequest} from "../Requests/IGenerateReportsRequest";


export function ReportsApi(accessToken?: string) {

    const getClientsStructure = async (
        request: IClientStructureRequest
    ) => {
        const result = await axiosInstance.get<GetClientStructureResponse>(
            ROUTE_GET_CLIENTS_STRUCTURE,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    clientCode: request.clientCode,
                    status: request.status,
                    monthNum: request.monthNum,
                    quarter: request.quarter,
                    year: request.year,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd,
                },
            }
        );

        if (result.status === 200) {
            return result.data;
        }
        return result.data;
    };

    const getStatemetSales = async (
        request: ICommonReportRequest
    ) => {

        const result = await axiosInstance.get<IFalconResponse<IStatementSale>>(
            ROUTE_GET_STATEMENT_SALES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
        return result.data;
    };

    const getStatemetIncomes = async (
        request: ICommonReportRequest
    ) => {
        const result = await axiosInstance.get<IFalconResponse<IStatementIncome>>(
            ROUTE_GET_STATEMENT_INCOMES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
        return result.data;
    };

    const getStatemetOpenBalance = async (
        request: ICommonReportRequest
    ) => {
        const result = await axiosInstance.get<GetStatementOpenBalanceResponse>(
            ROUTE_GET_STATEMENT_OPEN_BALANCE,
            {
                headers: {
                    Authorization: BEARER + request.token
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
        return result.data;
    };

    const getStatemetCloseBalance = async (
        request: ICommonReportRequest
    ) => {
        const result = await axiosInstance.get<GetStatementCloseBalanceResponce>(
            ROUTE_GET_STATEMENT_CLOSE_BALANCE,
            {
                headers: {
                    Authorization: BEARER + request.token
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
        return result.data;
    };

    const getCustomerOpeningBalanceEveryMon = async (clientCode: string,
                                                     dateStart: string,
                                                     dateEnd: string,
                                                     gkCode: string) => {

        const result = await axiosInstance.get<IFalconResponse<ICustomerOpeningMonthlyBalance>>(
            ROUTE_GET_STATEMENT_CLOSE_BALANCE,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode,
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    gkCode: gkCode,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
        return result.data;

    }

    const generateReports = async (body: IGenerateReportsRequest, token: string) => {
        try {
            const response = await axios.post(BASE_URL + ROUTE_EDM_GENERATE_REPORTS,
                body,
                {
                    headers: {
                        Authorization: BEARER + token
                    }
                }
            );
            return response.data;
        } catch (ex) {
            return null;
        }
    }

    return {
        getStatemetSales,
        getClientsStructure,
        getStatemetIncomes,
        getStatemetOpenBalance,
        getStatemetCloseBalance,
        generateReports,
    };
}
