import axios from "axios";
import { BASE_URL } from "../constants/routeConstants/ApiRouteConstants";

export function newAbortSignal(timeoutMs: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}




export const axiosInstance = axios.create({
  //baseURL: "https://lk.turne-trans.ru",
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*'
    //Authorization: "ae2b1fca515949e5d54fb22b8ed95575",
    // Host: "lk.turne-trans.ru",
    //Accept: AxiosHeaderValue;
    //"Content-Length": "",
    //"User-Agent": "",
    //"Content-Encoding": "",
  },
  withCredentials: true,

  //! turned off for testing
  //timeout: 10000,
  // responseType: "json", // default

  // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  //xsrfCookieName: "XSRF-TOKEN", // default

  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  //xsrfHeaderName: "X-XSRF-TOKEN", // default

  // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
  //maxContentLength: 2000,

  // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
  // maxBodyLength: 2000,

  //TODO
  // cancelToken: new CancelToken(function (cancel) {}),
});
