import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../../../constants/FieldsConstants";
import {UserRoles} from "../../../enums/UserRole";
import {ICustomerEmployee} from "../../../interfaces/reportInterfaces/customerInterfaces";
import {ICompany} from "../../../interfaces/companyInfoInterfaces/ICompany";
import {ICompanyRole, ICompanyUserRoles} from "../../../interfaces/requestsInterfaces/reportRequests";
import {CustomerApi} from "../../../api/CustomerApi";
import {AuthService} from "../../../services/AuthService";
import {EmployeesService} from "../../../services/EmployeesService";
import {EmployeeEditComponent} from "./employeeEditComponent";
import {EmployeeTableComponent} from "./employeesTableComponent";
import {EmployeeSearchComponent} from "./employeesSearchComponent";
import {MdModal} from "../../../components/md-modal";
import {EmployeeAddComponent} from "./employeeAddComponent";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {Guid} from "../../../utils/utils";
import {CompanySelectionModal} from "./companySelectionModal";



export function EmployeesPage() {
  const initialized = useRef(false);

  const [cookies] = useCookies([ACCESS_TOKEN]);
  const authState = useAppSelector((state) => state.auth);
  const reportState = useAppSelector((state) => state.reports);

  const dispatch = useAppDispatch();

  const {} = CustomerApi();

  const { getEmployees, addEmployee, updateEmployee, getRolesForEmployee } = EmployeesService();

  const {getUserCompanyRoles} = AuthService();

  const {open, close} = useContext(ModalContext);


  const [addEmployeeModalId] = useState(Guid.newGuid());
  const [editEmployeeModalId] = useState(Guid.newGuid());


  const [code, setCode] = useState("");

  const [employeeList, setEmployeeList] = useState<ICustomerEmployee[]>(reportState.customerEmployees);
  const [employeeListPending, setEmployeeListPending] = useState(false);
  const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<ICustomerEmployee[]>([]);

  const [addEmployeePending, setAddEmployeePending] = useState(false);
  const [addEmployeeError, setAddEmployeeError] = useState(false);

  const [employeeToEdit, setEmployeeToEdit] = useState<ICustomerEmployee>();
  const [editEmployeePending, setEditEmployeePending] = useState(false);
  const [editEmployeeError, setEditEmployeeError] = useState(false);

  const [companySelectionModalOpened, setCompanySelectionModalOpened] = useState<boolean>(false);

  const [companyRoles, setCompanyRoles] = useState<ICompanyUserRoles[]>([]);



  const searchEmployeesHandler = async () => {
    setEmployeeListPending(true);
    setSelectedEmployees([]);
    setEmployeeList([]);
    setIsSearchResultNotFound(false);
    const response = await getEmployees(code);
    if (response !== undefined && response.length > 0) {
      setEmployeeList(response);
    } else {
      setIsSearchResultNotFound(true);
    }
    setEmployeeListPending(false);
  };



  const openEmployeeAddModal = () => {
    open(addEmployeeModalId);
  }

  const onAddModalOpenedHandler = async () => {
    setAddEmployeeError(false);
  };

  const addEmployeeHandler = async (
      name: string,
      lastName: string,
      patronymic: string,
      position: string,
      roles: ICompanyRole[],
      dateOfBurn: string,
      email: string,
      gender: boolean
  ) => {
    setAddEmployeeError(false);
    setAddEmployeePending(true);
    const result = await addEmployee(name, lastName, patronymic, position, roles, dateOfBurn, email, gender);
    if(result != null) {
      setAddEmployeePending(false);
      closeEmployeeAddModal();
    }
    else {
      setAddEmployeePending(false);
      setAddEmployeeError(true);
      return;
    }
    await searchEmployeesHandler();
  };

  const onAddModalClosedHandler = async () => {
    setAddEmployeeError(false);
  };

  const closeEmployeeAddModal = () => {
    close(addEmployeeModalId);
  }




  const requestEditEmployee = async (employee: ICustomerEmployee) => {
    setCompanyRoles([]);

    setEmployeeToEdit(employee);

    let roles = await getUserCompanyRoles(employee.email);

    setCompanyRoles(roles);

    openEmployeeEditModal();
  }


  const openEmployeeEditModal = () => {
    open(editEmployeeModalId);
  }

  const onEditModalOpenedHandler = async () => {
    setEditEmployeeError(false);
  };

  const editEmployeeHandler = async (
      name: string,
      lastName: string,
      patronymic: string,
      position: string,
      roles: ICompanyRole[],
      dateOfBurn: string,
      email: string,
      gender: boolean
  ) => {
    setEditEmployeeError(false);
    setEditEmployeePending(true);
    const result = await updateEmployee(name, lastName, patronymic, position, roles, dateOfBurn, email, gender);
    if(result != null) {
      setEditEmployeePending(false);
      closeEmployeeEditModal();
    }
    else {
      setEditEmployeePending(false);
      setEditEmployeeError(true);
      return;
    }
    await searchEmployeesHandler();
  };

  const onEditModalClosedHandler = async () => {
    setEditEmployeeError(false);
    setEmployeeToEdit(undefined);
    setCompanyRoles([]);
  };

  const closeEmployeeEditModal = () => {
    close(editEmployeeModalId);
  }



  const openCompanySelectionModal = () => {
    setCompanySelectionModalOpened(true);
  }

  const addCompanyHandler = (company: ICompany) => {
    if(!companyRoles.some(x => x.clientCode == company.code || x.clientCode == company.clientCode)) {
      setCompanyRoles(prev => [
          ...prev.filter(x => (x.clientCode != company.code)),
          {
            clientCode: company.code ?? company.clientCode,
            companyFullName: company.fullName,
            roles: [UserRoles.AnonymousUser]
          }
        ]
      );
    }
  }

  const removeCompanyHandler = (companyCode: string) => {
    if(companyRoles.some(x => x.clientCode == companyCode)) {
      setCompanyRoles(prev => prev.filter(x => x.clientCode != companyCode));
    }
  }



  const addCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
    setCompanyRoles(prev => [
      ...prev.map(company => {
        return {
          clientCode: company.clientCode,
          companyFullName: company.companyFullName,
          roles: (company.clientCode == companyCode ? [...company.roles.filter(role => role != roleName), roleName] : company.roles)
        }
      })
    ]);

  }

  const removeCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
    setCompanyRoles(prev => [
      ...prev.map(company => {
        return {
          clientCode: company.clientCode,
          companyFullName: company.companyFullName,
          roles: (company.clientCode == companyCode ? company.roles.filter(role => role != roleName) : company.roles)
        }
      })
    ]);
  }


  useEffect(() => { }, []);


  return (
      <>
        <EmployeeSearchComponent
            code={code}
            setCode={setCode}
            searchHandler={searchEmployeesHandler}
        />

        <EmployeeTableComponent
            employees={employeeList}
            isLoading={employeeListPending}
            isSearchResultNotFound={isSearchResultNotFound}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            // selectEmployee={selectEmployee}
            // unselectEmployee={unselectEmployee}
            // selectAllEmployees={selectAllEmployees}
            // unselectAllEmployees={unselectAllEmployees}
            openEmployeeAddModal={openEmployeeAddModal}
            requestEditEmployee={requestEditEmployee}
        />

        <MdModal
            id={addEmployeeModalId}
            title="Новый сотрудник"
            openHandler={onAddModalOpenedHandler}
            closeHandler={onAddModalClosedHandler}>
            <EmployeeAddComponent
                pending={addEmployeePending}
                error={addEmployeeError}
                //companies={companies}
                companyRoles={companyRoles}
                addCompanyRoleHandler={addCompanyRoleHandler}
                removeCompanyHandler={removeCompanyHandler}
                removeCompanyRoleHandler={removeCompanyRoleHandler}
                openCompanySelectionModal={openCompanySelectionModal}
                addHandler={addEmployeeHandler}
            />
        </MdModal>

        {
          employeeToEdit != null && (
                <MdModal
                    id={editEmployeeModalId}
                    title="Редактирование сотрудника"
                    openHandler={onEditModalOpenedHandler}
                    closeHandler={onEditModalClosedHandler}>
                  <EmployeeEditComponent
                      employee={employeeToEdit}
                      pending={editEmployeePending}
                      error={editEmployeeError}
                      //companies={companies}
                      companyRoles={companyRoles}
                      addCompanyRoleHandler={addCompanyRoleHandler}
                      removeCompanyHandler={removeCompanyHandler}
                      removeCompanyRoleHandler={removeCompanyRoleHandler}
                      openCompanySelectionModal={openCompanySelectionModal}
                      submitHandler={editEmployeeHandler}
                  />
                </MdModal>
            )
        }

        <CompanySelectionModal
            opened={companySelectionModalOpened}
            setOpened={setCompanySelectionModalOpened}
            submitHandler={addCompanyHandler}
        />

      </>
  );
}
