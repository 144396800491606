import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {ICompanyInfo} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";
import {MdButton} from "../../../../components/md-button";
import "./style.scss";


export interface EmployeeSearchProps {
    code: string;
    setCode: (value: string) => void;
    searchHandler: () => void;
}

export function EmployeeSearchComponent(props: EmployeeSearchProps) {
    const {t} = useTranslation();

    const authState = useAppSelector(state => state.auth);
    const companyState = useAppSelector(state => state.companyInfo as ICompanyInfo);

    const [company, setCompany] = useState(companyState);


    const onCompanyChange = (company: ICompanyInfo) => {
        setCompany(company);
        props.setCode(company.code);
    }


    const searchEmployees = () => {
        props.searchHandler();
    };

    return (
        <>
            <div className="filters-container">
                {/*<div className="filters-caption">Фильтры</div>*/}
                {/*<div className="filters-description">Используйте фильтры для поиска нужного документа</div>*/}
                <form
                    className="filters-form"
                    onSubmit={e => {
                        e.preventDefault();
                        searchEmployees();
                    }}
                >
                    <div className="filters-form-fields-container">

                        {/*<div className="filters-form-fields-group">*/}
                        {/*    <MdMenu*/}
                        {/*        header={company.fullName}*/}
                        {/*        icon={*/}
                        {/*            <CompanyIcon*/}
                        {/*                width={"12px"}*/}
                        {/*                height={"12px"}*/}
                        {/*                style={{*/}
                        {/*                    "path": {*/}
                        {/*                        fill: "#777E90"*/}
                        {/*                    }*/}
                        {/*                }*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        }*/}
                        {/*        style={{*/}
                        {/*            "root": {*/}
                        {/*                marginTop: "6px"*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        onSelect={onCompanyChange}*/}
                        {/*    >*/}
                        {/*        {*/}
                        {/*            authState.gkCompanies.map((company, index) => {*/}
                        {/*                return (*/}
                        {/*                    <MdMenuItem key={index} value={company}*/}
                        {/*                                title={company.fullName}>*/}
                        {/*                        {company.fullName}*/}
                        {/*                    </MdMenuItem>*/}
                        {/*                )*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </MdMenu>*/}

                        {/*    <div style={{ flex: 1, display: "flex", justifyContent: "end"}}>*/}
                        {/*        <MdButton*/}
                        {/*            type="button"*/}
                        {/*            style={{*/}
                        {/*                "button": {*/}
                        {/*                    borderWidth: 0,*/}
                        {/*                    backgroundColor: "#3E5CB8",*/}
                        {/*                    color: "#FFFFFF",*/}
                        {/*                    borderRadius: "6px",*/}
                        {/*                    height: "35px",*/}
                        {/*                    padding: "5px 15px"*/}
                        {/*                },*/}
                        {/*                "ink": {*/}
                        {/*                    backgroundColor: "rgba(255,255,255,.5)"*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            onClick={searchEmployees}*/}
                        {/*        >*/}
                        {/*            {`${t("employeePage.search")}`}*/}
                        {/*        </MdButton>*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                        <div className="filters-form-fields-group">
                            <span className="filters-form-input-field">
                                <label>&nbsp;{`${t("employeePage.company")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("employeePage.code")}`}
                                    value={props.code}
                                    onChange={(e) => {
                                        props.setCode(e.target.value);
                                    }}
                                />
                            </span>
                                <MdButton
                                    type="button"
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={searchEmployees}
                                >
                                    {`${t("employeePage.search")}`}
                                </MdButton>
                            </div>

                    </div>

                </form>
            </div>
        </>
    );
}
