import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/hooks";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING
} from "../../constants/ServiceCostants";
import "./style.scss";


enum Pages {
    Account,
    Profile,
    Orders,
    Reports,
    Registers,
    Documents,
    Employees,
    //Statements
    Booking
}


export function CabinetLayout() {

    const {t} = useTranslation();

    const location = useLocation();

    const authState = useAppSelector(state => state.auth);


    const getActivePage = () => {
        const segments = location.pathname.split('/');

        if (location.pathname === "/cabinet") {
            return Pages.Account;
        }
        else if (segments.includes("cabinet") && segments.includes("profile")) {
            return Pages.Profile;
        }
        else if (segments.includes("cabinet") && segments.includes("orders")) {
            return Pages.Orders;
        }
        else if (segments.includes("cabinet") && segments.includes("reports")) {
            return Pages.Reports;
        }
        else if (segments.includes("cabinet") && segments.includes("registers")) {
            return Pages.Registers;
        }
        else if (segments.includes("cabinet") && segments.includes("documents")) {
            return Pages.Documents;
        }
        else if (segments.includes("cabinet") && segments.includes("employees")) {
            return Pages.Employees;
        }
        // else if (segments.includes("cabinet") && segments.includes("statements")) {
        //     return Pages.Statements;
        // }
        else if (segments.includes("cabinet") && segments.includes("booking")) {
            return Pages.Booking;
        }
    }

    const isDevelopmentEnv = () => {
        return !(CURRENT_REACT_ENVIROMENT === ENVIROMENT_STAGING || CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION)
    }


    return (
        <div className="cabinet-layout-container">
            <div className="sub-header">
                <div className="header-container"
                     style={{
                         justifyContent: "center",
                         flexWrap: "wrap",
                         gap: "5px"
                     }}>
                    <ul>
                        <li className={(getActivePage() === Pages.Account ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet">
                                {t("cabinetLeftMenu.account")}
                            </Link>
                        </li>

                        <li className={(getActivePage() === Pages.Profile ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/profile">
                                {t("cabinetLeftMenu.myProfile")}
                            </Link>
                        </li>

                        {
                            isDevelopmentEnv() && (
                                <li className={(getActivePage() === Pages.Orders ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/orders">
                                        {t("cabinetLeftMenu.orders")}
                                    </Link>
                                </li>
                            )
                        }

                        <li className={(getActivePage() === Pages.Reports ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/reports">
                                {t("cabinetLeftMenu.reports")}
                            </Link>
                        </li>

                        {
                            isDevelopmentEnv() && (
                                <li className={(getActivePage() === Pages.Registers ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/registers">
                                        {t("cabinetLeftMenu.registers")}
                                    </Link>
                                </li>
                            )
                        }

                        <li className={(getActivePage() === Pages.Documents ? "--active" : "")}>
                            <Link className="sub-header-item" to="/cabinet/documents">
                                {t("cabinetLeftMenu.accDocuments")}
                            </Link>
                        </li>

                        {
                            (isDevelopmentEnv()) && (
                                <li className={(getActivePage() === Pages.Employees ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/employees">
                                        {t("cabinetLeftMenu.employees")}
                                    </Link>
                                </li>
                            )
                        }

                        {/*{*/}
                        {/*    isDevelopmentEnv() && (*/}
                        {/*        <li className={(getActivePage() === Pages.Statements ? "--active" : "")}>*/}
                        {/*            <Link className="sub-header-item" to="/cabinet/statements">*/}
                        {/*                /!*{t("cabinetLeftMenu.statements")}*!/*/}
                        {/*                Выписки*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*    )*/}
                        {/*}*/}

                        {
                            (CURRENT_REACT_ENVIROMENT !== ENVIROMENT_PRODUCTION) && (
                                <li className={(getActivePage() === Pages.Booking ? "--active" : "")}>
                                    <Link className="sub-header-item" to="/cabinet/booking">
                                        {/*{t("cabinetLeftMenu.statements")}*/}
                                        Букинг
                                    </Link>
                                </li>
                            )
                        }
                    </ul>


                    {/*<Link className="sub-header-item" to="/cabinet/MyProfile">*/}
                    {/*    Лицевой счет*/}
                    {/*</Link>*/}
                    {/*<span className="sub-header-item">*/}
                    {/*    <MdSelect*/}
                    {/*        label=""*/}
                    {/*        value={activePage}*/}
                    {/*        setValue={setActivePage}*/}
                    {/*        items={cabinetPages} />*/}
                    {/*</span>*/}
                    {/*<span className="sub-header-item">Лицевой счет</span>*/}
                </div>
            </div>

            <div className="cabinet-view-container">
                <div className="cabinet-view-content">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}