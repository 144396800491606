import { DEFAULT_ICON_SIZE } from "../components/const/componentsContants";
import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";

export function PlaneIcon({ iconSize }: IIconProps) {
  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height={iconSize}
        width={iconSize}
      >
        <path d="M3 18h18v2H3zm18.509-9.473a1.61 1.61 0 00-2.036-1.019L15 9 7 6 5 7l6 4-4 2-4-2-1 1 4 4 14.547-5.455a1.611 1.611 0 00.962-2.018z" />
      </svg>
    </>
  );
}
