import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const MeetingIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 48 48"
            style={style["svg"]}>
                <path
                    d="M18 27C18 29.7625 15.7625 32 13 32C10.2375 32 8 29.7625 8 27C8 24.2375 10.2375 22 13 22C15.7625 22 18 24.2375 18 27Z"
                    style={style["path"]}
                />
                <path
                    d="M3 39.3333C3 35.7867 9.6625 34 13 34C16.3375 34 23 35.7867 23 39.3333V42H3V39.3333Z"
                    style={style["path"]}
                />
                <path
                    d="M40 27C40 29.7625 37.7625 32 35 32C32.2375 32 30 29.7625 30 27C30 24.2375 32.2375 22 35 22C37.7625 22 40 24.2375 40 27Z"
                    style={style["path"]}
                />
                <path
                    d="M25 39.3333C25 35.7867 31.6625 34 35 34C38.3375 34 45 35.7867 45 39.3333V42H25V39.3333Z"
                    style={style["path"]}
                />
                <path
                    d="M18.4904 18C23.7371 18 27.9904 14.6421 27.9904 10.5C27.9904 6.35786 23.7371 3 18.4904 3C13.2437 3 8.99041 6.35786 8.99041 10.5C8.99041 11.5062 9.24139 12.4661 9.69661 13.3428C8.69115 14.5752 8.4449 16.4411 8.39704 17.6139C8.37962 18.0407 8.89496 18.259 9.23713 18.0033C9.92338 17.4904 11.0133 16.7948 12.4291 16.2753C14.0739 17.3525 16.1864 18 18.4904 18Z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    style={style["path"]}
                />
                <path
                    d="M25.7637 17.8655C28.3491 16.1235 30 13.471 30 10.5C30 9.70071 29.8805 8.92447 29.6555 8.18281C30.2456 8.06343 30.8635 8 31.5 8C35.6421 8 39 10.6863 39 14C39 14.9484 38.725 15.8453 38.2352 16.6427C38.6977 17.4261 38.8621 18.4084 38.9155 19.1475C38.9462 19.5735 38.4306 19.7969 38.0805 19.5521C37.6472 19.2491 37.0571 18.8929 36.3328 18.5885C35.0275 19.4692 33.3412 20 31.5 20C29.1987 20 27.1395 19.1708 25.7637 17.8655Z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    style={style["path"]}
                />

        </svg>
    )
}