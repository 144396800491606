import { CompanyApi } from "../api/CompanyApi"
import {
    companyInfoState,
    getClientDetailsThunk,
    getClientStructureExchangeThunk,
    getClientStructureRefoundThunk,
    getClientStructureSalesThunk,
    getClientsSalesThunk,
    searchAllowedPepsonsThunk,
    searchClientsThunk,
    setEndCompanyInfoDate,
    setStartCompanyInfoDate
} from "../redux/CompanyInfoSlice";
import {
    setCurrentClientCode,
    setGkClientCodes,
    setGkCode,
    setGkCompanies
} from "../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
//import { useAppSelector } from "../redux/hooks";
import { useCookies } from "react-cookie";
import { ACCESS_TOKEN } from "../constants/FieldsConstants";
import {
    IClientStructureRequest,
    ICompanyInfoRequest,
    ICompanyRequest, ISearchCompanyRequest
} from '../interfaces/requestsInterfaces/reportRequests';
import { DateService } from "./DateService";
import { DATE_FORMAT_YYYYMMDD, PERIOD_CURRENT_MONTH, PERIOD_CURRENT_QUARTER, PERIOD_LAST_MONTH, PERIOD_LAST_QUARTER, PERIOD_LAST_TWELVE_MONTH } from '../constants/ServiceCostants';
import { start } from "repl";
import {resetReports} from "../redux/reportsSlice";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompany";



export function CompanyService() {

    const { searchClient, searchClientByName, getCompany, addCompany, getAllCompanies, searchcompany, importCompany } = CompanyApi();
    const { dateToStringForDatePicker, dateToCustomFormat } = DateService();
    const [cookies] = useCookies([ACCESS_TOKEN]);
    const authState = useAppSelector((state) => state.auth);
    const companyState = useAppSelector((state) => state.companyInfo);
    const dispatch = useAppDispatch();

    const getClientListForTtsAdmin = async (nameLike: string) => {
        const responce = await searchClientByName({
            nameLike: nameLike,
            token: cookies[ACCESS_TOKEN]
        })

        if (responce.media.length > 0) {
            return responce.media;
        }

        return [];
    }

    const getDatePeriodClientInfo = (period?: string): [Date, Date] => {

        period = period ?? companyState.companyStatisticPeriod
        const currentDate = new Date();
        const quarterNum = Math.trunc(currentDate.getMonth() / 3);
        let startPeriodDate: string = "";
        let endPeriodDate: string = "";
        let startDate: Date = new Date()
        let endDate: Date = new Date()

        switch (period) {

            case PERIOD_LAST_MONTH:

                if (currentDate.getMonth() === 0) {
                    startDate = new Date(currentDate.getFullYear() - 1, 12, 1);
                }
                else {

                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
                }
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                break;

            case PERIOD_CURRENT_MONTH:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                endDate = currentDate;
                break;

            case PERIOD_CURRENT_QUARTER:

                startDate = new Date(currentDate.getFullYear(),
                    quarterNum * 3, 1);
                endDate = currentDate;
                break;

            case PERIOD_LAST_QUARTER:

                startDate = new Date(quarterNum === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear(),
                    quarterNum === 0 ? 9 : (quarterNum - 1) * 3, 1);
                endDate = new Date(currentDate.getFullYear(),
                    quarterNum * 3, 1);
                break;

            case PERIOD_LAST_TWELVE_MONTH:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                endDate = currentDate;
                break;
        }
        // dispatch(setStartCompanyInfoDate(dateToStringForDatePicker(startDate)))
        // dispatch(setEndCompanyInfoDate(dateToStringForDatePicker(endDate)))
        return [startDate, endDate];
    };

    const getClientInfoInBackground = async (clientCodes: string[], currentClientCode: string, token: string, period?: string) => {
        //const currentClientCode = authstate.clientCode;
        const datePeriod = getDatePeriodClientInfo(period);
        //dispatch(setStartCompanyInfoDate(datePeriod[0]))
        // dispatch(setEndCompanyInfoDate(datePeriod[1]))

        let companyInfoRequest: ICompanyInfoRequest = {
            clientCode: currentClientCode,
            token: token
        }

        let clientStructureRequest: IClientStructureRequest = {
            clientCode: currentClientCode,
            status: 1,
            monthNum: 0,
            quarter: 0,
            year: 0,
            dateStart: dateToCustomFormat(datePeriod[0], DATE_FORMAT_YYYYMMDD),
            dateEnd: dateToCustomFormat(datePeriod[1], DATE_FORMAT_YYYYMMDD),
            //dateStart: "20240505",
            // dateEnd: "20240604",
            token: token
        }

        if (currentClientCode !== undefined) {
            dispatch(searchClientsThunk(companyInfoRequest));
            dispatch(getClientDetailsThunk({
                clientCode: currentClientCode,
                dateStart: dateToCustomFormat(datePeriod[0], DATE_FORMAT_YYYYMMDD),
                token: token
            }));

            dispatch(searchAllowedPepsonsThunk(companyInfoRequest))
            //status 1 Sales
            dispatch(getClientStructureSalesThunk(clientStructureRequest))
            //status 2 exchanges
            clientStructureRequest.status = 2
            dispatch(getClientStructureExchangeThunk(clientStructureRequest))
            //status 33 refounds
            clientStructureRequest.status = 3
            dispatch(getClientStructureRefoundThunk(clientStructureRequest))

            dispatch(getClientsSalesThunk(companyInfoRequest))

            const promises = clientCodes.map(clientCode => searchClient({
                clientCode: clientCode,
                token: token
            }).then((response) =>
            {
                return response.media[0]
            }));
            Promise.all(promises).then(companies => {
                dispatch(setGkCompanies(companies));
            });
        }
        return true;
    }

    const getCompaniesInfoToCompanyGroup = async (clientCodes: string[]) => {

        const token = cookies[ACCESS_TOKEN];

        const promises = clientCodes.map((clientCode) => searchClient({ clientCode: clientCode, token: token }).then((response) => response.media[0]))
        const companies = await Promise.all(promises);
        dispatch(setGkCompanies(companies));
    }

    const makeActiveCompany = async (clientCode: string) => {
        dispatch(setCurrentClientCode(clientCode));

        await getClientInfoInBackground(authState.gkClientCodes, clientCode, authState.accessToken);

        const gkCode = authState.gkCompanies.find((c) => c.code === clientCode)?.gkCode;

        if (gkCode !== undefined) {
            dispatch(setGkCode(gkCode));

            const clientCodes = authState.gkCompanies
                .filter((c) => c.gkCode === authState.currentGkCode)
                .map((c) => c.code);
            dispatch(setGkClientCodes(clientCodes));
        }

        dispatch(resetReports());
    }


    const importNewCompany = async (company: ICompany) => {
        try {
            const token = cookies[ACCESS_TOKEN];

            const response = await importCompany(company, token);

            return response;
        }
        catch (ex) {
            return null;
        }
    }


    const addNewCompany = async (company: ICompany) => {
        try {
            const token = cookies[ACCESS_TOKEN];

            const response = await addCompany(company, token);

            return response;
        }
        catch (ex) {
            return null;
        }
    }


    const searchCompanyByName = async (name: string) => {
        try {
            let companyRequest: ISearchCompanyRequest = {
                name: name,
                token: cookies[ACCESS_TOKEN]
            }

            const response = await searchcompany(companyRequest);

            return response;
        }
        catch (ex) {
            return null;
        }
    }


    const getCompanyByCode = async (clientCode: string) => {
        try {
            let companyRequest: ICompanyRequest = {
                code: clientCode,
                token: cookies[ACCESS_TOKEN]
            }

            const response = await getCompany(companyRequest);

            return response;
        }
        catch (ex) {
            return null;
        }
    }

    const getCompaniesList = async () => {
        try {
            const response = await getAllCompanies(cookies[ACCESS_TOKEN]);
            if(response != null) {
                return response.data;
            }
            return null;
        }
        catch (ex) {
            return null;
        }
    }

    const removeCompanyInfo = () => { }

    return {
        getClientInfoInBackground,
        getCompaniesInfoToCompanyGroup,
        getClientListForTtsAdmin,
        getDatePeriodClientInfo,
        makeActiveCompany,
        getCompanyByCode,
        addNewCompany,
        importNewCompany,
        getCompaniesList,
        searchCompanyByName
    }
}
