import axios from "axios";
import {
    BASE_ROUTE_AUTH_SERVICE,
    BEARER, ROUTE_GET_RECONCILIATION,
    ROUTE_GET_REGISTERS, ROUTE_POST_REGISTERS_TO_EMAIL_BY_EDM,
} from "../constants/routeConstants/ApiRouteConstants";


export function RegisterApi() {

    const getReconciliationAct = async (clientCode: string, startDate: string, endDate: string, connectionId: string | null) => {
        const result = await axios.get<Array<string>>(
            BASE_ROUTE_AUTH_SERVICE + ROUTE_GET_RECONCILIATION,
            {
                params: {
                    clientCode,
                    startDate,
                    endDate,
                    connectionId
                }
            }
        );
        return result;
    };

    const getCustomerRegisters = async () => {
        //const result = await axiosInstance.get<Array<string>>(
        const result = await axios.get<Array<string>>(
            BASE_ROUTE_AUTH_SERVICE + ROUTE_GET_REGISTERS,
            //ROUTE_GET_REGISTERS,
        );
        return result;
    };

    const sendRegisterToEdm = async (reestrName: string, dateStart: string, dateEnd: string, clientCode: string, emails: string[], isTest: boolean, token: string) => {
        const response = await axios.post<boolean>(
            BASE_ROUTE_AUTH_SERVICE + ROUTE_POST_REGISTERS_TO_EMAIL_BY_EDM,
            {
                ReestrName: reestrName,
                DateStart: dateStart,
                DateEnd: dateEnd,
                ClientCode: clientCode,
                Emails: emails,
                IsTest: isTest,
            }, {
                headers: {
                    Authorization: BEARER + token,
                }
            }
        );
        return response.data
    }

    return {
        getReconciliationAct,
        getCustomerRegisters,
        sendRegisterToEdm,
    };
}