import { DEFAULT_ICON_SIZE } from "../components/const/componentsContants";
import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";

export function CheckPaymentIcon({ iconSize }: IIconProps) {
  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
    <svg
      fill="#000000"
      height={iconSize}
      width={iconSize}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <g>
            <path
              d="M97.28,269.454v38.533H76.268c-7.068,0-12.8,5.731-12.8,12.8c0,7.069,5.732,12.8,12.8,12.8H97.28v9.494
				c0,7.069,5.732,12.8,12.8,12.8c7.068,0,12.8-5.731,12.8-12.8v-10.46c24.818-3.843,41.244-18.936,41.244-39.329v-8.174
				c0-20.393-16.426-35.488-41.244-39.331v-38.533h21.012c7.068,0,12.8-5.731,12.8-12.8c0-7.069-5.732-12.8-12.8-12.8H122.88v-9.494
				c0-7.069-5.732-12.8-12.8-12.8c-7.068,0-12.8,5.731-12.8,12.8v10.523c-24.517,4.027-41.244,19.615-41.244,40.01v7.432
				C56.036,250.516,72.462,265.612,97.28,269.454z M122.88,271.871c8.85,2.134,15.644,6.597,15.644,13.245v8.174
				c0,6.647-6.794,11.11-15.644,13.244V271.871z M81.636,222.693c0-6.87,6.879-11.572,15.644-13.853v34.528
				c-8.85-2.134-15.644-6.597-15.644-13.244V222.693z"
            />
            <path
              d="M499.2,115.2H12.8C5.732,115.2,0,120.931,0,128v256c0,7.069,5.732,12.8,12.8,12.8h486.4c7.068,0,12.8-5.731,12.8-12.8
				V128C512,120.931,506.268,115.2,499.2,115.2z M486.4,371.2H25.6V140.8h460.8V371.2z"
            />
            <path
              d="M218.24,215.68h131.84c7.068,0,12.8-5.731,12.8-12.8c0-7.069-5.732-12.8-12.8-12.8H218.24c-7.068,0-12.8,5.731-12.8,12.8
				C205.44,209.949,211.172,215.68,218.24,215.68z"
            />
            <path
              d="M218.24,266.88H438.4c7.068,0,12.8-5.731,12.8-12.8c0-7.069-5.732-12.8-12.8-12.8H218.24c-7.068,0-12.8,5.731-12.8,12.8
				C205.44,261.149,211.172,266.88,218.24,266.88z"
            />
            <path
              d="M438.4,292.48h-74.24c-7.068,0-12.8,5.731-12.8,12.8c0,7.069,5.732,12.8,12.8,12.8h74.24c7.068,0,12.8-5.731,12.8-12.8
				C451.2,298.211,445.468,292.48,438.4,292.48z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
