import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IAviaRejsInfo, IAviaTariffInfo} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {MdButton} from "../../../../components/md-button";
import {MdList} from "../../../../components/md-list";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {MdSlider} from "../../../../components/md-slider";
import {IPassenger} from "../../../../interfaces/IPassenger";
import {PlusIcon} from "../../../../icons/PlusIcon";
import {MinusIcon} from "../../../../icons/MinusIcon";
import "./style.scss"
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {MdAlert} from "../../../../components/md-alert";
import {RefreshIcon} from "../../../../icons/RefreshIcon";
import {MdAccordion} from "../../../../components/md-accordion";
import {MagnifierIcon} from "../../../../icons/MagnifierIcon";


interface IBookingProps {
    ticket: IAviaRejsInfo;
    isLoadingPassengers: boolean;
    reloadPassengers: () => void;
    passengers: IPassenger[];
    selectedPassengers: IPassenger[];
    setSelectedPassengers: React.Dispatch<React.SetStateAction<IPassenger[]>>;
    bookHandler: (ticket: IAviaRejsInfo, tariff: IAviaTariffInfo, passengers: IPassenger[]) => void;
    isBookingPending: boolean;
    error: boolean;
}


export function BookingComponent(props: IBookingProps) {

    const {
        ticket,
        isLoadingPassengers,
        reloadPassengers,
        passengers,
        selectedPassengers,
        setSelectedPassengers,
        bookHandler,
        isBookingPending,
        error
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);


    const dateArrival = new Date(ticket?.dateArr ?? "");
    const dateDepartment = new Date(ticket?.dateDep ?? "");

    const [selectedTariff, setSelectedTariff] = useState<IAviaTariffInfo|undefined>(ticket?.tarifs[0]);

    const [activeSlide, setActiveSlide] = useState(0);

    const [availablePassengers, setAvailablePassengers] = useState(passengers);
    //const availablePassengers = passengers.filter(x => !selectedPassengers.some(y => y.id == x.id));

    const [availablePassengersSearch, setAvailablePassengersSearch] = useState("");
    const [filteredAvailablePassengers, setFilteredAvailablePassengers] = useState(availablePassengers);

    const [selectedPassengersSearch, setSelectedPassengersSearch] = useState("");
    const [filteredSelectedPassengers, setFilteredSelectedPassengers] = useState(selectedPassengers);


    const onSubmit = () => {
        if(ticket != null && selectedTariff != null && selectedPassengers.length > 0) {
            bookHandler(ticket, selectedTariff, selectedPassengers);
        }
    }


    useEffect(() => {
        setAvailablePassengers(passengers);
    }, [passengers]);


    useEffect(() => {
        setFilteredAvailablePassengers(availablePassengers.filter(x => x.fio.toUpperCase().includes(availablePassengersSearch.toUpperCase())))
    }, [availablePassengers, availablePassengersSearch]);


    useEffect(() => {
        setAvailablePassengers(passengers.filter(x => !selectedPassengers.some(y => y.id == x.id)));
        setFilteredSelectedPassengers(selectedPassengers.filter(x => x.fio.toUpperCase().includes(selectedPassengersSearch.toUpperCase())))
    }, [selectedPassengers, selectedPassengersSearch]);


    useEffect(() => {

    }, []);


    return (
        <MdSlider
            header={
                <div
                    className="ticket-summary-content"
                    style={{ padding: "10px 5px 0 5px"}}>
                    <div className="arrival-info">
                        <div className="arrival-airport-code">{ticket?.airCodeDep}</div>
                        <div
                            className="arrival-airport-city">{ticket?.cityDep.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                        <div className="arrival-time">
                            {
                                dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + dateDepartment.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + dateDepartment.getDay().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                    <div className="flight-path-info">
                        <div className="flight-track">
                            <hr style={{marginTop: "16px "}}/>
                            <div className="flight-track-airplane">
                                <AirplaneIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#3E5CB8"
                                        }
                                    }}
                                />
                            </div>
                            <hr style={{marginTop: "16px "}}/>
                        </div>
                        <div className="flight-time">
                            {ticket?.flightNumber}
                        </div>
                    </div>
                    <div className="department-info">
                        <div className="department-airport-code">{ticket?.airCodeArr}</div>
                        <div
                            className="department-airport-city">{ticket?.cityArr.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                        <div
                            className="department-time">
                            {
                                dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + dateArrival.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + dateArrival.getDay().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                </div>
            }
            slides={[
                {
                    content: (
                        <div className="tariffs-form">
                            <span className="booking-form-title">Выбор тарифа</span>

                            {
                                ticket != null && ticket.tarifs.length > 0
                                    ? (
                                        <MdList
                                            itemSource={ticket.tarifs}
                                            activeItem={(selectedTariff ?? ticket?.tarifs[0])}
                                            itemTemplate={(data: IAviaTariffInfo) => {
                                                return (
                                                    <>
                                                        <div className="tariff-icon">

                                                        </div>
                                                        <div className="tariff-details">
                                                            <span>{data.name}</span>
                                                            <span className="tariff-options">
                                                                {
                                                                    <>
                                                                        <span style={{
                                                                            textDecoration: (data.bagaj == "NotExist" ? "line-through" : "none"),
                                                                            color: (data.bagaj == "NotExist" ? "#9B9B9B" : "#74B100")
                                                                        }}>
                                                                            Багаж
                                                                        </span>

                                                                        <span style={{
                                                                            textDecoration: (data.bonusMili == "" ? "line-through" : "none"),
                                                                            color: (data.bonusMili == "" ? "#9B9B9B" : "#74B100")
                                                                        }}>
                                                                            Бонус-мили
                                                                        </span>
                                                                    </>

                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="tariff-indicator">
                                                            <i></i>
                                                        </div>
                                                    </>
                                                )
                                            }}
                                            style={{
                                                list: Object.assign({
                                                    "--selected-item-background": "rgba(62, 92, 184, 0.08)",
                                                    "--selected-item-font-color": "var(--item-font-color)",
                                                    "--selected-item-highlighted-background": "rgba(62, 92, 184, 0.12)",
                                                }, {
                                                    maxHeight: "176px",
                                                    border: "none"
                                                }),
                                                "list-item": {
                                                    margin: "2px",
                                                    borderRadius: "6px",
                                                    borderWidth: "1px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "5px"
                                                }
                                            }}
                                            onItemSelect={setSelectedTariff}
                                        />
                                    )
                                    : (
                                        <span className="no-companies-message">Нет данных для отображения</span>
                                    )
                            }

                            {(error) && (
                                <div className="error-message">
                                    не удалось забронировать билет, попробуйте повторить запрос позже.
                                </div>
                            )}

                        </div>
                    )
                },
                {
                    content: (
                        <div className="passengers-form">
                            <span className="passengers-form-title">Выбор пассажиров</span>

                            <div className="passengers-form-content">

                                {
                                    passengers.length > 0 && !isLoadingPassengers && (
                                        <MdAccordion
                                            mode="single-open"
                                            sections={[
                                                {
                                                    isOpen: true,
                                                    header: (
                                                        <span style={{ color: "rgb(62, 92, 184)"}}>
                                                            {
                                                                "Доступные пассажиры (" + availablePassengers.length + ")"
                                                            }
                                                        </span>
                                                    ),
                                                    content: (
                                                        <div
                                                            className="passengers-list-conatiner"
                                                            style={{
                                                                minHeight: "90px"
                                                            }}
                                                        >
                                                            {
                                                                availablePassengers.length > 0 && (
                                                                    <div className="passengers-search-bar">
                                                                        <MagnifierIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                            style={{
                                                                                "path": {
                                                                                    fill: "#CCCCCC"
                                                                                }
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            placeholder="поиск"
                                                                            onChange={(e) => setAvailablePassengersSearch(e.target.value)}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                availablePassengers.length > 0 && filteredAvailablePassengers.length > 0
                                                                    ? (
                                                                        <MdList
                                                                            itemSource={filteredAvailablePassengers}
                                                                            itemTemplate={(passenger: IPassenger) => {
                                                                                return (
                                                                                    <div className="passengers-list-item">
                                                                                        <div
                                                                                            className="passengers-list-item-add-button">
                                                                                            <MdButton
                                                                                                icon={
                                                                                                    <PlusIcon
                                                                                                        width="14px"
                                                                                                        height="14px"
                                                                                                        style={{
                                                                                                            "path": {
                                                                                                                stroke: "#989898"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                style={{
                                                                                                    "button": {
                                                                                                        justifyContent: "center",
                                                                                                        borderWidth: 0,
                                                                                                        backgroundColor: "transparent",
                                                                                                        color: "rgb(0 0 0)",
                                                                                                        borderRadius: "6px",
                                                                                                        width: "24px",
                                                                                                        height: "24px",
                                                                                                        padding: 0
                                                                                                    },
                                                                                                    "icon": {
                                                                                                        marginLeft: 0,
                                                                                                        marginRight: 0,
                                                                                                    },
                                                                                                    "ink": {
                                                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                                    },
                                                                                                    "text": {
                                                                                                        display: "none"
                                                                                                    }
                                                                                                }}
                                                                                                onClick={() => setSelectedPassengers(prev => [...prev.filter(x => x.id != passenger.id), passenger])}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="passengers-list-item-name">
                                                                                            {passenger.fio}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                            style={{
                                                                                "root": {
                                                                                    marginTop: 0,
                                                                                    flex: 1
                                                                                },
                                                                                "list":
                                                                                    Object.assign({
                                                                                        "--item-highlighted-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-background": "transparent",
                                                                                        "--selected-item-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-highlighted-background": "var(--item-highlighted-background)",
                                                                                        "--selected-item-highlighted-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-font-color": "#000000",
                                                                                        "--selected-item-box-shadow": "none"
                                                                                    }, {
                                                                                        width: "100%",
                                                                                        maxWidth: "100%",
                                                                                        maxHeight: "115px",
                                                                                        borderTopLeftRadius: 0,
                                                                                        borderTopRightRadius: 0,
                                                                                        borderTopWidth: 0,
                                                                                        borderBottomWidth: 0,
                                                                                    }),
                                                                                "list-item": {
                                                                                    padding: "2px 8px"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <div className="no-passengers-message">
                                                                            Нет данных для отображения
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    )
                                                },
                                                {
                                                    header: (
                                                        <span style={{ color: "rgb(62, 92, 184)"}}>
                                                            {
                                                                "Выбранные пассажиры (" + selectedPassengers.length + ")"
                                                            }
                                                        </span>
                                                    ),
                                                    content: (
                                                        <div
                                                            className="passengers-list-conatiner"
                                                            style={{
                                                                minHeight: "90px"
                                                            }}
                                                        >
                                                            {
                                                                selectedPassengers.length > 0 && (
                                                                    <div className="passengers-search-bar">
                                                                        <MagnifierIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                            style={{
                                                                                "path": {
                                                                                    fill: "#CCCCCC"
                                                                                }
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            placeholder="поиск"
                                                                            onChange={(e) => setSelectedPassengersSearch(e.target.value)}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                selectedPassengers.length > 0 && filteredSelectedPassengers.length > 0
                                                                    ? (
                                                                        <MdList
                                                                            itemSource={filteredSelectedPassengers}
                                                                            itemTemplate={(passenger: IPassenger) => {
                                                                                return (
                                                                                    <div className="passengers-list-item">
                                                                                        <div
                                                                                            className="passengers-list-item-add-button">
                                                                                            <MdButton
                                                                                                icon={
                                                                                                    <MinusIcon
                                                                                                        width="14px"
                                                                                                        height="14px"
                                                                                                        style={{
                                                                                                            "path": {
                                                                                                                stroke: "#989898"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                style={{
                                                                                                    "button": {
                                                                                                        justifyContent: "center",
                                                                                                        borderWidth: 0,
                                                                                                        backgroundColor: "transparent",
                                                                                                        color: "rgb(0 0 0)",
                                                                                                        borderRadius: "6px",
                                                                                                        width: "24px",
                                                                                                        height: "24px",
                                                                                                        padding: 0
                                                                                                    },
                                                                                                    "icon": {
                                                                                                        marginLeft: 0,
                                                                                                        marginRight: 0,
                                                                                                    },
                                                                                                    "ink": {
                                                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                                    },
                                                                                                    "text": {
                                                                                                        display: "none"
                                                                                                    }
                                                                                                }}
                                                                                                onClick={() => setSelectedPassengers(prev => prev.filter(x => x.id != passenger.id))}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="passengers-list-item-name">
                                                                                            {passenger.fio}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                            style={{
                                                                                "root": {
                                                                                    marginTop: 0,
                                                                                    flex: 1
                                                                                },
                                                                                "list":
                                                                                    Object.assign({
                                                                                        "--item-highlighted-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-background": "transparent",
                                                                                        "--selected-item-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-highlighted-background": "var(--item-highlighted-background)",
                                                                                        "--selected-item-highlighted-border-color": "var(--item-border-color)",
                                                                                        "--selected-item-font-color": "#000000",
                                                                                        "--selected-item-box-shadow": "none"
                                                                                    }, {
                                                                                        width: "100%",
                                                                                        maxWidth: "100%",
                                                                                        maxHeight: "116px",
                                                                                        borderTopLeftRadius: 0,
                                                                                        borderTopRightRadius: 0,
                                                                                        borderTopWidth: 0,
                                                                                        borderBottomWidth: 0,
                                                                                    }),
                                                                                "list-item": {
                                                                                    padding: "2px 8px"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <div className="no-passengers-message">
                                                                            Нет данных для отображения
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            ]}
                                            style={{
                                                "root": {
                                                    marginTop: "10px"
                                                }
                                            }}
                                        />
                                    )
                                }

                                {
                                    isLoadingPassengers && (
                                        <div className="loading-passengers-message">
                                            <CircleSpinnerIcon
                                                width="20px"
                                                height="20px"
                                                style={{
                                                    "circle": {
                                                        stroke: "#A0A1A6"
                                                    }
                                                }}
                                            />
                                            Загружаем пассажиров..
                                        </div>
                                    )
                                }

                                {
                                    !isLoadingPassengers && passengers.length == 0 && (
                                        <MdAlert
                                            type="warning"
                                            message="Отсутствуют данные по пассажирам"
                                            button={
                                                <MdButton
                                                    icon={
                                                        <RefreshIcon
                                                            width="12px"
                                                            height="12px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#856404"
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    tooltip="Повторить запрос"
                                                    style={{
                                                        "button": {
                                                            justifyContent: "center",
                                                            borderWidth: 0,
                                                            backgroundColor: "transparent",
                                                            color: "rgb(0 0 0)",
                                                            borderRadius: "6px",
                                                            width: "24px",
                                                            height: "24px",
                                                            padding: 0
                                                        },
                                                        "icon": {
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                        },
                                                        "text": {
                                                            display: "none"
                                                        }
                                                    }}
                                                    onClick={() => reloadPassengers()}
                                                />
                                            }
                                            style={{
                                                "root": {
                                                    marginTop: "10px"
                                                }
                                            }}
                                        />
                                    )
                                }

                            </div>

                        </div>
                    )
                },
                {
                    content: (
                        <div className="confirmation-form">
                            <span className="confirmation-form-title">Подтверждение</span>

                            <span className="confirmation-form-title"
                                  style={{marginTop: "10px", fontSize: "12px"}}>Тариф</span>

                            <div className="selected-tariff">
                                <div className="tariff-icon">

                                </div>
                                <div className="tariff-details">
                                    <span>{selectedTariff?.name}</span>
                                    <span className="tariff-options">
                                        {
                                            <>
                                                <span style={{
                                                    textDecoration: (selectedTariff?.bagaj == "NotExist" ? "line-through" : "none"),
                                                    color: (selectedTariff?.bagaj == "NotExist" ? "#9B9B9B" : "#74B100")
                                                }}>
                                                    Багаж
                                                </span>

                                                <span style={{
                                                    textDecoration: (selectedTariff?.bonusMili == "" ? "line-through" : "none"),
                                                    color: (selectedTariff?.bonusMili == "" ? "#9B9B9B" : "#74B100")
                                                }}>
                                                    Бонус-мили
                                                </span>
                                            </>

                                        }
                                    </span>
                                </div>
                                <div className="tariff-indicator">
                                    <i></i>
                                </div>
                            </div>

                            <span className="confirmation-form-title"
                                  style={{marginTop: "10px", fontSize: "12px"}}>Пассажиры</span>
                            <div className="confirmation-form-content">
                                {
                                    selectedPassengers.length > 0 && (
                                        <div className="selected-passengers-list">
                                            {
                                                selectedPassengers.map((passenger, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="selected-passenger" style={{height: "24px"}}>
                                                            <div className="selected-passenger-name">
                                                                {passenger.fio}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                },
            ]}
            footer={
                <div className="booking-form-footer">
                    <div>
                        {
                            activeSlide > 0 && (
                                <MdButton
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "icon": {
                                            marginRight: "5px",
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                >
                                    Назад
                                </MdButton>
                            )
                        }
                    </div>

                    <div>
                        {
                            activeSlide < 2 && (
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(selectedTariff == null || (activeSlide > 0 && selectedPassengers.length == 0))}
                                    onClick={()=> setActiveSlide(prev => Math.min(2, prev + 1))}
                                >
                                    Далее
                                </MdButton>
                            )
                        }
                        {
                            activeSlide == 2 && (

                                isBookingPending
                                ? (
                                    <CircleSpinnerIcon
                                        width="24px"
                                        height="24px"
                                    />
                                )
                                : (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                width: "200px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        disabled={(selectedTariff == null || selectedPassengers.length == 0)}
                                        onClick={onSubmit}
                                    >
                                        Забронировать
                                    </MdButton>
                                )
                            )
                        }
                    </div>
                </div>
            }
            activeSlide={activeSlide}
            style={{
                "root": {
                    width: "400px",
                    height: "500px",
                    padding: "10px 20px 20px 20px"
                }
            }}
        />
    );
}
