import {ITravelVariant} from "../interfaces/orderInterfaces/ITravelVariant";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface OrderState {
    travelVariants:ITravelVariant[]
    filteredVariants: ITravelVariant[];
    airlines: string[];
    trains: string[];
}

const initialState: OrderState = {
    travelVariants:[],
    filteredVariants: new Array<ITravelVariant>(),
    airlines: new Array<string>(),
    trains:new Array<string>(),
}

export const orderSlice = createSlice(
    {
        name: "order",
        initialState,
        reducers: {
            setTravelVariants: (state, action: PayloadAction<ITravelVariant[]>) => {
                state.filteredVariants = action.payload;
            },
            setFilteredVariants: (state, action: PayloadAction<ITravelVariant[]>) => {
                state.filteredVariants = action.payload;
            },
            setAirlines: (state, action: PayloadAction<string[]>) => {
                state.airlines = action.payload;
            },
            setTrains: (state, action: PayloadAction<string[]>) => {
                state.trains = action.payload;
            }
        }
    }
)

export const {
    setTravelVariants,
    setFilteredVariants,
    setAirlines,
    setTrains
} = orderSlice.actions;

export const ordersState = (state: RootState): OrderState => {
    // let st: OrderState = {
    //     travelVariants:state.order.filteredVariants,
    //     filteredVariants: state.order.filteredVariants,
    //     trains: state.order.trains,
    //     airlines: state.order.airlines
    // }
    return {
        travelVariants:state.order.filteredVariants,
        filteredVariants: state.order.filteredVariants,
        trains: state.order.trains,
        airlines: state.order.airlines
    }

}

export default orderSlice.reducer;