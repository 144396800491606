import { ICustomerTicketOrder } from "../../../../interfaces/reportInterfaces/customerInterfaces";
import { DataConvertService } from "../../../../services/DataConvertService";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

type CustomerTicketOrdersProps = {
  customerTicketOrders: ICustomerTicketOrder[];
};
export function CustomerTicketOrdersComponent({ customerTicketOrders }: CustomerTicketOrdersProps) {
  const { useMoneyRubFormat } = DataConvertService();
  const { t } = useTranslation();
  return (
    <>
      <table className="table table-bordered common-document-stripped-table table-bordered">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{`${t("statisticPage.termGroup")}`}</th>
            <th scope="col">{`${t("statisticPage.ticketClass")}`}</th>
            <th scope="col">{`${t("statisticPage.totalCharge")}`}</th>
            <th scope="col">{`${t("statisticPage.countCharge")}`}</th>
          </tr>
        </thead>
        <tbody>
          {customerTicketOrders.map((item, i) => {
            return (
              <tr>
                <td>{i}</td>
                <td>{item.termGroup}</td>
                <td>{item.ticketClass}</td>
                <td>{useMoneyRubFormat.format(item.totalCharge)}</td>
                <td>{item.countCharge}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
