import { Validator } from "fluentvalidation-ts";
import { ISignupRequests } from "../Requests/ISignupRequest";

export class SignupRequestValidator extends Validator<ISignupRequests> {
  constructor() {
    super();
    this.ruleFor("email")
      .notEmpty()
      .emailAddress()
      .withMessage("Email is incorrect.");
    this.ruleFor("password").minLength(8).withMessage("Password is too short");
    this.ruleFor("passwordConfirm")
      .minLength(8)
      .withMessage("Password is too short");
    this.ruleFor("firstName").minLength(1).withMessage("Name is short");
    this.ruleFor("lastName").minLength(1).withMessage("Name is short");
    this.ruleFor("position").notEmpty().withMessage("Please, input position!");
    this.ruleFor("shortCompanyName")
      .notEmpty()
      .withMessage("Please, input a company name!");
    this.ruleFor("legalAddress")
      .notEmpty()
      .withMessage("Please, input a legal address!");
    this.ruleFor("mainContract")
      .notEmpty()
      .withMessage("Please, input a main contract number!");
    this.ruleFor("mainContract")
      .notEmpty()
      .withMessage("Please, input a main contract number!");
    this.ruleFor("inn")
      .notEmpty()
      .minLength(10)
      .maxLength(12)
      .withMessage("Please, input a correct INN!");
    this.ruleFor("ogrn")
      .notEmpty()
      .minLength(13)
      .maxLength(13)
      .withMessage("Please, input a correct ogrn!");
  }
}
