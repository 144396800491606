import { useState } from "react";
import { DateService } from "../../../services/DateService";
import { SearchIcon } from "../../../icons/SearchIcon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {MdButton} from "../../../components/md-button";

interface SearchByDateProps {
  startDate?: Date;
  endDate?: Date;
  runSearch: (startDate: string, endDate: string) => void;
}

export function SearchByDateComponent(props: SearchByDateProps) {
  //services
  const { t } = useTranslation();
  const { dateToStringForDatePicker } = DateService();
  //hooks
  const [startDate, setStartDate] = useState(setDefaultDate(props.startDate));
  const [endDate, setEndDate] = useState(setDefaultDate(props.endDate));

  const clickHandler = () => {
    props.runSearch(startDate, endDate);
  };

  function setDefaultDate(date: Date | undefined) {
    return date === undefined
      ? dateToStringForDatePicker(new Date())
      : dateToStringForDatePicker(date);
  }
  return (
    <>
      <div className="filters-container">
        <div className="filters-form">

          <div>
        <span className=" input-group" style={{backgroundColor: "white"}}>
          <label className="sr-only  align-self-lg-center">
            &nbsp;{t("commonText.from") + ":"}&nbsp;
          </label>
          <div className="form-group">
          <input
              className="form-control"
              type="date" //value="option1"
              //placeholder="Clients name"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
          />
            </div>
        </span>

          </div>
          <div>
        <span className=" input-group" style={{backgroundColor: "white"}}>
          <label className="sr-only  align-self-lg-center">
            &nbsp;{t("commonText.to") + ":"}&nbsp;
          </label>
          <div className="form-group">
          <input
              className="form-control"
              type="date" //value="option1"
              //placeholder="Clients name"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
          />
            </div>
        </span>
          </div>
          <div className=" me-1 z-0">
          <span className="input-group">
            <MdButton
                style={{
                  "button": {
                    borderWidth: 0,
                    backgroundColor: "#3E5CB8",
                    color: "#FFFFFF",
                    borderRadius: "6px",
                    height: "35px",
                    padding: "5px 15px"
                  },
                  "ink": {
                    backgroundColor: "rgba(255,255,255,.5)"
                  }
                }}
                onClick={clickHandler}
            >
                {t("documentPage.search")}
            </MdButton>
          </span>
          </div>

        {/*  <span className=" input-group-text p-0 border-white  ">*/}
        {/*  <button className="btn btn-outline-secondary " type="button" onClick={clickHandler}>*/}
        {/*    <SearchIcon/> {t("commonText.search")}*/}
        {/*  </button>*/}
        {/*</span>*/}

        </div>
      </div>
    </>
  );
}
