import { error } from "console";
import { platform } from "os";
import { ErrorMessage } from "../ErrorComponents/errorMessage/ErrorMessage";

interface InputSimpleDataProps {
  title: string;
  placeholderText?: string;
  inputDataType: "text" | "email" | "password";
  inputValue?: string;
  style?: string | null;
  minWidthInPx?: number;
  errorMessage?: string;
  changeHandler: (value: string) => void;
}

export function InputSimpleDataComponent(props: InputSimpleDataProps) {
  let blockWidth: number;
  let placeholder =
    props.placeholderText === undefined ? props.title : props.placeholderText;

  if (props.minWidthInPx === undefined) {
    blockWidth =
      props.placeholderText!.length > props.title!.length
        ? props.placeholderText!.length * 12 + 20
        : props.title!.length * 12 + 20;
  } else {
    blockWidth = props.minWidthInPx;
  }

  if (blockWidth > window.innerWidth - 80) {
    blockWidth = window.innerWidth - 80;
  }

  return (
    <>
      <div className="p-1">
        <div>{props.title}:</div>
        <input
          className="form-control"
          type="text"
          style={{ width: blockWidth + "px" }}
          placeholder={placeholder}
          //placeholder={}
          onChange={(e) => {
            props.changeHandler(e.target.value);
          }}
        />
        {props.errorMessage && <ErrorMessage error={props.errorMessage} />}
      </div>
    </>
  );
}
