import { Route, Routes } from "react-router-dom";
import {useAppSelector} from "./redux/hooks";
import {
    LEFT_MENU_MY_PROFILE,
    LEFT_MENU_REPORTS,
} from "./constants/routeConstants/InnerRouteConstants";
import {ProtectedRoute} from "./components/protectedRoute";
import MainLayout from "./layout/mainLayout";
import {HomeLayout} from "./layout/homeLayout";
import {CabinetLayout} from "./layout/cabinetLayout";
import {HomePage} from "./pages/landingPages/homePage";
import {SignInPage} from "./pages/landingPages/signInPage";
import {SignUpPage} from "./pages/landingPages/signUpPage";
import {HomeCabinetPage} from "./pages/cabinetPages/homeCabinetPage";
import {MyProfilePage} from "./pages/cabinetPages/myProfilePage";
import {StatisticPage} from "./pages/cabinetPages/statisticPage";
import {OrderCabinetPage} from "./pages/cabinetPages/orderCabinetPage";
import {RegisterComponentPage} from "./pages/cabinetPages/RegistersCabinetPage/RegisterCabinetPage";
import {AccountantDocumentsPage} from "./pages/cabinetPages/documentsPage";
import {EmployeesPage} from "./pages/cabinetPages/employeesPage";
import "./App.scss";
import {ServicesPage} from "./pages/landingPages/servicesPage";
import {TariffsPage} from "./pages/landingPages/tariffsPage";
import {ContactsPage} from "./pages/landingPages/contactsPage";
import {ChangePasswordPage} from "./pages/commonPages/changePasswordPage";
import {RecoveryPasswordPage} from "./pages/commonPages/recoveryPasswordPage";
import {NotFoundPage} from "./pages/errorPages/notFoundPage";
import {useEffect, useState} from "react";
import {AuthService} from "./services/AuthService";
import {BookingPage} from "./pages/cabinetPages/bookingPage";
import {Environments} from "./enums/Environments";
import {TripPage} from "./pages/cabinetPages/tripPage";


export function App() {

    const authState = useAppSelector((state) => state.auth);

    const {login, logout, refreshToken} = AuthService();

    const [isFocused, setIsFocused] = useState(false);


    const refreshAccessToken = async () => {
        if(!document.hidden && isFocused) {
            try {
                // request token refreshing
                let refreshTokenResult = await refreshToken({expiredJwtToken: authState.accessToken});
                // if token refreshing was successfully complete
                if (refreshTokenResult) {
                    return true;
                } else {
                    // user logout
                    await logout();
                }
            } catch (error) {
                // user logout
                await logout();
            }
        }
        return false;
    }


    useEffect(() => {
        function onFocus() {
            setIsFocused(true);
        }

        function onBlur() {
            setIsFocused(false);
        }

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        }
    }, [isFocused]);


    useEffect(() => {
        let timer: NodeJS.Timeout;
        // check if user is logged in
        if (authState.isValidUser) {
            // get token expiration date
            let tokenExpirationDate = new Date(authState.expiredAt * 1000);
            // get token lifetime
            let tokenLifetime = ((tokenExpirationDate.getTime() - new Date().getTime()));
            // log info about token expiration time
            // TimeUtils.getTimeDetails(tokenLifetime, function (hours, minutes, seconds) {
            //     console.log('token expires on', tokenExpirationDate.toLocaleDateString(), tokenExpirationDate.toLocaleTimeString(), '(after ' + minutes + ":" + seconds.toFixed(0).padStart(2, '0') + ')');
            // });
            // calculate the scheduled time of a token refresh request
            let schedule = new Date();
            schedule.setTime(schedule.getTime() + tokenLifetime - 60 * 1000);
            // log info about remaining time before the token refresh request
            // TimeUtils.getTimeDetails(schedule.getTime() - new Date().getTime(), function (hours, minutes, seconds) {
            //     console.log('token updates on', schedule.toLocaleDateString(), schedule.toLocaleTimeString(), '(after ' + minutes + ":" + seconds.toFixed(0).padStart(2, '0') + ')');
            // });
            // create timeout for a token refresh request
            timer = setTimeout(() => {
                refreshAccessToken();
            }, schedule.getTime() - new Date().getTime());
        }
        return () => {
            // if the user is logged in and a timeout is created, clear the timeout to create another one
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [authState.expiredAt, authState.isValidUser, isFocused]);


    return (
        <>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route path="/" element={<HomeLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="/services" element={<ServicesPage/>}/>
                        <Route path="/tariffs" element={<TariffsPage/>}/>
                        <Route path="/contacts" element={<ContactsPage/>}/>
                        <Route path="sign-in" element={<SignInPage/>}/>
                        <Route path="sign-up" element={<SignUpPage/>}/>
                        <Route path="changePassword" element={<ChangePasswordPage/>}/>
                        <Route path="recovery" element={<RecoveryPasswordPage/>}/>
                    </Route>
                    <Route path="/cabinet/" element={<ProtectedRoute/>}>
                        <Route path="/cabinet/" element={<CabinetLayout/>}>
                            <Route path="" element={<HomeCabinetPage/>}/>
                            <Route path={LEFT_MENU_MY_PROFILE} element={<MyProfilePage/>}/>
                            <Route path={LEFT_MENU_REPORTS} element={<StatisticPage/>}/>
                            <Route path="documents" element={<AccountantDocumentsPage/>}/>

                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development | Environments.Staging}/>}>
                                <Route path="booking" element={<BookingPage/>}/>
                            </Route>

                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="trip" element={<TripPage/>}/>
                                <Route path="orders" element={<OrderCabinetPage/>}/>
                                <Route path="registers" element={<RegisterComponentPage/>}/>
                                {/*<Route path="statements" element={<StatementsPage/>}/>*/}
                                <Route path="employees" element={<EmployeesPage/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route path='*' element={<NotFoundPage />}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;