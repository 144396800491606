import React, {
    useRef,
    useState,
    CSSProperties
} from "react";
import './style.scss'


interface IMdScrollViewer {
    children?: any;
    style?: { [selector: string]: CSSProperties }
}


export function MdScrollViewer({children = null, style = {}}: IMdScrollViewer) {
    const root = useRef(null);
    const content = useRef(null);

    const [shadowedTop, setShadowedTop] = useState<boolean>(false);
    const [shadowedBottom, setShadowedBottom] = useState<boolean>(false);


    const onScroll = () => {
        if(content.current != null) {
            const element = content.current as HTMLElement;
            setShadowedTop(element.scrollTop > 0)
            setShadowedBottom(element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
        }
    }


    const isShadowedTop = () => {
        if(content.current != null) {
            const element = content.current as HTMLElement;
            return (element.scrollTop > 0);
        }
    }

    const isShadowedBottom = () => {
        if(content.current != null) {
            const element = content.current as HTMLElement;
            return (element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
        }
    }


    return (
        <div
            ref={root}
            className={"md-scroll-viewer" + (shadowedTop || isShadowedTop() ? " --shadowed-top" : "") + (shadowedBottom || isShadowedBottom() ? " --shadowed-bottom" : "")}
            style={style["root"]}
            >
            <div className="top-shadow"></div>
            <div ref={content} className="scrollable-content" style={style["content"]} onScroll={onScroll}>
                {
                    children
                }
            </div>
            <div className="bottom-shadow"></div>
        </div>
    );
}