interface StatisticSimpleCardProps {
  type?: "blue"|"lightBlue"|"green"|"lightGreen"
  icon?: JSX.Element;
  title: string;
  footer: string;
  data: [string, string][];
}

export function StatisticSimpleCardComponent(props: StatisticSimpleCardProps) {
  // style={{ backgroundColor: "#eeffe3" }}s
  let color = "#85c9f5";
  switch (props.type) {
    case "blue": color="#7e9aff"
          break;
    case "lightBlue": color="#85c9f5"
      break;
    case "lightGreen": color="#6be2e4"
      break;
    case "green": color="#65d6b6"
      break;

  }
  return (
    <>
      <span>
        <div className="card col"
        style = {{minWidth:"160px"}}>
          <div className="card-header"
               style={{
                // backgroundColor: "#424856",
                 backgroundColor: color,
                 color: "black"
          }}
          >
            {props.icon} {props.title}
          </div>
          <div className="card-body p-1">
            {/* <blockquote className="blockquote mb-0"> */}
            <ul className="list-group list-group-flush  pb-0">
              {props.data.map((item, index) => {
                return (
                  <li
                    className="list-group-item pb-0"
                    id={props.title + "_" + index.toString()}
                    key={props.title + index}
                  >
                    <p className="p-0">
                      <strong>{item[0] + ": "}</strong>
                      {item[1]}
                    </p>
                  </li>
                );
              })}
            </ul>
            {/* </blockquote> */}
          </div>
        </div>
      </span>
    </>
  );
}
