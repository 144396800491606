import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const LocationFilledIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0.5 0 24 24"
            fill="none"
            style={style["svg"]}
        >
            <path
                d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}/>
        </svg>
    )
}