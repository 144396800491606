import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {
    ModalContext,
    NEW_TRIP_MODAL_ID,
} from "../../../commonComponents/modal/ModalContext";
import {IAviaVariant, IAviaTariffInfo} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IPassenger} from "../../../../interfaces/IPassenger";
import {MdButton} from "../../../../components/md-button";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdSlider} from "../../../../components/md-slider";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {RecycleBinIcon} from "../../../../icons/RecycleBin";
import {WarningRhombusIcon} from "../../../../icons/WarningRhombusIcon";
import {TimeUtils} from "../../../../utils/timeUtils";
import "./style.scss"


interface INewOrderDetailsModal {
    trips: IAviaVariant[],
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    openSearchTicketModal: () => void;
    openSearchPassengersModal: () => void;
    removeTicketFromOrder: (ticket: IAviaVariant) => void;
    removePassengersFromOrder: (passengers: IPassenger[]) => void;
    submitHandler: (ticket: IAviaVariant, tariff: IAviaTariffInfo) => void;
}


export function NewOrderDetailsModal(props: INewOrderDetailsModal) {

    const {
        opened,
        setOpened,
        openSearchTicketModal, trips,
        removeTicketFromOrder,
        openSearchPassengersModal,
        removePassengersFromOrder,
        submitHandler} = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const {isOpen, open, close} = useContext(ModalContext);

    const [activeSlide, setActiveSlide] = useState(0);

    // const resetForm = () => {
    //
    // }

    const onModalOpen = () => {
        if (!opened) {
            setOpened(true);
        }
        //resetForm();
    }

    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
        //resetForm();
    }


    useEffect(() => {
        if (isOpen(NEW_TRIP_MODAL_ID) && !opened) {
            close(NEW_TRIP_MODAL_ID);
        } else if (!isOpen(NEW_TRIP_MODAL_ID) && opened) {
            open(NEW_TRIP_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => {
    }, []);


    return (
        <MdModal
            id={NEW_TRIP_MODAL_ID}
            title="Создание заказа"
            openHandler={onModalOpen}
            closeHandler={onModalClose}>

            <MdSlider
                header={
                    <></>
                }
                slides={[
                    {
                        content: (
                            <div className="trip-builder-form">
                                <span className="trip-builder-form-title">Построение маршрута</span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >

                                    {
                                        trips.map((trip, tripIndex) => {

                                            let warningMessage = null;

                                            let messages = [];

                                            if (tripIndex > 0) {
                                                const prevTrip = trips[tripIndex - 1];

                                                const prevDirection = prevTrip.rejsInfo[prevTrip.rejsInfo.length - 1];
                                                const prevCityArrival = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
                                                const prevDateArrival = new Date(prevDirection.dateArr);

                                                const nextDirection = trip.rejsInfo[0];
                                                const nextCityDepartment = nextDirection.cityDep.replace(/ *\([^)]*\) */g, "")
                                                const nextDateDepartment = new Date(nextDirection.dateDep);

                                                if (prevCityArrival != nextCityDepartment) {
                                                    messages.push("Предыдущий аэропорт не совпадает со следующим");
                                                }
                                            }

                                            if(messages.length > 0) {
                                                warningMessage = (
                                                    <div className="trip-item" key={tripIndex}>
                                                        <div className="trip-item-validation">
                                                            <div className="trip-item-validation-node">
                                                                <div
                                                                    className="trip-item-validation-node-button">
                                                                    <WarningRhombusIcon
                                                                        width="18px"
                                                                        height="18px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#F66000"
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="trip-item-validation-message">
                                                                <div
                                                                    className="trip-item-validation-message-title">
                                                                    Предупреждение
                                                                </div>
                                                                <ul
                                                                    className="trip-item-validation-message-content">
                                                                    {
                                                                        messages.map(message => {
                                                                            return (
                                                                                <li>{message}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            return (

                                                <>

                                                    {
                                                        warningMessage != null && (
                                                            warningMessage
                                                        )
                                                    }

                                                    <div className="trip-item">
                                                        <div className="trip-roadmap">

                                                            {
                                                                trip.rejsInfo.map((ticket, ticketIndex) => {
                                                                    const tickets = trip.rejsInfo;

                                                                    const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "")
                                                                    const dateDepartment = new Date(ticket.dateDep);

                                                                    const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "")
                                                                    const dateArrival = new Date(ticket.dateArr);

                                                                    let warningMessage = null;

                                                                    let messages = [];

                                                                    if (ticketIndex > 0) {
                                                                        const prevDirection = tickets[ticketIndex - 1];
                                                                        const prevCity = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
                                                                        const prevDateArrival = new Date(ticket.dateArr);

                                                                        let waitTime = TimeUtils.getTimeDetails(prevDateArrival.getTime() - dateDepartment.getTime());

                                                                        messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + Math.floor(waitTime.minutes / 60) + "ч " + (waitTime.minutes % 60) + "м");
                                                                    }

                                                                    if(messages.length > 0) {
                                                                        warningMessage = (
                                                                            <div className="trip-roadmap-item"
                                                                                 key={ticketIndex}>
                                                                                <div
                                                                                    className="trip-roadmap-validation">
                                                                                    <div
                                                                                        className="trip-roadmap-validation-node">
                                                                                        <div
                                                                                            className="trip-roadmap-validation-node-button">
                                                                                            <WarningRhombusIcon
                                                                                                width="18px"
                                                                                                height="18px"
                                                                                                style={{
                                                                                                    "path": {
                                                                                                        fill: "#F66000"
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="trip-roadmap-validation-message">
                                                                                        <div
                                                                                            className="trip-roadmap-validation-message-title">
                                                                                            Предупреждение
                                                                                        </div>
                                                                                        <ul
                                                                                            className="trip-roadmap-validation-message-content">
                                                                                            {
                                                                                                messages.map(message => {
                                                                                                    return (
                                                                                                        <li>{message}</li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (
                                                                        <>

                                                                            {
                                                                                warningMessage != null && (
                                                                                    warningMessage
                                                                                )
                                                                            }

                                                                            <div className="trip-roadmap-item"
                                                                                 key={ticketIndex}>
                                                                                <div
                                                                                    className="trip-roadmap-item-start-node">
                                                                                    <div
                                                                                        className="trip-roadmap-item-start-node-time">
                                                                                        <div
                                                                                            className="trip-roadmap-item-start-node-time-info --arrive">
                                                                                            {
                                                                                                <>
                                                                                                    <span>{dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")}</span>
                                                                                                    |
                                                                                                    <span>
                                                                                                    {dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                                        {" "}
                                                                                                        {dateDepartment.getDay().toString().padStart(2, "0")}
                                                                                                </span>
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <button
                                                                                        className={"trip-roadmap-item-start-node-button" + ( ticketIndex == 0 ? " --trip-begin" : "")}></button>
                                                                                    <div
                                                                                        className="trip-roadmap-item-start-node-location">
                                                                                        {townDepartment} ({ticket.airCodeDep})
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="trip-roadmap-item-content">
                                                                                    <div
                                                                                        className="trip-roadmap-item-ticket">
                                                                                        <div
                                                                                            className="trip-roadmap-item-ticket-header">
                                                                                            <div
                                                                                                className="depart-info">
                                                                                                <div
                                                                                                    className="city-code">
                                                                                                    {ticket.airCodeDep}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="flight-info">
                                                                                                <div
                                                                                                    className="air-company-name">
                                                                                                    {ticket.airCompanyName}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="arrive-info">
                                                                                                <div
                                                                                                    className="city-code">
                                                                                                    {ticket.airCodeArr}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className="trip-roadmap-item-ticket-content">
                                                                                            <div
                                                                                                className="depart-info">
                                                                                                <div
                                                                                                    className="city-name">
                                                                                                    {townDepartment}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="datetime">
                                                                                                    {
                                                                                                        <>
                                                                                                            <span>{dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0") + ", "}</span>
                                                                                                            <span>
                                                                                                            {dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                                                {" "}
                                                                                                                {dateDepartment.getDay().toString().padStart(2, "0")}
                                                                                                        </span>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="flight-info">
                                                                                                <div className="icon">
                                                                                                <span
                                                                                                    style={{opacity: 0.25}}></span>
                                                                                                    <span
                                                                                                        style={{opacity: 0.5}}></span>
                                                                                                    <span></span>
                                                                                                    <div
                                                                                                        style={{margin: "0 2px"}}>
                                                                                                        <AirplaneIcon
                                                                                                            width="16px"
                                                                                                            height="16px"
                                                                                                            style={{
                                                                                                                "path": {
                                                                                                                    fill: "#475AEF"
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <span></span>
                                                                                                    <span
                                                                                                        style={{opacity: 0.5}}></span>
                                                                                                    <span
                                                                                                        style={{opacity: 0.25}}></span>
                                                                                                </div>
                                                                                                <div className="number">
                                                                                                    {ticket.flightNumber}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="arrive-info">
                                                                                                <div
                                                                                                    className="city-name">
                                                                                                    {townArrival}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="datetime">
                                                                                                    {
                                                                                                        <>
                                                                                                            <span>{dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0") + ", "}</span>
                                                                                                            <span>
                                                                                                            {dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                                                {" "}
                                                                                                                {dateArrival.getDay().toString().padStart(2, "0")}
                                                                                                        </span>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="trip-roadmap-item-end-node">
                                                                                    <div
                                                                                        className="trip-roadmap-item-end-node-time">
                                                                                        <div
                                                                                            className="trip-roadmap-item-end-node-time-info --arrive">
                                                                                            {
                                                                                                <>
                                                                                                    <span>{dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")}</span>
                                                                                                    |
                                                                                                    <span>
                                                                                                    {dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                                        {" "}
                                                                                                        {dateArrival.getDay().toString().padStart(2, "0")}
                                                                                                </span>
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <button
                                                                                        className={"trip-roadmap-item-end-node-button" + ( ticketIndex == tickets.length - 1 ? " --trip-end" : "")}></button>
                                                                                    <div
                                                                                        className="trip-roadmap-item-end-node-location">
                                                                                        {townArrival} ({ticket.airCodeArr})
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                        <div className="trip-item-tools">
                                                            <MdButton
                                                                icon={
                                                                    <RecycleBinIcon
                                                                        width="14px"
                                                                        height="14px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#721c24"
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                style={{
                                                                    "button": {
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#FFFFFF",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                        marginRight: "0px",
                                                                        width: "28px",
                                                                        height: "28px",
                                                                        padding: 0
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                    },
                                                                    "icon": {
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                    }
                                                                }}
                                                                onClick={() => removeTicketFromOrder(trip)}
                                                            />
                                                        </div>
                                                    </div>

                                                </>

                                            )
                                        })
                                    }

                                    <div className="trip-add-button">
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(253 253 253)",
                                                    color: "rgb(175 175 175)",
                                                    border: "1px solid rgb(175 175 175)",
                                                    borderStyle: "dashed",
                                                    borderRadius: "6px",
                                                    height: "30px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            onClick={() => openSearchTicketModal()}
                                        >
                                            <span>Добавить</span>
                                        </MdButton>
                                    </div>
                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <div className="passengers-selection-form">
                                <span className="passengers-selection-form-title">Выбор пассажиров</span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >



                                    <div className="passenger-add-button">
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(253 253 253)",
                                                    color: "rgb(175 175 175)",
                                                    border: "1px solid rgb(175 175 175)",
                                                    borderStyle: "dashed",
                                                    borderRadius: "6px",
                                                    height: "30px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            onClick={() => openSearchPassengersModal()}
                                        >
                                            <span>Добавить</span>
                                        </MdButton>
                                    </div>
                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <></>
                        )
                    },
                ]}
                footer={
                    <div className="booking-form-footer">
                        <div>
                            {
                                activeSlide > 0 && (
                                    <MdButton
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "icon": {
                                                marginRight: "5px",
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                    >
                                        Назад
                                    </MdButton>
                                )
                            }
                        </div>

                        <div>
                            {
                                activeSlide < 2 && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={(trips.length == 0)}
                                        onClick={() => setActiveSlide(prev => Math.min(2, prev + 1))}
                                    >
                                        Далее
                                    </MdButton>
                                )
                            }
                            {/*{*/}
                            {/*    activeSlide == 2 && (*/}

                            {/*        isBookingPending*/}
                            {/*            ? (*/}
                            {/*                <CircleSpinnerIcon*/}
                            {/*                    width="24px"*/}
                            {/*                    height="24px"*/}
                            {/*                />*/}
                            {/*            )*/}
                            {/*            : (*/}
                            {/*                <MdButton*/}
                            {/*                    type="submit"*/}
                            {/*                    style={{*/}
                            {/*                        "button": {*/}
                            {/*                            width: "200px",*/}
                            {/*                            borderWidth: 0,*/}
                            {/*                            backgroundColor: "#3E5CB8",*/}
                            {/*                            color: "#FFFFFF",*/}
                            {/*                            borderRadius: "6px",*/}
                            {/*                            height: "35px",*/}
                            {/*                            padding: "5px 15px"*/}
                            {/*                        },*/}
                            {/*                        "ink": {*/}
                            {/*                            backgroundColor: "rgba(255,255,255,.5)"*/}
                            {/*                        }*/}
                            {/*                    }}*/}
                            {/*                    disabled={(selectedTariff == null || selectedPassengers.length == 0)}*/}
                            {/*                    onClick={onSubmit}*/}
                            {/*                >*/}
                            {/*                    Забронировать*/}
                            {/*                </MdButton>*/}
                            {/*            )*/}
                            {/*    )*/}
                            {/*}*/}
                        </div>
                    </div>
                }
                activeSlide={activeSlide}
                style={{
                    "root": {
                        width: "580px",
                        height: "600px",
                        padding: "10px 20px 20px 20px"
                    }
                }}
            />

        </MdModal>
    );
}