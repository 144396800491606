import { PlaneIcon } from "../../../../icons/PlaneIcon";
import { TrainIcon } from "../../../../icons/TrainIcon";
import {useEffect, useState} from "react";
import "./style.scss";
import { TicketType } from "../../../../enums/TicketType";
import { TripVariantComponent } from "../tripVariantComponent";
import { ITrainVariant } from "../../../../interfaces/orderInterfaces/TrainOrderInterfaces";
import { MobileCheckBoxComponent } from "../../../commonComponents/checkBoxComponents/mobileCheckBoxComponent";
import { BackwardIcon } from "../../../../icons/BackwardIcon";
import { ISelectedVariant } from "../../../../interfaces/componentInterfaces/ISelectedVariant";
import { SelectComponent } from "../selectVariantComponent";
import {useTranslation} from "react-i18next";
import {IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {TravelVariantComponent} from "../travelVariantComponent";

type SearchResultProps = {
  selectedVariants: number[];
  filteredVariants:ITravelVariant[];

};

export function SearchResultComponent(props: SearchResultProps) {
  const { t } = useTranslation();


// t [showBackWardAviaVariant, setShowBacwardAviaVariant] = useState(false);
  const [showBackwardTrainVariants, setShowBackwardTrainVariants] = useState(false);

  //const [showHotelVariants, setShowHotelVariants] = useState(false);
  const [backwardState, setBackwardState] = useState(false);
  useEffect(() => {

  }, [props.selectedVariants]);

  // function setDefaultState(ticketType: TicketType): boolean {
  //   switch (ticketType) {
  //     case TicketType.AviaTicket: {
  //       return props.aviaVariants?.length !== 0;
  //     }
  //     case TicketType.TrainTicket: {
  //       return props.trainVariants?.length !== 0 && props.aviaVariants?.length === 0;
  //     }
  //     default:
  //       return false;
  //   }
  // }

  // const setAllShowFalse = () => {
  //   setShowAviaVariants(false);
  //   setShowTrainVariants(false);
  //   setShowBackwardTrainVariants(false);
  //   setShowBacwardAviaVariant(false);
  // };
  //
  // const aviaVariantsHandler = (state: boolean) => {
  //   setAllShowFalse();
  //   if (backwardState) {
  //     setShowBacwardAviaVariant(true);
  //   } else {
  //     setShowAviaVariants(true);
  //   }
  // };
  // const trainVariantsHandler = (state: boolean) => {
  //   setAllShowFalse();
  //   if (backwardState) {
  //     setShowBackwardTrainVariants(true);
  //   } else {
  //     setShowTrainVariants(true);
  //   }
  // };

  return (
    <>
      <div className=" ">
        <div className="search-result-container-form">
          <div className=" d-flex flex-row flex-wrap gap-1 search-result-container-with-filters p-2">
            <h5 className=" align-self-center">{t("searchPage.searchResult")}:</h5>


            {props.filteredVariants && props.filteredVariants.map((item, index) => (
                <TravelVariantComponent key={index} travelVariant={item} index={index}/>))}



          </div>



        </div>
      </div>
      {/* </div> */}
    </>
  );
}
