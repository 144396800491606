import {ITrainVariant} from "../../../../interfaces/orderInterfaces/TrainOrderInterfaces";
import {TicketType} from "../../../../enums/TicketType";
import "./style.scss";
import {HeartIcon} from "../../../../icons/HeartIcon";
import {PlaneHorizontalIcon} from "../../../../icons/PlaneHorizontalIcon";
import {TrainHorizontalIcon} from "../../../../icons/TrainHorizontalIcon";
import {OrderService} from "../../../../services/OrderServices";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {AviaInfoComponent} from "../aviaInfoComponent";
import {CollapseIcon} from "../../../../icons/CollapseIcon";
import {PlaneIcon} from "../../../../icons/PlaneIcon";
import {useEffect, useState} from "react";
import {TrainInfoComponent} from "../trainInfoComponent";
import {useTranslation} from "react-i18next";

type TravelVariantProps = {
    index: number;
    travelVariant: ITravelVariant
}

export function TravelVariantComponent({index,travelVariant}: TravelVariantProps) {
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
    const {toTravelVariant} = OrderService();
    const {t} = useTranslation();

    //let travelVariant: ITravelVariant = toTravelVariant(props.ticketType, props.tripVariant)

    useEffect(() => {

    }, [showAdditionalInfo]);

    return (
        <>
            <div className="travel-variant-container">

                {/*main block info*/}
                <div className="travel-variant-main-sub-container">
                    <div className="company-name-sub-container">
                        {travelVariant.travelCompanyOrVehicle.map((company,index) => <div key={index}>{company}</div>)}
                    </div>

                    {/*main info bock*/}

                    <div className=" travel-main-info">

                        <div className="travel-main-direction-info">

                            {/*  block "FROM */}
                            <div className="travel-time-and-city">
                                <div
                                    className="d-flex flex-row time mx-2 align-self-center"> {travelVariant.departureTime}</div>
                                <div
                                    className="d-flex flex-row time mx-2 align-self-center"> {travelVariant.departureDate}</div>
                                <div
                                    className="d-flex flex-rowair-port-code mx-2">&nbsp;{travelVariant.departurePlaceCode}</div>
                            </div>

                            {/*  block "Direction" */}
                            <div className="travel-direction-block">
                                <div className="travel-direction-block-info">
                                    <div typeof='travel-time'>
                                        {travelVariant.travelTime}
                                    </div>
                                    <div typeof="horizontal-line">
                                        <svg height="10px" width="100%" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0 L300 0 Z "
                                                  style={{fill: "none", stroke: "black", strokeWidth: 3}}/>
                                        </svg>
                                    </div>
                                    <div>
                                        <small>
                                            <p className=" align-self-start">{travelVariant.transfersCount <= 0 ? "Прямой" : "Пересадки: " + travelVariant.transfersCount}</p>{" "}
                                        </small>
                                    </div>
                                </div>

                                <div className="travel-direction-block-icon">
                                    {travelVariant.ticketType === TicketType.AviaTicket ?
                                        <PlaneHorizontalIcon iconSize="45px"/> :
                                        <TrainHorizontalIcon iconSize={"45px"}/>}
                                </div>
                            </div>

                            {/*  block "TO */}
                            <div className="travel-time-and-city">
                                <div className="row time"> {travelVariant.arriveTime}</div>
                                <div className="row time"> {travelVariant.arrivedDate}</div>
                                <div className="row">{travelVariant.arrivePlaceCode}</div>
                            </div>

                        </div>
                        <div className="travel-direction-block-cities">
                            <div>{travelVariant.departureCity}</div>
                            <div>&nbsp;-&nbsp;</div>
                            <div>{travelVariant.arriveCity}</div>
                        </div>
                    </div>

                    <div className="travel-price-info">
                        <div typeof="icon"><HeartIcon/></div>
                        <div typeof="price" style={{color: "darkgreen"}}>Min: {travelVariant.minPrice} rub</div>
                        <div typeof="price" style={{color: "darkred"}}> Max: {travelVariant.maxPrice} rub</div>
                    </div>

                    <div
                        className={travelVariant.ticketType === TicketType.AviaTicket ? "additional-info-avia" : "additional-info-train"}
                        typeof={travelVariant.ticketType === TicketType.AviaTicket ? "additional-info-avia" : "additional-info-train"}
                    >
                            <div style={{color: "black"}}>{travelVariant.ticketType === TicketType.AviaTicket ?
                                <PlaneIcon/> : <TrainHorizontalIcon/>}
                            </div>
                            <div>
                                {`${t("orderPage.tariffCount")}: ${travelVariant.tariffCount}`}
                            </div>
                            <div className="push-last-child-to-right"
                                 onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                               <CollapseIcon/>
                            </div>
                    </div>

                </div>
                    <div
                        className={showAdditionalInfo ? " expanded " : " collapsed"}>
                        {travelVariant.ticketType === TicketType.AviaTicket && showAdditionalInfo &&
                            <AviaInfoComponent isCollapsed={false}
                                               aviaVariant={travelVariant.travelVariant as IAviaVariant}/>}

                        {travelVariant.ticketType === TicketType.TrainTicket && showAdditionalInfo &&
                            <TrainInfoComponent isCollapsed={false}
                                               trainVariant={travelVariant.travelVariant as ITrainVariant}/>}
                    </div>
            </div>


        </>
    )
}