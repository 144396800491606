import {useEffect} from "react";
import {TicketSearch} from "./ticketSearch";
import "./style.scss";

export function HomePage() {


    useEffect(() => {

    }, []);

    return (
        <>
            <section id="section-1">
                <div className="container">
                    <div className="title-block">
                        <h2>Решения для любой программы деловых поездок</h2>
                        <p>Мы отказались от всего, что не относится к деловым поездкам,</p>
                        <p>для того, чтобы делать это лучше других</p>
                    </div>
                    <div className="main-image-block">
                        <div className="sky-mask">
                            <img src="/img/newHomePage/sky.jpg" alt=""/>
                        </div>
                        <div className="airplane-container">
                            <img src="/img/newHomePage/airplane.png" alt=""/>
                        </div>
                    </div>
                    <div className="ticket-search-container">
                        {
                            <TicketSearch/>
                        }
                    </div>
                </div>
            </section>

            <section id="section-2">
                <div className="container">
                    <div className="title-block">
                        <h2>Планируйте своё путешествие с уверенностью</h2>
                        <p>Мы знаем, как сделать рабочую поездку проще и приятнее</p>
                    </div>
                    <div className="tiles-container">
                        <div className="tile">
                            <div className="tile-inner">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.536 16">
                                        <path fill="#3E5CB8"
                                              d="M15.187,6.2h-0.033V5.041C15.153,4.991,15.087,0,9.269,0c-5.82,0-5.887,4.991-5.887,5.041V6.2H3.35  C1.503,6.2,0,7.703,0,9.549C0,11.397,1.503,12.9,3.35,12.9h0.032v1.238l4.612,1.844L10.928,16v-2.19H7.61v0.874l-3.165-1.266V5.042  C4.446,4.88,4.523,1.063,9.269,1.063c4.768,0,4.822,3.814,4.822,3.977V12.9h1.096c1.847,0,3.35-1.503,3.35-3.351  C18.536,7.703,17.033,6.2,15.187,6.2 M17.473,9.549c0,1.261-1.025,2.287-2.286,2.287h-0.033V7.264h0.033  C16.447,7.264,17.473,8.289,17.473,9.549 M3.382,7.264v4.573H3.35c-1.261,0-2.286-1.026-2.286-2.287S2.089,7.264,3.35,7.264H3.382z"></path>
                                    </svg>
                                </i>
                                <p>Один из крупнейших профильных колл-центров в северо-западном регионе</p>
                                <span>Подробнее</span>
                            </div>
                        </div>
                        <div className="tile">
                            <div className="tile-inner">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.615 22.457">
                                        <g>
                                            <path fill="#3E5CB8"
                                                  d="M14.299,12.585h-0.817c-0.122,0-0.231,0.038-0.345,0.069c-0.071-0.66-0.611-1.182-1.29-1.182H11.03   c-0.12,0-0.236,0.018-0.349,0.048c-0.082-0.649-0.615-1.16-1.286-1.16H8.579c-0.112,0-0.214,0.038-0.317,0.065V6.333   c0-0.933-0.83-1.692-1.851-1.692c-1.02,0-1.85,0.759-1.85,1.692v6.06c-0.21,0.172-0.602,0.498-1.257,1.063   c-1.249,1.079-0.795,2.32-0.769,2.388l2.372,5.622c0.005,0.012,0.017,0.017,0.023,0.027c0.012,0.023,0.016,0.048,0.031,0.069   c0.402,0.561,1.05,0.895,1.735,0.895h6.786c1.178,0,2.134-0.958,2.134-2.135v-1.676v-0.703v-4.041   C15.615,13.175,15.024,12.585,14.299,12.585 M14.615,20.322c0,0.626-0.509,1.135-1.134,1.135H6.696   c-0.351,0-0.682-0.17-0.897-0.449L3.466,15.48c-0.023-0.066-0.202-0.668,0.492-1.267c0.233-0.201,0.431-0.371,0.603-0.517v1.736   c0,0.276,0.224,0.5,0.5,0.5c0.277,0,0.5-0.224,0.5-0.5V6.333c0-0.382,0.382-0.692,0.85-0.692c0.47,0,0.851,0.31,0.851,0.692v5.345   v0.681v1.315c0,0.276,0.224,0.5,0.5,0.5c0.276,0,0.5-0.224,0.5-0.5v-1.315v-0.681c0-0.174,0.143-0.317,0.317-0.317h0.816   c0.172,0,0.318,0.146,0.318,0.317v0.817v1.18c0,0.276,0.223,0.5,0.5,0.5c0.275,0,0.5-0.224,0.5-0.5v-0.884   c0-0.175,0.142-0.317,0.316-0.317h0.816c0.175,0,0.318,0.143,0.318,0.317v0.884c0,0.041,0.013,0.075,0.022,0.112   c-0.004,0.04-0.022,0.074-0.022,0.115c0,0.276,0.223,0.5,0.5,0.5c0.275,0,0.5-0.224,0.5-0.5c0-0.175,0.142-0.316,0.316-0.316h0.817   c0.174,0,0.316,0.142,0.316,0.316v4.041v0.703V20.322z"></path>
                                            <polygon fill="#3E5CB8"
                                                     points="3.845,10.072 1.156,5.433 3.715,1 8.835,1 11.402,5.446 9.701,8.708 10.588,9.171 12.544,5.422 9.413,0 3.138,0    0,5.434 2.979,10.574  "></polygon>
                                        </g>
                                    </svg>
                                </i>
                                <p>Современный сервис онлайн-бронирования билетов и гостиничных номеров</p>
                                <span>Подробнее</span>
                            </div>
                        </div>
                        <div className="tile">
                            <div className="tile-inner">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path fill="#3E5CB8"
                                              d="M24,12A12,12,0,0,1,0,12a1,1,0,0,1,2,0A10,10,0,1,0,12,2a1,1,0,0,1,0-2A12.013,12.013,0,0,1,24,12ZM10.277,11H8a1,1,0,0,0,0,2h2.277A1.994,1.994,0,1,0,13,10.277V7a1,1,0,0,0-2,0v3.277A2,2,0,0,0,10.277,11ZM1.827,8.784a1,1,0,1,0-1-1A1,1,0,0,0,1.827,8.784ZM4.221,5.207a1,1,0,1,0-1-1A1,1,0,0,0,4.221,5.207ZM7.779,2.841a1,1,0,1,0-1-1A1,1,0,0,0,7.779,2.841Z"/>
                                    </svg>
                                </i>
                                <p>Отсрочка платежа до 30 календарных дней</p>
                                <span>Подробнее</span>
                            </div>
                        </div>
                        <div className="tile">
                            <div className="tile-inner">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M13.5295 8.35186C12.9571 8.75995 12.2566 9 11.5 9C9.567 9 8 7.433 8 5.5C8 3.567 9.567 2 11.5 2C12.753 2 13.8522 2.65842 14.4705 3.64814M6 20.0872H8.61029C8.95063 20.0872 9.28888 20.1277 9.61881 20.2086L12.3769 20.8789C12.9753 21.0247 13.5988 21.0388 14.2035 20.9214L17.253 20.3281C18.0585 20.1712 18.7996 19.7854 19.3803 19.2205L21.5379 17.1217C22.154 16.5234 22.154 15.5524 21.5379 14.9531C20.9832 14.4134 20.1047 14.3527 19.4771 14.8103L16.9626 16.6449C16.6025 16.9081 16.1643 17.0498 15.7137 17.0498H13.2855L14.8311 17.0498C15.7022 17.0498 16.4079 16.3633 16.4079 15.5159V15.2091C16.4079 14.5055 15.9156 13.892 15.2141 13.7219L12.8286 13.1417C12.4404 13.0476 12.0428 13 11.6431 13C10.6783 13 8.93189 13.7988 8.93189 13.7988L6 15.0249M20 6.5C20 8.433 18.433 10 16.5 10C14.567 10 13 8.433 13 6.5C13 4.567 14.567 3 16.5 3C18.433 3 20 4.567 20 6.5ZM2 14.6L2 20.4C2 20.9601 2 21.2401 2.10899 21.454C2.20487 21.6422 2.35785 21.7951 2.54601 21.891C2.75992 22 3.03995 22 3.6 22H4.4C4.96005 22 5.24008 22 5.45399 21.891C5.64215 21.7951 5.79513 21.6422 5.89101 21.454C6 21.2401 6 20.9601 6 20.4V14.6C6 14.0399 6 13.7599 5.89101 13.546C5.79513 13.3578 5.64215 13.2049 5.45399 13.109C5.24008 13 4.96005 13 4.4 13L3.6 13C3.03995 13 2.75992 13 2.54601 13.109C2.35785 13.2049 2.20487 13.3578 2.10899 13.546C2 13.7599 2 14.0399 2 14.6Z"
                                            stroke="#3E5CB8" strokeWidth="1.6" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </i>
                                <p>Выбор величины сервисного сбора, изменение условий отсрочки платежа</p>
                                <span>Подробнее</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-3">
                <div className="container">
                    <div className="services-container">
                        <div className="services-previews">
                            <div className="service-1-preview-mask">
                                <img src="/img/business-travel.jpg" alt=""/>
                            </div>
                            <div className="service-2-preview-mask">
                                <img src="/img/rent-a-car.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="services-content">
                            <div className="services-title">
                                <h2>Организация мероприятий любой сложности</h2>
                            </div>
                            <div className="services-list">
                                <div className="service-item">
                                    <div className="service-item-title">Бизнес-трэвел консьерж служба</div>
                                    <div className="service-item-description">Решение организационных вопросов в рамках
                                        рабочей поездки
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">MICE</div>
                                    <div className="service-item-description">Организация мероприятий любой сложности,
                                        без ограничения количества участников
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">Билеты на аэроэкспресс</div>
                                    <div className="service-item-description">Предоставление билетов на аэроэкспресс без
                                        взимания дополнительных сборов
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">Fast-track</div>
                                    <div className="service-item-description">ускоренное прохождение формальностей в
                                        общем зале аэропорта в сопровождении ассистента
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">VIP-обслуживание</div>
                                    <div className="service-item-description">Регистрация, сдача багажа и быстрое
                                        прохождение всех таможенных формальностей
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">Аренда автомобиля</div>
                                    <div className="service-item-description">Помощь в подобре автомобиля, который
                                        наилучшим образом подходит для деловой поездки
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">Визы</div>
                                    <div className="service-item-description">Предосталение необходимой консультации,
                                        помощь в подготовке обязательного пакета документов и в ее получении
                                    </div>
                                </div>
                                <div className="service-item">
                                    <div className="service-item-title">Страхование</div>
                                    <div className="service-item-description">Помощь в подборе оптимальной программы
                                        страхования на выгодных условиях
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-4">
                <div className="container">
                    <div className="title-block">
                        <h2>Тарифные планы корпоративного обслуживания</h2>
                        <p>Для каждого тарифного плана предоставляется возможность подбора
                            отсрочки платежа</p>
                    </div>
                    <div className="rates-container">
                        <div className="rate-item">
                            <div className="rate-item-header">
                                <h4>Офлайн</h4>
                            </div>
                            <div className="rate-item-description">
                                <div>Оформление через контактный центр</div>
                                <button>Подробнее</button>
                            </div>
                            <div className="rate-item-content">
                                <div>5 систем бронирования</div>
                                <div>быстрая обработка</div>
                                <div>круглосуточный доступ</div>
                                <div>-</div>
                                <div>-</div>
                            </div>
                        </div>
                        <div className="rate-item">
                            <div className="rate-item-header">
                                <h4>Онлайн</h4>
                            </div>
                            <div className="rate-item-description">
                                <div>Оформление через онлайн-сервис</div>
                                <button>Подробнее</button>
                            </div>
                            <div className="rate-item-content">
                                <div>5 систем бронирования</div>
                                <div>быстрая обработка</div>
                                <div>круглосуточный доступ</div>
                                <div>онлайн сервис</div>
                                <div>календарь минимальных цен</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}