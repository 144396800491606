type CheckProps = {
    type :"checkbox"|"radio";
    isChecked:boolean;
    name?:string
    title?:string;
}

export function CheckComponent (props:CheckProps) {
    const style = props.isChecked?" radio-button checked": " radio-button disabled"
    return (
        <>
            <div className={style}>
            <label><input type="radio" name="e"/> {props.title}</label>
            </div>
        </>
    )
}