import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";
import { DEFAULT_ICON_SIZE } from "../components/const/componentsContants";

export function DocumentIcon({ iconSize }: IIconProps) {
  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
    <>
      <svg viewBox="0 0 40 40" width={iconSize} height={iconSize}>
        <path fill="whitesmoke" d="M6.5 37.5L6.5 2.5 24.793 2.5 33.5 11.207 33.5 37.5z" />
        <path
          fill="currentColor"
          d="M24.586,3L33,11.414V37H7V3H24.586 M25,2H6v36h28V11L25,2L25,2z"
        />
        <path fill="currentColor" d="M24.5 11.5L24.5 2.5 24.793 2.5 33.5 11.207 33.5 11.5z" />
        <path
          fill="currentColor"
          d="M25 3.414L32.586 11H25V3.414M25 2h-1v10h10v-1L25 2 25 2zM12 16H28V17H12zM12 19H24V20H12zM12 22H28V23H12zM12 25H24V26H12zM12 28H28V29H12z"
        />
      </svg>
    </>
  );
}
