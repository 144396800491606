export const VISIBLE_VISIBLE: string = "visible";
export const VISIBLE_COLLAPSE: string = "collapse";
export const VISIBLE_HIDDEN: string = "hidden";

//AccountantDocumentPage
export const EXEL: string = "exel";
export const PDF: string = "pdf";
export const WITH_STAMP = "withStamp";

//color

export const MAIN_BACKGROUND_COLOR = "#EEFFE3";
export const FORBIDDEN_COLOR = "#b00020";
export const GRANTED_COLOR = "#74b100";

//OrderPage
// ConditionsListValue
export const NO_REFUNDS= "NoRefunds";
export const UNDEFINED = "Undefined";


