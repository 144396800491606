import React, {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useTranslation} from "react-i18next";
import { setGkCompanies } from "../../../redux/authSlice";
import {CompanyService} from "../../../services/CompanyService";
import {CompaniesSearchComponent} from "./companySearchComponent";
import {CompanyTableComponent} from "./companyTableComponent";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING,
    TTS_ADMIN_ROLE
} from "../../../constants/ServiceCostants";
import {ICompany} from "../../../interfaces/companyInfoInterfaces/ICompany";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {MdModal} from "../../../components/md-modal";
import {CompanyAddComponent} from "./companyAddComponent";
import {CompanyImportModal} from "./companyImportModal";
import {Guid} from "../../../utils/utils";
import "./style.scss";


export function MyProfilePage() {
    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const currentCompanyState = useAppSelector((state) => state.companyInfo);

    const {getCompaniesInfoToCompanyGroup, getClientListForTtsAdmin, makeActiveCompany, addNewCompany, getCompaniesList, importNewCompany} = CompanyService();

    const {open, close} = useContext(ModalContext);

    const [addCompanyModalId] = useState(Guid.newGuid());
    const [addCompanyPending, setAddCompanyPending] = useState(false);
    const [addCompanyError, setAddCompanyError] = useState(false);


    const [companyImportModalOpened, setCompanyImportModalOpened] = useState<boolean>(false);
    const [importCompanyPending, setImportCompanyPending] = useState(false);
    const [importCompanyError, setImportCompanyError] = useState(false);


    const [isLoading, setIsLoading] = useState(false);
    const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);
    const [companyName, setCompanyName] = useState("");


    const isDevelopmentEnv = () => {
        return !(CURRENT_REACT_ENVIROMENT === ENVIROMENT_STAGING || CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION)
    }

    const updateGroupCompanyData = async () => {
        await getCompaniesInfoToCompanyGroup(authState.gkClientCodes);
    };

    const makeActive = async (clientCode: string) => {
        await makeActiveCompany(clientCode);
    };

    const searchCompaniesByName = async () => {
        setIsLoading(true);

        setIsSearchResultNotFound(false);

        dispatch(setGkCompanies([]));

        if (companyName.length < 3) { // ???
            setIsLoading(false);
            return;
        }

        const response = await getClientListForTtsAdmin(companyName);

        if(response.length > 0) {
            dispatch(setGkCompanies(response));
        }
        else {
            setIsSearchResultNotFound(true);
        }

        setIsLoading(false);
    };


    const openCompanyAddModal = () => {
        open(addCompanyModalId);
    }

    const onAddModalOpenedHandler = async () => {
        setAddCompanyError(false);
    };

    const addCompanyHandler = async (company: ICompany, callback: Function) => {
        setAddCompanyError(false);
        setAddCompanyPending(true);
        try {
            const result = await addNewCompany(company);
            if(result != null) {
                closeCompanyAddModal();
                callback();
                // TODO update list of companies
            }
            else {
                setAddCompanyError(true);
            }
        }
        catch (ex) {
            setAddCompanyError(true);
        }
        setAddCompanyPending(false);
    };

    const onAddModalClosedHandler = async () => {
        setAddCompanyError(false);
    };

    const closeCompanyAddModal = () => {
        close(addCompanyModalId);
    }



    const openCompanyImportModal = () => {
        setCompanyImportModalOpened(true);
    }

    const importCompanyHandler = async (company: ICompany) => {
        setImportCompanyError(false);
        setImportCompanyPending(true);
        try {
            const result = await importNewCompany(company);
            if(result != null) {
                // TODO update list of companies
            }
            else {
                setImportCompanyError(true);
            }
        }
        catch (ex) {
            setImportCompanyError(true);
        }
        setImportCompanyPending(false);
    };


    useEffect(() => {

    }, [companyName]);


    return (
        <>
            <div className="company-profile-info" style={{marginBottom: "10px"}}>
                <div className="my-profile-info-sub-block">
                    <label>{t("myProfilePage.currentCompany")}</label>
                    <div>{currentCompanyState.fullName}</div>
                </div>

                {currentCompanyState.agreementNo && <>
                    <div className="my-profile-info-sub-block">
                        <label>{t("myProfilePage.agreement")}</label>
                        <div>&nbsp; {currentCompanyState.agreementNo}</div>
                    </div>
                    <div className="my-profile-info-sub-block">
                        <label>{t("myProfilePage.agreementFrom")}</label>
                        <div>&nbsp; {currentCompanyState.agreementFrom}</div>
                    </div>

                    <div className="my-profile-info-sub-block">
                        <label>{t("myProfilePage.agreementTill")}</label>
                        <div>&nbsp; {currentCompanyState.agreementTill}</div>
                    </div>
                </>
                }
                {currentCompanyState.address && <div className="my-profile-info-sub-block">
                    <label>{t("myProfilePage.address")}</label>
                    <div>&nbsp; {currentCompanyState.address}</div>
                </div>}
            </div>

            {
                (authState.userRole === TTS_ADMIN_ROLE || isDevelopmentEnv()) && (
                    <CompaniesSearchComponent
                        name={companyName}
                        nameHandler={setCompanyName}
                        searchHandler={searchCompaniesByName}
                    />
                )
            }

            <CompanyTableComponent
                gkName={"MMP"}
                companies={authState.gkCompanies}
                activeCompany={authState.currentClientCode}
                makeActiveCompany={makeActive}
                isLoading={isLoading}
                isSearchResultNotFound={isSearchResultNotFound}
                openCompanyImportModal={openCompanyImportModal}
                openCompanyAddModal={openCompanyAddModal}
                updateDataHandler={updateGroupCompanyData}
            />

            <CompanyImportModal
                title="Импорт компании"
                opened={companyImportModalOpened}
                setOpened={setCompanyImportModalOpened}
                submitHandler={importCompanyHandler}
            />

            <MdModal
                id={addCompanyModalId}
                title="Новая компания"
                openHandler={onAddModalOpenedHandler}
                closeHandler={onAddModalClosedHandler}>
                <CompanyAddComponent
                    pending={addCompanyPending}
                    error={addCompanyError}
                    addHandler={addCompanyHandler}
                />
            </MdModal>
        </>
    );
}
