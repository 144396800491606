import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../../../constants/FieldsConstants";
import {IAviaRejsInfo, IAviaTariffInfo} from "../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IPassenger} from "../../../interfaces/IPassenger";
import {IBookFlightRequest} from "../../../Requests/IBookFlightRequest";
import {TripService} from "../../../services/TripService";
import {OrderService} from "../../../services/OrderServices";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {MdModal} from "../../../components/md-modal";
import {TicketSearchComponent} from "./ticketSearchComponent";
import {TicketComponent} from "./ticketComponent";
import {BookingComponent} from "./bookingComponent";
import {Guid} from "../../../utils/utils";
import "./style.scss";
import {MdButton} from "../../../components/md-button";
import {setOrders} from "../../../redux/bookSlice";
import {IBookingOrder} from "../../../interfaces/IBookingOrder";



export function BookingPage() {
    const initialized = useRef(false);

    const [cookies] = useCookies([ACCESS_TOKEN]);
    const authState = useAppSelector((state) => state.auth);
    const bookingState = useAppSelector((state) => state.booking);
    const reportState = useAppSelector((state) => state.reports);

    const dispatch = useAppDispatch();

    const {getFlightList, getFlightList2, getPaxesList, flightsBook} = TripService();

    const {searchTravelVariants} = OrderService();


    const {open, close} = useContext(ModalContext);
    const [bookTicketModalId] = useState(Guid.newGuid());


    const [tickets, setTickets] = useState<IAviaRejsInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);

    const [isLoadingPassengers, setIsLoadingPassengers] = useState(false);
    const [isPassengersNotFound, setIsPassengersNotFound] = useState(false);
    const [passengers, setPassengers] = useState<IPassenger[]>([]);
    const [selectedPassengers, setSelectedPassengers] = useState<IPassenger[]>([]);

    const [ticketToBook, setTicketToBook] = useState<IAviaRejsInfo | undefined>(undefined);
    const [isBookingPending, setIsBookingPending] = useState(false);
    const [order, setOrder] = useState<IBookingOrder | undefined>(undefined);

    const searchFlightsHandler = async (origin: string, direction: string, date: string) => {
        setIsLoading(true);
        setIsSearchResultNotFound(false);
        // setSelectedDocs([]);
        // setActiveDoc(undefined);
        setTickets([]);

        const response = await getFlightList2(origin, direction, date, authState.currentClientCode, null);

        if (response !== null && response.length > 0) {
            setTickets(response.map(x => x.rejsInfo).flat());
        } else {
            setIsSearchResultNotFound(true);
        }

        setIsLoading(false);
    };


    const onRequestBooking = async (id: string, ticket: IAviaRejsInfo) => {
        setTicketToBook(ticket);

        open(bookTicketModalId);

        loadPassengers();
    }


    const loadPassengers = async () => {
        setIsLoadingPassengers(true);

        const response = await getPaxesList(authState.currentClientCode);

        setPassengers(response);
        setIsLoadingPassengers(false);
    }


    const onBookTicketModalOpenedHandler = async () => {
        //setBookingError(false);
    };

    const bookHandler = async (ticket: IAviaRejsInfo, tariff: IAviaTariffInfo, passengers: IPassenger[]) => {
        //setBookingError(false);
        setIsBookingPending(true);

        let request: IBookFlightRequest = {
            requested: {
                trips: [
                    {
                        passengers: passengers.map(x => {
                            return Object.assign(x, {
                                first: x.fio.split(' ')[1] ?? "",
                                middle: x.fio.split(' ')[2] ?? "",
                                last: x.fio.split(' ')[0] ?? "",
                                CountryCode: "RU",
                                PassportInfo: Object.assign(x.PassportInfo, {
                                    typeData: "rus",
                                } as any)
                            } as IPassenger)
                        }),
                        dirs: [
                            {
                                townFrom: ticket.cityDep.replace(/ *\([^)]*\) */g, ""),
                                townTo: ticket.cityArr.replace(/ *\([^)]*\) */g, ""),
                                departDate: ticket.dateDep,
                                // departDate: (() => {
                                //   const departDate = new Date(ticket.dateDep);
                                //   return departDate.toLocaleDateString()
                                // })(),
                                departTime: ticket.dateDep,
                                // departTime: (() => {
                                //   const departDate = new Date(ticket.dateDep);
                                //   return departDate.toLocaleTimeString()
                                // })(),
                                tripWay: {
                                    isAvia: true
                                },
                                fareBasises: tariff.fareBasis
                            }
                        ]
                    }
                ]
            }
        };

        const response = await flightsBook(authState.currentClientCode, request);

        if(response != null) {
            let orders = bookingState.orders.concat([response]);
            setOrder(response);
            dispatch(setOrders(orders));
        }

        setIsBookingPending(false);

        setTicketToBook(undefined);
        setSelectedPassengers([]);
        setPassengers([]);

        close(bookTicketModalId);
    }

    const onIssueHandler = async () => {

    }

    const onBookTicketModalClosedHandler = async () => {
        //setBookingError(false);
        setTicketToBook(undefined);
        setSelectedPassengers([]);
        setPassengers([]);
    };


    useEffect(() => {
    }, []);


    // return (
    //     <div className="trip-timeline">
    //
    //         <div className="trip-node">
    //             <div className="trip-node-header">
    //                 <div className="trip-node-time">
    //                     14:10, Окт. 10
    //                 </div>
    //                 <button className="trip-node-button">
    //
    //                 </button>
    //                 <div className="trip-node-location">
    //                     Калининград (KLD)
    //                 </div>
    //             </div>
    //             <div className="trip-node-content">
    //
    //                 <button className="avia-ticket-button">
    //
    //                 </button>
    //
    //             </div>
    //         </div>
    //
    //         <div className="trip-node">
    //             <div className="trip-node-header">
    //                 <div className="trip-node-time">
    //                     16:10, Окт. 10
    //                 </div>
    //                 <button className="trip-node-button --active">
    //
    //                 </button>
    //                 <div className="trip-node-location">
    //                     Москва (DME)
    //                 </div>
    //             </div>
    //             <div className="trip-node-content">
    //                 <MdButton
    //                     type="submit"
    //                     style={{
    //                         "button": {
    //                             borderWidth: 0,
    //                             backgroundColor: "rgb(253 253 253)",
    //                             color: "rgb(175 175 175)",
    //                             border: "1px solid rgb(175 175 175)",
    //                             borderStyle: "dashed",
    //                             borderRadius: "6px",
    //                             height: "30px",
    //                             padding: "5px 15px"
    //                         },
    //                         "ink": {
    //                             backgroundColor: "rgba(255,255,255,.5)"
    //                         }
    //                     }}
    //                 >
    //                     <span>Добавить</span>
    //                 </MdButton>
    //             </div>
    //         </div>
    //
    //     </div>
    // )


    const orders = bookingState.orders;


    return (
        <>




            <TicketSearchComponent
                isLoading={isLoading}
                searchHandler={searchFlightsHandler}
            />


            <div className="order-list">
                {
                    orders.map(order => {
                        return (
                            <div className="order-list-item">
                                <div className="order-list-item-header">
                                    <span style={{flex: 1}}>Заказ № {order.orderId}</span>
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                width: "auto",
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => onIssueHandler()}
                                    >
                                        Выписать
                                    </MdButton>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                !isLoading && !isSearchResultNotFound && tickets.length > 0 && (
                    <>
                        <div className="ticket-list">
                            {
                                tickets.map((ticket, index) =>
                                    <div key={index}>
                                        <TicketComponent
                                            ticket={ticket}
                                            requestBooking={onRequestBooking}
                                        />
                                    </div>
                                )
                            }
                        </div>

                        {
                            ticketToBook != null && (
                                <MdModal
                                    id={bookTicketModalId}
                                    title="Бронирование билета"
                                    openHandler={onBookTicketModalOpenedHandler}
                                    closeHandler={onBookTicketModalClosedHandler}>
                                    <BookingComponent
                                        ticket={ticketToBook}
                                        isLoadingPassengers={isLoadingPassengers}
                                        reloadPassengers={loadPassengers}
                                        passengers={passengers}
                                        selectedPassengers={selectedPassengers}
                                        setSelectedPassengers={setSelectedPassengers}
                                        isBookingPending={isBookingPending}
                                        error={false}
                                        bookHandler={bookHandler}
                                    />
                                </MdModal>
                            )
                        }
                    </>
                )
            }

            {
                isLoading && (
                    <div className="table-skeleton-preloader">
                        {
                            Array.from(Array(10).keys()).map((item, index) => {
                                return (
                                    <div key={index} className="table-skeleton-item"></div>
                                )
                            })
                        }
                    </div>
                )
            }

            {
                isSearchResultNotFound && (
                    <div className="empty-search-result-message">
                        Билеты не найдены, попробуйте изменить параметры поиска и повторите.
                    </div>
                )
            }

        </>
    );
}
