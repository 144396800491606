import { useCookies } from "react-cookie";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ACCESS_TOKEN } from "../constants/FieldsConstants";
import {RegisterApi} from "../api/RegisterApi";
import { DateService } from "./DateService";
import {setCustomerRegisterNames, setSentToEdmBuhDocs} from "../redux/reportsSlice";


export function RegisterService() {
  const { getReconciliationAct, getCustomerRegisters, sendRegisterToEdm } = RegisterApi();
  const { dateFromDatePickerToCustomFormat } = DateService();
  const authState = useAppSelector((state) => state.auth);
  const reportsState = useAppSelector((state) => state.reports);

  const [cookies] = useCookies([ACCESS_TOKEN]);
  const dispatch = useAppDispatch();

  const getReconciliation = async (clientCode: string, startDate: string, endDate: string, connectionId: string | null) => {
    try {
      const response = await getReconciliationAct(clientCode, startDate, endDate, connectionId);
      if (response.status === 200) {
        dispatch(setCustomerRegisterNames(response.data))
        return response.data;
      }
      return response.data;
    } catch (error) {
      return []
    }
  }

  const getRegisters = async () => {
    try {
      const response = await getCustomerRegisters();
      if (response.status === 200) {
        dispatch(setCustomerRegisterNames(response.data))
        return response.data;
      }
      return response.data;
    } catch (error) {
      return []
    }
  }

  const sendRegister = async (regName: string, dateFrom: string, dateTo: string, recipients: string[]) => {
    const response = await sendRegisterToEdm(regName, dateFrom, dateTo, "Ф0014196", recipients, true, cookies[ACCESS_TOKEN]);
    return response;
  }

  return {
    getReconciliation,
    getRegisters,
    sendRegister,
  };
}
