import { useState } from "react";
import { AllStatisticComponent } from "./allStatisticComponent.tsx/AllStatisticComponent";
import { CommonCalendarComponent } from "../../commonComponents/commonCalendarComponent";
import { StatisticTabComponent } from "./staticticTabComponent";
import {
  COMMON_PAGE,
  CUSTOMER_ADDITIONAL_SERVICES,
  CUSTOMER_AIR_COMPANIES,
  CUSTOMER_TICKET_ORDERS,
  STATEMENT_SALES,
  STATEMENT_INCOMES,
  USER_ACCOUNT,
  CUSTOMER_BUH_DOCS,
  CUSTOMER_FLIGHTS,
} from "../../../constants/routeConstants/InnerRouteConstants";
import { ClientStructureComponent } from "./clientStructureComponent/ClientStructureComponent";
import { StatementBalanceComponent } from "./balanceComponent";
import { StatementSalesComponent } from "./statementSalesComponent";
import { StatementIncomesComponent } from "./statementIncomesComponent";
import { CustomerBuhDocsComponent } from "./customerBuhDocsComponent/CustomerBuhDocsComponent";
import { CustomerFlightsComponent } from "./customerFlightsComponent";
import { CustomerAirCompaniesComponent } from "./customerAirCompaniesComponent";
import { CustomerTicketOrdersComponent } from "./customerTicketOrdersComponent";
import { CustomerAdditionalServiceComponent } from "./customerAdditionalServiseComponent";
import { IStatementSale } from "../../../interfaces/reportInterfaces/IStatementSale";
import { ReportsApi } from "../../../api/ReportsApi";
import { SearchByDateComponent } from "../../commonComponents/searchByDateComponent";
import { useTranslation } from "react-i18next";
import { ReportService } from "../../../services/ReportService";
import { IError } from "../../../interfaces/dataInterfaces/IError";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ErrorInputComponent } from "../../commonComponents/ErrorComponents/errorInputComponent/ErrorInputComponent";
import { CustomerApi } from "../../../api/CustomerApi";
import { useCookies } from "react-cookie";
import { ACCESS_TOKEN } from "../../../constants/FieldsConstants";

export function StatisticPage() {
  //services
  const { getStatemetSales } = ReportsApi();
  const { getCustomerAdditionalServices } = CustomerApi();
  const { getReportsForPeriod } = ReportService();
  const reportState = useAppSelector((state) => state.reports);
  const [cookies] = useCookies([ACCESS_TOKEN]);

  //hooks
  const [currentInfoTab, setCurrentInfoTab] = useState(COMMON_PAGE);
  const [errors, setErrors] = useState<IError[]>([]);
  const [statementSales, setStatementCurrentSales] = useState<IStatementSale[]>([]);
  const [isStatementSalesRequired, setIsStatementSalesRequired] = useState(false);
  const [requiredReports, setRequiredReports] = useState({
    isStatementSalesRequired: true,
    isStatementIncomesRequired: true,
    isCustomerFlightsRequired: true,
    isCustomerBuhDocsRequired: true,
    isCustomerAirCompaniesRequired: true,
    isCustomerTicketOrdersRequired: true,
    isCustomerAdditionalService: true,
  });

  const tabHandler = (tabName: string = COMMON_PAGE) => {
    setCurrentInfoTab(tabName);
  };

  //
  const requiredReportsHandler = (tabName: string, value: boolean) => {
    switch (tabName) {
      case STATEMENT_SALES:
        setRequiredReports({ ...requiredReports, isStatementSalesRequired: value });
        break;
      case STATEMENT_INCOMES:
        setRequiredReports({ ...requiredReports, isStatementIncomesRequired: value });
        break;
      case CUSTOMER_FLIGHTS:
        setRequiredReports({ ...requiredReports, isCustomerFlightsRequired: value });
        break;
      case CUSTOMER_TICKET_ORDERS:
        setRequiredReports({ ...requiredReports, isCustomerTicketOrdersRequired: value });
        break;
      case CUSTOMER_AIR_COMPANIES:
        setRequiredReports({ ...requiredReports, isCustomerAirCompaniesRequired: value });
        break;
      case CUSTOMER_ADDITIONAL_SERVICES:
        setRequiredReports({ ...requiredReports, isCustomerAdditionalService: value });
        break;
    }
  };

  const searchHandler = async (startDate: string, endDate: string) => {
    if (errors.length !== 0) return;
    const err = await getReportsForPeriod(requiredReports, startDate, endDate);
    setErrors(err);
  };

  return (
      <>
        <SearchByDateComponent runSearch={searchHandler}></SearchByDateComponent>
        {errors && errors.map((err) => <ErrorInputComponent error={err.errorMessage}/>)}
        <div className="filters-container">
          <StatisticTabComponent
              activeTabName={currentInfoTab}
              changeTab={tabHandler}
              requiredReports={requiredReports}
              reportsCheckHandler={requiredReportsHandler}
          />
          {/* CommonPage */}


          {currentInfoTab === USER_ACCOUNT && <CommonCalendarComponent/>}
          {/* StatementSales */}
          {currentInfoTab === STATEMENT_SALES && (
              <StatementSalesComponent statementSalesByPeriod={reportState.statementSales}/>
          )}
          {/* StatementIncomes */}
          {currentInfoTab === STATEMENT_INCOMES && (
              <StatementIncomesComponent statementIncomes={reportState.statementIncomes}/>
          )}
          {/* CustomerFlights */}
          {currentInfoTab === CUSTOMER_FLIGHTS && (
              <CustomerFlightsComponent customerFlights={reportState.customerFlights}/>
          )}
          {/* CustomerBuhDoc */}
          {currentInfoTab === CUSTOMER_BUH_DOCS && <CustomerBuhDocsComponent/>}
          {/* CustomerAirCompanies */}
          {currentInfoTab === CUSTOMER_AIR_COMPANIES && (
              <CustomerAirCompaniesComponent customerAirCompanies={reportState.customerAirCompanies}/>
          )}
          {/* CustomerTicketOrders*/}
          {currentInfoTab === CUSTOMER_TICKET_ORDERS && (
              <CustomerTicketOrdersComponent customerTicketOrders={reportState.customerTicketOrders}/>
          )}
          {/* CustomerAdditionalServices*/}
          {currentInfoTab === CUSTOMER_ADDITIONAL_SERVICES && (
              <CustomerAdditionalServiceComponent
                  customerAddServices={reportState.customerAdditionalServices}
              />
          )}
        </div>
        <div className="filters-container ">
          {currentInfoTab === COMMON_PAGE && (
              <AllStatisticComponent
                  clientSales={reportState.reportClientStructureSales}
                  clientExchnge={reportState.reportClientStructureExchange}
                  clientRefound={reportState.reportClientStructureRefound}
              />
          )}
        </div>
        <div className="filters-container">
          <div className="filters-form">
            <div className="filters-caption">
              {currentInfoTab === COMMON_PAGE && (
                  <StatementBalanceComponent
                      openBalance={reportState.statementOpenBalance}
                      closeBalance={reportState.statementCloseBalance}
                  />
              )}
            </div>

          </div>
        </div>
        <div className="filters-container">
          <div className="filters-form">

            {currentInfoTab === COMMON_PAGE && (
                <ClientStructureComponent
                    clientSales={reportState.reportClientStructureSales}
                    clientExchnge={reportState.reportClientStructureExchange}
                    clientRefound={reportState.reportClientStructureRefound}
                />
            )}
          </div>
        </div>
      </>
  );
}
