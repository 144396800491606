import { ROUTE_SEARCH_PAX_INFO } from "../constants/routeConstants/ApiRouteConstants";
import { useAppSelector } from "../redux/hooks";
import { SearchPaxInfo } from "../responces/companyInfoResponses/SearchPaxInfo";
import { axiosInstance } from "./AxiosInstance";

export function UserApi() {
  //const authState = useAppSelector((state) => state.auth);
  //route: /searchPaxInfo paxName example :" "ДЕНЕКИН ИВАН АЛЕКСАНДРОВИЧ""
  const searchPaxInfo = async (clientCode: string, paxName?: string) => {
    const result = await axiosInstance.get<SearchPaxInfo>(
      ROUTE_SEARCH_PAX_INFO,
      { params: { clientCode: clientCode, nameLike: paxName } }
    );
    return result.data;
  };

  return searchPaxInfo;
}
