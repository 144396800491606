import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {t} from "i18next";
import {AuthApi} from "../../../api/AuthApi";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {useAppSelector} from "../../../redux/hooks";
import "./style.scss";


export function RecoveryPasswordPage() {
    const authState = useAppSelector(state => state.auth);

    const navigate = useNavigate();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [recoveryError, setRecoveryError] = useState("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [recoveryCompleted, setRecoveryCompleted] = useState(false);

    const {recoveryPassword, getUserActiveState} = AuthApi();

    const changeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setError("");
        setEmail(event.target.value);
    }


    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setLoadingSpinner(true);

        if (email === "") {
            setLoadingSpinner(false);
            setError("поле не заполнено");
            return;
        }
        else if(!isValidEmail(email)) {
            setLoadingSpinner(false);
            setError("не корректный почтовый адрес");
            return;
        }

        const result = await recoveryPassword(email);

        if(result == null) {

        }
        else {
            if (result) {
                setRecoveryCompleted(true);
            } else {
                setLoadingSpinner(false);
                setRecoveryError('Проверьте правильность ввода почтового адреса.');
            }
        }
    }


    const isValidEmail = (email: string) => {
        //return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        //return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
        return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    }


    useEffect(() => { }, []);


    return (
        <>
            {
                !recoveryCompleted
                ? (
                        <form className="login-form">
                            <div className="login-form-header">Сброс пароля</div>

                            <div className="login-form-body">

                                <MdTextField
                                    type="email"
                                    required={true}
                                    label={t("email")}
                                    value={email}
                                    invalid={error.length !== 0}
                                    errorMessage={error}
                                    onChange={(e) => {
                                        changeHandler(e);
                                    }}
                                />

                                <div className="login-form-error-message">
                                    {recoveryError && <ErrorMessage error={recoveryError}/>}
                                </div>

                                {
                                    !loadingSpinner
                                        ? (
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "14px",
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                    }
                                                }}
                                                onClick={(event) => submitHandler(event)}
                                            >
                                                Сбросить
                                            </MdButton>
                                        )
                                        : (
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "20px 0 0 0 "
                                            }}>
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </div>
                                        )
                                }
                            </div>
                        </form>
                )
                : (
                    <div className="recovery-complete-message">
                        <p>Сброс пароля успешно завершен. На вашу почту отправлено письмо с ссылкой для изменения пароля.</p>
                        <MdButton
                            type="submit"
                            style={{
                                "button": {
                                    margin: "auto",
                                    marginTop: "10px",
                                    width: "200px",
                                    height: "40px",
                                    fontSize: "14px",
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                }
                            }}
                            onClick={(event) => navigate("/")}
                        >
                            На главную
                        </MdButton>
                    </div>
                )
            }
        </>
    );
}
