import { combineReducers, configureStore, } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authReducer from "./authSlice";
import companyInfoReducer from "./CompanyInfoSlice";
import reportsReducer from "./reportsSlice";
import orderReducer from "./orderSlice";
import bookingReducer from "./bookSlice";

import { thunk } from "redux-thunk";
import {createStateSyncMiddleware} from "redux-state-sync";


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // if you do not want to persist this part of the state
  // blacklist: ['omitedPart']
}

const reducer = combineReducers({
  auth: authReducer,
  companyInfo: companyInfoReducer,
  reports: reportsReducer,
  order:orderReducer,
  booking:bookingReducer,
})
const persistedReducer = persistReducer(persistConfig, reducer)


const syncStateMiddleware = [
    createStateSyncMiddleware({
        blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
    })
];


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(syncStateMiddleware as any),
});

//export default store;

// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     companyInfo: companyInfoReducer,
//   },
//   preloadedState: {},
// });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

