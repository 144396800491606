import React, {
    Children,
    useEffect,
    useRef,
    useState
} from "react";
import {MdButton} from "../md-button";
import {ArrowLeftIcon} from "../../icons/ArrowLeftIcon";
import {ThreeDotsIcon} from "../../icons/ThreeDotsIcon";
import {CrossIcon} from "../../icons/CrossIcon";
import {SmallArrowRightIcon} from "../../icons/SmallArrowRightIcon";
import {PlusIcon} from "../../icons/PlusIcon";
import {SmileIcon} from "../../icons/SmileIcon";
import {AttachmentIcon} from "../../icons/AttachmentIcon";
import {MagnifierIcon} from "../../icons/MagnifierIcon";
import SignalRService from "../../services/SignalRService";
import {useAppSelector} from "../../redux/hooks";
import './style.scss'
import {HubConnection, HubConnectionState} from "@microsoft/signalr";
import {axiosInstance} from "../../api/AxiosInstance";
import {
    BASE_URL,
    BEARER,
    ROUTE_GET_ALLOWED_PERSONS,
    ROUTE_REPORTS_HUB
} from "../../constants/routeConstants/ApiRouteConstants";
import {MdTabs} from "../md-tabs";
import {ChatIcon2} from "../../icons/ChatIcon2";
import {CircleSpinnerIcon} from "../../icons/CircleSpinnerIcon";
import {AirplaneIcon} from "../../icons/AirplaneIcon";
import {HotelIcon2} from "../../icons/HotelIcon2";
import {TrainIcon2} from "../../icons/TrainIcon2";
import {MeetingIcon} from "../../icons/MeetingIcon";
import {IGeneratedDocument, IReadyToDownloadDoc} from "../../interfaces/reportInterfaces/accountantPageInterfaces";
import {addAvailableEdmBuhDocsThunk} from "../../redux/reportsSlice";


enum LayoutType {
    List,
    Room,
}


enum OrderType {
    Avia,
    Hotel,
    Train,
    MICE
}


interface IUser {
    id: string;
    email: string;
    name: string;
    surname: string;
    Device: string;
}

interface IRoom {
    id: string;
    type: OrderType;
    name: string;
    date: string;
    utcOffset: string;
}

interface IMessage {
    id: string,
    roomId: string;
    fromUser: string;
    content: string;
    date: string;
    utcOffset: string;
    read: boolean;
}


interface IChatComponentProps {
    onCloseHandler?: (e: React.MouseEvent) => void;
}

interface IChatListProps {
    userData: IUser | null;
    activeTab: number;
    setActiveTab: (index: number) => void;
    rooms: IRoom[];
    messages: IMessage[];
    roomCreating: boolean;
    onCreateRoomHandler: (type: OrderType) => void;
    onSelectRoomHandler: (roomId: string) => void;
    onAddChatHandler?: (e: React.MouseEvent) => void;
    onCloseHandler?: (e: React.MouseEvent) => void;
}

interface IChatListItemProps {
    icon?: any;
    userData: IUser | null;
    room: IRoom;
    messages: IMessage[];
    onSelect: (roomId: string) => void;
}

interface IChatProps {
    userData: IUser | null;
    room: IRoom | null,
    messages: IMessage[],
    //onUserStartPrintingHandler: (roomId: string, userId: string) => void;
    //onUserEndPrintingHandler: (roomId: string, userId: string) => void;
    onBackToListHandler?: (e: React.MouseEvent) => void;
    onSendMessageHandler: (roomId: string, message: string) => void;
    onCloseHandler?: (e: React.MouseEvent) => void;
}


export function ChatComponent({
                                  onCloseHandler
                              }: IChatComponentProps) {

    const initialized = useRef(false);

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);


    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [connectionId, setConnectionId] = useState<string | null>(null);

    const [userData, setUserData] = useState<IUser | null>(null);

    const [activeLayout, setActiveLayout] = useState<LayoutType>(LayoutType.List);

    const [activeTab, setActiveTab] = useState<OrderType>(OrderType.Avia);

    const [rooms, setRooms] = useState<IRoom[]>([]);
    const [activeRoom, setActiveRoom] = useState<IRoom | null>(null);
    const [roomCreating, setRoomCreating] = useState<boolean>(false);


    const [messages, setMessages] = useState<IMessage[]>([]);


    const hubUrl = "http://localhost:5001"


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            const initSignalR = async (hostUrl: string) => {
                let connection =
                    await SignalRService.startConnection(hostUrl,
                        {
                            accessTokenFactory: () => authState.accessToken
                        });
                setConnection(connection);
                setConnectionId(connection.connectionId);
                return connection;
            };
            initSignalR(hubUrl + "/chatHub").then(connection => {
                if (connection.state == HubConnectionState.Connected) {

                    SignalRService.addEventListener('userConnected', async function (user: IUser) {
                        console.warn('[SignalR message] userConnected', user);
                        await onUserConnect(user);
                    });

                    SignalRService.addEventListener('userDisconnected', async function (user: IUser) {
                        console.warn('[SignalR message] userDisconnected', user);
                        await onUserDisconnect(user);
                    });

                    SignalRService.addEventListener('roomCreated', async function (userId: string, room: IRoom) {
                        console.warn('[SignalR message] createdRoom', userId, room);
                        await onRoomCreate(userId, room);
                    });

                    SignalRService.addEventListener('messageOwnSent', async function (roomId: string, message: IMessage) {
                        console.warn('[SignalR message] messageOwnSent', roomId, message);
                        await onSentOwnMessage(roomId, message);
                    });

                    SignalRService.addEventListener('messageOtherSent', async function (roomId: string, message: IMessage) {
                        console.warn('[SignalR message] messageOtherSent', roomId, message);
                        await onSentOtherMessage(roomId, message);
                    });

                    SignalRService.addEventListener('inviteToRoom', async function (roomId: string) {
                        console.warn('[SignalR message] inviteToRoom', roomId);
                        await onInvite(roomId);
                    });


                    getAllRooms().then(() => {
                        getAllMessages();
                    });
                }
            });
        }
    }, []);


    // api requests
    const getAllRooms = async () => {
        console.warn('[Request] api/rooms');
        try {
            const result = await axiosInstance.get<IRoom[]>(
                hubUrl + "/api/rooms", {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true
                }
            );
            console.warn('[Response] api/rooms', result);
            if(result.data != null) {
                setRooms(result.data);
            }
        } catch (ex) {
            console.error('[Response] api/rooms', ex);
        }
    };

    const getRoom = async (roomId: string) => {
        console.warn('[Request] api/rooms/' + roomId);
        try {
            const result = await axiosInstance.get<IRoom>(
                hubUrl + "/api/rooms/" + roomId, {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true
                }
            );
            console.warn('[Response] api/rooms/' + roomId, result);
            return result.data;
        } catch (ex) {
            console.error('[Response] api/rooms/' + roomId, ex);
        }
        return null;
    };

    const getAllMessages = async () => {
        console.warn('[Request] api/messages');
        try {
            const result = await axiosInstance.get<IMessage[]>(
                hubUrl + "/api/messages", {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true
                }
            );
            console.warn('[Response] api/messages', result);
            if(result.data != null) {
                setMessages(result.data);
            }
        } catch (ex) {
            console.error('[Response] api/messages', ex);
        }
    };

    const getRoomMessages = async (roomId: string) => {
        console.warn('[Request] api/messages?roomId=' + roomId);
        try {
            const result = await axiosInstance.get<IMessage[]>(
                hubUrl + "/api/messages",
                {
                        headers: {
                            Authorization: BEARER + authState.accessToken,
                        },
                        params: {
                            roomId: roomId
                        },
                        withCredentials: true,
                    },
            );
            console.warn('[Response] api/messages?roomId=' + roomId, result);
            return result.data;
        } catch (ex) {
            console.error('[Response] api/messages?roomId=' + roomId, ex);
        }
        return null;
    };

    const createRoom = async (type: OrderType) => {
        console.warn('[Request] api/rooms/create', type);
        try {
            const result = await axiosInstance.post<IRoom>(
                hubUrl + "/api/rooms/create",
                {
                    Type: type,
                },
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] api/rooms/create', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] api/rooms/create', ex);
        }
        return null;
    }

    const sendMessage = async (roomId: string, message: string) => {
        console.warn('[Request] api/messages/send', { roomId: roomId, message: message });
        try {
            const result = await axiosInstance.post(
                hubUrl + "/api/messages/send",
                {
                    roomId: roomId,
                    message: message,
                },
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] api/messages/send', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] api/messages/send', ex);
        }
        return null;
    }


    // callbacks from signalR
    const onUserConnect = async (user: IUser) => {
        setUserData(user);
    };

    const onUserDisconnect = async (user: IUser) => {
        setUserData(null);
        setMessages([]);
        setRooms([]);
    };

    const onRoomCreate = async (userId: string, room: IRoom) => {
        const roomData = await getRoom(room.id);
        if(roomData != null) {
            setRooms(prev => [...prev.filter(x => x.id != room.id), roomData]);
        }
    };

    const onInvite = async (roomId: string) => {
        const roomData = await getRoom(roomId);
        if(roomData != null) {
            setRooms(prev => [...prev.filter(x => x.id != roomId), roomData]);
        }

        const roomMessages = await getRoomMessages(roomId);
        if(roomMessages != null) {
            setMessages(prev => [...prev.filter(x => x.roomId != roomId), ...roomMessages]);
        }
    };

    const onSentOwnMessage = async (roomId: string, message: IMessage) => {
        const roomMessages = await getRoomMessages(roomId);
        if(roomMessages != null) {
            setMessages(prev => [...prev.filter(x => x.roomId != roomId), ...roomMessages]);
        }
    };

    const onSentOtherMessage = async (roomId: string, message: IMessage) => {
        const roomMessages = await getRoomMessages(roomId);
        if(roomMessages != null) {
            setMessages(prev => [...prev.filter(x => x.roomId != roomId), ...roomMessages]);
        }
    };


    // handlers
    const onAddChatHandler = async () => {
        setRoomCreating(true);
        let room = await createRoom(activeTab);
        if(room != null) {
            setActiveRoom(room);
            setActiveLayout(LayoutType.Room);
        }
        setRoomCreating(false);
    }

    const onBackToListHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        setActiveLayout(0);
    }

    const onCreateRoomHandler = async (type: OrderType) => {
        setRoomCreating(true);
        let room = await createRoom(type);
        if(room != null) {
            setActiveRoom(room);
            setActiveLayout(LayoutType.Room);
        }
        setRoomCreating(false);
    }

    const onSelectRoomHandler = (roomId: string) => {
        let room = rooms.find(x => x.id == roomId);
        if (room != null) {
            setActiveRoom(room);
            setActiveLayout(LayoutType.Room);
        }
    }

    const onSendMessageHandler = async (chatId: string, message: string) => {
        await sendMessage(chatId, message.trimEnd());
    }

    // const onUserStartPrintingHandler = (roomId: string) => {
    //     if (!activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             console.warn(authState.email, connection?.connectionId);
    //             connection?.invoke("StartPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }

    // const onUserEndPrintingHandler = (roomId: string) => {
    //     if (activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             connection?.invoke("EndPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }


    // ????
    const onCloseButtonClick = (event: React.MouseEvent) => {
        event.preventDefault();
        if (onCloseHandler) {
            onCloseHandler(event);
        }
    }


    useEffect(() => {

    }, []);


    return (
        <div ref={root}
             className="chat">
            {
                activeLayout == 0
                    ? (
                        <ChatList
                            userData={userData}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            rooms={rooms}
                            messages={messages}
                            roomCreating={roomCreating}
                            onAddChatHandler={onAddChatHandler}
                            onCreateRoomHandler={onCreateRoomHandler}
                            onSelectRoomHandler={onSelectRoomHandler}
                            onCloseHandler={onCloseHandler}
                        />
                    )
                    : (
                        <Chat
                            userData={userData}
                            room={activeRoom}
                            messages={messages?.filter(x => x.roomId == activeRoom?.id) ?? []}
                            //onUserStartPrintingHandler={onUserStartPrintingHandler}
                            //onUserEndPrintingHandler={onUserEndPrintingHandler}
                            onBackToListHandler={onBackToListHandler}
                            onCloseHandler={onCloseHandler}
                            onSendMessageHandler={onSendMessageHandler}
                        />
                    )
            }
        </div>
    )
}


export function ChatList({userData, activeTab, setActiveTab, rooms, messages, roomCreating, onCreateRoomHandler, onSelectRoomHandler, onAddChatHandler, onCloseHandler}: IChatListProps) {

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);

    const onTabChange = (index: number) => {
        setActiveTab(index);
    }


    useEffect(() => {
    }, []);


    return (
        <div ref={root}
             className="chat-layout">
            <div className="chat-header" style={{paddingBottom: 0}}>
                <div className="chat-header-title">
                    <MdButton
                        icon={
                            <PlusIcon
                                width="18px"
                                height="18px"
                                style={{
                                    "path": {
                                        stroke: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={onAddChatHandler}
                    />
                    <span className="chat-description" style={{textAlign: "center"}}>
                        Чаты
                    </span>
                    <MdButton
                        icon={
                            <ThreeDotsIcon
                                width="16px"
                                height="16px"
                                style={{
                                    "path": {
                                        fill: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                    />
                    <MdButton
                        icon={
                            <CrossIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        fill: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                marginLeft: "5px",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={onCloseHandler}
                    />
                </div>
                <div className="chat-header-details" style={{padding: 0, justifyContent: "center"}}>
                    <div className="chat-search-bar">
                        <MagnifierIcon
                            width="16px"
                            height="16px"
                            style={{
                                "path": {
                                    fill: "#CCCCCC"
                                }
                            }}
                        />
                        <input type="text" placeholder="поиск"/>
                    </div>
                </div>
            </div>
            <ul className="chat-list">
                <MdTabs
                    activeIndex={activeTab}
                    onTabChange={onTabChange}
                    tabs={[
                        {
                            header: (
                                <span className="tab-header">
                                    Авиа
                                    {
                                        messages
                                            .some(m =>
                                                m.fromUser != userData?.id
                                                && !m.read
                                                && rooms
                                                    .filter(r => r.type == OrderType.Avia)
                                                    .map(r => r.id)
                                                    .includes(m.roomId)) && (
                                            <span className="unread-indicator"></span>
                                        )
                                    }
                                </span>
                            ),
                            content: (
                                !rooms.some(x => x.type == OrderType.Avia)
                                    ? (
                                        <div className="no-items-message">
                                            <div className="no-items-message-icon">
                                                <ChatIcon2 width="40px" height="40px" style={{
                                                    "path": {
                                                        fill: "rgb(100 100 100)"
                                                    }
                                                }}/>
                                            </div>
                                            <div className="no-items-message-caption">Нет заказов в этой категории</div>
                                            <div className="no-items-message-content">Нажмите на кнопку ниже чтобы создать
                                                новый заказ
                                            </div>
                                            <div className="no-items-message-button">
                                                {
                                                    roomCreating
                                                        ? (
                                                            <CircleSpinnerIcon
                                                                width="30px"
                                                                height="30px"
                                                                style={{
                                                                    "svg": {
                                                                      margin: "2px"
                                                                    },
                                                                    "circle": {
                                                                        stroke: "#F9952A"
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                        : (
                                                            <MdButton
                                                                style={{
                                                                    "button": {
                                                                        borderWidth: 0,
                                                                        backgroundColor: "rgb(233 233 233)",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        height: "35px",
                                                                        padding: "5px 15px"
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                                    }
                                                                }}
                                                                onClick={() => onCreateRoomHandler(OrderType.Avia)}
                                                            >
                                                                Создать заказ
                                                            </MdButton>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="chat-list-items">
                                            {
                                                rooms.filter(x => x.type == OrderType.Avia).map((room, index) => {
                                                    return (
                                                        <ChatListItem
                                                            key={index}
                                                            icon={
                                                                <AirplaneIcon
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#CCCCCC"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            userData={userData}
                                                            room={room}
                                                            messages={messages.filter(x => x.roomId == room.id)}
                                                            onSelect={onSelectRoomHandler}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            )
                        },
                        {
                            header: (
                                <span className="tab-header">
                                    Отели
                                    {
                                        messages
                                            .some(m =>
                                                m.fromUser != userData?.id
                                                && !m.read
                                                && rooms
                                                    .filter(r => r.type == OrderType.Hotel)
                                                    .map(r => r.id)
                                                    .includes(m.roomId)) && (
                                            <span className="unread-indicator"></span>
                                        )
                                    }
                                </span>
                            ),
                            content: (
                                !rooms.some(x => x.type == OrderType.Hotel)
                                    ? (
                                        <div className="no-items-message">
                                            <div className="no-items-message-icon">
                                                <ChatIcon2 width="40px" height="40px" style={{
                                                    "path": {
                                                        fill: "rgb(100 100 100)"
                                                    }
                                                }}/>
                                            </div>
                                            <div className="no-items-message-caption">Нет заказов в этой категории</div>
                                            <div className="no-items-message-content">Нажмите на кнопку ниже чтобы создать
                                                новый заказ
                                            </div>
                                            <div className="no-items-message-button">
                                                {
                                                    roomCreating
                                                        ? (
                                                            <CircleSpinnerIcon
                                                                width="30px"
                                                                height="30px"
                                                                style={{
                                                                    "svg": {
                                                                        margin: "2px"
                                                                    },
                                                                    "circle": {
                                                                        stroke: "#F9952A"
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                        : (
                                                            <MdButton
                                                                style={{
                                                                    "button": {
                                                                        borderWidth: 0,
                                                                        backgroundColor: "rgb(233 233 233)",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        height: "35px",
                                                                        padding: "5px 15px"
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                                    }
                                                                }}
                                                                onClick={() => onCreateRoomHandler(OrderType.Hotel)}
                                                            >
                                                                Создать заказ
                                                            </MdButton>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="chat-list-items">
                                            {
                                                rooms.filter(x => x.type == OrderType.Hotel).map((room, index) => {
                                                    return (
                                                        <ChatListItem
                                                            key={index}
                                                            icon={
                                                                <HotelIcon2
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#CCCCCC"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            userData={userData}
                                                            room={room}
                                                            messages={messages.filter(x => x.roomId == room.id)}
                                                            onSelect={onSelectRoomHandler}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            )
                        },
                        {
                            header: (
                                <span className="tab-header">
                                    ЖД
                                    {
                                        messages
                                            .some(m =>
                                                m.fromUser != userData?.id
                                                && !m.read
                                                && rooms
                                                    .filter(r => r.type == OrderType.Train)
                                                    .map(r => r.id)
                                                    .includes(m.roomId)) && (
                                            <span className="unread-indicator"></span>
                                        )
                                    }
                                </span>
                            ),
                            content: (
                                !rooms.some(x => x.type == OrderType.Train)
                                    ? (
                                        <div className="no-items-message">
                                            <div className="no-items-message-icon">
                                                <ChatIcon2 width="40px" height="40px" style={{
                                                    "path": {
                                                        fill: "rgb(100 100 100)"
                                                    }
                                                }}/>
                                            </div>
                                            <div className="no-items-message-caption">Нет заказов в этой категории</div>
                                            <div className="no-items-message-content">Нажмите на кнопку ниже чтобы создать
                                                новый заказ
                                            </div>
                                            <div className="no-items-message-button">
                                                {
                                                    roomCreating
                                                        ? (
                                                            <CircleSpinnerIcon
                                                                width="30px"
                                                                height="30px"
                                                                style={{
                                                                    "svg": {
                                                                        margin: "2px"
                                                                    },
                                                                    "circle": {
                                                                        stroke: "#F9952A"
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                        : (
                                                            <MdButton
                                                                style={{
                                                                    "button": {
                                                                        borderWidth: 0,
                                                                        backgroundColor: "rgb(233 233 233)",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        height: "35px",
                                                                        padding: "5px 15px"
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                                    }
                                                                }}
                                                                onClick={() => onCreateRoomHandler(OrderType.Train)}
                                                            >
                                                                Создать заказ
                                                            </MdButton>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="chat-list-items">
                                            {
                                                rooms.filter(x => x.type == OrderType.Train).map((room, index) => {
                                                    return (
                                                        <ChatListItem
                                                            key={index}
                                                            icon={
                                                                <TrainIcon2
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#CCCCCC"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            userData={userData}
                                                            room={room}
                                                            messages={messages.filter(x => x.roomId == room.id)}
                                                            onSelect={onSelectRoomHandler}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            )
                        },
                        {
                            header: (
                                <span className="tab-header">
                                    MICE
                                    {
                                        messages
                                            .some(m =>
                                                m.fromUser != userData?.id
                                                && !m.read
                                                && rooms
                                                    .filter(r => r.type == OrderType.MICE)
                                                    .map(r => r.id)
                                                    .includes(m.roomId)) && (
                                            <span className="unread-indicator"></span>
                                        )
                                    }
                                </span>
                            ),
                            content: (
                                !rooms.some(x => x.type == OrderType.MICE)
                                    ? (
                                        <div className="no-items-message">
                                            <div className="no-items-message-icon">
                                                <ChatIcon2 width="40px" height="40px" style={{
                                                    "path": {
                                                        fill: "rgb(100 100 100)"
                                                    }
                                                }}/>
                                            </div>
                                            <div className="no-items-message-caption">Нет заказов в этой категории</div>
                                            <div className="no-items-message-content">Нажмите на кнопку ниже чтобы создать
                                                новый заказ
                                            </div>
                                            <div className="no-items-message-button">
                                                {
                                                    roomCreating
                                                        ? (
                                                            <CircleSpinnerIcon
                                                                width="30px"
                                                                height="30px"
                                                                style={{
                                                                    "svg": {
                                                                        margin: "2px"
                                                                    },
                                                                    "circle": {
                                                                        stroke: "#F9952A"
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                        : (
                                                            <MdButton
                                                                style={{
                                                                    "button": {
                                                                        borderWidth: 0,
                                                                        backgroundColor: "rgb(233 233 233)",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        height: "35px",
                                                                        padding: "5px 15px"
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                                    }
                                                                }}
                                                                onClick={() => onCreateRoomHandler(OrderType.MICE)}
                                                            >
                                                                Создать заказ
                                                            </MdButton>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="chat-list-items">
                                            {
                                                rooms.filter(x => x.type == OrderType.MICE).map((room, index) => {
                                                    return (
                                                        <ChatListItem
                                                            key={index}
                                                            icon={
                                                                <MeetingIcon
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#CCCCCC"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            userData={userData}
                                                            room={room}
                                                            messages={messages.filter(x => x.roomId == room.id)}
                                                            onSelect={onSelectRoomHandler}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            )
                        }
                    ]}
                />
            </ul>
        </div>
    )
}


export function ChatListItem({icon, userData, room, messages, onSelect}: IChatListItemProps) {

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);

    const unreadMessages = messages.filter(x => x.fromUser != userData?.id && !x.read);


    useEffect(() => {
    }, []);


    return (
        <div ref={root}
             className="chat-list-item"
             onClick={() => onSelect(room.id)}>

            <div className="chat-list-item-avatar"> { icon } </div>

            <div className="chat-list-item-details">
                <div className="chat-title">{room.name}</div>
                <div className="chat-last-message">
                    {
                        messages[messages.length - 1]?.content ?? <span className="no-messages-text">Сообщений нет</span>
                    }
                    {/*{*/}
                    {/*    printingUsers.length > 0*/}
                    {/*    ? <span style={{fontStyle: "italic"}}>{printingUsers.map(user => user.fullName + " печатает...")}</span>*/}
                    {/*    : lastMessage.content*/}
                    {/*}*/}
                </div>
            </div>

            <div className="chat-list-item-status">
                {
                    messages.length > 0
                        ? (
                            <div className="chat-last-message-time">
                                {
                                    new Date(messages[messages.length - 1].date).getHours().toString().padStart(2, "0") + ":" + new Date(messages[messages.length - 1].date).getMinutes().toString().padStart(2, "0")
                                }
                            </div>
                        ) : (
                            <div className="chat-last-message-time">
                                {
                                    new Date(room.date).getHours().toString().padStart(2, "0") + ":" + new Date(room.date).getMinutes().toString().padStart(2, "0")
                                }
                            </div>
                        )
                }
                {
                    unreadMessages.length > 0 && (
                        <div className="chat-unread-messages">{unreadMessages.length}</div>
                    )
                }
                {/*<div className="chat-last-message-time">{new Date(lastMessage.timestamp).getHours().toString().padStart(2, "0") + ":" + new Date(lastMessage.timestamp).getMinutes().toString().padStart(2, "0")}</div>*/}
                {/*{*/}
                {/*    unreadMessages.length > 0 && (*/}
                {/*        <div className="chat-unread-messages">{unreadMessages.length}</div>*/}
                {/*    )*/}
                {/*}*/}
            </div>

        </div>
    )
}


export function Chat({
                         userData,
                         room,
                         messages,
                         // onUserStartPrintingHandler = () => {
                         // },
                         // onUserEndPrintingHandler = () => {
                         // },
                         onBackToListHandler = () => {
                         },
                         onCloseHandler = () => {
                         },
                         onSendMessageHandler = () => {
                         }
                     }: IChatProps) {

    const initialized = useRef(false);

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);
    const header = useRef(null);
    const textArea = useRef(null);
    const messagesList = useRef(null);
    //const printTimer = useRef(null);

    let printTimer: NodeJS.Timeout;


    const onInput = () => {
        // if (printTimer != null) {
        //     clearTimeout(printTimer);
        // }
        //
        // onUserStartPrintingHandler(chat?.id ?? -1, authState.email);
        //
        // printTimer = setTimeout(() => {
        //     onUserEndPrintingHandler(chat?.id ?? -1, authState.email);
        // }, 1500);
    };

    const sendMessage = () => {
        if (textArea.current != null && (textArea.current as HTMLTextAreaElement).value.length > 0) {
            const ta = (textArea.current as HTMLTextAreaElement);
            if(room != null) {
                onSendMessageHandler(room.id, ta.value);
            }
            ta.value = "";
        }
    }


    useEffect(() => {
        if (messagesList.current != null) {
            //(messagesList.current as HTMLElement).scrollTop = (messagesList.current as HTMLElement).scrollHeight + 20;
        }
    }, [messages]);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            if(messagesList.current) {
                const unreadMessages = (messagesList.current as HTMLElement).querySelectorAll(".--unread-message");
                if(header.current != null && unreadMessages.length > 0) {

                    const headerBB = (header.current as HTMLElement).getBoundingClientRect();

                    const messageOffsetTop = (unreadMessages[0] as HTMLElement).offsetTop;

                    const messageStyle = window.getComputedStyle((unreadMessages[0] as HTMLElement));

                    (messagesList.current as HTMLElement).scrollTop = messageOffsetTop - headerBB.height - parseInt(messageStyle.marginTop);
                }
            }
        }
    }, []);


    return (
        <div ref={root}
             className="chat-layout">
            <div
                ref={header}
                className="chat-header">
                <div className="chat-header-title">
                    <MdButton
                        icon={
                            <ArrowLeftIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        stroke: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={onBackToListHandler}
                    />
                    <span className="chat-description">
                        {room?.name}
                        <span className="chat-status">обработка</span>
                    </span>
                    <MdButton
                        icon={
                            <ThreeDotsIcon
                                width="16px"
                                height="16px"
                                style={{
                                    "path": {
                                        fill: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                    />
                    <MdButton
                        icon={
                            <CrossIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        fill: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                marginLeft: "5px",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={onCloseHandler}
                    />
                </div>
                <div className="chat-header-details" style={{justifyContent: "start"}}>
                    <div className="origin-data">
                        <div className="city-code">KLD</div>
                        <div className="departure-date">25.07.24</div>
                        <div className="departure-time">13:00</div>
                    </div>
                    <div className="chat-header-divider">
                        <SmallArrowRightIcon
                            width="20px"
                            height="20px"
                            style={{
                                "path": {
                                    fill: "#989898"
                                }
                            }}
                        />
                    </div>
                    <div className="direction-data">
                        <div className="city-code">MSK</div>
                        <div className="arrival-date">25.07.24</div>
                        <div className="arrival-time">15:00</div>
                    </div>
                    <div className="chat-header-details-tools">

                    </div>
                </div>
            </div>
            <ul ref={messagesList}
                id="messages-list"
                className="chat-messages messages">
                {
                    messages != null && messages.map((message, index) => {
                        return (
                            message.fromUser == userData?.id
                                ? (
                                    <li key={index} className="self">
                                        <div className="message">
                                            {message.content}
                                            <span className="message-time">
                                                {
                                                    new Date(message.date).getHours().toString().padStart(2, "0") + ":" + new Date(message.date).getMinutes().toString().padStart(2, "0")
                                                }
                                                <div className={"message-status" + (message.read ? " --read" : "")}>
                                                    <div className="checkmark"></div>
                                                    <div className="checkmark"></div>
                                                </div>
                                            </span>

                                        </div>
                                    </li>
                                )
                                : (
                                    <li key={index} className={"other" + (message.fromUser != userData?.id && !message.read ? " --unread-message" : "")}>
                                        <div className="user-avatar-container">
                                            {/*<img alt=""*/}
                                            {/*     src={message.fromUser.avatar}*/}
                                            {/*     className="user-image"/>*/}
                                            {/*<div className="user-status"></div>*/}
                                        </div>
                                        <div className="message">
                                            {message.content}
                                            <span className="message-time">
                                                {
                                                    new Date(message.date).getHours().toString().padStart(2, "0") + ":" + new Date(message.date).getMinutes().toString().padStart(2, "0")
                                                }
                                            </span>
                                        </div>
                                    </li>
                                )
                        )
                    })
                }
            </ul>
            {/*{*/}
            {/*    chat != null && chat.printingUsers.length > 0 && (*/}
            {/*        <div className="printing-status">*/}
            {/*            {*/}
            {/*                chat.printingUsers.filter(x => x.email != authState.email).map((user, index) => {*/}
            {/*                    return (*/}
            {/*                        <span key={index}>{user.fullName} печатает...</span>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            <div className="chat-footer">
                <textarea ref={textArea}
                          placeholder="Сообщение в чат"
                          onInput={onInput}
                          onKeyDown={(e) => {
                              if(e.which === 13 && e.ctrlKey && textArea.current != null) {
                                  e.preventDefault();
                                  sendMessage();
                              }
                          }}
                />
                <div className="chat-footer-controls">
                    <div className="chat-footer-tools-block">
                        <MdButton
                            icon={
                                <PlusIcon
                                    width="14px"
                                    height="14px"
                                    style={{
                                        "path": {
                                            stroke: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderWidth: 0,
                                    backgroundColor: "#FFFFFF",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                    marginRight: "0px",
                                    width: "28px",
                                    height: "28px",
                                    padding: 0
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                }
                            }}
                        />
                        <MdButton
                            icon={
                                <SmileIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "path": {
                                            fill: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    marginLeft: "5px",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "2px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                },
                            }}
                        />
                        <MdButton
                            icon={
                                <AttachmentIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    marginLeft: "5px",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "2px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                },
                            }}
                        />
                    </div>
                    <div className="chat-footer-send-block">
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#000000",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "0 8px",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginRight: "10px",
                                },
                                "text": {
                                    color: "#FFFFFF",
                                    fontWeight: 500,
                                    fontSize: "12px"
                                }
                            }}
                            onClick={() => sendMessage()}
                        >
                            Отправить
                        </MdButton>
                    </div>
                </div>
            </div>
        </div>
    )
}