import {useState, useDeferredValue, useRef} from "react";
import {DateService} from "../../../../services/DateService";
import {t} from "i18next";
import "./style.scss";
import {IError} from "../../../../interfaces/dataInterfaces/IError";
import {IDirectionRequest} from "../../../../interfaces/requestsInterfaces/orderInterfaces";
import {
    ARRIVED_CITY_ERROR,
    DEPARTURE_CITY_ERROR,
    FILTER_ERROR,
    TIME_SPAN_ERROR,
} from "../../../../constants/ErrorConstants";
import {InputTextWithPopoverComponent} from "../../../commonComponents/inputTextWithPopoverComponent";
import {OrderService} from "../../../../services/OrderServices";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {CheckBoxIcon} from "../../../../icons/CheckBoxIcon";

type SearchProps = {
    runSearch: (
        direction: IDirectionRequest[],
        needSearchAvia: boolean,
        needSearchTrain: boolean,
        needBackwardVariant: boolean
    ) => {};
    errors: IError[];
    needSearchAvia: boolean;
    needSearchTrain: boolean;
    needSearchHotel: boolean;
    needBackwardVariant: boolean;
};

export function SearchComponent(props: SearchProps) {
    const {t} = useTranslation();
    //services
    const {dateToStringForDatePicker, stringToDateFromDatePicker, isTimeSpanCorrect} =
        DateService();
    //const { searchCities } = OrderTickethApi();
    const {searchCityNamesByPartName} = OrderService();

    //hooks
    const [arrivedCity, setArrivedCity] = useState("");
    const [departureCity, setDepartureCity] = useState("");
    const [departureDate, setDepartureDate] = useState<Date>(new Date());
    const [backwardTripDate, setTripBackwardDate] = useState<Date>(new Date());
    const defferedDepartureCity = useDeferredValue(departureCity);
    const defferedArrivalCity = useDeferredValue(arrivedCity);

    const [departureCitiesCatalog, setDepartureCitiesCatalog] = useState<string[]>([]);
    const [arrivedCitiesCatalog, setArrivedCitiesCatalog] = useState<string[]>([]);
    //filters
    const [needSearchAvia, setNeedSearchAvia] = useState(true);
    const [needSearchTrain, setNeedSearchTrain] = useState(false);
    const [needBackwardTrip, setNeedBackwardTrip] = useState(false);
    const [needSearchHotel, setNeedSearchHotel] = useState(false);
    const [errors, setErrors] = useState<IError[]>([]);

    //popover
    const [show, setShow] = useState(false);
    const target = useRef(null);

    //test
    const [cities, setcities] = useState<string[]>([
        "москва",
        "Санкт-Петербург",
        "Владивосток",
        "мосты",
        "можайск",
        "можайск",
        "можайск",
        "махачкала-ыдпотывда",
        "можайск",
        "можайск",
        "можаск",
    ]);

    const departureDateHandler = (date: string) => {
        setDepartureDate(stringToDateFromDatePicker(date));
    };
    const backwardDateHandler = (date: string) => {
        setTripBackwardDate(stringToDateFromDatePicker(date));
    };

    const node = useRef<HTMLDivElement | null>(null);

    const hasErrorsHandler = () => {
        setErrors([]);
        if (needSearchAvia === false && needSearchTrain === false) {
            setErrors((olderrors) => [
                ...olderrors,
                {
                    errorMessage: "You need to choose a train or air or both.",
                    errorType: FILTER_ERROR,
                },
            ]);
        }

        if (defferedDepartureCity.length < 2) {
            setErrors((olderrors) => [
                ...olderrors,
                {
                    errorMessage: "The departure city is empty or too short.",
                    errorType: DEPARTURE_CITY_ERROR,
                },
            ]);
        }

        if (defferedArrivalCity.length < 2) {
            setErrors((olderrors) => [
                ...olderrors,
                {
                    errorMessage: "You need to choose a train or air or both.",
                    errorType: ARRIVED_CITY_ERROR,
                },
            ]);
        }

        if (needBackwardTrip && isTimeSpanCorrect(departureDate, backwardTripDate)) {
            setErrors((olderrors) => [
                ...olderrors,
                {
                    errorMessage: "dghdfhjhfg",
                    errorType: TIME_SPAN_ERROR,
                },
            ]);
        }
        return errors.length !== 0;
    };

    const searchHandler = async () => {
        if (hasErrorsHandler()) {
            return;
        }

        let directions: IDirectionRequest[] = [
            {
                townFrom: departureCity,
                townTo: arrivedCity,
                departDate: departureDate.toISOString(),
                tripWay: {
                    isAvia: true,
                },
            },
        ];
        if (needBackwardTrip) {
            directions.push({
                townFrom: defferedArrivalCity,
                townTo: defferedDepartureCity,
                departDate: backwardTripDate.toISOString(),
                tripWay: {
                    isAvia: true,
                },
            });
        }

        await props.runSearch(directions, needSearchAvia, needSearchTrain, needBackwardTrip);
    };

    const departureCitySearchHandler = async (partCityName: string) => {
        //for test
        if (partCityName.length>=3) setDepartureCitiesCatalog(cities);

        //Todo uncomment after test.
        // const result = await searchCityNamesByPartName(partCityName);
        // if (result.length > 0) setDepartureCitiesCatalog(result);
    };

    const arriveCitySearchHandler = async (partCityName: string) => {

        if (partCityName.length>=3) setArrivedCitiesCatalog(cities);
        //need to fix autocompleteService
        // const result = await searchCityNamesByPartName(partCityName);
        // if (result.length > 0) setArrivedCitiesCatalog(result);
    };

    // const arrivedCitySearchHandler = async (partCityName: string) => {
    //   const result = await searchCityNamesByPartName(partCityName);
    //   if (result.length > 0) setArrivedCitiesCatalog(result);
    // };

    const removeDepartureCitiesCatalog = () => {
        setDepartureCitiesCatalog(new Array<string>());
    };

    //@param
    //?
    //! sadf
    const removeArriveCitiesCatalog = () => {
        setArrivedCitiesCatalog(new Array<string>());
    };
    return (
        <>
            <div className="order-search-container">
                <div className="order-search-filter-row">
                    <MdToggleButton
                        buttonStyle={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px 0 10px",
                            borderRadius: "10px",
                            height: "30px",
                            fontSize: "1rem",
                            color: (needSearchAvia ? "#74B100" : "#C1C1C1"),
                            border: (needSearchAvia ? "1px solid #74B100" : "1px solid #CCCCCC"),
                        }}
                        inkStyle={{
                             backgroundColor: (needSearchAvia ? "#74B100" : "#C1C1C1"),
                            opacity: 0.1
                        }}
                        checked={needSearchAvia}
                        onChange={(state) => {
                                   setNeedSearchAvia(!needSearchAvia)
                        }}
                    >
                        <div style={{display: "flex", gap: "5px"}}>
                            <CheckBoxIcon iconSize={"22px"} isChecked={needSearchAvia}/>
                            <span>{t("orderPage.searchAvia")}</span>
                        </div>
                    </MdToggleButton>
                    <MdToggleButton
                        buttonStyle={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px 0 10px",
                            borderRadius: "10px",
                            height: "30px",
                            fontSize: "1rem",
                            color: (needSearchTrain ? "#74B100" : "#C1C1C1"),
                            border: (needSearchTrain ? "1px solid #74B100" : "1px solid #CCCCCC"),
                        }}
                        inkStyle={{
                            //  backgroundColor: (hasInvoice ? "#74B100" : "#C1C1C1"),
                            opacity: 0.1
                        }}
                        checked={needSearchTrain}
                        onChange={(state) => {
                            setNeedSearchTrain(!needSearchTrain)
                        }}
                    >
                        <div style={{display: "flex", gap: "5px"}}>
                            <CheckBoxIcon iconSize={"22px"} isChecked={needSearchTrain}/>
                            <span>{t("orderPage.searchTrain")}</span>
                        </div>
                    </MdToggleButton>
                    <MdToggleButton
                        buttonStyle={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px 0 10px",
                            borderRadius: "10px",
                            height: "30px",
                            fontSize: "1rem",
                            color: (needSearchHotel ? "#74B100" : "#C1C1C1"),
                            border: (needSearchHotel ? "1px solid #74B100" : "1px solid #CCCCCC"),
                        }}
                        inkStyle={{
                            //  backgroundColor: (hasInvoice ? "#74B100" : "#C1C1C1"),
                            opacity: 0.1
                        }}
                        checked={needSearchHotel}
                        onChange={(state) => {
                            setNeedSearchHotel(!needSearchHotel)
                        }}
                    >
                        <div style={{display: "flex", gap: "5px"}}>
                            <CheckBoxIcon iconSize={"22px"} isChecked={needSearchHotel}/>
                            <span>{t("orderPage.searchHotel")}</span>
                        </div>
                    </MdToggleButton>
                    <MdToggleButton
                        buttonStyle={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px 0 10px",
                            borderRadius: "10px",
                            height: "30px",
                            fontSize: "1rem",
                            color: (needBackwardTrip ? "#74B100" : "#C1C1C1"),
                            border: (needBackwardTrip ? "1px solid #74B100" : "1px solid #CCCCCC"),
                        }}
                        inkStyle={{
                            //  backgroundColor: (hasInvoice ? "#74B100" : "#C1C1C1"),
                            opacity: 0.1
                        }}
                        checked={needBackwardTrip}
                        onChange={(state) => {
                           setNeedBackwardTrip(!needBackwardTrip)
                        }}
                    >
                        <div style={{display: "flex", gap: "5px"}}>
                            <CheckBoxIcon iconSize={"22px"} isChecked={needBackwardTrip}/>
                            <span>{t("orderPage.searchBackward")}</span>
                        </div>
                    </MdToggleButton>
                </div>
                <div className="order-search-filter-row">
                    <div>
                        <InputTextWithPopoverComponent
                            value={departureCity}
                            placeholder={t("searchDirectionComponent.from")}
                            popoverItems={departureCitiesCatalog}
                            setValue={setDepartureCity}
                            // selectItemHandler={function (itemNumber: number): void {
                            //   throw new Error("Function not implemented.");
                            // }}
                            //title={t("searchDirectionComponent.from")}
                            runItemsSearch={departureCitySearchHandler}
                            clearPopoverItemsResult={removeDepartureCitiesCatalog}
                        />
                    </div>
                    <div>
                        <InputTextWithPopoverComponent
                            value={arrivedCity}
                            placeholder={t("searchDirectionComponent.to")}
                            popoverItems={arrivedCitiesCatalog}
                            setValue={setArrivedCity}
                            //title={t("searchDirectionComponent.to")}
                            runItemsSearch={arriveCitySearchHandler}
                            clearPopoverItemsResult={removeArriveCitiesCatalog}
                        />
                    </div>

                    <div className="order-input-date">
                        <label
                            className="sr-only ">{t("searchPage.departureDate")}:&nbsp;&nbsp;</label>
                        {/*<div className="form-group">*/}
                        <input
                            type="date"
                            className="form-control order-search-input"
                            placeholder=""
                            style={{width:"180px"}}
                            //defaultValue={dateToStringForDatePicker(departureDate)}
                            value={dateToStringForDatePicker(departureDate)}
                            onChange={(e) => {
                                e.preventDefault();
                                departureDateHandler(e.target.value);
                            }}
                        />
                        {/*</div>*/}
                    </div>

                    <div className="order-input-date">
                        <label
                            className="sr-only ">{t("searchPage.backwardDate")}:&nbsp;&nbsp;</label>
                        {/*<div className="form-group">*/}
                        <input
                            type="date"
                            className="form-control order-search-input"
                            placeholder=""
                            style={{width: "180px"}}
                            //defaultValue={dateToStringForDatePicker(departureDate)}
                            value={dateToStringForDatePicker(departureDate)}
                            onChange={(e) => {
                                e.preventDefault();
                                departureDateHandler(e.target.value);
                            }}
                        />
                        {/*</div>*/}
                    </div>

                    <MdButton
                        style={{
                            "button": {
                                borderWidth: 0,
                                backgroundColor: "#3E5CB8",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                                height: "35px",
                                padding: "5px 15px"
                            },
                            "ink": {
                                backgroundColor: "rgba(255,255,255,.5)"
                            }
                        }}
                        children={t("documentPage.search")}
                        onClick={searchHandler}
                    />

                </div>

            </div>




            {/*        <div className="form-check form-switch">*/}
            {/*          <input*/}
            {/*            className="form-check-input"*/}
            {/*            type="checkbox"*/}
            {/*            id="SearchAviaFlexSwitch"*/}
            {/*            checked={needSearchAvia}*/}
            {/*            onChange={() => setNeedSearchAvia(!needSearchAvia)}*/}
            {/*          />*/}
            {/*          <label className="form-check-label" style={{ userSelect: "none" }}>*/}
            {/*            Искать Авиа*/}
            {/*          </label>*/}
            {/*        </div>*/}
            {/*        <div className="form-check form-switch">*/}
            {/*          <input*/}
            {/*            className="form-check-input"*/}
            {/*            type="checkbox"*/}
            {/*            id="SearchTrainFlexSwitch"*/}
            {/*            checked={needSearchTrain}*/}
            {/*            onChange={() => setNeedSearchTrain(!needSearchTrain)}*/}
            {/*          />*/}
            {/*          <label className="form-check-label" style={{ userSelect: "none" }}>*/}
            {/*            Искать ЖД*/}
            {/*          </label>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="+">*/}
            {/*        <div className="form-check form-switch">*/}
            {/*          <input*/}
            {/*            className="form-check-input"*/}
            {/*            type="checkbox"*/}
            {/*            id="SearchBackwardsTripFlexSwitch"*/}
            {/*            checked={needBackwardTrip}*/}
            {/*            onChange={() => setNeedBackwardTrip(!needBackwardTrip)}*/}
            {/*          />*/}
            {/*          <label className="form-check-label" style={{ userSelect: "none" }}>*/}
            {/*            Искать обратные варианты*/}
            {/*          </label>*/}
            {/*        </div>*/}


            {/* To */}




            {/* Date to */}





            {/*  <span className=" input-group-text p-0 border-white  ">*/}
            {/*  <button className="btn btn-outline-secondary " type="button" onClick={clickHandler}>*/}
            {/*    <SearchIcon/> {t("commonText.search")}*/}
            {/*  </button>*/}
            {/*</span>*/}


            {/*<div className="filter-shape">*/}
            {/*  <div className="d-flex flex-column">*/}
            {/*    <div className="d-flex flex-row gap-1">*/}
            {/*      <MobileCheckBoxComponent*/}
            {/*        currentState={needSearchAvia}*/}
            {/*        icon={<PlaneIcon />}*/}
            {/*        tooltipText={`${t("searchPage.searchAviaVariants")}`}*/}
            {/*        setCurrentState={setNeedSearchAvia}*/}
            {/*      />*/}
            {/*      <MobileCheckBoxComponent*/}
            {/*        icon={<TrainIcon />}*/}
            {/*        currentState={needSearchTrain}*/}
            {/*        tooltipText={`${t("searchPage.searchTrainVariants")}`}*/}
            {/*        //title={"Search Avia Ticket"}*/}
            {/*        setCurrentState={setNeedSearchTrain}*/}
            {/*      />*/}
            {/*      <MobileCheckBoxComponent*/}
            {/*        currentState={needSearchHotel}*/}
            {/*        icon={<HotelIcon />}*/}
            {/*        tooltipText={`${t("searchPage.searchHotel")}`}*/}
            {/*        setCurrentState={setNeedSearchHotel}*/}
            {/*      />*/}
            {/*      <MobileCheckBoxComponent*/}
            {/*        currentState={needBackwardTrip}*/}
            {/*        tooltipText={`${t("searchPage.needBackwardTicket")}`}*/}
            {/*        icon={<BackwardIcon />}*/}
            {/*        setCurrentState={setNeedBackwardTrip}*/}
            {/*      />*/}
            {/*      <div className="">*/}


            {/*    <div className=" d-flex flex-row flex-wrap">*/}


            {/*      /!* <div className=""> *!/*/}

            {/*      <span className=" input-group-text" style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}>*/}
            {/*        <label className="sr-only">{t("searchPage.departureDate")}:&nbsp;&nbsp;</label>*/}
            {/*        <div className="form-group">*/}
            {/*          <input*/}
            {/*            type="date"*/}
            {/*            className="form-control"*/}
            {/*            placeholder="Check-out"*/}
            {/*            //defaultValue={dateToStringForDatePicker(departureDate)}*/}
            {/*            value={dateToStringForDatePicker(departureDate)}*/}
            {/*            onChange={(e) => {*/}
            {/*              e.preventDefault();*/}
            {/*              departureDateHandler(e.target.value);*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </span>*/}

            {/*      {needBackwardTrip && (*/}
            {/*        <span className=" input-group-text">*/}
            {/*          <label className="sr-only">{t("searchPage.backwardDate")}:&nbsp;&nbsp;</label>*/}
            {/*          <div className="form-group">*/}
            {/*            <input*/}
            {/*              type="date"*/}
            {/*              className="form-control"*/}
            {/*              placeholder="Check-out"*/}
            {/*              value={dateToStringForDatePicker(departureDate)}*/}
            {/*              onChange={(e) => {*/}
            {/*                e.preventDefault();*/}
            {/*                backwardDateHandler(e.target.value);*/}
            {/*                //setDepartureDate(stringToDateFromDatePicker(e.target.value));*/}
            {/*              }}*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </span>*/}
            {/*      )}*/}

            {/*      <span className=" input-group-text">*/}
            {/*        <button*/}
            {/*          className="btn btn-outline-secondary"*/}
            {/*          type="button"*/}
            {/*          onClick={() => {*/}
            {/*            //props.runSearch();*/}
            {/*            searchHandler();*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {t("searchDirectionComponent.find")}*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      /!* </div> *!/*/}
            {/*    </div>*/}
            {/*    <div className="d-flex flex-row gap-1">*/}
            {/*      {errors &&*/}
            {/*        errors.map((err) => {*/}
            {/*          if (err === undefined) {*/}
            {/*            return <></>;*/}
            {/*          }*/}
            {/*          return (*/}
            {/*            <span>*/}
            {/*              <ErrorInputComponent error={err.errorMessage} allignMessage="left" />*/}
            {/*            </span>*/}
            {/*          );*/}
            {/*        })}*/}
            {/*    </div>*/}

            {/*    /!* Find Hotel *!/*/}
            {/*    <div className="collapse" id="hotelBook">*/}
            {/*      <div className="">*/}
            {/*        <div className="input-group p-1">*/}
            {/*          <input*/}
            {/*            type="text"*/}
            {/*            className="form-control"*/}
            {/*            placeholder="City/Hotel"*/}
            {/*            aria-label=""*/}
            {/*          />*/}
            {/*          <input type="date" className="form-control" placeholder="Check-in" aria-label="" />*/}
            {/*          <input type="date" className="form-control" placeholder="Check-out" aria-label="" />*/}

            {/*          <button className="btn btn-outline-secondary" type="button">*/}
            {/*            Book*/}
            {/*          </button>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
        </>
    );
}
