import {useState} from "react";
import {useCookies} from "react-cookie";
import axios, {AxiosError} from "axios";
import {
    BASE_ROUTE_AUTH_SERVICE,
    BASE_URL,
    BEARER,
    ROUTE_LOGIN,
    ROUTE_SIGNUP_COMPANY_WITH_ADMIN,
} from "../constants/routeConstants/ApiRouteConstants";
import {
    setAccessToken,
    setCurrentClientCode,
    setEmail,
    setGkClientCodes,
    setGkCode,
    setIsUserValid,
    setUserName,
    setFirstName,
    setLastName,
    setPatronymic,
    setUserRoles,
    setUserRole,
    setExpiredAt, setGkCompanies, setUserId,
} from "../redux/authSlice";
import {JwtPayload, jwtDecode} from "jwt-decode";
import {ITokenRequest} from "../Requests/ITokenRequest";
import {IRefreshTokenRequest} from "../Requests/IRefreshTokenRequest";
import {ITokenResponse} from "../responces/ITokenResponse";

import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {axiosInstance} from "../api/AxiosInstance";
import {ISignupRequests} from "../Requests/ISignupRequest";
import {ValidationErrors} from "fluentvalidation-ts";
import {SignupRequestValidator} from "../validators/SignupRequestValidator";
import {DataConvertService} from "./DataConvertService";
import {CompanyService} from "./CompanyService";
import {ITokenClaims} from "../interfaces/AuthInterfaces";
import {IError} from '../interfaces/dataInterfaces/IError';
import {AppError} from "../domain/Errors";
import {resetReports} from "../redux/reportsSlice";
import {AuthApi} from "../api/AuthApi";
import {IGetUserRolesRequest} from "../Requests/IGetUserRolesRequest";


export function AuthService() {
    // hooks
    const [authError, setAuthError] = useState("");
    const [errorResponse, setErrorResponse] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const authState = useAppSelector(root => root.auth)

    const dispatch = useAppDispatch();

    const {getUserRoles} = AuthApi();

    const {getClientInfoInBackground} = CompanyService()

    //custom services
    //const { signUpCompanyWithAdmin } = AuthApi();
    const {objHasAnyPropertyValue} = DataConvertService();
    const signupValidator = new SignupRequestValidator();

    const getTokenClaims = (token: string): ITokenClaims => {

        const tokenPayload = jwtDecode<JwtPayload>(token);
        const tokenClaims =
            Object.fromEntries(Object.entries(tokenPayload).map(([k, v], i) => {
                let cleanKey = k.substring(k.lastIndexOf("/") + 1);
                return [cleanKey, cleanKey == "role" ? JSON.parse(v) : v];
            }));

        const roleDictionary = tokenClaims.role;
        const clientCodes = roleDictionary != null ? Object.keys(roleDictionary) : [];

        return {
            iss: tokenPayload.iss,
            sub: tokenPayload.sub,
            aud: tokenPayload.aud,
            exp: tokenPayload.exp,
            nbf: tokenPayload.nbf,
            iat: tokenPayload.sub,
            jti: tokenPayload.sub,
            firstName: tokenClaims["User FirstName"],
            lastName: tokenClaims["User LastName"],
            patronymic: tokenClaims["User Patronymic"],
            roles: roleDictionary,
            gkClientsCodes: clientCodes,
            gkCode: tokenClaims.GkCode,
        }
    }

    const login = async (tokenRequest: ITokenRequest) => {
        try {
            setErrorResponse(false);

            const response = await axiosInstance.post<ITokenResponse>(
                BASE_URL + ROUTE_LOGIN,
                tokenRequest
            );

            if (response.status === 200) {

                const tokenClaims = getTokenClaims(response.data.jwtToken);

                const firstName = tokenClaims.firstName ?? "";
                const lastName = tokenClaims.lastName ?? "";
                const patronymic = tokenClaims.patronymic ?? "";
                const fullName = [firstName, lastName, patronymic].join(' ');

                dispatch(setEmail(tokenRequest.email));
                dispatch(setUserName(fullName));
                dispatch(setFirstName(tokenClaims.firstName ?? ""));
                dispatch(setLastName(tokenClaims.lastName ?? ""));
                dispatch(setPatronymic(tokenClaims.patronymic ?? ""));

                dispatch(setAccessToken(response.data.jwtToken));
                dispatch(setExpiredAt(tokenClaims.exp ?? 0));

                //setClientCodes
                if (tokenClaims.gkClientsCodes !== undefined) {
                    dispatch(setGkClientCodes(tokenClaims.gkClientsCodes));
                }

                //check clients code
                const defaultClientCode = Array.isArray(tokenClaims.gkClientsCodes) ? tokenClaims.gkClientsCodes[0] : "";

                dispatch(setCurrentClientCode(defaultClientCode));

                dispatch(setUserRoles(tokenClaims.roles));

                let userRole = tokenClaims.roles[defaultClientCode]?.find((x, i) => i == 0) ?? "";

                dispatch(setUserRole(userRole));

                //ToDo need update Auth api; HardCode!!!
                dispatch(setIsUserValid(true));

                dispatch(setGkCode(tokenClaims.gkCode ?? ""))

                setCookie(ACCESS_TOKEN, response.data.jwtToken, {
                    //secure: true,
                    //sameSite: "strict",
                    //httpOnly: true,
                });

                dispatch(resetReports());

                //return true
                const result = await getClientInfoInBackground(tokenClaims.gkClientsCodes ?? [], defaultClientCode, response.data.jwtToken);
                return result;
            } else {
                setErrorResponse(true);
            }
            // redirect("/lk/cabinet");
        } catch (ex: unknown) {
            const err = ex as AxiosError;
            dispatch(setIsUserValid(false));
            setAuthError(err.message);
            setErrorResponse(true);
        }
        return false;
    };

    const refreshToken = async (request: IRefreshTokenRequest) => {
        try {
            const response = await axiosInstance.post<ITokenResponse>(
                BASE_ROUTE_AUTH_SERVICE + "/auth/refresh-token",
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                const token = getTokenClaims(response.data.jwtToken);

                dispatch(setAccessToken(response.data.jwtToken));
                dispatch(setExpiredAt(token.exp ?? 0));

                setCookie(ACCESS_TOKEN, response.data.jwtToken, {
                    //secure: true,
                    //sameSite: "strict",
                    //httpOnly: true,
                });

                return true;
            }
        } catch (ex) {
            console.error(ex);
        }
        return false;
    }

    const revokeToken = async () => {
        try {
            const response = await axiosInstance.post<any>(
                BASE_ROUTE_AUTH_SERVICE + "/auth/revoke-token",
                null,
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    }
                }
            );
            if (response.status === 200) {
                return true;
            }
        } catch (ex) {
            console.error(ex);
        }
        return false;
    }

    const logout = async () => {
        try {
            await axiosInstance.post<any>(
                BASE_ROUTE_AUTH_SERVICE + "/auth/logout",
                null,
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    }
                }
            );
        } catch (ex) {
        }

        dispatch(setEmail(""));
        dispatch(setUserName(""));
        dispatch(setFirstName(""));
        dispatch(setLastName(""));
        dispatch(setPatronymic(""));
        dispatch(setAccessToken(""));
        dispatch(setExpiredAt(0));
        dispatch(setGkCompanies([]));
        dispatch(setGkClientCodes([]));
        dispatch(setCurrentClientCode(""));
        dispatch(setUserRoles({}));
        dispatch(setUserRole(""));
        dispatch(setIsUserValid(false));

        removeCookie(ACCESS_TOKEN, {path: '/'});

        dispatch(resetReports());
    };

    const getUserCompanyRoles = async (email: string) => {
        const request: IGetUserRolesRequest = {
            email: email
        };
        return await getUserRoles(request);
    }


    const getAccessToken = (): string | AppError => {
        const token: string = cookies[ACCESS_TOKEN];
        const err: IError = {
            errorMessage: "Access Token Not Found",
        }
        if (token === undefined || token.length === 0) return new AppError("Access Token Not Found")
        return token;
    };


// Signup function for export
    const signupCompany = async (request: ISignupRequests) => {
        setErrorResponse(false);
        try {


            const response = await axiosInstance.post<ISignupRequests>(
                BASE_ROUTE_AUTH_SERVICE + ROUTE_SIGNUP_COMPANY_WITH_ADMIN,
                request
            );

            if (response.status === 200) {
                return true;
            }

        } catch (error) {
        }
        return false;
    };

    const validateSignupRequest = (
        validateErrorHandler: (
            value: ValidationErrors<ISignupRequests> | undefined
        ) => void,
        passwordErrorHandler: (value: string) => void,
        request: ISignupRequests
    ): boolean => {
        clearSignupValidationError(validateErrorHandler);
        const validationResult = signupValidator.validate({
            email: request.email,
            password: request.password,
            passwordConfirm: request.passwordConfirm,
            firstName: request.firstName,
            lastName: request.lastName,
            patronymic: request.patronymic,
            position: request.position,
            shortCompanyName: request.shortCompanyName,
            fullCompanyName: request.fullCompanyName,
            companyOwner: request.companyOwner,
            legalAddress: request.legalAddress,
            mailingAddress: request.mailingAddress,
            deliveryAddress: request.deliveryAddress,
            mainContract: request.mainContract,
            inn: request.inn,
            hasInnForm: request.hasInnForm,
            ogrn: request.ogrn,
            hasOgrnForm: request.hasOgrnForm,
        });

        //Check fluentvalidator errors
        if (objHasAnyPropertyValue(validationResult)) {
            validateErrorHandler(validationResult);
            return false;
        }

        return true;
    };

//own function
    const clearSignupValidationError = (
        hanler: (value: ValidationErrors<ISignupRequests> | undefined) => void
    ) => {
        hanler({
            email: "",
            password: "",
            passwordConfirm: "",
            firstName: "",
            lastName: "",
            patronymic: "",
            position: "",
            shortCompanyName: "",
            fullCompanyName: "",
            companyOwner: "",
            legalAddress: "",
            mailingAddress: "",
            deliveryAddress: "",
            mainContract: "",
            inn: "",
            hasInnForm: "",
            ogrn: "",
            hasOgrnForm: "",
        });
    };

    return {
        errorResponse,
        authError,
        login,
        refreshToken,
        logout,
        signupCompany,
        validateSignupRequest,
        getUserCompanyRoles,
    };
}
