import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICustomerRegister} from "../../../../interfaces/reportInterfaces/customerInterfaces";
import {MdButton} from "../../../../components/md-button";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";


interface RegistersTableProps {
    registerNames?: string[];
    registers?: ICustomerRegister[];
    activeRegister?: ICustomerRegister;
    setActiveRegister: (doc: ICustomerRegister) => void;
    selectedRegisters?: ICustomerRegister[];
    selectRegister: (doc: ICustomerRegister) => void;
    unselectRegister: (doc: ICustomerRegister) => void;
    openDownloadForm: () => void;
    openSendForm: () => void;
    downloadingDocuments: boolean;
}

enum RowTypes {
    DataRow,
    DetailsRow
}


export function RegisterTableComponent({
                                           registers,
                                           activeRegister,
                                           setActiveRegister,
                                           selectedRegisters,
                                           selectRegister,
                                           unselectRegister,
                                           openDownloadForm,
                                           openSendForm,
                                           downloadingDocuments
                                       }: RegistersTableProps) {

    const {t} = useTranslation();

    const [regList, setRegList] = useState<ICustomerRegister[]>([]);
    const [expandedRegisters, setExpandedRegisters] = useState<string[]>([]);

    const isActiveRegister = (regId: string) => {
        return (activeRegister !== undefined && activeRegister.id === regId);
    };

    const toggleDocumentDetails = (register: ICustomerRegister) => {
        let registerId = register.id;
        let index = expandedRegisters.indexOf(registerId);
        if (index == -1) {
          setExpandedRegisters([...expandedRegisters, registerId]);
        } else {
          setExpandedRegisters(expandedRegisters.filter(x => x != registerId));
        }
    };


    return (
        <>
            <div className="document-results">
                <div className="document-results-header">
                    <div className="document-results-caption">Реестры
                        <span>{registers?.length}</span>
                    </div>
                    <div className="document-results-tools">
                        <MdButton
                            icon={
                                downloadingDocuments ? <CircleSpinnerIcon width="12px" height="12px" /> : null
                            }
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={downloadingDocuments}
                            onClick={openDownloadForm}
                        >
                            Скачать акт сверки
                        </MdButton>

                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={openSendForm}
                        >
                            {`${t("documentPage.send")}`}
                        </MdButton>
                    </div>
                </div>

                {(regList.length > 0) && (
                    <table
                        className="table common-document-stripped-table table-hover table-bordered"
                        //style={{ backgroundColor: "aquamarine" }}
                    >
                        <thead className="document-table">
                        <tr key="header-row">
                            <th scope="col">
                                {/*<input*/}
                                {/*    id="CheckAllRows"*/}
                                {/*    className="form-check-input"*/}
                                {/*    type="checkbox"*/}
                                {/*/>*/}
                            </th>
                            <th scope="col">Дата</th>
                            <th scope="col">№</th>
                            <th scope="col">Клиент</th>
                            <th scope="col">Статус</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody className=" overflow-y document-table">
                        {
                            regList
                                .map(x => {
                                    // if (expandedRegisters.includes(x.docNo.trim())) {
                                    //   return [{item: x, type: RowTypes.DataRow}, {
                                    //     item: x,
                                    //     type: RowTypes.DetailsRow
                                    //   }];
                                    // } else {
                                    //   return [{item: x, type: RowTypes.DataRow}];
                                    // }
                                    return [{item: x, type: RowTypes.DataRow}];
                                })
                                .flat()
                                .map(({item, type}, i) => {
                                    let isRowExpanded = expandedRegisters.includes(item.id);
                                    return (
                                        type == RowTypes.DataRow
                                            ? (
                                                <tr
                                                    key={i.toString() + "-row"}
                                                    className={"d-blok " + (isActiveRegister(item.id) ? "selected-row" : "")}
                                                    onClick={() => {
                                                        setActiveRegister(item);
                                                    }}
                                                >
                                                    <td>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            //value={isPreProcessing}
                                                            // checked={selectedRegisters?.some(
                                                            //     (d) => d.Id === item.Id
                                                            // )}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    selectRegister(item);
                                                                } else {
                                                                    unselectRegister(item);
                                                                }
                                                            }}
                                                            id="PreProcessingCheck"
                                                        />
                                                    </td>
                                                    <td>
                                                        {"???"}
                                                    </td>
                                                    <td>
                                                        {"???"}
                                                    </td>
                                                    <td>
                                                        {"???"}
                                                    </td>
                                                    <td>
                                                        {"???"}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className={"row-details-toggle-button " + (isRowExpanded ? "--expanded" : "")}
                                                            onClick={(e) => {
                                                                toggleDocumentDetails(item)
                                                            }}></button>
                                                    </td>
                                                </tr>
                                            )
                                            : (
                                                <tr
                                                    key={i.toString() + "-details"}
                                                    className="row-details">
                                                    <td colSpan={11}>

                                                    </td>
                                                </tr>
                                            )
                                    );
                                })}
                        </tbody>
                    </table>
                )}
            </div>

        </>
    );
}
