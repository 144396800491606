import { ICLientSale } from '../interfaces/companyInfoInterfaces/IClientSale';

export function DataConvertService() {
  const convertObjToTupleArray = (obj: Object): [string, string][] => {
    let result: [string, string][] = [];

    const keys = Object.keys(obj);
    const values = Object.values(obj);
    for (let index = 0; index < keys.length; index++) {
      result.push([keys[index], values[index]]);
    }

    return result;
  };

  const objHasAnyPropertyValue = (obj: Object): boolean => {
    return Object.values(obj).length !== 0;
  };

  const useMoneyRubFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "RUB",
  });

  const convertDataToGoogleBar = (clientSales: ICLientSale[]) => {

    let resultarray: (string | number)[][] = clientSales.map((x) => { return [x.monthNum + "-" + x.year, x.totalSum, x.operCount] })
    return resultarray;
  }

  const convertAndGetLastYearClientSalesToGoogleBar = (clientSales: ICLientSale[]) => {
    let resultClientSales: (ICLientSale[]);

    // if (clientSales.length > 12) {
    //   return convertDataToGoogleBar(clientSales.slice(clientSales.length - 12, clientSales.length))
    // } else {
    //   return []
    // }

    return
  }

  return { convertObjToTupleArray, useMoneyRubFormat, objHasAnyPropertyValue };
}
