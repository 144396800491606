//import { trainOrderItems } from "./AviaOrderTestData";
import {SearchResultComponent} from "./searchResultComponent";
import {useEffect, useState} from "react";
import {TicketType} from "../../../enums/TicketType";
import {
    OrderTicketWrapperComponent
} from "./orderCabinetComponents/OrderTicketWrapperComponent/OrderTicketWrapperComponent";
import {OrderTickethApi} from "../../../api/OrderTicketApi";
import {IDirectionRequest, ISearchTripVariants,} from "../../../interfaces/requestsInterfaces/orderInterfaces";
import {SearchComponent} from "./SearchComponents";
import {ITrainVariant} from "../../../interfaces/orderInterfaces/TrainOrderInterfaces";
import {
    AVIA_VARIANTS,
    FILTERED_TRAVEL_VARIANTS,
    TRAIN_VARIANTS,
    TRAVEL_VARIANTS
} from "../../../constants/ServiceCostants";
import {useSessionStorage} from "../../../services/StorageService";
import {ISelectedVariant} from "../../../interfaces/componentInterfaces/ISelectedVariant";
import {TripVariantComponent} from "./tripVariantComponent";
import {ICityElement} from "../../../interfaces/orderInterfaces/orderResponses";
import "./style.scss";
import {FilterResultComponent} from "./filterResultComponent";
import {MobileVariantComponent} from "./ticketComponents/mobileTicketComponents/mobileVariantComponent";
import {TravelVariantComponent} from "./travelVariantComponent";
import {IAviaVariant} from "../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ITravelVariant} from "../../../interfaces/orderInterfaces/ITravelVariant";
import {OrderService} from "../../../services/OrderServices";

export function OrderCabinetPage() {
    //testData
    //const trainItems = trainOrderItems;

    //hooks
    const [searchResultState, setSearchResultState] = useState(TicketType.None);

    //!sessionStorage
    // for testing
    const [aviaVariants, setAviaVariants] = useSessionStorage<IAviaVariant[]>(AVIA_VARIANTS, []);
    const [trainVariants, setTrainVariants] = useSessionStorage<ITrainVariant[]>(TRAIN_VARIANTS, []);
    //!sessionStorage
    //forUsing
    const [travelVariants, setTravelVariants] = useSessionStorage<ITravelVariant[]>(TRAVEL_VARIANTS, []);
    const [filteredTravelVariants, setFilteredTravelVariants] = useSessionStorage<ITravelVariant[]>(FILTERED_TRAVEL_VARIANTS, []);


    //select hooks
    const [selectedAviaVariant, setSelectedAviaVariant] = useState(-1);
    const [selectedAviaTicket, setSelectedAviaTicket] = useState(-1);
    const [selectedTrainVariant, setSelectedTrainVariant] = useState<number>(-1);
    const [selectedTrainTicket, setSelectedTrainTicket] = useState<number>(-1);

    const [selectedAviaBacwardVariant, setSelectedAviaBacwardVariant] = useState(-1);
    const [selectedAviaBacwardTicket, setSelectedAviaBacwardTicket] = useState(-1);
    const [selectedTrainBacwardVaraiant, setSelectedTrainBacwardVariant] = useState<number>(-1);
    const [selectedTrainBacwardTicket, setSelectedTrainBacwardTicket] = useState<number>(-1);


    //new functionality hooks

    // need add hotels
    //services
    const {searchAviaFlights, searchTrains} = OrderTickethApi();
    const {searchTravelVariants} = OrderService();

    //Handler for search result by type
    function searchResultStateHandler(ticketType: TicketType) {
        switch (ticketType) {
            case TicketType.None: {
                setSearchResultState(TicketType.None);
                return;
            }
            case TicketType.AviaTicket: {
                setSearchResultState(TicketType.AviaTicket);
                return;
            }
            case TicketType.TrainTicket: {
                setSearchResultState(TicketType.TrainTicket);
                return;
            }
            case TicketType.HotelTicket: {
                setSearchResultState(TicketType.HotelTicket);
                return;
            }
            default:
                return;
        }
    }

    const selectHandler = (props: ISelectedVariant) => {
        if (props.isVariant) {
            switch (props.ticketType) {
                case TicketType.AviaTicket:
                    setSelectedAviaVariant(props.index);
                    break;
                case TicketType.TrainTicket:
                    setSelectedTrainVariant(props.index);
                    break;
                case TicketType.AviaBackwardTicket:
                    setSelectedAviaBacwardVariant(props.index);
                    break;
                case TicketType.TrainBackwardTicket:
                    setSelectedTrainBacwardVariant(props.index);
                    break;

                default:
                    break;
            }
        }
        if (props.isTicket) {
            switch (props.ticketType) {
                case TicketType.AviaTicket:
                    setSelectedAviaTicket(props.index);
                    break;
                case TicketType.TrainTicket:
                    setSelectedTrainTicket(props.index);
                    break;
                case TicketType.AviaBackwardTicket:
                    setSelectedAviaBacwardTicket(props.index);
                    break;
                case TicketType.TrainBackwardTicket:
                    setSelectedTrainBacwardTicket(props.index);
                    break;

                default:
                    break;
            }
        }
    };

    const cancelSelectedAviaTicket = () => {
        setSelectedAviaVariant(-1);
    };

    const cancelSelectedTrainTicket = () => {
        setSelectedTrainVariant(-1);
    };

    const selectTrainTicketHandler = (index: number) => {
        setSelectedTrainTicket(index);
    };

    const searchHandler = async (
        direction: IDirectionRequest[],
        needSearchAvia: boolean,
        needSearchTrain: boolean,
        needBackwardVariant: boolean
    ) => {
        const searchTripRequest: ISearchTripVariants<IDirectionRequest> = {
            trips: [
                {
                    dirs: [direction[0]],
                },
            ],
        };


        //ToDO Uncommit after test
        const res = await searchTravelVariants(direction, needSearchAvia, needSearchTrain, needBackwardVariant);
        // console.log(`orderPage: ${res}`)
        // setTravelVariants(res)
        //setFilteredTravelVariants(res);

        setTravelVariants(res)
        // if (needSearchAvia) {
        //     const response = await searchAviaFlights(searchTripRequest);
        //     setAviaVariants(response.data.media);
        //     setStatus(response.status.toString());
        //     //setAviaVariants(response.data.media);
        // }
        //
        // if (needSearchTrain) {
        //     const response = await searchTrains(searchTripRequest);
        //     setTrainVariants(response.data.media);
        //     setTrainStatus(response.status.toString());
        //     setTrainCountTicket(response.data.media.length);
        // }
       //
    };
    useEffect(() => {

    }, [filteredTravelVariants]);

    return (
        <div className="order-document-container">
            {/*<div className="order-document-search-container">*/}
            <SearchComponent
                //runSearch={}
                errors={[]}
                needSearchAvia={true}
                needSearchTrain={false}
                needSearchHotel={false}
                needBackwardVariant={false}
                runSearch={searchHandler} //runSearch={searchAviaFlights}
            />
            {/*</div>*/}

            <div>
                <div className="d-flex flex-row gap-2">
                    <div className="order-document-filter-container">
                        <FilterResultComponent isDefaultState={false} travelVariants={travelVariants}
                                               filteredTravelVariants={filteredTravelVariants}
                                               setFilteredTravelVariants={setFilteredTravelVariants}/>
                    </div>
                    <div className="overflow-auto"
                        // style={{height:"500px"}}
                    >
                        <p>Avia count: {aviaVariants.length}</p>
                        <div className="overflow-auto d-block"
                        >

                        </div>
                        <SearchResultComponent selectedVariants={[]} filteredVariants={filteredTravelVariants}/>

                    </div>
                </div>
            </div>

        </div>
    );
}
