import React, {CSSProperties, HTMLInputTypeAttribute, SetStateAction, useEffect, useState} from "react";
import './style.scss'

interface IMdSelectProps {
    style?: CSSProperties;
    label?: string;
    items: Array<any>;
    displayMemberPath?: string;
    value: any;
    setValue: React.Dispatch<SetStateAction<any>>;
    errorMessage?: string | null;
    invalid?: boolean;
    disabled?: boolean;
    onChange?: (event: any) => void;
}

export function MdSelect(props: IMdSelectProps) {
    let root: HTMLElement | null = null;

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(props.value != null && props.value.length > 0);
    const [isOpened, setIsOpened] = useState(false);

    const focusHandler = (event: React.FocusEvent) => {
        setIsFocused(true);
        setIsOpened(true);
    }

    const blurHandler = (event: React.FocusEvent) => {
        setIsFocused(false);
        setIsOpened(false);
    }

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 0) {
            setIsFilled(true)
        } else {
            setIsFilled(false);
        }
    }

    const onMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
    }

    const onItemClick = (event: React.MouseEvent, item: any) => {
        let value = (props.displayMemberPath != null ? item[props.displayMemberPath] : item);
        props.setValue(item);
        let input = root?.querySelector('input');
        if (input != null) {
            input.value = value;
        }
        setIsOpened(false);
    }


    useEffect(() => { }, [isFocused, isOpened]);


    return (
        <div
            ref={ref => root = ref}
            className={("md-select-container" + (isFocused ? " --focused" : "") + (isFilled ? " --filled" : "") + (props.invalid ? " --invalid" : ""))}
            style={props.style}>
            <label className="md-select md-select--outlined">
                <input
                    type="text"
                    className="md-select-field-input"
                    readOnly={true}
                    value={props.value}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    onInput={inputHandler}
                    onChange={props.onChange}/>
                <div className="md-select-outline-container">
                    <div className="md-select-outline-container-left"></div>
                    <div className="md-select-outline-container-middle">
                        <span className="md-select-label">{props.label}</span>
                    </div>
                    <div className="md-select-outline-container-right"></div>
                </div>
                <ul className="md-select-menu"
                    style={{
                        display: (isOpened ? "block" : "none")
                    }}
                >
                    {
                        props.items.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    onMouseDown={onMouseDown}
                                    onClick={(e) => onItemClick(e, item)}
                                >{(props.displayMemberPath != null ? item[props.displayMemberPath] : item.toString())}</li>
                            )
                        })
                    }
                </ul>
            </label>
            <div className="md-select-helper-line">
                <div className="md-select-helper-line-text">
                    {props.errorMessage}
                </div>
            </div>
        </div>
    );
}