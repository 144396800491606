import {Dispatch, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import "./style.scss";

export interface CompaniesSearchProps {
    name: string;
    nameHandler: Dispatch<SetStateAction<string>>
    searchHandler: () => void;
}

export function CompaniesSearchComponent(props: CompaniesSearchProps) {
    const [name, setName] = useState("");
    const {t} = useTranslation();

    const searchCompanies = () => {
        props.searchHandler();
    };

    return (
        <>
            <div className="company-filters-container">
                {/*<div className="company-filters-caption">Фильтры</div>*/}
                {/*<div className="company-filters-description">Используйте фильтры для поиска нужного документа</div>*/}
                <form
                    className="company-filters-form"
                    onSubmit={e => {
                        e.preventDefault();
                        searchCompanies();
                    }}
                >
                    <div>
                        <span className="input-group">
                            <label className="sr-only align-self-lg-center ">
                                &nbsp;{`${t("myProfilePage.client")}`}:&nbsp;&nbsp;
                            </label>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={`${t("myProfilePage.name")}`}
                                    value={props.name}
                                    onChange={(e) => {
                                        props.nameHandler(e.target.value);
                                    }}
                                />
                            </div>
                        </span>
                    </div>

                    <div>
                        <span className="input-group">
                            <MdButton
                                type="button"
                                style={{
                                    "button": {
                                        borderWidth: 0,
                                        backgroundColor: "#3E5CB8",
                                        color: "#FFFFFF",
                                        borderRadius: "6px",
                                        height: "35px",
                                        padding: "5px 15px"
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(255,255,255,.5)"
                                    }
                                }}
                                onClick={searchCompanies}
                            >
                                {`${t("myProfilePage.search")}`}
                            </MdButton>
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
}
