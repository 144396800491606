import {useState} from "react";
import {DateService} from "../../../../services/DateService";
import {TripService} from "../../../../services/TripService";
import {FlightVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {LocationFilledIcon} from "../../../../icons/LocationFilledIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {MdButton} from "../../../../components/md-button";
import {MagnifierIcon} from "../../../../icons/MagnifierIcon";
import "./style.scss";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING
} from "../../../../constants/ServiceCostants";

export type ITicketSearchTest = {
    class?: number;
    location?: number;
    travelers?: number;
    checkIn?: Date;
    checkOut?: Date;
};

export function TicketSearch(props: ITicketSearchTest) {

    const {getFlightList} = TripService();

    const {dateToStringForDatePicker} = DateService();

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [origin, setOrigin] = useState("Калининград");
    const [direction, setDirection] = useState("Москва");
    const [passengers, setPassengers] = useState<number>(1);
    const [startDate, setStartDate] = useState(dateToStringForDatePicker(today));
    const [endDate, setEndDate] = useState(dateToStringForDatePicker(tomorrow));

    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [flightList, setFlightList] = useState<FlightVariant[]>([]);


    const isDevelopmentEnv = ()=> {
        return !( CURRENT_REACT_ENVIROMENT === ENVIROMENT_STAGING || CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION)
    }

    const searchTickets = async () => {
        setFlightList([]);

        setIsSearching(true);

        const res = await getFlightList(origin, direction, startDate, null, null);

        setFlightList(res);

        setIsSearching(false);
    };

    return (
        <div className="ticket-search-form">
            {
                isDevelopmentEnv() && (
                    <>
                        <div className="ticket-class-switcher-container">
                            <div className="ticket-class-switcher">
                                <button className="switcher-button">ЖД</button>
                                <button className="switcher-button active-button">Авиа</button>
                                <button className="switcher-button">Отели</button>
                            </div>
                        </div>
                        <div className="ticket-input-fields-container">
                            <div className="ticket-input-field">
                                <div className="ticket-input-field-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                        <path fill="#2F323B"
                                              d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"/>
                                        <path fill="#2F323B"
                                              d="M512 512a96 96 0 1 0 0-192 96 96 0 0 0 0 192zm0 64a160 160 0 1 1 0-320 160 160 0 0 1 0 320z"/>
                                    </svg>
                                </div>
                                <div className="ticket-input-field-content">
                                    <button>
                                        <span>Город отправления</span>
                                        <span>
                                            <input
                                                type="text"
                                                placeholder="Добавить"
                                                value={origin}
                                                onChange={(e) => setOrigin(e.target.value)}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="ticket-input-field">
                                <div className="ticket-input-field-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                        <path fill="#2F323B"
                                              d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"/>
                                        <path fill="#2F323B"
                                              d="M512 512a96 96 0 1 0 0-192 96 96 0 0 0 0 192zm0 64a160 160 0 1 1 0-320 160 160 0 0 1 0 320z"/>
                                    </svg>
                                </div>
                                <div className="ticket-input-field-content">
                                    <button>
                                        <span>Город прибытия</span>
                                        <span>
                                            <input
                                                type="text"
                                                placeholder="Добавить"
                                                value={direction}
                                                onChange={(e) => setDirection(e.target.value)}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="ticket-input-field">
                                <div className="ticket-input-field-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24"
                                         fill="none">
                                        <path stroke="#2F323B"
                                              d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="ticket-input-field-content">
                                    <button>
                                        <span>Дата отправления</span>
                                        <span>
                                            <input
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => {
                                                    setStartDate(e.target.value);
                                                }}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <MdButton
                            className="ticket-submit-button"
                            type="button"
                            disabled={isSearching}
                            icon={
                                isSearching
                                    ? (
                                        <CircleSpinnerIcon
                                            style={{
                                                "circle": {
                                                    stroke: "#FFFFFF"
                                                }
                                            }}
                                        />
                                    )
                                    : (
                                        <MagnifierIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    fill: "#FFFFFF"
                                                }
                                            }}
                                        />
                                    )
                            }
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "20px",
                                    height: "35px",
                                    padding: "5px 15px",
                                    justifyContent: "center",
                                    marginBottom: "20px"
                                },
                                "icon": {
                                    marginRight: (!isSearching ? 0 : "5px")
                                },
                                "text": {
                                    display: (isSearching ? "block" : "none"),
                                    width: "auto",
                                    flex: 0,
                                    color: "#FFFFFF"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={searchTickets}
                        >
                            {"Поиск..."}
                        </MdButton>

                        <div className="ticket-search-results">
                            {
                                flightList.map(flight => {

                                    const dateArrival = new Date(flight.dateArr);
                                    const dateDepartment = new Date(flight.dateDep);

                                    return (
                                        <details className="ticket-item">
                                            <summary className="ticket-summary">
                                                <div className="ticket-summary-header">
                                                <div className="air-company"><span>{flight.airCompanyName}</span></div>
                                                    <div className="price"><span>{"от " + (flight.tarifs.length > 0 ? Math.min(...flight.tarifs.map(x => x.price)) : 0) + "₽"}</span></div>
                                                </div>
                                                <div className="ticket-summary-content">
                                                    <div className="arrival-info">
                                                        <div className="arrival-airport-code">{flight.airCodeDep}</div>
                                                        <div
                                                            className="arrival-time">{dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")}</div>
                                                    </div>
                                                    <div className="flight-path-info">
                                                        <div className="flight-track">
                                                            <hr/>
                                                            <div className="flight-track-airplane">
                                                                <AirplaneIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#3E5CB8"
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <div className="flight-time">
                                                            2ч 35м
                                                        </div>
                                                    </div>
                                                    <div className="department-info">
                                                        <div className="department-airport-code">{flight.airCodeArr}</div>
                                                        <div
                                                            className="department-time">{dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")}</div>
                                                    </div>
                                                </div>
                                            </summary>
                                            <div className="ticket-details">

                                                <div className="ticket-details-section">
                                                    <div className="ticket-details-section-item">
                                                        <div>Отправление</div>
                                                        <div className="ticket-details-arrival-city">
                                                            <div className="arrival-city-icon">
                                                                <LocationFilledIcon
                                                                    width="18px"
                                                                    height="18px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#F04D5E",
                                                                            fill: "#F04D5E"
                                                                        }
                                                                    }}
                                                                />
                                                                <span>A</span>
                                                            </div>
                                                            <div className="arrival-city-name">
                                                                {/*{flight.cityArr.split('(')[0]}*/}
                                                                {flight.cityDep}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ticket-details-section-item">
                                                        <div>Прибытие</div>
                                                        <div className="ticket-details-department-city">
                                                            <div className="department-city-icon">
                                                                <LocationFilledIcon
                                                                    width="18px"
                                                                    height="18px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#107BD9",
                                                                            fill: "#107BD9"
                                                                        }
                                                                    }}
                                                                />
                                                                <span>B</span>
                                                            </div>
                                                            <div className="department-city-name">
                                                                {/*{flight.cityDep.split('(')[0]}*/}
                                                                {flight.cityArr}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ticket-details-section">
                                                    <div className="ticket-details-section-item">
                                                        <div>Авиакомпания</div>
                                                        <div className="air-company-name">
                                                            {flight.airCompanyName}
                                                        </div>
                                                    </div>
                                                    <div className="ticket-details-section-item">
                                                        <div>Рейс</div>
                                                        <div className="flight-number">
                                                            {flight.flightNumber}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </details>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}