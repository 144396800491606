import {ContractIcon} from "../../../../icons/ContactIcon";
import {MoneyLimitIcon} from "../../../../icons/MoneyLimitIcon";
import {InstalmentIcon} from "../../../../icons/InstalmentIcon";
import {CommonSimpleCardComponent} from "../../../commonComponents/commonCardComponents/commonSimpleCardComponent";
import {useTranslation} from "react-i18next";
import {OutputSimpleDataComponent} from "../../../commonComponents/outputSimpleDataComponent";
import {MAIN_BACKGROUND_COLOR} from "../../../../constants/PageConstants";
import {DocumentIcon} from "../../../../icons/DocumentIcon";
import {CheckPaymentIcon} from "../../../../icons/CheckPaymentIcon";
import {ColorCardComponent} from "../../../commonComponents/colorCardComponent";

type CompanyAccInfoProps = {
    agreementNo: string;
    agreementFrom: string;
    agreementTill: string;
    conpanyLimit: number;
    //installmentConditions: string;
    companyDuty: number;
    deptType: string;
    deptSum: number;
};

export function CompanyAccInfoComponent(accountInfo: CompanyAccInfoProps) {
    const {t} = useTranslation();
    const boottrapStyle = "col-6 col-sm-6 col-md-6 col-lg-2";
    return (
        <>
            <div
                className="filters-container"
                //style={{backgroundColor: MAIN_BACKGROUND_COLOR }}
            >
                <div className="filters-caption "> Общая информация</div>
                <div className="filters-form">
                    <ColorCardComponent id={"blue"} icon={<DocumentIcon/>} title={"Номер договора"}
                                        data={[["", accountInfo.agreementNo]]}/>
                    <ColorCardComponent id={"lightBlue"} icon={<DocumentIcon/>}
                                        title={"Срок действия котракта"}

                                        data={[["От: " + accountInfo.agreementFrom, " / До" + accountInfo.agreementTill]]}/>
                    <ColorCardComponent id={"lightgreen"} icon={<MoneyLimitIcon iconSize="18px"/>}
                                        title={t("accInfoPage.companyLimit")}
                                        data={[[accountInfo.conpanyLimit.toString(), " Rub"]]}/>

                    <ColorCardComponent id={"green"} icon={<CheckPaymentIcon/>}
                                        title={t("accInfoPage.accountStatus")}
                                        data={[[accountInfo.deptType + ": ", accountInfo.deptSum.toString() + " Rub"]]}/>

                </div>
            </div>
        </>
    );
}
