import React, {useEffect, useState} from "react";
import {MdButton} from "../../../components/md-button";
import {NewOrderComponent} from "./newOrderComponent";
import {ScheduleIcon} from "../../../icons/ScheduleIcon";
import "./style.scss";


export function TripPage() {

    const [newOrderModalOpened, setNewOrderModalOpened] = useState<boolean>(false);


    const onCreateOrderHandler = () => {
        setNewOrderModalOpened(true);
    }


    useEffect(() => {

    }, []);


    return (
        <>

            {
                <div className="no-orders-message">
                    <div className="no-orders-message-icon">
                        <ScheduleIcon width="60px" height="60px" style={{
                            "path": {
                                fill: "rgb(100 100 100)"
                            }
                        }}/>
                    </div>
                    <div className="no-orders-message-caption">Нет заказов для отображения</div>
                    <div className="no-orders-message-content">Нажмите на кнопку ниже чтобы создать
                        новый заказ
                    </div>
                    <div className="no-orders-message-button">
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={() => onCreateOrderHandler()}
                        >
                            Создать заказ
                        </MdButton>
                    </div>
                </div>
            }

            <NewOrderComponent
                newOrderModalOpened={newOrderModalOpened}
                setNewOrderModalOpened={setNewOrderModalOpened}
            />

        </>
    );
}
