import {useState} from "react";
//import {Overlay} from "react-bootstrap";
import "../../../scss/extends.scss";
import "./style.scss";

type InputTextWithPopoverComponentProps = {
    title?: string;
    value: string;
    placeholder: string;
    popoverItems: string[];
    setValue: (value: string) => void;
    runItemsSearch: (partInputValue: string) => void;
    clearPopoverItemsResult: () => void;
};

export function InputTextWithPopoverComponent(props: InputTextWithPopoverComponentProps) {
    const [popOverItems, setPopOverItems] = useState<string[]>(props.popoverItems);

    const inputHandler = (value: string) => {
        props.setValue(value);
        if (value.length === 3 && props.popoverItems.length === 0) {
            props.runItemsSearch(value);
        }
        if (value.length === 1 && props.popoverItems.length > 0) {
            props.clearPopoverItemsResult();
        }
        if (value.length >= 3) {
            setPopOverItems(
                props.popoverItems.filter((city) => city.toLowerCase().includes(value.toLowerCase()))
            );
        }
    };
    const selectHandler = (itemValue: string) => {
        props.setValue(itemValue);
    };

    return (
        <span className="input-group ">
      <label className="sr-only align-self-center">{props.title}</label>
      <div className="form-group dropdown-input-text-container ">
          <input
              id={"popover-trigger-focus" + props.title}
              type="text"
              className="form-control common-input "
              placeholder={props.placeholder}
              autoComplete="off"
              value={props.value}
              onChange={(e) => {
                  inputHandler(e.target.value);
              }}
          />
          {props.popoverItems &&
              <div className="dropdown-input-text-container-content"
                   style={{display: props.popoverItems.length > 0 ?   "":"none"}}
              >
              {props.popoverItems.map((item,index) => (<li key={index} onClick={() => selectHandler(item)}
              >{item}</li>))}
          </div>}



          {/*<Overlay*/}
          {/*    target={target.current}*/}
          {/*    show={show}*/}
          {/*    placement="bottom-start"*/}
          {/*    //container={}*/}
          {/*    containerPadding={20}*/}
          {/*><div className="dropdown-input-container">*/}
          {/*  <ul className="list-group overflow-y-auto" style={{ height: "150px" }}>*/}
          {/*    /!* {props.popoverItems.map((item, index) => ( *!/*/}
          {/*    {popOverItems.map((item, index) => (*/}
          {/*      <li*/}
          {/*        className="list-group-item"*/}
          {/*        key={InputTextWithPopoverComponent.name + "_" + index + "_" + item}*/}
          {/*        value={item}*/}
          {/*        onClick={() => {*/}
          {/*          selectHandler(item);*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {item}*/}
          {/*      </li>*/}
          {/*    ))}*/}
          {/*  </ul>*/}
          {/*  </div>*/}
          {/*</Overlay>*/}
      </div>

    </span>
    );
}
