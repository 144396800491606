import { DEFAULT_ICON_SIZE } from "../components/const/componentsContants";
import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";

export function HotelIcon({ iconSize}: IIconProps) {

  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" height={iconSize} width={iconSize}>
      <path d="M32 0C14.3 0 0 14.3 0 32s14.3 32 32 32v384c-17.7 0-32 14.3-32 32s14.3 32 32 32h176v-64h96v64h176c17.7 0 32-14.3 32-32s-14.3-32-32-32V64c17.7 0 32-14.3 32-32S497.7 0 480 0H32zm80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32zm144-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16zM96 208c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32zm144-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32zm-3 152.2c3.3 12.8-7.8 23.8-21 23.8H184c-13.3 0-24.3-10.9-21-23.8 10.6-41.5 48.2-72.2 93-72.2s82.5 30.7 93 72.2z" />
    </svg>
  );
}
