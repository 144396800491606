import { useEffect, useState } from "react";
import { DataConvertService } from "../../../../services/DataConvertService";
//import { statementSaleTest } from "./StatementSalesTestData";
import { SearchByDateComponent } from "../../../commonComponents/searchByDateComponent";
import { SortIcon } from "../../../../icons/SortIcon";
import { KeyAttributesOptions, KeyOption, RankingInfo, matchSorter } from "match-sorter";
import { SORT_AS_NUMBER, SORT_BY_ASC, SORT_BY_DESC } from "../../../../constants/ServiceCostants";
import { IStatementSale } from "../../../../interfaces/reportInterfaces/IStatementSale";
import { FilterComponent } from "../../../commonComponents/filterComponent/FilterComponent";
import { DataFilterService } from "../../../../services/DataFilterService";
import { ISortOrder } from "../../../../interfaces/componentInterfaces/ISortOrder";
import { FilterIcon } from "../../../../icons/FilterIcon";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

//import { KeyOption } from "match-sorter";

type StatementSalesProps = {
  statementSalesByPeriod: IStatementSale[];
};
export function StatementSalesComponent(props: StatementSalesProps) {
  const { t } = useTranslation();
  const [sortParameter, setSortParameter] = useState("nn");
  const { useMoneyRubFormat } = DataConvertService();
  const [sortBySumm, setSortBySumm] = useState("");
  const [sortByDate, setSortByDate] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [sortParams, setSortParams] = useState<ISortOrder[]>([
    {
      propName: "nn",
      isDefault: true,
      sortOrder: SORT_BY_ASC,
      sortAsDataType: SORT_AS_NUMBER,
    },
  ]);

  //const [nameFilterLenght, setNameFilterLenght];
  const [tableData, setTableData] = useState<IStatementSale[]>(props.statementSalesByPeriod);

  const highlightColor = "#73807a";
  const { filterAndSortArray } = DataFilterService();

  // const keyOption = new Array<KeyAttributesOptions<IStatementSale>>(
  //   { threshold: matchSorter.rankings.CONTAINS, key: "status" },
  //   { threshold: matchSorter.rankings.CONTAINS, key: "fio" },
  //   { threshold: matchSorter.rankings.CONTAINS, key: "additionalInfo" }
  // );

  // const findHandler = () => {
  //   setTableData(statementSaleTest.media);
  // };
  const filterByNameHandler = (partName: string) => {
    const i = 6;
    if (nameFilter.length > partName.length) {
      setNameFilter(partName);
      setTableData(
        filterAndSortArray(
          props.statementSalesByPeriod,
          partName,
          ["nn", "dateOper", "sumOper"],
          sortParams
        )
      );
    } else {
      setNameFilter(partName);
      setTableData(
        filterAndSortArray(tableData, partName, ["nn", "dateOper", "sumOper"], sortParams)
      );
    }
  };

  const sortBySummHandler = () => {
    if (sortBySumm === undefined || sortBySumm === SORT_BY_DESC) {
      setSortBySumm(SORT_BY_ASC);
      setTableData(
        matchSorter(tableData, "*", {
          threshold: matchSorter.rankings.NO_MATCH,
          baseSort: (a, b) => (a.item.sumOper < b.item["sumOper"] ? -1 : 1),
        })
      );
    } else {
      setSortBySumm(SORT_BY_DESC);
      setTableData(
        matchSorter(tableData, "*", {
          threshold: matchSorter.rankings.NO_MATCH,
          baseSort: (a, b) => (a.item.sumOper < b.item.sumOper ? 1 : -1),
        })
      );
    }
  };

  return (
    <>
      <table className="table  table-bordered common-document-stripped-table">
        <thead>
          {/*<tr>*/}
          {/*  <th scope="col" colSpan={5}>*/}
          {/*    <div className="d-flex">*/}
          {/*      /!* <SearchByDateComponent runSearch={findHandler} /> *!/*/}
          {/*    </div>*/}
          {/*  </th>*/}
          {/*  <th scope="col" colSpan={2}>*/}
          {/*    <FilterComponent*/}
          {/*      childrenIcon={<FilterIcon />}*/}
          {/*      inputChangeHandler={filterByNameHandler}*/}
          {/*      clearFilter={function (): void {*/}
          {/*        throw new Error("Function not implemented.");*/}
          {/*      }}*/}
          {/*    />{" "}*/}
          {/*  </th>*/}
          {/*</tr>*/}
          <tr>
            <th
              //style={{ backgroundColor: "#73807a" }}
              onClick={() => {
                setSortParameter("");
              }}
            >
              №
            </th>
            <th scope="col">{`${t("accInfoPage.date")}`}</th>
            <th scope="col">{`${t("statisticPage.service")}`}</th>
            <th scope="col" style={{ width: "10%" }}>
              {`${t("statisticPage.blankService")}`}
            </th>
            <th scope="col" style={{ width: "20%" }}>
              {`${t("statisticPage.info")}`}
            </th>
            <th scope="col">{`${t("statisticPage.name")}`}</th>
            <th scope="col">
              {`${t("statisticPage.summaRub")}`}{" "}
              <span
                onClick={() => {
                  sortBySummHandler();
                }}
              >
                <SortIcon />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.statementSalesByPeriod.map((item, i) => {
            return (
              <tr>
                <td>{item.nn}</td>
                <td>{item.dateOper}</td>
                <td>{item.status}</td>
                <td>{item.blankNumServiceName}</td>
                <td>{item.additionalInfo}</td>
                <td>{item.fio}</td>
                <td>{useMoneyRubFormat.format(item.sumOper)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
