//import { IFindAviaRequest } from "../Requests/IFindAviaReques";
//import { IAviaSearchResponse } from "../responces/IAviaSearchResponse";
//import { json } from "stream/consumers";
import {TicketType} from "../enums/TicketType";
import {OrderTickethApi} from "../api/OrderTicketApi";
import {ITrainVariant} from "../interfaces/orderInterfaces/TrainOrderInterfaces";
import {ITravelVariant} from "../interfaces/orderInterfaces/ITravelVariant";
import {IAviaVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "./DateService";
import {IDirectionRequest, ISearchTripVariants} from "../interfaces/requestsInterfaces/orderInterfaces";
import {useSessionStorage} from "./StorageService";
import {AVIA_VARIANTS, SORT_BY_ASC, SORT_BY_DESC, TRAIN_VARIANTS} from "../constants/ServiceCostants";
import {SortByPrice} from "../enums/SortByPrice";

export function OrderService() {

    const {searchCities, searchAviaFlights, searchTrains} = OrderTickethApi();
    const {getTravelTime} = DateService()
    const [aviaVariants, setAviaVariants] = useSessionStorage<IAviaVariant[]>(AVIA_VARIANTS, []);
    const [trainVariants, setTrainVariants] = useSessionStorage<ITrainVariant[]>(TRAIN_VARIANTS, []);


    const selectResultFilter = (travelResultTypes: TicketType[], variants: ITravelVariant[]) => {

        let filteredData = new Array<ITravelVariant>();
        filteredData = variants.filter((item: ITravelVariant) => {
            travelResultTypes.some(t => t === item.ticketType)
        })
    }


    const searchTravelVariants = async (direction: IDirectionRequest[],
                                        needSearchAvia: boolean,
                                        needSearchTrain: boolean,
                                        needBackwardVariant: boolean) => {
        let promises = new Array<Promise<ITravelVariant[]>>();
        //search avia
        if (needSearchAvia) {
            const promise = new Promise<ITravelVariant[]>(async (resolve, reject) => {
                const responce = await searchAviaFlights(getTripRequest(direction[0]));
                if (responce.status === 200) {
                    //todo remove next line after test
                    setAviaVariants(responce.data.media)

                    return resolve(responce.data.media.map(item => toTravelVariant(TicketType.AviaTicket, item)))
                } else return reject(responce.status)
            })
            promises.push(promise);
        }

        //search train
        if (needSearchTrain) {
            const promise = new Promise<ITravelVariant[]>(async (resolve, reject) => {
                const response = await searchTrains(getTripRequest(direction[0]));
                if (response.status === 200) {
                    //todo remove next line after test
                    setTrainVariants(response.data.media)
                    return resolve(response.data.media.map(item => toTravelVariant(TicketType.TrainTicket, item)))
                } else return reject(response.status)
            })
            promises.push(promise);
        }


        //Run All request
        let travelVariantResult = new Array<ITravelVariant>();

       // let promiseResult = await Promise.allSettled(promises);
      //  promiseResult.forEach(result => {if(result.status==="fulfilled")travelVariantResult.push(...result.value)})
        await Promise.allSettled(promises).then(results=>results.forEach(x=>{
            if(x.status === "fulfilled") travelVariantResult.push(...x.value)}));

        return travelVariantResult;
    }

    const searchCityNamesByPartName = async (partName: string): Promise<string[]> => {
        const result = await searchCities(partName);
        return result.map(item => item.element.name)
    }

    const toTravelVariant = (ticketType?: TicketType, variant?: IAviaVariant | ITrainVariant,): ITravelVariant => {

        try {
            if (ticketType === TicketType.AviaTicket && variant !== undefined) {
                let ticket = variant as IAviaVariant
                return aviaVariantToTravelVariant(ticket)
            }

            //! need to implement
            if (ticketType === TicketType.TrainTicket && variant !== undefined) {
                let ticket = variant as ITrainVariant
                return trainVariantToTravelVariant(ticket)
            }
            return initializeTravelVariant();
        } catch (err) {
            console.warn(err)
            return initializeTravelVariant();
        }
    }

    const sortByPrice = (sortOptions:number, unsortedVariants: ITravelVariant[]) => {

        if(sortOptions===(SortByPrice.MinPrice|SortByPrice.SortByAsc)){

            return unsortedVariants.sort((a, b) => a.minPrice - b.maxPrice)
        }

        if(sortOptions===(SortByPrice.MinPrice|SortByPrice.SortByDesc)){
            console.log(`sort min by desc before ${unsortedVariants[0]}`)
            const res = unsortedVariants.sort((a, b) => b.minPrice - a.maxPrice)
            console.log(`sort min by desc after ${res[0]}`)
            return res
        }

        if(sortOptions===(SortByPrice.MaxPrice|SortByPrice.SortByAsc)){
            return unsortedVariants.sort((a, b) => a.maxPrice - b.maxPrice)
        }

        if(sortOptions===(SortByPrice.MaxPrice|SortByPrice.SortByDesc)){
            return unsortedVariants.sort((a, b) => b.maxPrice - a.maxPrice)
        }
        return unsortedVariants

    }

    //inner functions
    function getTripRequest(directionRequest: IDirectionRequest): ISearchTripVariants<IDirectionRequest> {
        return {
            trips: [
                {
                    dirs: [directionRequest],
                },
            ],
        };
    }

    function aviaVariantToTravelVariant(ticket: IAviaVariant) {
        let travelVariant = initializeTravelVariant()
        travelVariant.travelVariant = ticket;
        travelVariant.ticketType = TicketType.AviaTicket;

        if (ticket !== undefined && ticket.rejsInfo !== undefined) {
            const rejs = ticket.rejsInfo[ticket.rejsInfo.length - 1]
            const departureDate = new Date(ticket.rejsInfo[0].dateDep);
            const arrivedDate = new Date(rejs.dateArr);
            travelVariant.travelTime = getTravelTime(departureDate, arrivedDate);

            // let travelVariant = initializeTravelVariant()
            //departure Data
            travelVariant.departureTime = (departureDate?.getHours() < 10 ? "0" + departureDate.getHours() : departureDate.getHours())
                + ":"
                + (departureDate.getMinutes() < 10 ? "0" + departureDate.getMinutes() : departureDate.getMinutes());
            //travelVariant.departureDate = departureDate.getDate()+"-"+departureDate.getMonth()+"-"+departureDate.getFullYear()
            travelVariant.departureDate = departureDate.toDateString();
            travelVariant.departureCity = ticket.rejsInfo[0].cityDep;
            travelVariant.departurePlaceCode = ticket.rejsInfo[0].airCodeDep;

            //arrivedData
            travelVariant.arrivedDate = arrivedDate.toDateString();
            travelVariant.arriveTime = (arrivedDate.getHours() < 10 ? "0" + arrivedDate.getHours() : arrivedDate.getHours())
                + ":"
                + (arrivedDate.getMinutes() < 10 ? "0" + arrivedDate.getMinutes() : arrivedDate.getMinutes());
            travelVariant.arriveCity = rejs.cityArr;
            travelVariant.arrivePlaceCode = rejs.airCodeArr;
            travelVariant.minPrice = rejs.tarifs[0]?.price;
            travelVariant.maxPrice = rejs.tarifs[0]?.price;
            travelVariant.tariffCount = rejs.tarifs.length;
            //travelVariant.travelCompanyName = (ticket.rejsInfo[0].airCompanyName.split('-'))[0]
            travelVariant.vehicleName = ticket.rejsInfo[0].flightNumber
            travelVariant.estimated = ticket.rejsInfo[0].estimated
            travelVariant.transfersCount = ticket.rejsInfo.length - 1;
            ticket.rejsInfo.forEach(rejs => {
                travelVariant.travelCompanyOrVehicle.push((rejs.airCompanyName.split("-"))[0])
            })
            rejs.tarifs.forEach(tariff => {
                if (tariff.price < travelVariant.minPrice) travelVariant.minPrice = tariff.price;
                if (tariff.price > travelVariant.maxPrice) travelVariant.maxPrice = tariff.price;
            })
        }
        return travelVariant;
    }

    function trainVariantToTravelVariant(variant: ITrainVariant) {
        let travelVariant = initializeTravelVariant()
        travelVariant.travelVariant = variant;
        travelVariant.ticketType = TicketType.TrainTicket;
        //travelVariant.minPrice = 100
        let firstCategory = variant.categoryPrice[0];
        //let lastCategory = variant.categoryPrice[variant.categoryPrice.length-1];

        travelVariant.travelCompanyOrVehicle = [variant.trainNum + (variant.trainTitle.length > 0 ? "/" + variant.trainTitle : "")];
        travelVariant.minPrice = firstCategory.price;
        travelVariant.maxPrice = firstCategory.price;
        travelVariant.travelTime = variant.travelTime;
        travelVariant.tariffCount = variant.categoryPrice.length

        variant.categoryPrice.forEach(c => {
            if (travelVariant.minPrice > c.price) {
                travelVariant.minPrice = c.price
            }
            if (travelVariant.maxPrice < c.price) {
                travelVariant.maxPrice = c.price
            }
        })

        //departure
        travelVariant.departureTime = variant.departureShortOn;
        travelVariant.departureDate = variant.departureAt;
        // travelVariant.departurePlaceCode = variant.codeFrom;
        travelVariant.departureCity = `${variant.cityFrom} (${variant.stationFrom})`;

        //arrive
        travelVariant.arriveTime = variant.arriveAtn;
        travelVariant.arrivedDate = variant.arriveShortOn;
        // travelVariant.arrivePlaceCode = variant.codeTo;
        travelVariant.arriveCity = `${variant.cityTo} (${variant.stationTo})`;// variant.cityTo;
        return travelVariant
    }

    function initializeTravelVariant(): ITravelVariant {
        return {
            ticketType: TicketType.None,
            variantPrice: 0,
            minPrice: 0,
            maxPrice: 0,
            tariffCount: 0,
            travelCompanyOrVehicle: [],
            transfersCount: 0,
            vehicleName: "",
            estimated: "",
            departureTime: "0",
            departureDate: "0",
            departureCity: "0",
            departurePlaceCode: "",
            arriveTime: "",
            arrivedDate: "",
            arriveCity: "",
            arrivePlaceCode: "",
            travelTime: "",
            isSelected: false
        }

    }

    return {searchCityNamesByPartName, toTravelVariant, searchTravelVariants, sortByPrice};
}
