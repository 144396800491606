import {HubConnectionBuilder, HubConnection, HttpTransportType, IHttpConnectionOptions} from '@microsoft/signalr';

class SignalRService {
    private connection: HubConnection | null = null;
    protected eventListeners: { [key: string]: Function[] } = {};

    public async startConnection( hubUrl: string, options: IHttpConnectionOptions = {} ): Promise<HubConnection> {
        this.connection = new HubConnectionBuilder()
            .withUrl(hubUrl,
                Object.assign({
                //skipNegotiation: true,
                //transport: HttpTransportType.WebSockets,
                //withCredentials: false
                }, options)
            )
            //.withAutomaticReconnect()
            .build();
        try {
            await this.connection.start()
                .then(() => console.log('Connection started'))
                .catch(err => console.log('Error while starting connection: ' + err))
        } catch (err) {
            console.error('[SignalR] connection error: ', err);
        }
        return this.connection;
    }

    public getConnectionId(): string | null {
        return this.connection ? this.connection.connectionId : null;
    }

    public async sendData( method: string, ...args: any[] ): Promise<void> {
        if (this.connection) {
            try {
                await this.connection.invoke(method, ...args);
            } catch (err) {
                console.error('[SignalR] invoke method ' + method +' error: ', err);
            }
        }
    }

    /**
     * Event subscribe function
     *
     * @param {String} event Event name
     * @param {Function} callback Function
     */
    addEventListener( event: string, callback: Function ) {
        this.eventListeners[event] = this.eventListeners[event] || [];
        this.eventListeners[event].push(callback);

        this.connection?.on(event, (...args: any[]) => {
            callback(...args);
        });

        return this;
    }

    /**
     * Event unsubscribe function
     *
     * @param {String} event Event name
     * @param {Function} callback Function
     */
    removeEventListener(event: string, callback: Function) {
        const functions = (this.eventListeners[event] || []);
        const index = functions.indexOf(callback);
        if (~index) {
            functions.splice(index, 1);
        }
        this.connection?.off(event);
        return this;
    }

    /**
     * Event emitter function
     *
     * @param {String} event Event name
     * @param {Any} args Argument list
     */
    private emit( event: string, ...args: any[] ) {
        this.eventListeners[event].forEach(cb => cb(...args, this));
    }
}

export default new SignalRService();