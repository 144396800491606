import React, {CSSProperties, useEffect, useState} from "react";
import './style.scss'

interface IMdAccordionProps {
    mode?: "multi-open" | "single-open";
    sections: Array<IMdAccordionSectionProps>;
    style?: { [selector: string]: CSSProperties }
}

interface IMdAccordionSectionProps {
    header?: string | React.ReactNode;
    content?: string | React.ReactNode;
    isOpen?: boolean;
    style?: React.CSSProperties;
}


export function MdAccordion({ mode = "multi-open", sections = [], style = {} }: IMdAccordionProps) {

    const [openedSections, setOpenedSections] = useState<number[]>(sections.map((x, index) => (x.isOpen ? index : -1 )).filter(x => x != -1));


    const onHeaderClick = (index: number) => {
        if(!openedSections.some(x => x == index)) {
            if(mode == "multi-open") {
                setOpenedSections(prev => [...prev.filter(x => x != index), index]);
            }
            else {
                setOpenedSections(prev => [index]);
            }
        }
        else {
            if(mode == "multi-open") {
                setOpenedSections(prev => [...prev.filter(x => x != index)]);
            }
            else {
                setOpenedSections(prev => []);
            }
        }
    }

    // useEffect(() => {
    //
    //     if(sections.some(x => !openedSections))
    //
    //     const item = (activeItem != null ? itemSource.find(x => x == activeItem) : null);
    //     if(selectedItem != item) {
    //         setSelectedItem(item);
    //     }
    // }, [sections]);


    useEffect(() => { }, []);


    return (
        <div
            className={"md-accordion"}
            style={ style["root"] }>
            {
                sections.map((section, index) => {
                    const isOpen = openedSections.some(x => x == index);
                    return (
                        <div
                            key={index}
                            className="md-accordion-section">
                            <div className="md-accordion-section-header">
                                <button
                                    className="md-accordion-section-header-button"
                                    onClick={() => onHeaderClick(index)}
                                >
                                    {section.header}
                                </button>
                            </div>
                            <div className={("md-accordion-section-content" + (isOpen ? " --open" : ""))}>
                                {section.content}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}