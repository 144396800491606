import React, {
    Children,
    CSSProperties,
    useEffect,
    useRef,
    useState
} from "react";
import './style.scss'


interface IMdTabsProps {
    tabs?: IMdTabProps[];
    activeIndex?: number;
    style?: { [selector: string]: CSSProperties }
    disabled?: boolean;
    onTabChange?: (index: number) => void;
}

interface IMdTabProps {
    header?: string | React.ReactNode;
    icon?: any;
    content?: React.ReactNode;
    style?: CSSProperties;
    disabled?: boolean;
    onClick?: (event: any) => void;
}


export function MdTabs({
                           tabs = [], activeIndex = 0, style = {}, disabled = false, onTabChange = () => {
    }
                       }: IMdTabsProps) {
    const root = useRef(null);
    const bar = useRef(null);
    const indicator = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState<number>(-1);


    const onSelectTab = (index: number) => {
        setSelectedIndex(index);
        updateIndicator(index);
        onTabChange(index);
    }

    const updateIndicator = (index: number) => {
        if (root.current != null && bar.current != null && indicator.current != null) {
            let rootNode = root.current as HTMLElement;
            let barNode = bar.current as HTMLElement;
            let indicatorNode = indicator.current as HTMLElement;
            let activeNav = rootNode.querySelector('button[data-tab-index="' + index + '"]');
            if (activeNav != null) {
                let barBB = barNode.getBoundingClientRect();
                let buttonBB = activeNav.getBoundingClientRect();
                indicatorNode.style.left = (buttonBB.left - barBB.left) + "px";
                indicatorNode.style.width = buttonBB.width + "px";
            }
        }
    }

    useEffect(() => {
        if (selectedIndex != activeIndex) {
            setSelectedIndex(activeIndex);
            updateIndicator(activeIndex);
        }
    }, [activeIndex]);

    useEffect(() => {

    }, []);


    return (
        <div
            ref={root}
            className="md-tabs">
            <div ref={bar}
                 className="md-tabs-bar">
                {
                    tabs.map((tab: any, index: number) => {
                        return (
                            <button key={index}
                                    className={"md-tabs-bar-item" + (selectedIndex == index ? " --selected" : "")}
                                    data-tab-index={index}
                                    onClick={() => onSelectTab(index)}
                            >
                                {tab.header}
                            </button>
                        )
                    })
                }
                <div ref={indicator}
                     className="md-tabs-bar-indicator"></div>
            </div>
            <div className="md-tabs-container">
                {
                    tabs.map((tab: any, index: number) => {
                        return (
                            <div key={index}
                                 className={"md-tabs-container-item" + (selectedIndex == index ? " --selected" : "")}
                                 data-tab-index={index}
                            >
                                {tab.content}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}