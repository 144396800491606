import React, {CSSProperties, useEffect} from "react";
import './style.scss'


interface IMdRadioButton {
    style?: { [selector: string]: CSSProperties | {[key: string]: string | number} };
    name: string;
    checked?: boolean;
    disabled?: boolean;
    onChecked?: () => void;
    onUnchecked?: () => void;
    children: any;
    onChanged?: (state: boolean) => void;
}


export function MdRadioButton({ name = "", children = null, style = {}, checked = false, disabled = false, onChecked = () => {}, onUnchecked = () => {}, onChanged = () => {},  }: IMdRadioButton) {


    const changeState = (e: React.ChangeEvent) => {
        let state = (e.target as HTMLInputElement).checked;

        if(state && onChecked != null) {
            onChecked();
        }
        else if(!state && onUnchecked != null) {
            onUnchecked();
        }

        if(onChanged != null) {
            onChanged(state);
        }
    };

    useEffect(() => { }, []);

    return (
        <label
            className="md-radio-button"
            style={style["label"]}
        >
            <input
                type="radio"
                name={name}
                checked={checked}
                style={style["input"]}
                onChange={changeState}
            />
            <span>
                {children}
            </span>
        </label>
    );
}