import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICompanyInfo} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";
import {MdButton} from "../../../../components/md-button";
import {MdPagination} from "../../../../components/md-pagination";
import {MdTable} from "../../../../components/md-table";
import "./style.scss";
import "../../../../scss/tables.scss";


type CompanyTableProps = {
    gkName?:string;
    companies?: ICompanyInfo[],
    activeCompany: string;
    makeActiveCompany: (name: string) => void
    isLoading: boolean;
    isSearchResultNotFound: boolean;
    openCompanyImportModal: () => void;
    openCompanyAddModal: () => void;
    updateDataHandler:()=>void;
}


export function CompanyTableComponent({
    gkName,
    companies,
    activeCompany,
    makeActiveCompany,
    isLoading,
    isSearchResultNotFound,
                                          openCompanyImportModal,
    openCompanyAddModal,
    updateDataHandler
    }: CompanyTableProps) {

    const {t} = useTranslation();

    let tableData = companies;

    const itemsPerPage = 100;

    const isTableLoading = isLoading;
    const isEmptySearchResult = isSearchResultNotFound;


    const [companyList, setCompanyList] = useState<ICompanyInfo[]>([]);
    const [activePage, setActivePage] = useState(1);


    const onPageChange = (pageNumber: number) => {
        let skip = Math.max(0, (pageNumber - 1) * itemsPerPage);
        setActivePage(pageNumber);
        if (tableData != null) {
            setCompanyList(tableData.slice(skip, skip + itemsPerPage));
        }
    }


    useEffect(() => {
        if (tableData != null) {
            let skip = Math.max(0, (activePage - 1) * itemsPerPage);
            setCompanyList(tableData.slice(skip, skip + itemsPerPage));
        } else {
            setCompanyList([]);
        }
    }, [companies, activePage]);


    return (
        <>
            <div className="company-results">
                <div className="company-results-header">
                    <div className="company-results-caption">
                        {
                            gkName !== undefined
                            ? "Группа компаний (" + (gkName) + ")"
                            : "Результаты"
                        }
                        <span style={{display: (isTableLoading ? "none" : "block")}}>{tableData?.length}</span>
                        <span className="company-results-preloader"
                              style={{display: (isTableLoading ? "flex" : "none")}}>
                            <div className="spinner-border company-results-preloader-icon"></div>
                            <span className="company-results-preloader-text">поиск...</span>
                        </span>
                    </div>
                    <div className="company-results-tools">
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={isTableLoading}
                            onClick={() => openCompanyImportModal()}
                        >
                            Импорт
                        </MdButton>
                        {/*<MdButton*/}
                        {/*    style={{*/}
                        {/*        "button": {*/}
                        {/*            borderWidth: 0,*/}
                        {/*            backgroundColor: "rgb(233 233 233)",*/}
                        {/*            color: "rgb(0 0 0)",*/}
                        {/*            borderRadius: "6px",*/}
                        {/*            height: "35px",*/}
                        {/*            padding: "5px 15px"*/}
                        {/*        },*/}
                        {/*        "icon": {*/}
                        {/*            marginRight: "5px",*/}
                        {/*        },*/}
                        {/*        "ink": {*/}
                        {/*            backgroundColor: "rgba(255,255,255,.5)"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    disabled={isTableLoading}*/}
                        {/*    onClick={() => openCompanyAddModal()}*/}
                        {/*>*/}
                        {/*    Добавить*/}
                        {/*</MdButton>*/}
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={updateDataHandler}
                        >
                            {`${t("myProfilePage.updateData")}`}
                        </MdButton>
                    </div>
                </div>

                {(companyList.length == 0 && isTableLoading) && (
                    <>
                        <div className="table-skeleton-preloader">
                            {
                                Array.from(Array(itemsPerPage + 1).keys()).map((item, index) => {
                                    return (
                                        <div key={index} className="table-skeleton-item"></div>
                                    )
                                })
                            }
                        </div>
                    </>
                )}

                {(isEmptySearchResult) && (
                    <>
                        <div className="empty-search-result-message">
                            Компании не найдены, попробуйте изменить параметры поиска и повторите.
                        </div>
                    </>
                )}

                <MdTable
                    columns={[
                        {
                            header: t("myProfilePage.gcName"),
                            cellTemplate(data) {
                                return <div>{data.gkName}</div>
                            }
                        },
                        {
                            header: t("myProfilePage.fullName"),
                            cellTemplate(data) {
                                return <div>{data.fullName}</div>
                            }
                        },
                        {
                            header: t("myProfilePage.agreement"),
                            cellTemplate(data) {
                                return <div>{data.agreementNo}</div>
                            }
                        },
                        {
                            header: t("myProfilePage.agreementPeriod"),
                            cellTemplate(data) {
                                return <div>{data.agreementFrom + " / " + data.agreementTill}</div>
                            }
                        },
                    ]}
                    itemSource={companyList}
                    activeItem={companyList.find(x => x.code == activeCompany)}
                    onRowSelect={(data) => makeActiveCompany(data.code)}
                    style={{
                        "tbody": {
                            maxHeight: "calc(100vh - 400px)"
                        }
                    }}
                />

            </div>

            <div className="pagination-container" style={{float: "right"}}>
                <MdPagination
                    activePage={activePage}
                    itemsCount={(tableData != null ? tableData.length : 0)}
                    itemsPerPage={itemsPerPage}
                    onChange={(page) => onPageChange(page)}
                />
            </div>
        </>
    );
}
