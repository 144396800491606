import {createAsyncThunk, createSlice, PayloadAction, isAction} from '@reduxjs/toolkit';
import {RootState} from "./store";
import {AuthApi} from "../api/AuthApi";
import {IUserResponse} from "../responces/IUserResponse";
import {ICompanyInfo} from '../interfaces/companyInfoInterfaces/ICompanyInfo';


interface AuthState {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    fullName: string;
    currentClientCode: string;
    userRole: string;
    roles: { [company: string]: string[] },
    accessToken: string;
    expiredAt: number;
    currentGkCode: string;
    gkClientCodes: string[];
    authError: string;
    isValidUser: boolean;
    gkCompanies: ICompanyInfo[],
}

const initialState: AuthState = {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    fullName: "",
    userRole: "",
    roles: {},
    //? need to remove arr add to export access and refresh tokens
    accessToken: "",
    expiredAt: 0,
    //! HardCode for Testing
    currentClientCode: "",
    authError: "",
    isValidUser: false,
    currentGkCode: "",
    gkClientCodes: [],
    gkCompanies: [],
};

const {getInfoAboutUser} = AuthApi();

export const getUserInfo = createAsyncThunk(
    "auth/getUserInfo",
    async (thunkApi) => {
        const responce = await getInfoAboutUser();
        return responce;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<{ [company: string]: string[] }>) => {
            state.roles = action.payload;
        },
        setUserRole: (state, action: PayloadAction<string>) => {
            state.userRole = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setExpiredAt: (state, action: PayloadAction<number>) => {
            state.expiredAt = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setPatronymic: (state, action: PayloadAction<string>) => {
            state.patronymic = action.payload;
        },
        setCurrentClientCode: (state, action: PayloadAction<string>) => {
            state.currentClientCode = action.payload;
        },
        setAuthError: (state, action: PayloadAction<string>) => {
            state.authError = action.payload;
        },
        setIsUserValid: (state, action: PayloadAction<boolean>) => {
            state.isValidUser = action.payload;
        },
        setGkCode: (state, action: PayloadAction<string>) => {
            state.currentGkCode = action.payload;
        },
        setGkClientCodes: (state, action: PayloadAction<string[]>) => {
            state.gkClientCodes = action.payload;
        },
        setGkCompanies: (state, action: PayloadAction<ICompanyInfo[]>) => {
            state.gkCompanies = action.payload
        },
        //gkCode: string;
        //gkClientCodes: string[];
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getUserInfo.fulfilled,
                (state, action: PayloadAction<IUserResponse>) => {
                    state.fullName =
                        action.payload.firstName +
                        " " +
                        action.payload.lastName +
                        " " +
                        action.payload.patronymic;
                }
            )
    },
});

export const {
    setEmail,
    setFirstName,
    setLastName,
    setPatronymic,
    setUserRole,
    setUserRoles,
    setUserName,
    setUserId,
    setAccessToken,
    setExpiredAt,
    setAuthError,
    setIsUserValid,
    setCurrentClientCode,
    setGkCode,
    setGkClientCodes,
    setGkCompanies,
} = authSlice.actions;
export const userValidationStatus = (state: RootState) =>
    state.auth.isValidUser;
export default authSlice.reducer;
