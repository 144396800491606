import {useContext, useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {ICustomerRegister} from "../../../interfaces/reportInterfaces/customerInterfaces";
import {BASE_URL, ROUTE_REPORTS_HUB} from "../../../constants/routeConstants/ApiRouteConstants";
import {RegisterService} from "../../../services/RegisterService";
import SignalRService from "../../../services/SignalRService";
import {MdModal} from "../../../components/md-modal";
import {RegisterTableComponent} from "./registersTableComponent/RegistersTableComponent";
import {RegisterSendComponent} from "./registerSendComponent";
import {DownloadReconciliationComponent} from "./downloadReconciliationComponent";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../../../constants/FieldsConstants";
import {CustomerApi} from "../../../api/CustomerApi";
import {ICompanyInfo} from "../../../interfaces/companyInfoInterfaces/ICompanyInfo";
import {Guid} from "../../../utils/utils";
import {ModalContext} from "../../commonComponents/modal/ModalContext";


interface GeneratedDocument {
    ConnectionId: string;
    Tag: string;
    FileName: string;
    Link: string;
}


export function RegisterComponentPage() {

    const initialized = useRef(false);

    const [cookies] = useCookies([ACCESS_TOKEN]);

    const authState = useAppSelector((state) => state.auth);

    const {downloadBuchDocsEdm} = CustomerApi();

    const reportState = useAppSelector((state) => state.reports);

    const {getReconciliation, getRegisters, sendRegister} = RegisterService();

    const {isOpen, open, close} = useContext(ModalContext);


    const [connectionId, setConnectionId] = useState<string | null>(null);

    const [sendModalId] = useState(Guid.newGuid());
    const openSendModal = () => open(sendModalId);
    const closeSendModal = () => close(sendModalId);

    const [downloadModalId] = useState(Guid.newGuid());
    const openDownloadModal = () => open(downloadModalId);
    const closeDownloadModal = () => close(downloadModalId);


    const [downloadingReconciliation, setDownloadingReconciliation] = useState(false);

    const onDownloadReconciliationSubmit = async ( company: ICompanyInfo, startDate: string, endDate: string ) => {
        // set document download status
        setDownloadingReconciliation(true);
        //
        await getReconciliation(company.code, startDate, endDate, connectionId);

        closeDownloadModal();

        setTimeout(function () {
            setDownloadingReconciliation(false);
        }, 30000);
    }


    const [activeRegister, setActiveRegister] = useState<ICustomerRegister>();
    const [selectedRegisters, setSelectedRegisters] = useState<ICustomerRegister[]>([]);
    const [registerNames, setRegisterNames] = useState<string[]>(reportState.customerRegisterNames);
    const [registers, setRegisters] = useState<ICustomerRegister[]>(reportState.customerRegisters);

    const setActiveRegisterHandler = (register: ICustomerRegister) => {
        setActiveRegister(register);
    };

    const addRegisterToSelected = (reg: ICustomerRegister) => {
        setSelectedRegisters([...selectedRegisters, reg]);
    };

    const removeRegisterFromSelected = (reg: ICustomerRegister) => {
        setSelectedRegisters(prev => prev.filter(x => x.id !== reg.id));
    };

    const sendRegisterHandler = async (registerName: string, dateFrom: string, dateTo: string, recipients: string[]) => {
        await sendRegister(registerName, dateFrom, dateTo, recipients);
        closeSendModal();
    };


    useEffect(() =>
    {
        // on component initialization
        if (!initialized.current)
        {
            initialized.current = true;

            {
                // define async method for signalR initialization
                const initSignalR = async (hostUrl: string) => {
                    // start connection
                    let connection = await SignalRService.startConnection(hostUrl);
                    // set connection id
                    setConnectionId(connection.connectionId);
                };
                // after signalR initialization
                initSignalR(BASE_URL + ROUTE_REPORTS_HUB).then(r => {
                    // subscribe on event
                    SignalRService.addEventListener('completeGeneration', async function (docNumber: string, data: string) {
                        setDownloadingReconciliation(false);
                        let doc = JSON.parse(data) as GeneratedDocument;
                        const response = await downloadBuchDocsEdm(doc.Link, true, authState.currentClientCode, cookies[ACCESS_TOKEN]);
                        try {
                            const file = new Blob([response], {type: "application/pdf"});
                            //Build a URL from the file
                            const fileURL = URL.createObjectURL(file);
                            //Open the URL on new Window
                            const pdfWindow = window.open();
                            pdfWindow!.location.href = fileURL;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });
                });
            }

            {
                // get list of registers
                getRegisters().then(response => {
                    if (response !== undefined && response.length > 0) {
                        setRegisterNames(response);
                    }
                });
            }
        }
    }, []);


    return (
        <>
            <RegisterTableComponent
                registerNames={registerNames}
                registers={registers}
                activeRegister={activeRegister}
                setActiveRegister={setActiveRegisterHandler}
                selectedRegisters={selectedRegisters}
                selectRegister={addRegisterToSelected}
                unselectRegister={removeRegisterFromSelected}
                openDownloadForm={openDownloadModal}
                openSendForm={openSendModal}
                downloadingDocuments={downloadingReconciliation}
            />

            <MdModal
                id={sendModalId}
                title="Акт сверки">
                <DownloadReconciliationComponent
                    opened={isOpen(downloadModalId)}
                    onSubmit={onDownloadReconciliationSubmit}
                />
            </MdModal>

            <MdModal
                id={downloadModalId}
                title="Отправка реестров">
                <RegisterSendComponent
                    registerNames={registerNames}
                    //dateFrom={new Date()}
                    //dateTo={new Date()}
                    sendHandler={async (registerName, DateFrom, DateTo, recipients) =>
                        await sendRegisterHandler(registerName, DateFrom, DateTo, recipients)
                    }/>
            </MdModal>
        </>
    );
}
