import React, {CSSProperties, HTMLInputTypeAttribute, useEffect, useState} from "react";
import './style.scss'

interface IMdTextFieldProps {
    style?: CSSProperties;
    type: HTMLInputTypeAttribute;
    label?: string;
    value: any;
    errorMessage?: string | null;
    invalid?: boolean;
    required?: boolean;
    disabled?: boolean;
    onChange?: (event: any) => void;
}

export function MdTextField(props: IMdTextFieldProps) {
    let input: HTMLInputElement | null = null;

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const focusHandler = (event: React.FocusEvent) => {
        setIsFocused(true);
    }

    const blurHandler = (event: React.FocusEvent) => {
        setIsFocused(false);
    }

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 0) {
            setIsFilled(true)
        } else {
            setIsFilled(false);
        }
    }


    useEffect(() => {
        setIsFilled(props.value != null && props.value.length > 0);
    }, [props.value]);


    useEffect(() => {

    }, [isFocused]);


    return (
        <div className={("md-text-field-container" + (isFocused ? " --focused" : "") + (isFilled ? " --filled" : "") + (props.invalid ? " --invalid" : ""))}>
            <label className="md-text-field md-text-field--outlined">
                <input
                    ref={ref => input = ref}
                    className="md-text-field-field-input" type={props.type} value={props.value}
                    required={props.required}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    onInput={inputHandler}
                    onChange={props.onChange}/>
                <div className="md-text-field-outline-container">
                    <div className="md-text-field-outline-container-left"></div>
                    <div className="md-text-field-outline-container-middle">
                        <span className="md-text-field-label">{props.label}</span>
                    </div>
                    <div className="md-text-field-outline-container-right"></div>
                </div>
            </label>
            <div className="md-text-field-helper-line">
                <div className="md-text-field-helper-line-text">
                    {props.errorMessage}
                </div>
            </div>
        </div>
    );
}