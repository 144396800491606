import {useEffect, useState} from "react";
import "./style.scss";
import {CustomCheckBox} from "../../../commonComponents/checkBoxComponents/customCheckBox";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {DataFilterService} from "../../../../services/DataFilterService";
import {TicketType} from "../../../../enums/TicketType";
import {SortIcon} from "../../../../icons/SortIcon";
import {use} from "i18next";
import {SORT_BY_ASC, SORT_BY_DESC} from "../../../../constants/ServiceCostants";
import {CheckComponent} from "../../../commonComponents/radioComponent";
import {OrderService} from "../../../../services/OrderServices";
import {SortByPrice} from "../../../../enums/SortByPrice";

type FilterResultProps = {
    isDefaultState: boolean;
    // setSortedTravelList: () => void;
    travelVariants: ITravelVariant[];
    filteredTravelVariants: ITravelVariant[];
    setFilteredTravelVariants: (filteredTravelVariants: ITravelVariant[]) => void;
}

export function FilterResultComponent(props: FilterResultProps) {


    const [hasAvia, setHasAvia] = useState(true);
    const [hasTrain, setHasTrain] = useState(true);
    const [hasAviaBackward, setHasAviaBackward] = useState(true);
    const [hasTrainBackward, setHasTrainBackward] = useState(true);

    //accordion hook
    const [showSelectVariant, setShowSelectVariant] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    //filters hooks
    const [showAviaVariants, setShowAviaVariants] = useState(true);
    const [showTrainVariants, setShowTrainVariants] = useState(true);
    const [showAviaBackwardVariants, setShowAviaBackwardVariants] = useState(true);
    const [showTrainBackwardVariants, setShowTrainBackwardVariants] = useState(true);
    const [showTransfersVariants, setShowTransfersVariants] = useState(false);
    const [airLineFilter, setAirLineFilter] = useState("");
    const [trainCompanyFilter, setTrainCompanyFilter] = useState("");

    //sortOptions
    const [sortVariantsByPrice, setSortVariantsByPrice] = useState(0);

    const [minPriceSorter, setMinPriceSorter] = useState("");
    const [maxPriceSorter, setMaxPriceSorter] = useState("");
    const [travelTimeSorter, setTravelTimeSorter] = useState("");
    const [departeTimeSorter, setDeparteTimeSorter] = useState("");
    const [arriveTimeSorter, setArriveTimeSorter] = useState("");

    //services
    const {filterAndSortArray} = DataFilterService();
    const {sortByPrice} = OrderService();
    const setDefaultState = () => {
        if (props.isDefaultState) {

        } else return;
    }

    //set default state for selected item
    function setDefaultSelectState(ticketType: TicketType) {
        switch (ticketType) {
            case   TicketType.AviaTicket: {
                return props.travelVariants.some(item => item.ticketType === TicketType.AviaTicket);
            }

            case   TicketType.TrainTicket: {
                return props.travelVariants.some(item => item.ticketType === TicketType.TrainTicket);
            }

            case   TicketType.AviaBackwardTicket: {
                return props.travelVariants.some(item => item.ticketType === TicketType.AviaBackwardTicket);
            }

            case   TicketType.TrainBackwardTicket: {
                return props.travelVariants.some(item => item.ticketType === TicketType.TrainBackwardTicket);
            }
        }
    }

    const selectFilterHandler = (filterBy: TicketType) => {

        let filterParameters = new Array<TicketType>();
        filterParameters.push(TicketType.None);
        switch (filterBy) {
            case TicketType.AviaTicket: {
                if (showAviaVariants) {
                    filterParameters[0] = TicketType.AviaTicket;
                } else {
                    filterParameters.push(TicketType.AviaTicket);
                }
                setShowAviaVariants(!showAviaVariants);
                break;
            }
            case TicketType.TrainTicket: {
                if (showTrainVariants) {
                    filterParameters[0] = TicketType.TrainTicket;
                } else {
                    filterParameters.push(TicketType.TrainTicket);
                }
                setShowTrainVariants(!showTrainVariants);
                break;
            }
            case TicketType.AviaBackwardTicket: {
                if (showAviaBackwardVariants) {
                    filterParameters[0] = TicketType.AviaBackwardTicket;
                } else {
                    filterParameters.push(TicketType.AviaBackwardTicket);
                }
                setShowAviaBackwardVariants(!showAviaBackwardVariants);
                break;
            }
            case TicketType.TrainBackwardTicket: {
                console.log(`case Train Backwards`)
                if (showTrainBackwardVariants) {
                    filterParameters[0] = TicketType.TrainBackwardTicket;
                } else {
                    filterParameters.push(TicketType.TrainBackwardTicket);
                }
                setShowTrainBackwardVariants(!showTrainBackwardVariants);
                break;
            }
        }

        // this block working
        if (filterParameters[0] !== TicketType.None) {
            //console.log(`In if before filtering param: ${filterParameters}`);
            //console.log(props.filteredTravelVariants.length);
            props.setFilteredTravelVariants(props.filteredTravelVariants.filter(v => v.ticketType !== filterParameters[0]))
            return;
        } else {

            //! Need to implement
            if (showAviaVariants) filterParameters.push(TicketType.AviaTicket);
            if (showTrainVariants) filterParameters.push(TicketType.TrainTicket);
            if (showAviaBackwardVariants) filterParameters.push(TicketType.AviaBackwardTicket);
            if (showTrainBackwardVariants) filterParameters.push(TicketType.TrainBackwardTicket);

            //let filteredData = props.travelVariants.filter(v => filterParameters.find(travelType => travelType === v.ticketType) !== undefined);
            let filteredData = props.travelVariants.filter((item: ITravelVariant) =>
                filterParameters.some(t => t === item.ticketType)
            )
            props.setFilteredTravelVariants(filteredData);
            return;
        }
    }

    const sortByPriceHandler =async (sortOpt: number) => {

        console.log("input sort opt: ",sortOpt);
        if (sortOpt === sortVariantsByPrice) {
            console.log("in if, check currentState: ",sortVariantsByPrice);
            setSortVariantsByPrice(0);
            return;
        }

        setSortVariantsByPrice(sortOpt);
        let result = sortByPrice(sortOpt, props.filteredTravelVariants);
        console.log("filter component sort handler",result[0])
        console.log(result.length)
        props.setFilteredTravelVariants([]);
        console.log(result.length)
        props.setFilteredTravelVariants(result);
        return;
    }

    useEffect(() => {

    }, [showAviaVariants, showTrainBackwardVariants, sortVariantsByPrice]);



    const selectVariantStyle = showSelectVariant ? "order-acc-body " : "order-acc-body hidden";
    const filterStyle = showFilter ? "order-acc-body " : "order-acc-body hidden";

    return (
        <>
            <div className="">
                <div className="document-accordion-item active">
                    <div className="document-accordion-header"
                         onClick={() => setShowSelectVariant(!showSelectVariant)}>
                        <div>Варианты</div>
                        <div typeof="push">{showSelectVariant ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>
                    <div>
                        <div className={selectVariantStyle}
                        >
                            {hasAvia && <dd>
                                <CustomCheckBox title={"Авиа варианты"} isChecked={showAviaVariants}
                                                icon={undefined}
                                                onChange={() => {
                                                    //setShowAviaVariants(!showAviaVariants)
                                                    selectFilterHandler(TicketType.AviaTicket)
                                                }}
                                />
                            </dd>}

                            {hasTrain && <dd>
                                <CustomCheckBox title={"ЖД варианты"} isChecked={showTrainVariants}
                                                icon={undefined}
                                                onChange={() => selectFilterHandler(TicketType.TrainTicket)}/>
                            </dd>}

                            {hasAviaBackward && <dd>
                                <CustomCheckBox title={"Обратные авиа варианты"}
                                                isChecked={showAviaBackwardVariants}
                                                icon={undefined}
                                                onChange={() => selectFilterHandler(TicketType.AviaBackwardTicket)}/>
                            </dd>}

                            {hasTrainBackward && <dd><CustomCheckBox title={"Обратные Жд варианты"}
                                                                     isChecked={showTrainBackwardVariants}
                                                                     icon={undefined}
                                                                     onChange={() => selectFilterHandler(TicketType.TrainBackwardTicket)}/>
                            </dd>}
                        </div>
                    </div>
                </div>

                {/*Sort by params*/}
                <div className="document-accordion-item">
                    <div className="document-accordion-header"
                         onClick={() => setShowFilter(!showFilter)}>
                        <div> Сортировать по цене</div>
                        <div typeof="push"><SortIcon/> {showFilter ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>
                    <div>
                        <div className={filterStyle}>
                            <dd><CustomCheckBox
                                isChecked={sortVariantsByPrice === (SortByPrice.MinPrice | SortByPrice.SortByAsc)}
                                title={"Мин. цена возрастание"}
                                icon={<>&uarr;</>}
                                onChange={() => {
                                    sortByPriceHandler(SortByPrice.MinPrice | SortByPrice.SortByAsc)
                                }}


                                // onChange={function (checkBoxState: boolean): void {
                                //     console.log("Мин. цена возрастание",SortByPrice.MinPrice|SortByPrice.SortByAsc)
                                // }}
                            /></dd>
                            <dd><CustomCheckBox
                                isChecked={sortVariantsByPrice === (SortByPrice.MinPrice | SortByPrice.SortByDesc)}
                                title={"Мин. цена убывание"}
                                icon={<>&darr;</>}

                                onChange={() => {
                                    sortByPriceHandler(SortByPrice.MinPrice | SortByPrice.SortByDesc)
                                }}
                                // onChange={function (checkBoxState: boolean): void {
                                //     console.log("Мин. цена убывание",SortByPrice.MinPrice|SortByPrice.SortByDesc)
                                // }}
                            /></dd>
                            <dd><CustomCheckBox
                                isChecked={sortVariantsByPrice === (SortByPrice.MaxPrice | SortByPrice.SortByAsc)}
                                title={"Макс цена возрастание"}
                                icon={<>&uarr;</>}
                                onChange={() => {
                                    sortByPriceHandler(SortByPrice.MaxPrice | SortByPrice.SortByAsc)
                                }}
                                // onChange={function (checkBoxState: boolean): void {
                                //     console.log("Макс цена возрастание",SortByPrice.MaxPrice|SortByPrice.SortByAsc)
                                // }}
                            /></dd>
                            <dd><CustomCheckBox
                                isChecked={sortVariantsByPrice === (SortByPrice.MaxPrice | SortByPrice.SortByDesc)}
                                title={"Макс цена убывание"}
                                icon={<>&darr;</>}
                                onChange={() => {
                                    sortByPriceHandler(SortByPrice.MaxPrice | SortByPrice.SortByDesc)
                                }}
                                // onChange={function (checkBoxState: boolean): void {
                                //     console.log("Макс цена возрастание",SortByPrice.MaxPrice|SortByPrice.SortByDesc)
                                // }}
                            /></dd>
                        </div>
                    </div>
                </div>

                <div className="document-accordion-item">
                    <div className="document-accordion-header"
                         onClick={() => setShowFilter(!showFilter)}>
                        <div> Сортировать по дате</div>
                        <div typeof="push"><SortIcon/> {showFilter ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>
                    <div>
                        <div className={filterStyle}>
                            <dd><label>Цена от &uarr;</label></dd>
                            <dd><label>Цена от &darr;</label></dd>
                            <dd><CheckComponent type="radio" isChecked={true}/></dd>
                            <dd><label> Сначала авиа </label></dd>
                            <dd><label> Сначала Жд </label></dd>
                            <li><label><input type="radio" id="test" itemID={"search"}/> Искать обратные билеты </label>
                            </li>
                        </div>
                    </div>
                </div>

                <div className="document-accordion-item">
                    <div className="document-accordion-header">
                        Пересадки в пути
                    </div>
                    <div>
                        <div className="accordion-body">
                            <li><label><input type="checkbox"/> No </label></li>
                            <li><label><input type="checkbox"/> 1 </label></li>
                            <li><label><input type="checkbox"/> 2 </label></li>
                            <li><label><input type="checkbox"/> 3 </label></li>
                        </div>
                    </div>
                </div>

                <div className="document-accordion-item">
                    <div className="document-accordion-header">
                        Авиакомпании
                    </div>
                    <div>
                        <div className="accordion-body">
                            <li><label><input type="checkbox"/> Аэрофлот </label></li>
                            <li><label><input type="checkbox"/> Уральские авиалинии </label></li>
                        </div>
                    </div>
                </div>

                <div className="document-accordion-item">
                    <div className="document-accordion-header">
                        Изменить политики
                    </div>
                    <div>
                        <div className="accordion-body">
                            <li><label><input type="checkbox" checked={true}/> Только эконом </label></li>
                            <li><label><input type="checkbox" checked={true}/> Без пересадок </label></li>
                            <li><label><input type="checkbox" checked={false}/> Прилет в дневное время </label></li>
                            <li><label><input type="checkbox" checked={true}/> Только с багажом </label></li>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
