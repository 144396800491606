import {useState} from "react";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import {MdEmailArea} from "../../../../components/md-email-area";
import {MdSelect} from "../../../../components/md-select";
import {DateService} from "../../../../services/DateService";
import "./style.scss"


interface IRegisterSendProps {
    registerNames?: string[];
    sendHandler: (registerName: string, dateFrom: string, dateTo: string, recipients: string[]) => void;
}


export function RegisterSendComponent(props: IRegisterSendProps) {

    let { registerNames, sendHandler } = props;

    const {t} = useTranslation();

    const { dateToStringForDatePicker, stringToDateFromDatePicker } = DateService();

    const [registerName, setRegisterName] = useState<string>((registerNames != null ? registerNames[0] : ""));

    const [recipientAddresses, setRecipientAddresses] = useState<string[]>([
        "d.illarionov@mail.com",
    ]);

    const [dateFrom, setDateFrom] = useState<string>(dateToStringForDatePicker(new Date()));

    const [dateTo, setDateTo] = useState<string>(dateToStringForDatePicker(new Date()));


    const onSubmit = () => {
        sendHandler(registerName, dateFrom, dateTo, recipientAddresses);
    }


    return (
        <div className="send-documents-form">

            <div className="send-documents-form-sections">
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Реестр</span>
                    <div className="send-documents-form-section-content" style={{ zIndex: 100 }}>
                        <MdSelect
                            label=""
                            value={registerName}
                            setValue={setRegisterName}
                            items={(Array.isArray(registerNames) ? registerNames : [])} />
                    </div>
                </div>
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Период</span>
                    <div className="send-documents-form-section-content" style={{padding: "10px 0"}}>
                        <div style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                            <span style={{ display: "flex", alignItems: "start" }}>
                                <label className="sr-only align-self-lg-center ">&nbsp;{`${t("commonText.from")}`}:&nbsp;&nbsp;</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        //value="option1"
                                        value={dateFrom}
                                        onChange={(e) => {
                                            setDateFrom(e.target.value);
                                        }}
                                    />
                                </div>
                            </span>
                            <span style={{ display: "flex", alignItems: "start" }}>
                                <label className="sr-only align-self-lg-center ">&nbsp;{`${t("commonText.to")}`}:&nbsp;&nbsp;</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        //value="option2"
                                        value={dateTo}
                                        onChange={(e) => {
                                            setDateTo(e.target.value);
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Получатели</span>
                    <div className="send-documents-form-section-content">
                        <MdEmailArea
                            style={{
                                marginBottom: "10px",
                                border: "1px solid #f5f5f5",
                                borderRadius: "10px",
                                backgroundColor: "rgb(249, 249, 249)",
                                padding: "5px",
                                fontSize: "12px",
                                width: "700px"
                            }}
                            placeholder="example@domain.com"
                            valuesList={recipientAddresses}
                            setValuesList={setRecipientAddresses}
                        />
                    </div>
                </div>
            </div>

            <MdButton
                type="submit"
                style={{
                    "button": {
                        margin: "auto",
                        marginTop: "20px",
                        width: "200px",
                        borderWidth: 0,
                        backgroundColor: "#3E5CB8",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        height: "35px",
                        padding: "5px 15px"
                    },
                    ink: {
                        backgroundColor: "rgba(255,255,255,.5)"
                    }
                }}
                onClick={onSubmit}>
                {`${t("documentPage.send")}`}
            </MdButton>

        </div>
    );
}
