import { SearchByDateComponent } from "../../../commonComponents/searchByDateComponent";
import { ReportsApi } from "../../../../api/ReportsApi";
import { useState } from "react";
import { DateService } from "../../../../services/DateService";
import { CommonSimpleCardComponent } from "../../../commonComponents/commonCardComponents/commonSimpleCardComponent";
import { StatisticSimpleCardComponent } from "../../../commonComponents/commonCardComponents/StatisticSimpleCard";
import { MAIN_BACKGROUND_COLOR } from "../../../../constants/PageConstants";
import { useTranslation } from "react-i18next";

type StatementBalanceProps = {
  openBalance: number;
  closeBalance: number;
};

export function StatementBalanceComponent({ openBalance, closeBalance }: StatementBalanceProps) {
  const { t } = useTranslation();
  let useRUB = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "RUB",
  });

  return (
    <>
      <div className="d-flex"
           //style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}
      >
        <StatisticSimpleCardComponent
            type="blue"
          title={`${t("statisticPage.statisticBalance")}`}
          footer={""}
          data={[
            [`${t("statisticPage.openBalance")}`, useRUB.format(openBalance)],
            [`${t("statisticPage.closeBalance")}`, useRUB.format(closeBalance)],
          ]}
        />
      </div>
    </>
  );
}
