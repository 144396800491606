import {
    BEARER,
    ROUTE_EDM_UPLOADS,
    ROUTE_GET_CUSTOMER_AIR_COMPANIES,
    ROUTE_GET_CUSTOMER_BUH_DOCS,
    ROUTE_GET_CUSTOMER_FLIGHTS,
    ROUTE_GET_CUSTOMER_TICKETORDERS,
    ROUTE_GET_CUSTOMER_EMPLOYEES,
    ROUTE_ADD_CUSTOMER_EMPLOYEE,
    ROUTE_GET_CUSTOMER_ACC_DOCS_INTEROP,
    ROUTE_GET_CUSTOMER_EMPLOYEE_ROLES,
    ROUTE_PUT_CUSTOMER_EMPLOYEE,
    BASE_ROUTE_INTEROP_SERVICE,
} from "../constants/routeConstants/ApiRouteConstants";
import {axiosInstance} from "./AxiosInstance";
import {ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES} from "../constants/routeConstants/ApiRouteConstants";
import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {IFalconResponse} from "../interfaces/IFalconGenericResponce";
import {
    IAccountantDocument,
    ICustomerAdditionalService,
    ICustomerAirCompany,
    ICustomerEmployee,
    ICustomerFlight,
    ICustomerTicketOrder,
} from "../interfaces/reportInterfaces/customerInterfaces";
import {useCookies} from "react-cookie";
import {
    IGetCustomerBuhDocsRequest,
    ISendBuhDocsToEmailByEdmRequest,
    ICommonReportRequest,
    IGetCustomerEmployeesRequest, IAddCustomerEmployeesRequest, IGetCustomerEmployeeRolesRequest, ICompanyRole
} from '../interfaces/requestsInterfaces/reportRequests';
import axios from "axios";
import {AttachmentFormat, IDocAttachments, ISendDocsToEdm} from "../Requests/ISendDocsToEdm";

export function CustomerApi() {
    //const [cookies, setCookie] = useCookies([ACCESS_TOKEN]);

    // const bearerToken: string = 'Bearer ' + token;


    const getCustomerBuhDocs = async (
        request: IGetCustomerBuhDocsRequest
    ) => {
        const result = await axiosInstance.get<IAccountantDocument[]>(
            BASE_ROUTE_INTEROP_SERVICE + ROUTE_GET_CUSTOMER_ACC_DOCS_INTEROP,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd,
                    gkCode: request.gkCode,
                    name: request.name,
                    docNo: request.docNo,
                    route: request.route,
                    ticketNum: ""
                },
            }
        );

        if (result.status === 200) {
            return result;
        }

        return result;
    };

    const getUploadsBuhDocs = async (clientCode: string, token: string) => {
        const response = await axiosInstance.post(ROUTE_EDM_UPLOADS, {
            headers: {
                Authorization: BEARER + token,
            },
            params: {
                clientCode: clientCode,
            },
        });
        return response;
    }

    const sendBuhDocsToEdm = async (
            documents: IDocAttachments,
            emails: string[],
            format: AttachmentFormat,
            needEdmUpload: boolean,
            ConnectionId: string,
            token: string) => {

        const data: ISendDocsToEdm = {
            documents: documents,
            emails: emails,
            format: format,
            needEdmUpload: needEdmUpload,
            connectionId: ConnectionId,
        };
        const response = await axiosInstance.post<boolean>("/api/Edm/sendToEdm",
            data, {
            headers: {
            Authorization: BEARER + token,
            }
        });
        return response.data
    }

    // [" 1518146", " 1518098", " 1518337", " 1518873"]
    const getUploadsBuchDocs = async (buchDocsNums: string[], clientCode: string, token: string) => {
        try {
            const response = await axiosInstance.post("/api/Edm/getUploads",
                buchDocsNums,
                {
                    headers: {
                        Authorization: BEARER + token,
                    },
                    params: {
                        clientCode: clientCode
                    }
                }
            )
            return response.data;
        } catch (ex) {
        }
        return null;
    }

    // "0KQwMDE5MTI3_1518098_0JDQutGCINC+0LrQsNC30LDQvdC90YvRhSDRg9GB0LvRg9CzIOKEljE1MTgwOTgg0L7RgiAwNi4wNS4yMDI0LnBkZg=="

    // const downloadBuchDocsEdm = async (key: string, clientCode: string, token: string) => {
    //   const data: string = "0KQwMDE5MTI3_1518098_0JDQutGCINC+0LrQsNC30LDQvdC90YvRhSDRg9GB0LvRg9CzIOKEljE1MTgwOTgg0L7RgiAwNi4wNS4yMDI0LnBkZg==";
    //   //const data = "segsdhbfgbfgbnfgbfgbfgbf";
    //   const response = await axiosInstance.post("/api/Edm/download",
    //     key
    //     ,
    //     {
    //       headers: {
    //         Authorization: BEARER + token,
    //         "Accept": "application/json",
    //         "Content-type": "application/json",
    //       },
    //       params: {
    //         clientCode: clientCode
    //       },
    //       responseType: "blob"
    //     }
    //   )
    //   return response.data
    // }

    const downloadBuchDocsEdm = async (keyOrLink: string, asLink: boolean, clientCode: string, token: string) => {
        if (asLink) {
            const response = await axiosInstance.get(keyOrLink,
                {
                    headers: {
                        Authorization: BEARER + token,
                        "Accept": "application/json",
                        "Content-type": "application/json",
                    },
                    responseType: "blob"
                }
            )
            return response.data;
        } else {
            const response = await axiosInstance.post("/api/Edm/download",
                keyOrLink
                ,
                {
                    headers: {
                        Authorization: BEARER + token,
                        "Accept": "application/json",
                        "Content-type": "application/json",
                    },
                    params: {
                        clientCode: clientCode
                    },
                    responseType: "blob"
                }
            )
            return response.data;
        }
    }

    //? update or delete
    // const sendBuchDocsToEmailByEdm = async (request: ISendBuhDocsToEmailByEdmRequest) => {
    //   //const response = await axios.post(BASE_ROUTE_EDM_SERVICE + ROUTE_POST_BUCH_DOCS_TO_EMAIL_BY_EDM, request)
    //   try {
    //     const response = await axios.post(BASE_ROUTE_EDM_SERVICE + ROUTE_POST_BUCH_DOCS_TO_EMAIL_BY_EDM, {

    //       documents: [
    //         " 1516376",
    //         " 1516500"
    //       ],
    //       emails: [
    //         "m.a.andronovich@mmzavod.ru"
    //       ],
    //       isTest: true
    //     }, {
    //       headers: {
    //         "Postman-Token": 'a8ac040d- 1c21- 45c4 - babe - e693f57d59d0'
    //       }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

    const getCustomerAirCompanies = async (
        request: ICommonReportRequest
    ) => {

        const result = await axiosInstance.get<IFalconResponse<ICustomerAirCompany>>(
            ROUTE_GET_CUSTOMER_AIR_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + request.token
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
            }
        );

        return result.data;
    };

    const getCustomerFlights = async (
        request: ICommonReportRequest
    ) => {

        const result = await axiosInstance.get<IFalconResponse<ICustomerFlight>>(
            ROUTE_GET_CUSTOMER_FLIGHTS,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
            }
        );
        return result.data;
    };

    const getCustomerTicketOrders = async (
        request: ICommonReportRequest
    ) => {
        const result = await axiosInstance.get<IFalconResponse<ICustomerTicketOrder>>(
            ROUTE_GET_CUSTOMER_TICKETORDERS,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
            }
        );

        return result.data;
    };

    const getCustomerAdditionalServices = async (
        request: ICommonReportRequest
    ) => {
        const result = await axiosInstance.get<IFalconResponse<ICustomerAdditionalService>>(
            ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
            }
        );
        return result.data;
    };



    const getCustomerEmployees = async (request: IGetCustomerEmployeesRequest) => {
        const result = await axiosInstance.get<ICustomerEmployee[]>(
            ROUTE_GET_CUSTOMER_EMPLOYEES,
            {
                headers: {
                    //Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    code: request.companyCode,
                },
            }
        );
        return result;
    };


    const getEmployeeRoles = async (request: IGetCustomerEmployeeRolesRequest) => {
        const result = await axiosInstance.get<string[]>(
            ROUTE_GET_CUSTOMER_EMPLOYEE_ROLES,
            {
                headers: {
                    //Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    code: request.code,
                    email: request.email
                },
            }
        );
        return result;
    };


    const addCustomerEmployees = async (request: IAddCustomerEmployeesRequest) => {
        try {
            const result = await axiosInstance.put(
                ROUTE_ADD_CUSTOMER_EMPLOYEE,
                request,
                {
                    headers: {
                        //Authorization: BEARER + request.token,
                        Auth: 123
                    },
                }
            );
            return result;
        }
        catch (ex) {
            console.error(ex);
        }
        return null;
    };

    const updateCustomerEmployee = async (request: IAddCustomerEmployeesRequest) => {
        try {
            const result = await axiosInstance.put(
                ROUTE_PUT_CUSTOMER_EMPLOYEE,
                request,
                {
                    headers: {
                        //Authorization: BEARER + request.token,
                        Auth: 123
                    },
                }
            );
            return result;
        }
        catch (ex) {
            console.error(ex);
        }
        return null;
    };


    return {
        getCustomerAirCompanies,
        getCustomerBuhDocs,
        getUploadsBuhDocs,
        getCustomerFlights,
        getCustomerTicketOrders,
        getCustomerAdditionalServices,
        sendBuhDocsToEdm,
        getUploadsBuchDocs,
        downloadBuchDocsEdm,
        getCustomerEmployees,
        addCustomerEmployees,
        getEmployeeRoles,
        updateCustomerEmployee
    };
}
