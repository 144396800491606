import { createAsyncThunk, createSlice, isAction, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { CompanyApi } from "../api/CompanyApi";
import { GetClientDetailsResponse } from "../responces/GetClientDetailsResponse";
import { SearchClientByCodeResponce } from "../responces/companyInfoResponses/SearchClientByCodeResponce";
import { IFalconResponse } from '../interfaces/IFalconGenericResponce';
import { IAllowedPerson } from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import { IClientStructure } from "../interfaces/companyInfoInterfaces/IClientStructure";
import { ICLientSale } from '../interfaces/companyInfoInterfaces/IClientSale';
import { getUserInfo } from './authSlice';
import { getStatementSalesThunk } from './reportsSlice';
import { IClientDetailsRequest, IClientStructureRequest, ICompanyInfoRequest } from "../interfaces/requestsInterfaces/reportRequests";
import { ICompanyInfo } from "../interfaces/companyInfoInterfaces/ICompanyInfo";
import { IClientDetail } from '../interfaces/companyInfoInterfaces/IClientDetail';
import { PERIOD_LAST_MONTH } from '../constants/ServiceCostants';

interface CompanyInfoState {
  code: string;
  fullName: string;
  gkCode: string;
  gkName: string;
  inn: string;
  vatUse: boolean;
  agreementNo: string;
  agreementFrom: string;
  agreementTill: string;
  address: string;
  postalAddress: string;
  dogovor?: string;
  deptSum: number;
  deptType: string;
  allwedPersons: IAllowedPerson[]
  clientStructureSales: IClientStructure[]
  clientStructureExchange: IClientStructure[]
  clientStructureRefound: IClientStructure[],
  clientSales: ICLientSale[],
  startCompanyInfoDate: string;
  endCompanyInfoDate: string;
  companyStatisticPeriod: string;
}

// interface IClientDetailsRequest {
//   clientCode: string,
//   dateStart: string
// }

const initialState: CompanyInfoState = {
  code: "",
  fullName: "",
  gkCode: "",
  gkName: "",
  inn: "",
  vatUse: false,
  agreementNo: "",
  agreementFrom: "",
  agreementTill: "",
  address: "",
  postalAddress: "",
  dogovor: "",
  deptSum: 0,
  deptType: "",
  allwedPersons: [],
  clientStructureSales: [],
  clientStructureExchange: [],
  clientStructureRefound: [],
  clientSales: [],
  startCompanyInfoDate: "",
  endCompanyInfoDate: "",
  companyStatisticPeriod: PERIOD_LAST_MONTH
};

const { getClientDetails, searchClient, searchAllowedPersons, getClientStructure, getClientsSales } = CompanyApi();

export const getClientDetailsThunk = createAsyncThunk(
  "companyInfo/getCompanyInfo",
  async (request: IClientDetailsRequest, thunkAPI) => {
    const responce = await getClientDetails(request);
    if (responce.media.length === 0) {
      const newResponce: IClientDetail = {
        fullname: "NotData",
        gkName: "NoGkCompany",
        deptSum: 0,
        deptType: "No data"
      }
      return newResponce
    }
    return responce.media[0];
  }
);

export const searchClientsThunk = createAsyncThunk(
  "companyInfo/getClientDetail",
  async (request: ICompanyInfoRequest, thunkAPI) => {
    const response = await searchClient(request);
    return response;
  }
);

export const searchAllowedPepsonsThunk = createAsyncThunk("companyInfo/searchAllowedPepsons",
  async (request: ICompanyInfoRequest, thunkAPI) => {
    const response = await searchAllowedPersons(request)
    return response;
  }
);

//need to update responce to remove copy/paste
export const getClientStructureSalesThunk = createAsyncThunk("companyInfo/getClientStructureSales",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)

    return response
  }
);

export const getClientStructureExchangeThunk = createAsyncThunk("companyInfo/getClientStructureExchange",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)
    return response;
  }
);

export const getClientStructureRefoundThunk = createAsyncThunk("companyInfo/getClientStructureRefound",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)
    return response;
  }
);
export const getClientsSalesThunk = createAsyncThunk("companyInfo/getClientsSales", async (request: ICompanyInfoRequest, thunkApi) => {
  const response = await getClientsSales(request);
  return response;
})

export const companyInfoSlice = createSlice({
  name: "companyInfo",
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setGkKode: (state, action: PayloadAction<string>) => {
      state.gkCode = action.payload;
    },
    setGkName: (state, action: PayloadAction<string>) => {
      state.gkName = action.payload;
    },
    setInn: (state, action: PayloadAction<string>) => {
      state.inn = action.payload;
    },
    setVatUse: (state, action: PayloadAction<boolean>) => {
      state.vatUse = action.payload;
    },
    setAgreementNo: (state, action: PayloadAction<string>) => {
      state.agreementNo = action.payload;
    },
    setAgreementFrom: (state, action: PayloadAction<string>) => {
      state.agreementFrom = action.payload;
    },
    setAgreementTill: (state, action: PayloadAction<string>) => {
      state.agreementTill = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setPostalAddress: (state, action: PayloadAction<string>) => {
      state.postalAddress = action.payload;
    },
    setDogovor: (state, action: PayloadAction<string>) => {
      state.dogovor = action.payload;
    },
    setAllowedPerson: (state, action: PayloadAction<IAllowedPerson[]>) => {
      state.allwedPersons = action.payload
    },
    setClientStructureSales: (state, action: PayloadAction<IClientStructure[]>) => {
      state.clientStructureSales = action.payload
    },
    setClientStructureExchange: (state, action: PayloadAction<IClientStructure[]>) => {
      state.clientStructureExchange = action.payload
    },
    setClientStructureRefound: (state, action: PayloadAction<IClientStructure[]>) => {
      state.clientStructureRefound = action.payload
    },
    setClientSales: (state, action: PayloadAction<ICLientSale[]>) => {
      state.clientSales = action.payload
    },
    setStartCompanyInfoDate: (state, action: PayloadAction<string>) => {
      state.startCompanyInfoDate = action.payload
    },
    setEndCompanyInfoDate: (state, action: PayloadAction<string>) => {
      state.endCompanyInfoDate = action.payload
    },
    setCompanyStatisticPeriod: (state, action: PayloadAction<string>) => {
      state.companyStatisticPeriod = action.payload
    }

  },
  extraReducers: (builder) => {
    builder
      // Get client details
      .addCase(
        getClientDetailsThunk.fulfilled,
        (state, action: PayloadAction<IClientDetail>) => {
          state.deptSum = action.payload.deptSum;
          state.deptType = action.payload.deptType;
        }
      )
      .addCase(getClientDetailsThunk.pending, (state, action) => { })
      .addCase(
        searchClientsThunk.fulfilled,
        (state, action: PayloadAction<IFalconResponse<ICompanyInfo>>) => {
          state.code = action.payload.media[0] !== undefined ? action.payload.media[0].code : "";
          state.fullName = action.payload.media[0] !== undefined ? action.payload.media[0].fullName : "";
          state.gkCode = action.payload.media[0] !== undefined ? action.payload.media[0].gkCode : "";
          state.gkName = action.payload.media[0] !== undefined ? action.payload.media[0].gkName : "";
          state.inn = action.payload.media[0] !== undefined ? action.payload.media[0].inn : "";
          state.agreementNo = action.payload.media[0] !== undefined ? action.payload.media[0].agreementNo : "";
          state.agreementFrom = action.payload.media[0] !== undefined ? action.payload.media[0].agreementFrom : "";
          state.agreementTill = action.payload.media[0] !== undefined ? action.payload.media[0].agreementTill : "";
          state.address = action.payload.media[0] !== undefined ? action.payload.media[0].address : "";
          state.postalAddress = action.payload.media[0] !== undefined ? action.payload.media[0].postalAddress : "";
          state.dogovor = action.payload.media[0] !== undefined ? action.payload.media[0].dogovor : "";
        }
      )
      .addCase(
        searchAllowedPepsonsThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IAllowedPerson>>) => {
          state.allwedPersons = action.payload.media
        })
      .addCase(
        getClientStructureSalesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
          state.clientStructureSales = action.payload.media
        })
      .addCase(getClientStructureExchangeThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
        state.clientStructureExchange = action.payload.media
      })
      .addCase(getClientStructureRefoundThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
        state.clientStructureRefound = action.payload.media
      })
      .addCase(getClientsSalesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<ICLientSale>>) => {
        state.clientSales = action.payload.media
      })
      .addCase(
        getClientStructureSalesThunk.pending, (state, action) => { }
      )
  },
});

export const {
  setCode,
  setFullName,
  setGkKode,
  setGkName,
  setInn,
  setVatUse,
  setAgreementNo,
  setAgreementFrom,
  setAgreementTill,
  setAddress,
  setPostalAddress,
  setDogovor,
  setClientStructureSales,
  setClientStructureExchange,
  setClientStructureRefound,
  setStartCompanyInfoDate,
  setEndCompanyInfoDate,
  setCompanyStatisticPeriod
} = companyInfoSlice.actions;

export const companyInfoState = (state: RootState): CompanyInfoState => {
  return {
    code: state.companyInfo.code,
    fullName: state.companyInfo.fullName,
    gkCode: state.companyInfo.gkCode,
    gkName: state.companyInfo.gkName,
    inn: state.companyInfo.inn,
    vatUse: state.companyInfo.vatUse,
    agreementNo: state.companyInfo.agreementNo,
    agreementFrom: state.companyInfo.agreementFrom,
    agreementTill: state.companyInfo.agreementTill,
    address: state.companyInfo.address,
    postalAddress: state.companyInfo.postalAddress,
    dogovor: state.companyInfo.dogovor,
    deptSum: state.companyInfo.deptSum,
    deptType: state.companyInfo.deptType,
    allwedPersons: state.companyInfo.allwedPersons,
    clientStructureSales: state.companyInfo.clientStructureSales,
    clientStructureExchange: state.companyInfo.clientStructureExchange,
    clientStructureRefound: state.companyInfo.clientStructureRefound,
    clientSales: state.companyInfo.clientSales,
    startCompanyInfoDate: state.companyInfo.startCompanyInfoDate,
    endCompanyInfoDate: state.companyInfo.endCompanyInfoDate,
    companyStatisticPeriod: state.companyInfo.companyStatisticPeriod
  };
};

export default companyInfoSlice.reducer;
