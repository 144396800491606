import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {resetDocumentFilters, setDocumentFilters, setStartReportDate} from "../../../../redux/reportsSlice";
import {IDocumentFilters} from "../../../../interfaces/reportInterfaces/customerInterfaces";
import {DateService} from "../../../../services/DateService";
import {MdButton} from "../../../../components/md-button";
import "./style.scss";
import {PaperPlaneIcon} from "../../../../icons/PaperPlaneIcon";
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompany";


export interface DocumentSearchProps {
    company?: ICompany,
    setCompany: React.Dispatch<React.SetStateAction<ICompany | undefined>>;
    openCompanySelectionModal: () => void;
    searchHandler: (startDate: string, endDate: string, docNumber: string, direction: string, name: string, clientCode?: string) => void;
}

export function DocumentSearchComponent({ company, setCompany, openCompanySelectionModal, searchHandler }: DocumentSearchProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const reportState = useAppSelector((state) => state.reports);
    const {dateToStringForDatePicker} = DateService();

    const companyState = useAppSelector((state) => state.companyInfo);

    const [startDate, setStartDate] = useState(reportState.documentFilters?.startDate ?? dateToStringForDatePicker(new Date()));
    const [endDate, setEndDate] = useState(reportState.documentFilters?.endDate ?? dateToStringForDatePicker(new Date()));
    const [docNumber, setDocNumber] = useState(reportState.documentFilters?.docNumber ?? "");
    const [direction, setDirection] = useState(reportState.documentFilters?.direction ?? "");
    const [name, setName] = useState(reportState.documentFilters?.clientName ?? "");

    const [filters, setFilters] = useState(reportState.documentFilters);


    const resetFilters = () => {
        setStartDate(dateToStringForDatePicker(new Date()));
        setEndDate(dateToStringForDatePicker(new Date()));
        setDocNumber("");
        setDirection("");
        setName("");
        setCompany(undefined);

        dispatch(resetDocumentFilters());
        setFilters(reportState.documentFilters);
    }


    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        searchHandler(startDate, endDate, docNumber, direction, name, (company?.code ?? company?.clientCode));
    };


    useEffect(() => {
        setFilters(prev => ({...prev, company: company}));
        dispatch(setDocumentFilters({...filters, company: company}));
    }, [company]);


    return (
        <>
            <div className="filters-container">
                {/*<div className="filters-caption">Фильтры</div>*/}
                {/*<div className="filters-description">Используйте фильтры для поиска нужного документа</div>*/}
                <form
                    className="filters-form"
                    onSubmit={onSubmit}
                >
                    <div className="filters-form-fields-container">

                        <div className="filters-form-fields-group">
                            <span className="filters-form-input-field">
                                <label
                                    style={{width: "118px"}}>&nbsp;{`${t("documentPage.period")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        setFilters(prev => ({...prev, startDate: e.target.value}));
                                        dispatch(setDocumentFilters({...filters, startDate: e.target.value}));
                                    }}
                                    style={{width: "143px"}}
                                />
                            </span>
                            <span className="filters-form-input-field">
                                <label>&nbsp;-&nbsp;&nbsp;</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                        setFilters(prev => ({...prev, endDate: e.target.value}));
                                        dispatch(setDocumentFilters({...filters, endDate: e.target.value}));
                                    }}
                                    style={{width: "143px"}}
                                />
                            </span>
                            <span className="filters-form-input-field">
                                <label
                                    style={{width: "92px"}}>&nbsp;{`${t("documentPage.document")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("documentPage.number")}`}
                                    value={docNumber}
                                    onChange={(e) => {
                                        setDocNumber(e.target.value);
                                        setFilters(prev => ({...prev, docNumber: e.target.value}));
                                        dispatch(setDocumentFilters({...filters, docNumber: e.target.value}));
                                    }}
                                    style={{width: "306px"}}
                                />
                            </span>
                        </div>

                        <div className="filters-form-fields-group">
                            <span className="filters-form-input-field">
                                <label
                                    style={{width: "118px"}}>&nbsp;{`${t("documentPage.direction")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("documentPage.city")}`}
                                    value={direction}
                                    onChange={(e) => {
                                        setDirection(e.target.value);
                                        setFilters(prev => ({...prev, direction: e.target.value}));
                                        dispatch(setDocumentFilters({...filters, direction: e.target.value}));
                                    }}
                                    style={{width: "306px"}}
                                />
                            </span>
                            <span className="filters-form-input-field">
                                <label
                                    style={{width: "92px"}}>&nbsp;{`${t("documentPage.fullName")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("documentPage.recipient")}`}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setFilters(prev => ({...prev, clientName: e.target.value}));
                                        dispatch(setDocumentFilters({...filters, clientName: e.target.value}));
                                    }}
                                    style={{width: "306px"}}
                                />
                            </span>
                        </div>

                        <div className="filters-form-fields-group">
                            <span className="filters-form-input-field">
                                <label
                                    style={{width: "118px"}}>&nbsp;{`${t("documentPage.company")}`}:&nbsp;&nbsp;</label>
                                <MdButton
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        },
                                        "text": {
                                            maxWidth: "270px",
                                            overflow: "hidden"
                                        }
                                    }}
                                    onClick={() => openCompanySelectionModal()}
                                >
                                    {
                                        company != null ? company.fullName : companyState.fullName
                                    }
                                </MdButton>
                            </span>
                        </div>

                    </div>


                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <MdButton
                            type="button"
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={onSubmit}
                        >
                            {`${t("documentPage.search")}`}
                        </MdButton>

                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                },
                                "text": {
                                    maxWidth: "270px",
                                }
                            }}
                            onClick={() => resetFilters()}
                        >
                            Сброс
                        </MdButton>
                    </div>

                </form>
            </div>
        </>
    );
}
