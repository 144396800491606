import { useState } from "react";
import { IAllowedPerson } from "../../../../interfaces/companyInfoInterfaces/IAllowedPerson";
import { useTranslation } from "react-i18next";
import { MAIN_BACKGROUND_COLOR } from "../../../../constants/PageConstants";

export interface AuthorizedUsersInfoProps {
  //userId: number;
  // userName: string;
  autorizedUsers: IAllowedPerson[];
}

export function AuthorizedUsersInfoComponent({ autorizedUsers }: AuthorizedUsersInfoProps) {
  const { t } = useTranslation();
  const [selectedItem, setselectedItem] = useState<IAllowedPerson>();

  const handleChange = (value: string) => {
    setselectedItem(autorizedUsers.find((user) => user.email === value));
  };
  const bootstrapStyle = "col-12 col-sm-6 col-md- col-lg-5 m-3";
  return (
    <>
      <div className="filters-container">
        <div className="document-filters-form">
        <div className={bootstrapStyle}>
          <h5>{`${t("accInfoPage.authorizedUsers")}`}:</h5>
          <select
            className="form-select"
            value={selectedItem?.email}
            onChange={(e) => handleChange(e.target.value)}
            aria-label="Default select example"
          >
            {autorizedUsers &&
              autorizedUsers.map((u, index) => (
                <option
                  key={AuthorizedUsersInfoComponent.name + "_" + index.toString()}
                  id={index.toString()}
                  value={u.email}
                >
                  {u.email}
                </option>
              ))}
            {/* <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option> */}
          </select>
        </div>
        <div className={bootstrapStyle}>
          <div>
            {" "}
            <h5>{`${t("accInfoPage.comments")}`}:</h5>
          </div>
          <div className="overflow-y-auto" style={{ height: 120 }}>
            <ul className=" list-unstyled">
              <li>{selectedItem && selectedItem.code}</li>
              <li>{selectedItem && selectedItem.email}</li>
            </ul>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
