import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import "./style.scss";
import {WarningRhombusIcon} from "../../../../icons/WarningRhombusIcon";
import {TimeUtils} from "../../../../utils/timeUtils";


export interface TicketComponentProps {
    trip: IAviaVariant,
    selectTrip:  (ticket: IAviaVariant, tariff: IAviaTariffInfo) => void;
}

export function TicketComponent({ trip, selectTrip }: TicketComponentProps) {
    const {t} = useTranslation();


    const [selectedTariffIndex, setSelectedTariffIndex] = useState<number>(0);

    const [selectedTariff, setSelectedTariff] = useState<IAviaTariffInfo>(trip.rejsInfo.map(x => x.tarifs).flat()[selectedTariffIndex]);


    const tickets = trip.rejsInfo;

    const minPrice = Math.min(...tickets.map(r => r.tarifs.map(t => t.price)).flat());

    const routeStart = tickets[0];
    const routeStartDate = new Date(routeStart.dateArr);

    const routeEnd = tickets[tickets.length - 1];
    const routeEndDate = new Date(routeEnd.dateDep);


    const changeVariants: { [selector: string]: string } = {
        "NoRefunds": "запрещен",
        "AdditionalPayment": "за плату",
    }

    const refundVariants: { [selector: string]: string } = {
        "NoRefunds": "запрещен",
        "AdditionalPayment": "за плату",
    }


    return (
        <details className="trip-component">
            <summary className="trip-component-header" style={{width: "100%"}}>
                <div className="trip-component-header-first-line">
                    <div className="air-company"><span>{routeStart.airCompanyName}</span></div>
                    <div className="price">
                        {

                        }
                        <span>{"от " + minPrice + "₽"}</span>
                    </div>
                </div>
                <div className="trip-component-header-second-line">
                    <div className="arrival-info">
                        <div className="arrival-airport-code">{routeStart.airCodeDep}</div>
                        <div
                            className="arrival-airport-city">{routeStart.cityDep.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                        <div className="arrival-time">
                            {
                                routeStartDate.getHours().toString().padStart(2, "0") + ":" + routeStartDate.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + routeStartDate.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + routeStartDate.getDay().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                    <div className="flight-path-info">
                        <div className="flight-track">
                            <hr style={{marginTop: "16px "}}/>
                            <div className="flight-track-airplane">
                                <AirplaneIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#3E5CB8"
                                        }
                                    }}
                                />
                            </div>
                            <hr style={{marginTop: "16px "}}/>
                        </div>
                        {
                            trip.rejsInfo.length > 1
                                ? (
                                    <div className="flight-connections">
                                        {trip.rejsInfo.length - 1} пересадка
                                    </div>
                                )
                                : (
                                    <div className="flight-time">
                                        {routeStart.flightNumber}
                                    </div>
                                )
                        }
                    </div>
                    <div className="department-info">
                        <div className="department-airport-code">{routeEnd.airCodeArr}</div>
                        <div
                            className="department-airport-city">{routeEnd.cityArr.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                        <div
                            className="department-time">
                            {
                                routeEndDate.getHours().toString().padStart(2, "0") + ":" + routeEndDate.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + routeEndDate.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + routeEndDate.getDay().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                </div>
            </summary>

            <div className="trip-component-details" style={{backgroundColor: "#fdfdfd"}}>

                <div className="trip-tariff-switcher">
                    {
                        trip.rejsInfo[trip.rejsInfo.length - 1].tarifs.map((tariff, tariffIndex) => {
                            return (
                                <MdToggleButton
                                    buttonStyle={{
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "1px 10px 0 10px",
                                        borderRadius: "4px",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "11px",
                                        color: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                        border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={selectedTariffIndex == tariffIndex}
                                    onClick={() => {
                                        setSelectedTariffIndex(tariffIndex);
                                        setSelectedTariff(tariff);
                                    }}
                                >
                                    {tariff.name != null && tariff.name.length > 0 ? tariff.name : ("Тариф " + (tariffIndex + 1))}
                                </MdToggleButton>
                            )
                        })
                    }
                </div>

                <div className="trip-tariff-info">
                    <div className="trip-tariff-info-item">
                        <span>Обмен</span>
                        <span></span>
                        <span>
                                {
                                    (changeVariants[selectedTariff.obmen ?? ""] ?? "неопределён")
                                }
                            </span>
                    </div>
                    <div className="trip-tariff-info-item">
                        <span>Возврат</span>
                        <span></span>
                        <span>
                                {
                                    (refundVariants[selectedTariff.vozvrat ?? ""] ?? "неопределён")
                                }
                            </span>
                    </div>
                    <div className="trip-tariff-info-item">
                        <span>Багаж</span>
                        <span></span>
                        <span>
                                {
                                    selectedTariff.bagajInfo?.length > 0 ? selectedTariff.bagajInfo : "запрещен"
                                }
                            </span>
                    </div>
                </div>

                <div className="trip-info-section">
                    <div className="trip-roadmap" style={{padding: 0}}>
                        {
                            trip.rejsInfo.map((ticket, ticketIndex) => {
                                const dateDepartment = new Date(ticket.dateDep);
                                const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "");

                                const dateArrival = new Date(ticket.dateArr);
                                const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "");

                                let tripValidation = null;

                                let messages = [];


                                if (ticketIndex > 0) {
                                    const prevDirection = tickets[ticketIndex - 1];
                                    const prevCity = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
                                    const prevDateArrival = new Date(ticket.dateArr);

                                    let waitTime = TimeUtils.getTimeDetails(prevDateArrival.getTime() - dateDepartment.getTime());

                                    messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + Math.floor(waitTime.minutes / 60) + "ч "  + (waitTime.minutes % 60) + "м");

                                    //if (prevCity != townDepartment) {

                                        tripValidation = (
                                            <div className="trip-roadmap-item" key={ticketIndex}>
                                                <div className="trip-roadmap-validation">
                                                    <div className="trip-roadmap-validation-node">
                                                        <div
                                                            className="trip-roadmap-validation-node-button">
                                                            <WarningRhombusIcon
                                                                width="18px"
                                                                height="18px"
                                                                style={{
                                                                    "path": {
                                                                        fill: "#F66000" //"#838383"//"#F66000"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="trip-roadmap-validation-message">
                                                        <div
                                                            className="trip-roadmap-validation-message-title">
                                                            Предупреждение
                                                        </div>
                                                        <ul
                                                            className="trip-roadmap-validation-message-content">
                                                            {
                                                                messages.map(message => {
                                                                    return (
                                                                        <li>{message}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    //}
                                }

                                return (
                                    <>
                                        {
                                            tripValidation != null && (
                                                tripValidation
                                            )
                                        }

                                        <div className="trip-roadmap-item" key={ticketIndex}>
                                            <div className="trip-roadmap-item-start-node">
                                                <button className={"trip-roadmap-item-start-node-button" + ( ticketIndex == 0 ? " --trip-begin" : "")}>

                                                </button>
                                                <div className="trip-roadmap-item-start-node-location">
                                                    {townDepartment} ({ticket.airCodeDep})
                                                </div>
                                            </div>
                                            <div className="trip-roadmap-item-content">

                                                <div className="trip-roadmap-item-ticket">

                                                    <div className="trip-roadmap-item-ticket-header">
                                                        <div className="depart-info">
                                                            <div className="city-code">
                                                                {ticket.airCodeDep}
                                                            </div>
                                                        </div>
                                                        <div className="flight-info">
                                                            <div className="air-company-name">
                                                                {ticket.airCompanyName}
                                                            </div>
                                                        </div>
                                                        <div className="arrive-info">
                                                            <div className="city-code">
                                                                {ticket.airCodeArr}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="trip-roadmap-item-ticket-content">
                                                        <div className="depart-info">
                                                            <div className="city-name">
                                                                {townDepartment}
                                                            </div>
                                                            <div className="datetime">
                                                                {
                                                                    <>
                                                                        <span>{dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0") + ", "}</span>
                                                                        <span>
                                                                                     {dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                            {" "}
                                                                            {dateDepartment.getDay().toString().padStart(2, "0")}
                                                                                 </span>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="flight-info">
                                                            <div className="icon">
                                                                <span style={{opacity: 0.25}}></span>
                                                                <span style={{opacity: 0.5}}></span>
                                                                <span></span>
                                                                <div style={{margin: "0 2px"}}>
                                                                    <AirplaneIcon
                                                                        width="16px"
                                                                        height="16px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#475AEF"
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <span></span>
                                                                <span style={{opacity: 0.5}}></span>
                                                                <span style={{opacity: 0.25}}></span>
                                                            </div>
                                                            <div className="number">
                                                                {ticket.flightNumber}
                                                            </div>
                                                        </div>
                                                        <div className="arrive-info">
                                                            <div className="city-name">
                                                                {townArrival}
                                                            </div>
                                                            <div className="datetime">
                                                                {
                                                                    <>
                                                                        <span>{dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0") + ", "}</span>
                                                                        <span>
                                                                                     {dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                            {" "}
                                                                            {dateArrival.getDay().toString().padStart(2, "0")}
                                                                                 </span>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="trip-roadmap-item-end-node">
                                                <button className={"trip-roadmap-item-end-node-button" + ( ticketIndex == tickets.length - 1 ? " --trip-end" : "")}>

                                                </button>
                                                <div className="trip-roadmap-item-end-node-location">
                                                    {townArrival} ({ticket.airCodeArr})
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                )
                            })
                        }
                    </div>
                </div>

                <div className="trip-info-section" style={{justifyContent: "center"}}>
                    {
                        <MdButton
                            type="button"
                            style={{
                                "button": {
                                    marginTop: "5px",
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px 15px"
                                },
                                "text": {
                                    width: "auto",
                                    flex: 0,
                                    color: "#FFFFFF"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={() => selectTrip(trip, selectedTariff)}
                        >
                            Выбрать
                        </MdButton>
                    }
                </div>
            </div>
        </details>
    );
}
