// common auth
import {CURRENT_REACT_ENVIROMENT, ENVIROMENT_PRODUCTION} from "../ServiceCostants";

export const BEARER = "Bearer ";
//Base routes

export const ROUTE_LOG_IN_TO_LOCAL_API = process.env.REACT_APP_AUTH_BASE_ROUTE;
export const BASE_ROUTE_AUTH_SERVICE = process.env.REACT_APP_AUTH_BASE_ROUTE;
export const BASE_ROUTE_EDM_SERVICE = process.env.REACT_APP_EDM_BASE_ROUTE;
export const BASE_ROUTE_INTEROP_SERVICE = process.env.REACT_APP_INTEROP_BASE_ROUTE;

// Auth Routes
export const ROUTE_LOGIN = "/auth/login";
export const ROUTE_SIGNUP_COMPANY_WITH_ADMIN = "/auth/signup";
export const ROUTE_CHANGE_PASS = "/auth/changePassword";
export const ROUTE_RECOVERY_PASS = "/auth/recovery";

//https://srv-dev.turne.local/auth/auth/signup

// Routes for orders
export const ROUTE_FIND_AVIA_VARIANTS = "/api/Order/searchFlights";
export const ROUTE_FIND_TRAIN_VARIANTS = "/api/Order/searchTrains";

export const BASE_URL = process.env.REACT_APP_BASE_ROUTE;
//export const BASE_URL = "https://lk.turne-trans.ru";

//* Routes for ttsAdmin
export const ROUTE_GET_CLIENT_INFO_BY_NAME = "/auth/TtsAdmin/searchClientByNameLike";

//* Routes for getting info for cabinet account
//searchClientByClientCode
export const ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE = "/api/company/searchClient";
export const ROUTE_GET_INFO_ABOUT_USER = "/getUserInfo";
export const ROUTE_GET_ALLOWED_PERSONS = "/api/company/searchAllowedPersons";
export const ROUTE_GET_CLIENTS_DETAILS = "api/company/clientDetails";
export const ROUTE_SEARCH_PAX_INFO = "/searchPaxInfo";
export const ROUTE_SEARCH_CLIENT_FEES = "/searchClientFees";
export const ROUTE_GET_CLIENTS_STRUCTURE = "api/company/getClientsStructure";
export const ROUTE_GETCLIENTS_Sales = "api/company/getClientsSales";
export const ROUTE_GET_USER_ACTIVE_STATE = "/auth/isActiveUser";
export const ROUTE_GET_COMPANY_BY_CODE = "/auth/TtsSync/getCompany";
export const ROUTE_ADD_COMPANY = "/auth/TtsSync/addOrUpdateCompany"
export const ROUTE_IMPORT_COMPANY = "/auth/TtsSync/importCompany"
export const ROUTE_GET_ALL_COMPANIES = "/auth/TtsSync/getAllCompanies"
export const ROUTE_SEARCH_COMPANY_BY_NAME = "/api/v1/" + (CURRENT_REACT_ENVIROMENT == ENVIROMENT_PRODUCTION ? "interop/" : "") + "companies/searchcompany"
export const ROUTE_GET_USER_ROLES = "/auth/TtsSync/getUserRoles";
//* Edm routed
export const ROUTE_EDM_UPLOADS = "/api/Edm/getUploads";
export const ROUTE_EDM_GENERATE_REPORTS = "/api/Edm/sendToEdm";

export const ROUTE_REPORTS_HUB = "/api/v1/Edm/edmHub";

//*  Reports
//* Reports/CustomerInfo Routes
//Routes getting info about customers
export const ROUTE_GET_CUSTOMER_BUH_DOCS = "/api/Reports/getCustomerBuhDocs";
export const ROUTE_GET_CUSTOMER_ACC_DOCS_INTEROP= "/api/v1/" + (CURRENT_REACT_ENVIROMENT == ENVIROMENT_PRODUCTION ? "interop/" : "") + "docs/getAccountantDocuments"
export const ROUTE_GET_CUSTOMER_AIR_COMPANIES = "/api/Reports/getCustomerAirCompanies";
export const ROUTE_GET_CUSTOMER_FLIGHTS = "/api/Reports/getCustomerFlights";
export const ROUTE_GET_CUSTOMER_TICKETORDERS = "/api/Reports/getCustomerTicketOrders";
export const ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES = "/api/Reports/getCustomerAdditionalServices";

//* Reports/send
export const ROUTE_POST_BUCH_DOCS_TO_EMAIL_BY_EDM = "/api/v1/edm/docs";

//* Reports/StatementInfo Routes
export const ROUTE_GET_STATEMENT_SALES = "/api/Reports/getStatementSales";
//! not work need to update
export const ROUTE_GET_CLIENT_STRUCTURE_REPORTS = "/api/Reports/getClientsStructure";
export const ROUTE_GET_STATEMENT_INCOMES = "/api/Reports/getStatementIncomes";
export const ROUTE_GET_STATEMENT_OPEN_BALANCE = "/api/Reports/getStatementOpenBalance";
export const ROUTE_GET_STATEMENT_CLOSE_BALANCE = "/api/Reports/getStatementCloseBalance";

//*  Registers
//* Registers/CustomerInfo Routes
//Routes getting info about registers
export const ROUTE_GET_RECONCILIATION = "/api/v1/edm/reestrs/reconciliation";
export const ROUTE_GET_REGISTERS = "/api/v1/edm/reestrs";
export const ROUTE_POST_REGISTERS_TO_EMAIL_BY_EDM = "/api/v1/edm/reestrs";

export const ROUTE_GET_FLIGHTS = "/api/v1/flights/search";
export const ROUTE_GET_PAXES = "/api/v1/flights/getpaxes";
export const ROUTE_POST_FLIGHTS_BOOK = "/api/v1/flights/book";

export const ROUTE_GET_CUSTOMER_EMPLOYEES = "/auth/TtsSync/getUsers";
export const ROUTE_GET_CUSTOMER_EMPLOYEE_ROLES = "/auth/TtsSync/getUserRolesInCompany";
export const ROUTE_ADD_CUSTOMER_EMPLOYEE = "/auth/TtsSync/signup";
export const ROUTE_PUT_CUSTOMER_EMPLOYEE = "/auth/TtsSync/updateUser";

