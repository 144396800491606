import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {IAviaRejsInfo} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "../../../../services/DateService";
import {MdButton} from "../../../../components/md-button";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {LocationFilledIcon} from "../../../../icons/LocationFilledIcon";
import {Guid} from "../../../../utils/utils";
import "./style.scss";


export interface TicketComponentProps {
    ticket: IAviaRejsInfo,
    requestBooking:  (id: string, ticket: IAviaRejsInfo) => void;
}

export function TicketComponent({ ticket, requestBooking }: TicketComponentProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const reportState = useAppSelector((state) => state.reports);
    const {dateToStringForDatePicker} = DateService();

    const [id] = useState(Guid.newGuid());
    const [dateArrival] = useState(new Date(ticket.dateArr));
    const [dateDepartment] = useState(new Date(ticket.dateDep));


    const onRequestBooking = () => {
        requestBooking(id, ticket);
    };


    return (
        <>
            <details className="ticket-item">
                <summary className="ticket-summary" style={{ width: "100%" }}>
                    <div className="ticket-summary-header">
                        <div className="air-company"><span>{ticket.airCompanyName}</span></div>
                        <div className="price">
                            <span>{"от " + (ticket.tarifs.length > 0 ? Math.min(...ticket.tarifs.map(x => x.price)) : 0) + "₽"}</span>
                        </div>
                    </div>
                    <div className="ticket-summary-content">
                        <div className="arrival-info">
                            <div className="arrival-airport-code">{ticket.airCodeDep}</div>
                            <div className="arrival-airport-city">{ticket.cityDep.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                            <div className="arrival-time">
                                {
                                    dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")
                                    // + ", "
                                    // + dateDepartment.toLocaleString('default', { month: 'short' }).replace(".", "")
                                    // + " "
                                    // + dateDepartment.getDay().toString().padStart(2, "0")
                                }
                            </div>
                        </div>
                        <div className="flight-path-info">
                        <div className="flight-track">
                                <hr style={{ marginTop: "16px "}}/>
                                <div className="flight-track-airplane">
                                    <AirplaneIcon
                                        width="16px"
                                        height="16px"
                                        style={{
                                            "path": {
                                                fill: "#3E5CB8"
                                            }
                                        }}
                                    />
                                </div>
                                <hr style={{ marginTop: "16px "}}/>
                            </div>
                            {/*<div className="flight-time">*/}
                            {/*    {ticket.flightNumber}*/}
                            {/*</div>*/}

                            <MdButton
                                type="button"
                                style={{
                                    "button": {
                                        marginTop: "5px",
                                        borderWidth: 0,
                                        backgroundColor: "#3E5CB8",
                                        color: "#FFFFFF",
                                        borderRadius: "6px",
                                        height: "30px",
                                        padding: "5px 15px"
                                    },
                                    "text": {
                                        width: "auto",
                                        flex: 0,
                                        color: "#FFFFFF"
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(255,255,255,.5)"
                                    }
                                }}
                                onClick={onRequestBooking}
                            >
                                Забронировать
                            </MdButton>

                        </div>
                        <div className="department-info">
                            <div className="department-airport-code">{ticket.airCodeArr}</div>
                            <div className="department-airport-city">{ticket.cityArr.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                            <div
                                className="department-time">
                                {
                                    dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")
                                    // + ", "
                                    // + dateArrival.toLocaleString('default', { month: 'short' }).replace(".", "")
                                    // + " "
                                    // + dateArrival.getDay().toString().padStart(2, "0")
                                }
                            </div>
                        </div>
                    </div>
                </summary>
                <div className="ticket-details" style={{backgroundColor: "#fdfdfd"}}>

                    <div className="ticket-details-section">
                        <div className="ticket-details-section-item">
                            <div>Отправление</div>
                            <div className="ticket-details-arrival-city">
                                <div className="arrival-city-icon">
                                    <div style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "18px",
                                        height: "18px"
                                    }}>
                                        <LocationFilledIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    stroke: "#F04D5E",
                                                    fill: "#F04D5E"
                                                }
                                            }}
                                        />
                                        <span>A</span>
                                    </div>
                                </div>
                                <div className="arrival-info">
                                    {/*{flight.cityArr.split('(')[0]}*/}
                                    <span className="arrival-info-city">{ticket.cityDep}</span>
                                    <span className="arrival-info-date">
                                        {
                                            dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0")
                                            + ", "
                                            + dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")
                                            + " "
                                            + dateDepartment.getDay().toString().padStart(2, "0")
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ticket-details-section-item">
                            <div>Прибытие</div>
                            <div className="ticket-details-department-city">
                                <div className="department-city-icon">
                                    <div style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "18px",
                                        height: "18px"
                                    }}>
                                        <LocationFilledIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    stroke: "#107BD9",
                                                    fill: "#107BD9"
                                                }
                                            }}
                                        />
                                        <span>B</span>
                                    </div>

                                </div>
                                <div className="department-info">
                                    {/*{flight.cityDep.split('(')[0]}*/}
                                    <span className="department-info-city">{ticket.cityArr}</span>
                                    <span className="department-info-date">
                                        {
                                            dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0")
                                            + ", "
                                            + dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")
                                            + " "
                                            + dateArrival.getDay().toString().padStart(2, "0")
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ticket-details-section">
                        <div className="ticket-details-section-item">
                            <div>Авиакомпания</div>
                            <div className="air-company-name">
                                {ticket.airCompanyName}
                            </div>
                        </div>
                        <div className="ticket-details-section-item">
                            <div>Рейс</div>
                            <div className="flight-number">
                                {ticket.flightNumber}
                            </div>
                        </div>
                    </div>

                    <div className="ticket-details-section">
                        <div className="ticket-details-section-item">
                            <div>Обмен</div>
                            <div className="change-variants" style={{ display: "flex", gap: "5px"}}>
                                {
                                    [...new Set(ticket.tarifs.map(x => x.obmen))].map((change, index) => {
                                        const changeVariants:  { [selector: string]: string} = {
                                            "NoRefunds": "запрещен",
                                            "AdditionalPayment": "за плату",
                                        }
                                        return (
                                            changeVariants[change].length > 0
                                                && (
                                                    <span key={index}>
                                                        {
                                                            (changeVariants[change] ?? "неопределён")
                                                        }
                                                    </span>
                                                )
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="ticket-details-section-item">
                            <div>Возврат</div>
                            <div className="refund-variants" style={{display: "flex", gap: "5px"}}>
                                {
                                    [...new Set(ticket.tarifs.map(x => x.vozvrat))].map((refund, index) => {
                                        const refundVariants: { [selector: string]: string } = {
                                            "NoRefunds": "запрещен",
                                            "AdditionalPayment": "за плату",
                                        }
                                        return (
                                            refundVariants[refund].length > 0
                                                && (
                                                    <span key={index}>
                                                        {
                                                            (refundVariants[refund] ?? "неопределён")
                                                        }
                                                    </span>
                                                )
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="ticket-details-section">
                        <div className="ticket-details-section-item">
                            <div>Багаж</div>
                            <div className="baggage-variants" style={{display: "flex", gap: "5px"}}>
                                {
                                    [...new Set(ticket.tarifs.map(x => x.bagajInfo))].map((baggage, index) => {
                                        return (
                                            baggage.length > 0
                                                && (
                                                    <span key={index}>
                                                        {
                                                            (baggage ?? "неопределён")
                                                        }
                                                    </span>
                                                )
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="ticket-details-section-item">

                        </div>
                    </div>

                </div>
            </details>
        </>
    );
}
