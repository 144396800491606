import "./style.scss";
import {ICheckBox} from "../../../../interfaces/componentInterfaces/ICheckBox";

export function CustomCheckBox ({title, isChecked, onChange,icon}:ICheckBox) {
    return(<>

        <div className="checkbox-wrapper-29">
            <label className="checkbox">
                <input type="checkbox" className="checkbox__input"
                checked={isChecked}
                onChange={(e) =>
                    onChange(!isChecked)}/>
                <span className="checkbox__label"></span>
                {title}{icon && <> {icon}</>}
            </label>
        </div>
    </>)
}