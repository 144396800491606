import { useCookies } from "react-cookie";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ACCESS_TOKEN } from "../constants/FieldsConstants";
import { DateService } from "./DateService";
import {TripApi} from "../api/TripApi";
import {IBookFlightRequest} from "../Requests/IBookFlightRequest";


export function TripService() {
  const { getFlights, getFlights2, getPaxes, postFlightsBook } = TripApi();
  const { dateFromDatePickerToCustomFormat } = DateService();
  const authState = useAppSelector((state) => state.auth);
  const [cookies] = useCookies([ACCESS_TOKEN]);
  const dispatch = useAppDispatch();

  const getFlightList = async (origin: string, direction: string, date: string, clientCore: string | null, connectionId: string | null) => {
    try {
      const response = await getFlights(origin, direction, date, clientCore, connectionId);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  const getFlightList2 = async (origin: string, direction: string, date: string, clientCore: string | null, connectionId: string | null) => {
    try {
      const response = await getFlights2(origin, direction, date, clientCore, connectionId);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const getPaxesList = async (clientCore: string) => {
    try {
      const response = await getPaxes(clientCore);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  const flightsBook = async (clientCode: string, request: IBookFlightRequest) => {
    try {
      const response = await postFlightsBook(clientCode, request);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  return {
    getFlightList,
    getFlightList2,
    flightsBook,
    getPaxesList,
  };
}
