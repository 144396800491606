import { t } from "i18next";
import { ErrorMessage } from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import { OutputSimpleDataComponent } from "../../commonComponents/outputSimpleDataComponent";
import { InputSimpleDataComponent } from "../../commonComponents/inputSimpleDataComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SignupRequestValidator } from "../../../validators/SignupRequestValidator";
import { ValidationErrors } from "fluentvalidation-ts";
import { ISignupRequests } from "../../../Requests/ISignupRequest";
import { DataConvertService } from "../../../services/DataConvertService";
import { AuthService } from "../../../services/AuthService";
import "./style.scss";
import {
  LEFT_MENU_COMPANY_ACCOUNT,
  REDIRECT_TO_CABINET_HOME_PAGE,
  REDIRECT_TO_COMMON_HOME_PAGE,
} from "../../../constants/routeConstants/InnerRouteConstants";

export function SignUpPage() {
  //userData
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [position, setPosition] = useState("");

  //companyData
  const [shortCompanyName, setShortCompanyName] = useState("");
  const [fullCompanyName, setFullCompanyName] = useState("");
  const [companyOwner, setCompanyOwner] = useState("");
  const [legalAddress, setLegalAddress] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [mainContract, setMainContract] = useState("");
  const [inn, setInn] = useState("");
  const [hasInnForm, setHasInnForm] = useState(true);
  const [ogrn, setOgrn] = useState("");
  const [hasOgrnForm, setHasOgrnForm] = useState(false);

  //other hooks/functions/services
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<ISignupRequests>>();
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const signupValidator = new SignupRequestValidator();
  const { objHasAnyPropertyValue } = DataConvertService();
  const { signupCompany, validateSignupRequest } = AuthService();

  const submitHandler = async () => {
    setLoadingSpinner(true);

    const requestData: ISignupRequests = {
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      firstName: firstName,
      lastName: lastName,
      patronymic: patronymic,
      position: position,
      shortCompanyName: shortCompanyName,
      fullCompanyName: fullCompanyName,
      companyOwner: companyOwner,
      legalAddress: legalAddress,
      mailingAddress: mailingAddress,
      deliveryAddress: deliveryAddress,
      mainContract: mainContract,
      inn: inn,
      hasInnForm: hasInnForm,
      ogrn: ogrn,
      hasOgrnForm: hasOgrnForm,
    };

    const isValidationResultOk = validateSignupRequest(
      setValidationErrors,
      setPasswordConfirmationError,
      requestData
    );

    if (!isValidationResultOk) {
      setLoadingSpinner(false);
      return;
    }

    const signUpResult = await signupCompany(requestData);
    setLoadingSpinner(false);
    if (!signUpResult) {
      alert("Internal error, try to register a compone late.");
    }
    navigate(REDIRECT_TO_COMMON_HOME_PAGE);
  };

  return (
    <>
      <section className="vh-100 sign-up">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card">
                <form>
                  <div className="d-flex align-items-center mb-3 pb-1">
                    <i className="fas fa-cubes fa-2x me-3 some-color"></i>
                    <img
                      src="/img/logo-h.png"
                      alt="Logo"
                      className="img-fluid border-radius"
                      style={{ height: "70px" }}
                    />
                    {/*<span className="h1 fw-bold mb-0">Logo</span>*/}
                  </div>

                  {/* Email / Password / Confirm password */}
                  <div className=" d-flex flex-wrap  ms-3 ">
                    <InputSimpleDataComponent
                      title={t("commonText.email")}
                      inputDataType={"email"}
                      inputValue={email}
                      minWidthInPx={200}
                      errorMessage={validationErrors?.email?.toString()}
                      changeHandler={setEmail}
                    />
                    <InputSimpleDataComponent
                      title={t("commonText.password")}
                      inputDataType={"text"}
                      minWidthInPx={190}
                      inputValue={password}
                      errorMessage={validationErrors?.password?.toString()}
                      changeHandler={setPassword}
                    />
                    <InputSimpleDataComponent
                      title={t("commonText.confirmPassword")}
                      placeholderText={t("commonText.password")}
                      inputDataType={"text"}
                      minWidthInPx={190}
                      inputValue={passwordConfirm}
                      errorMessage={validationErrors?.passwordConfirm?.toString()}
                      changeHandler={setPasswordConfirm}
                    />
                  </div>

                  {/* Name / LastName / Patronymic*/}
                  {/* <div className=" d-flex flex-wrap  align-self-center ms-3 "> */}
                  <div className=" d-flex flex-wrap  ms-3 ">
                    <InputSimpleDataComponent
                      title={t("signupForm.firstName")}
                      inputDataType={"text"}
                      minWidthInPx={150}
                      inputValue={firstName}
                      errorMessage={validationErrors?.firstName?.toString()}
                      changeHandler={setFirstName}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.lastName")}
                      inputDataType={"text"}
                      minWidthInPx={150}
                      inputValue={lastName}
                      errorMessage={validationErrors?.lastName?.toString()}
                      changeHandler={setLastName}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.patronymic")}
                      inputDataType={"text"}
                      minWidthInPx={150}
                      inputValue={patronymic}
                      errorMessage={validationErrors?.lastName?.toString()}
                      changeHandler={setPatronymic}
                    />
                  </div>

                  {/* Position / ShortCompanyName */}
                  <div className=" d-flex flex-wrap ms-3 ">
                    <InputSimpleDataComponent
                      title={t("signupForm.position")}
                      inputDataType={"text"}
                      minWidthInPx={200}
                      inputValue={position}
                      errorMessage={validationErrors?.position?.toString()}
                      changeHandler={setPosition}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.shortCompanyName")}
                      inputDataType={"text"}
                      minWidthInPx={300}
                      inputValue={shortCompanyName}
                      errorMessage={validationErrors?.shortCompanyName?.toString()}
                      changeHandler={setShortCompanyName}
                    />
                  </div>

                  {/* company Owner / FullCompanyName  */}
                  <div className=" d-flex flex-wrap ms-3 ">
                    <InputSimpleDataComponent
                      title={t("signupForm.companyOwner")}
                      inputDataType={"text"}
                      minWidthInPx={200}
                      inputValue={companyOwner}
                      changeHandler={setCompanyOwner}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.fullCompanyName")}
                      inputDataType={"text"}
                      minWidthInPx={300}
                      inputValue={fullCompanyName}
                      changeHandler={setFullCompanyName}
                    />
                  </div>

                  {/* Legal/mailing/delivery addresses  */}
                  <div className=" d-flex flex-wrap ms-3 ">
                    <InputSimpleDataComponent
                      title={t("signupForm.legalAddress")}
                      inputDataType={"text"}
                      minWidthInPx={400}
                      errorMessage={validationErrors?.legalAddress?.toString()}
                      inputValue={legalAddress}
                      changeHandler={setLegalAddress}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.mailingAddress")}
                      inputDataType={"text"}
                      minWidthInPx={400}
                      inputValue={mailingAddress}
                      changeHandler={setMailingAddress}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.deliveryAddress")}
                      inputDataType={"text"}
                      minWidthInPx={400}
                      inputValue={deliveryAddress}
                      changeHandler={setDeliveryAddress}
                    />
                  </div>

                  {/* mainContract / Inn  */}
                  <div className=" d-flex flex-wrap ms-3  ">
                    <div className="form-check m-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={hasInnForm}
                        onChange={(e) => setHasInnForm(!hasInnForm)}
                        id="HasInnCheck"
                      />
                      <label className="form-check-label">{t("signupForm.hasInnForm")}</label>
                    </div>

                    <div className="form-check m-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="HasOgrnCheck"
                        checked={hasOgrnForm}
                        onChange={() => setHasOgrnForm(!hasOgrnForm)}
                      />
                      <label className="form-check-label">{t("signupForm.hasOgrnForm")}</label>
                    </div>
                  </div>

                  {/* mainContract / Inn/ogrn  */}
                  <div className=" d-flex flex-wrap ms-3 ">
                    <InputSimpleDataComponent
                      title={t("signupForm.mainContract")}
                      inputDataType={"text"}
                      minWidthInPx={230}
                      inputValue={mainContract}
                      errorMessage={validationErrors?.mainContract?.toString()}
                      changeHandler={setMainContract}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.inn")}
                      inputDataType={"text"}
                      minWidthInPx={230}
                      inputValue={inn}
                      errorMessage={validationErrors?.inn?.toString()}
                      changeHandler={setInn}
                    />
                    <InputSimpleDataComponent
                      title={t("signupForm.ogrn")}
                      inputDataType={"text"}
                      minWidthInPx={230}
                      inputValue={ogrn}
                      errorMessage={validationErrors?.inn?.toString()}
                      changeHandler={setOgrn}
                    />
                  </div>
                  {/* mainContract / Inn/ogrn  */}
                  <div className=" d-flex flex-wrap ms-3 ">
                    <button
                      type="button"
                      className="btn btn-danger m-2"
                      style={{ background: "red", color: "whitesmoke" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("commonText.cancel")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-info m-2"
                      style={{ background: "#1a2b63", color: "whitesmoke" }}
                      onClick={() => {
                        submitHandler();
                      }}
                    >
                      {t("signupForm.signup")}

                      {loadingSpinner && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={
                            loadingSpinner ? { visibility: "visible" } : { visibility: "collapse" }
                          }
                        ></span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function v(value: ValidationErrors<ISignupRequests> | undefined): void {
  throw new Error("Function not implemented.");
}
