import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {TicketSelectionModal} from "../ticketSelectionModal";
import {NewOrderDetailsModal} from "../newOrderDetailsModal";
import "./style.scss"
import {PassengersSelectionModal} from "../passengersSelectionModal";
import {IPassenger} from "../../../../interfaces/IPassenger";


interface INewOrderComponentProps {
    newOrderModalOpened: boolean;
    setNewOrderModalOpened: React.Dispatch<React.SetStateAction<boolean>>,
}


export function NewOrderComponent(props: INewOrderComponentProps) {

    const {
        newOrderModalOpened,
        setNewOrderModalOpened,
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const [ticketSelectionModalOpened, setTicketSelectionModalOpened] = useState<boolean>(false);

    const [tickets, setTickets] = useState<IAviaVariant[]>([]);

    const [passengersSelectionModalOpened, setPassengersSelectionModalOpened] = useState<boolean>(false);

    const [passengers, setPassengers] = useState<IPassenger[]>([]);


    const openSearchModalHandler = () => {
        setTicketSelectionModalOpened(true);
    }

    const addTicketToOrder = (ticket: IAviaVariant, tariff: IAviaTariffInfo) => {
        setTickets(prev => [...prev, ticket]);
    }

    const removeTicketFromOrder = (ticket: IAviaVariant) => {
        setTickets(prev => prev.filter(x => x != ticket));
    }

    const openSearchPassengersModal = () => {
        setPassengersSelectionModalOpened(true);
    }

    const addPassengersToOrder = (passengers: IPassenger[]) => {
        setPassengers(prev => [...prev.filter(x => !passengers.includes(x)), ...passengers]);
    }

    const removePassengersFromOrder = (passengers: IPassenger[]) => {
        setPassengers(prev => prev.filter(x => !passengers.includes(x)));
    }

    const bookOrder = () => {

    }


    useEffect(() => {

    }, []);


    return (
        <>
            <NewOrderDetailsModal
                trips={tickets}
                opened={newOrderModalOpened}
                setOpened={setNewOrderModalOpened}
                openSearchTicketModal={openSearchModalHandler}
                openSearchPassengersModal={openSearchPassengersModal}
                removeTicketFromOrder={removeTicketFromOrder}
                removePassengersFromOrder={removePassengersFromOrder}
                submitHandler={bookOrder}
            />


            <TicketSelectionModal
                opened={ticketSelectionModalOpened}
                setOpened={setTicketSelectionModalOpened}
                submitHandler={addTicketToOrder}
            />

            <PassengersSelectionModal
                opened={passengersSelectionModalOpened}
                setOpened={setPassengersSelectionModalOpened}
                submitHandler={addPassengersToOrder}
            />
        </>

    );
}
