import axios from "axios";
import {
    BASE_ROUTE_AUTH_SERVICE,
    ROUTE_GET_FLIGHTS, ROUTE_GET_PAXES,
    ROUTE_POST_FLIGHTS_BOOK,
} from "../constants/routeConstants/ApiRouteConstants";
import {FlightVariant, IAviaVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IBookFlightRequest} from "../Requests/IBookFlightRequest";
import {IPassenger} from "../interfaces/IPassenger";
import {IBookFlightResponse} from "../responces/IBookFlightResponse";

export function TripApi() {

    const getFlights = async (origin: string, direction: string, date: string, clientCode: string | null, connectionId: string | null) => {
        const result = await axios.get<Array<FlightVariant>>(
            process.env.REACT_APP_INTEROP_BASE_ROUTE + ROUTE_GET_FLIGHTS,
            {
                params: {
                    fromCity: origin,
                    toCity: direction,
                    departDate: date,
                    clientCode: clientCode,
                    //connectionId
                }
            }
        );
        return result;
    };

    const getFlights2 = async (origin: string, direction: string, date: string, clientCode: string | null, connectionId: string | null) => {
        const result = await axios.get<Array<IAviaVariant>>(
            process.env.REACT_APP_INTEROP_BASE_ROUTE + ROUTE_GET_FLIGHTS,
            {
                params: {
                    fromCity: origin,
                    toCity: direction,
                    departDate: date,
                    clientCode: clientCode,
                    //connectionId
                }
            }
        );
        return result;
    };

    const getPaxes = async (clientCode: string) => {
        const result = await axios.get<Array<IPassenger>>(
            process.env.REACT_APP_INTEROP_BASE_ROUTE + ROUTE_GET_PAXES,
            {
                params: {
                    clientCode: clientCode,
                }
            }
        );
        return result;
    };

    const postFlightsBook = async (clientCode: string, request: IBookFlightRequest) => {
        const result = await axios.post<IBookFlightResponse>(
            process.env.REACT_APP_INTEROP_BASE_ROUTE + ROUTE_POST_FLIGHTS_BOOK,
            request,
            {
                params: {
                    clientCode: clientCode
                }
            },
        );
        return result;
    };

    return {
        getFlights,
        getFlights2,
        getPaxes,
        postFlightsBook
    };
}