import {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setFlightFilters} from "../../../../redux/reportsSlice";
import {DateService} from "../../../../services/DateService";
import {MdButton} from "../../../../components/md-button";
import "./style.scss";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {MagnifierIcon} from "../../../../icons/MagnifierIcon";


export interface TicketSearchProps {
    isLoading: boolean;
    searchHandler: (origin: string, direction: string, date: string) => void;
}

export function TicketSearchComponent({ isLoading, searchHandler }: TicketSearchProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const reportState = useAppSelector((state) => state.reports);
    const {dateToStringForDatePicker} = DateService();


    const [origin, setOrigin] = useState(reportState.flightFilters?.fromCity ?? "");
    const [direction, setDirection] = useState(reportState.flightFilters?.toCity ?? "");
    const [date, setDate] = useState(reportState.flightFilters?.departDate ?? dateToStringForDatePicker(new Date()));

    const [filters, setFilters] = useState(reportState.flightFilters);


    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        searchHandler(origin, direction, date);
    };


    return (
        <>
            <div className="filters-container">
                {/*<div className="filters-caption">Фильтры</div>*/}
                {/*<div className="filters-description">Используйте фильтры для поиска нужного документа</div>*/}
                <form
                    className="filters-form"
                    onSubmit={onSubmit}
                >
                    <div className="filters-form-fields-container">

                        <div className="filters-form-fields-group">
                            <span className="filters-form-input-field">
                                <label>&nbsp;{`${t("bookingPage.route")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("bookingPage.from")}`}
                                    value={origin}
                                    onChange={(e) => {
                                        setOrigin(e.target.value);
                                        setFilters(prev => ({...prev, fromCity: e.target.value}));
                                        dispatch(setFlightFilters({...filters, fromCity: e.target.value}));
                                    }}
                                    style={{width: "200px"}}
                                />
                            </span>
                            <span className="filters-form-input-field">
                                <label>&nbsp;-&nbsp;&nbsp;</label>
                                <input
                                    type="text"
                                    placeholder={`${t("bookingPage.to")}`}
                                    value={direction}
                                    onChange={(e) => {
                                        setDirection(e.target.value);
                                        setFilters(prev => ({...prev, toCity: e.target.value}));
                                        dispatch(setFlightFilters({...filters, toCity: e.target.value}));
                                    }}
                                    style={{width: "200px"}}
                                />
                            </span>
                            <span className="filters-form-input-field">
                                <label>&nbsp;{`${t("bookingPage.date")}`}:&nbsp;&nbsp;</label>
                                <input
                                    type="date"
                                    value={date}
                                    onChange={(e) => {
                                        setDate(e.target.value);
                                        setFilters(prev => ({...prev, departDate: e.target.value}));
                                        dispatch(setFlightFilters({...filters, departDate: e.target.value}));
                                    }}
                                    style={{width: "200px"}}
                                />
                            </span>
                        </div>

                    </div>

                    <MdButton
                        type="button"
                        icon={
                            isLoading
                                && (
                                    <CircleSpinnerIcon
                                        width="20px"
                                        height="20px"
                                        style={{
                                            "circle": {
                                                stroke: "#FFFFFF"
                                            }
                                        }}
                                    />
                                )
                        }
                        style={{
                            "button": {
                                borderWidth: 0,
                                backgroundColor: "#3E5CB8",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                                height: "35px",
                                padding: "5px 15px"
                            },
                            "text": {
                                width: "auto",
                                flex: 0,
                                color: "#FFFFFF"
                            },
                            "ink": {
                                backgroundColor: "rgba(255,255,255,.5)"
                            }
                        }}
                        onClick={onSubmit}
                        disabled={isLoading}
                    >
                        {`${t("documentPage.search")}`}
                    </MdButton>

                </form>
            </div>
        </>
    );
}
