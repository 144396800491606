import './style.scss'
import React, {CSSProperties, useEffect, useState} from "react";
import {MdButton} from "../md-button";

interface IMdPaginationProps {
    buttonStyle?: CSSProperties;
    inkStyle?: CSSProperties;
    activePage: number;
    itemsCount: number;
    //items: [];
    itemsPerPage: number;
    onChange?: (page: number) => void;
}

enum ButtonType {
    Page,
    Back,
    Range,
    Forward
}

interface IButtonData {
    page?: number;
    type: ButtonType;
    selected?: boolean;
    disabled?: boolean;
}

export function MdPagination(props: IMdPaginationProps) {

    let activePage = Math.max(1, props.activePage);
    let itemsPerPage = Math.max(1, props.itemsPerPage);
    let itemsCount = props.itemsCount;
    let skipCount = (activePage - 1) * itemsPerPage;

    let onChange = props.onChange;

    let adjacents = 1;
    let limit = itemsPerPage;
    let prev = activePage - 1; // previous page is page - 1
    let next = activePage + 1; // next page is page + 1
    let lastpage = Math.ceil(itemsCount / itemsPerPage); // lastpage
    let lpm1 = lastpage - 1; // last page minus 1

    let buttons: Array<IButtonData> = []; //new Map<number, ButtonType>();

    if (lastpage > 1) {
        buttons.push({
            page: (activePage - 1),
            type: ButtonType.Back,
            disabled: (activePage == 1)
        });

        // pages
        if (lastpage < 7 + (adjacents * 2)) // not enough pages to bother breaking it up
        {
            for (let i = 1; i <= lastpage; i++) {
                buttons.push({
                    page: i,
                    type: ButtonType.Page,
                    selected: (i == activePage)
                });
            }
        } else if (lastpage >= 7 + (adjacents * 2)) // enough pages to hide some
        {
            // close to beginning; only hide later pages
            if (activePage < 1 + (adjacents * 3)) {
                for (let i = 1; i < 4 + (adjacents * 2); i++) {
                    buttons.push({
                        page: i,
                        type: ButtonType.Page,
                        selected: (i == activePage)
                    });
                }

                buttons.push({
                    type: ButtonType.Range,
                });

                buttons.push({
                    page: lpm1,
                    type: ButtonType.Page,
                });

                buttons.push({
                    page: lastpage,
                    type: ButtonType.Page,
                });
            }
            // in middle; hide some front and some back
            else if (lastpage - (adjacents * 2) > activePage && activePage > (adjacents * 2)) {
                buttons.push({
                    page: 1,
                    type: ButtonType.Page,
                });

                buttons.push({
                    page: 2,
                    type: ButtonType.Page,
                });

                buttons.push({
                    type: ButtonType.Range,
                });

                for (let i = activePage - adjacents; i <= activePage + adjacents; i++) {
                    buttons.push({
                        page: i,
                        type: ButtonType.Page,
                        selected: (i == activePage)
                    });
                }

                buttons.push({
                    type: ButtonType.Range,
                });

                buttons.push({
                    page: lpm1,
                    type: ButtonType.Page,
                });

                buttons.push({
                    page: lastpage,
                    type: ButtonType.Page,
                });
            }
            // close to end; only hide early pages
            else {
                buttons.push({
                    page: 1,
                    type: ButtonType.Page,
                });

                buttons.push({
                    page: 2,
                    type: ButtonType.Page,
                });

                buttons.push({
                    type: ButtonType.Range,
                });

                for (let i = lastpage - (1 + (adjacents * 3)); i <= lastpage; i++) {
                    buttons.push({
                        page: i,
                        type: ButtonType.Page,
                        selected: (i == activePage)
                    });
                }
            }
        }

        buttons.push({
            page: (activePage + 1),
            type: ButtonType.Forward,
            disabled: (activePage == lastpage)
        });
    }


    useEffect(() => { }, []);


    return (
        <>
            {
                buttons.map((button, index) => {
                    switch (button.type) {
                        case ButtonType.Back:
                            return (
                                <MdButton
                                    key={index}
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            fontSize: "11px",
                                            minWidth: "30px",
                                            padding: "5px 5px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={() => {
                                        if (onChange != null && button.page != null) {
                                            onChange(button.page)
                                        }
                                    }}
                                    disabled={button.disabled}
                                >
                                    {"<"}
                                </MdButton>
                            )
                        case ButtonType.Page:
                            return (
                                <MdButton
                                    key={index}
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: (button.selected ? "rgb(62, 92, 184)" : "rgb(233 233 233)"),
                                            color: (button.selected ? "rgb(255, 255, 255)" : "rgb(0 0 0)"),
                                            borderRadius: "6px",
                                            fontSize: "11px",
                                            minWidth: "30px",
                                            padding: "5px 5px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={() => {
                                        if (onChange != null && button.page != null) {
                                            onChange(button.page)
                                        }
                                    }}
                                >
                                    {(button.page != null ? button.page.toString() : "")}
                                </MdButton>
                            )
                        case ButtonType.Forward:
                            return (
                                <MdButton
                                    key={index}
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            fontSize: "11px",
                                            minWidth: "30px",
                                            padding: "5px 5px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={() => {
                                        if (onChange != null && button.page != null) {
                                            onChange(button.page)
                                        }
                                    }}
                                    disabled={button.disabled}
                                >
                                    {">"}
                                </MdButton>
                            )
                        case ButtonType.Range:
                            return (
                                <MdButton
                                    key={index}
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "transparent",
                                            color: "rgb(0 0 0)",
                                            fontSize: "11px",
                                            minWidth: "30px",
                                            padding: "5px 5px",
                                            pointerEvents: "none"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                >
                                    {"..."}
                                </MdButton>
                            )
                    }
                })
            }
        </>
    )
}