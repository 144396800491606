//AuthError
export const CLIENT_CODE_NOT_FOUND_ERROR = "clientCodeNotFoundError";

//DateErrors

//common Errors
export const FILTER_ERROR: string = "filterError";
export const TIME_SPAN_ERROR: string = "timeSpanError";


//Order Errors
export const DEPARTURE_CITY_ERROR: string = "departureCityError";
export const ARRIVED_CITY_ERROR: string = "arrivedCityError";
