import React, {SetStateAction, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import {MdSelect} from "../../../../components/md-select";
import {ICompanyInfo} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";
import {useAppSelector} from "../../../../redux/hooks";
import {DateService} from "../../../../services/DateService";
import "./style.scss"


interface IDownloadReconciliation {
    opened: boolean;
    onSubmit?: ( company: ICompanyInfo, startDate: string, endDate: string ) => void;
}


export function DownloadReconciliationComponent(props: IDownloadReconciliation) {

    const companyState = useAppSelector(state => state.companyInfo);

    const {t} = useTranslation();

    const { dateToStringForDatePicker } = DateService();

    const authState = useAppSelector(state => state.auth);


    const [startDate, setStartDate] = useState<string>(dateToStringForDatePicker(new Date()));
    const [endDate, setEndDate] = useState<string>(dateToStringForDatePicker(new Date()));
    const [company, setCompany] = useState<ICompanyInfo>(companyState);


    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit( company, startDate, endDate );
        }
    }


    useEffect(() => {
        if(props.opened) {
            setCompany(companyState);
        }
    }, [props.opened]);


    return (
        <div className="download-documents-form">

            <div className="download-documents-form-sections">
                <div className="download-documents-form-section">
                    <span className="download-documents-form-section-title">Организация</span>
                    <div className="download-documents-form-section-content" style={{zIndex: 100}}>
                        <MdSelect
                            label=""
                            value={company.fullName}
                            displayMemberPath={"fullName"}
                            setValue={setCompany}
                            items={authState.gkCompanies}/>
                    </div>
                </div>
                <div className="download-documents-form-section">
                    <span className="download-documents-form-section-title">Период</span>
                    <div className="download-documents-form-section-content" style={{padding: "10px 0"}}>
                        <div style={{display: "flex", alignItems: "start", gap: "10px"}}>
                            <span style={{display: "flex", alignItems: "start"}}>
                                <label
                                    className="sr-only align-self-lg-center ">&nbsp;{`${t("commonText.from")}`}:&nbsp;&nbsp;</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                        }}
                                    />
                                </div>
                            </span>
                            <span style={{display: "flex", alignItems: "start"}}>
                                <label
                                    className="sr-only align-self-lg-center ">&nbsp;{`${t("commonText.to")}`}:&nbsp;&nbsp;</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <MdButton
                type="submit"
                style={{
                    "button": {
                        margin: "auto",
                        marginTop: "20px",
                        width: "200px",
                        borderWidth: 0,
                        backgroundColor: "#3E5CB8",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        height: "35px",
                        padding: "5px 15px"
                    },
                    ink: {
                        backgroundColor: "rgba(255,255,255,.5)"
                    }
                }}
                onClick={onSubmit}>
                Скачать
            </MdButton>

        </div>
    );
}