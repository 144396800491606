import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store"; 

// Используйте во всем приложении вместо обычных `useDispatch` и `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//need to read about new approach https://redux.js.org/usage/usage-with-typescript 
// Use throughout your app instead of plain `useDispatch` and `useSelector`
//export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
//export const useAppSelector = useSelector.withTypes<RootState>()
//export const useAppStore = useStore.withTypes<AppStore>()
