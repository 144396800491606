import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {
    ModalContext,
    SEARCH_TICKET_MODAL_ID,
} from "../../../commonComponents/modal/ModalContext";
import {setFlightFilters} from "../../../../redux/reportsSlice";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "../../../../services/DateService";
import {TripService} from "../../../../services/TripService";
import {TicketComponent} from "../ticketComponent";
import {MdButton} from "../../../../components/md-button";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import "./style.scss"


interface ITicketSelectionModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    submitHandler: (ticket: IAviaVariant, tariff: IAviaTariffInfo) => void;
}


export function TicketSelectionModal(props: ITicketSelectionModal) {

    const { opened, setOpened, submitHandler } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const {getFlightList, getFlightList2, getPaxesList, flightsBook} = TripService();

    const {dateToStringForDatePicker} = DateService();

    const {isOpen, open, close} = useContext(ModalContext);

    const dispatch = useAppDispatch();
    const reportState = useAppSelector((state) => state.reports);


    const [origin, setOrigin] = useState(reportState.flightFilters?.fromCity ?? "");
    const [direction, setDirection] = useState(reportState.flightFilters?.toCity ?? "");
    const [date, setDate] = useState(reportState.flightFilters?.departDate ?? dateToStringForDatePicker(new Date()));
    const [filters, setFilters] = useState(reportState.flightFilters);

    const [tickets, setTickets] = useState<IAviaVariant[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [isSearchNotFound, setIsSearchNotFound] = useState(false);


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchTickets();
        }
    }
    //
    // const onSubmit = (ticket: IAviaVariant, tariff: IAviaTariffInfo) => {
    //     submitHandler(ticket, tariff);
    //     close(SEARCH_TICKET_MODAL_ID);
    //     resetForm();
    // }


    const searchTickets = async () => {
        setSearchPending(true);
        setIsSearchNotFound(false);
        setTickets([]);

        const response = await getFlightList2(origin, direction, date, authState.currentClientCode, null);

        if (response !== null && response.length > 0) {
            setTickets(response);//.map(x => x.rejsInfo).flat());
        } else {
            setIsSearchNotFound(true);
        }

        setSearchPending(false);
    };

    const resetForm = () => {
        // setCompanyName("");
        // setCompanies([]);
        // setSelectedCompany(null);
    }

    const selectTrip = (ticket: IAviaVariant, tariff: IAviaTariffInfo) => {
        submitHandler(ticket, tariff);
        setOpened(false);
        setSearchPending(false);
        setIsSearchNotFound(false);
        setTickets([]);
    }

    const onModalOpen = () => {
        if(!opened) {
            setOpened(true);
        }
        resetForm();
    }

    const onModalClose = () => {
        if(opened) {
            setOpened(false);
        }
        resetForm();
    }


    useEffect(() => {
        if(isOpen(SEARCH_TICKET_MODAL_ID) && !opened) {
            close(SEARCH_TICKET_MODAL_ID);
        }
        else if(!isOpen(SEARCH_TICKET_MODAL_ID) && opened) {
            open(SEARCH_TICKET_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => { }, []);


    return (
        <MdModal
            id={SEARCH_TICKET_MODAL_ID}
            title="Поиск билета"
            openHandler={onModalOpen}
            closeHandler={onModalClose}>

            <div className="ticket-selection-dialog" style={{ maxHeight: (tickets.length > 0 ? "770px": "350px")}}>

                <div className="ticket-selection-dialog__searchbar"
                     style={{transform: (searchPending || tickets.length > 0 || searchError ? "translateY(0)" : "translateY(20px)")}}>
                    <div className="search-ticket-form-filters">
                        <div className="form-field">
                            <div className="form-field-label">
                                Откуда
                            </div>
                            <input
                                type="text"
                                placeholder={`${t("bookingPage.from")}`}
                                value={origin}
                                onKeyDown={onKeyDown}
                                onChange={(e) => {
                                    setOrigin(e.target.value);
                                    setFilters(prev => ({...prev, fromCity: e.target.value}));
                                    dispatch(setFlightFilters({...filters, fromCity: e.target.value}));
                                }}
                                style={{width: "200px"}}
                            />
                        </div>
                        <div className="form-field">
                            <div className="form-field-label">
                                Куда
                            </div>
                            <input
                                type="text"
                                placeholder={`${t("bookingPage.to")}`}
                                value={direction}
                                onKeyDown={onKeyDown}
                                onChange={(e) => {
                                    setDirection(e.target.value);
                                    setFilters(prev => ({...prev, toCity: e.target.value}));
                                    dispatch(setFlightFilters({...filters, toCity: e.target.value}));
                                }}
                                style={{width: "200px"}}
                            />
                        </div>
                        <div className="form-field">
                            <div className="form-field-label">
                                Когда
                            </div>
                            <input
                                type="date"
                                value={date}
                                onKeyDown={onKeyDown}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                    setFilters(prev => ({...prev, departDate: e.target.value}));
                                    dispatch(setFlightFilters({...filters, departDate: e.target.value}));
                                }}
                                style={{width: "200px"}}
                            />
                        </div>

                        <MdButton
                            type="button"
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px",
                                    alignItems: "center",
                                    justifyContent: "center"
                                },
                                "text": {
                                    width: "auto",
                                    flex: 0,
                                    color: "#FFFFFF"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={() => searchTickets()}
                            disabled={searchPending}
                        >
                            {`${t("documentPage.search")}`}
                        </MdButton>

                    </div>
                </div>

                {
                    tickets.length > 0 && (
                        <div className="ticket-selection-dialog__results">
                            <MdScrollViewer>
                                <div className="ticket-list"
                                     style={{maxHeight: "inherit", overflow: "hidden", paddingBottom: "10px"}}>
                                    {
                                        tickets.map((trip, index) =>
                                            <div key={index}>
                                                <TicketComponent
                                                    trip={trip}
                                                    selectTrip={selectTrip}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </MdScrollViewer>
                        </div>
                    )
                }

                {
                    searchPending && (
                        <div className="loading-results-message">
                            <CircleSpinnerIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "circle": {
                                        stroke: "#A0A1A6"
                                    }
                                }}
                            />
                            Ищем билеты..
                        </div>
                    )
                }

                {
                    searchError && (
                        <div className="no-results-message">
                            Не удалось загрузить билеты по указанным параметрам
                        </div>
                    )
                }

            </div>

        </MdModal>
    );
}