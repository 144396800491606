import { useState } from "react";
import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";

export function CollapseIcon({ iconSize, onClick }: IIconProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapsedState =
    "M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z";
  const expandedState =
    "M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z";
  if (iconSize === undefined) {
    iconSize = "25px";
  }
  const clickHandler = () => {
    setIsCollapsed(!isCollapsed);
    if (onClick !== undefined) {
      onClick(isCollapsed);
    }
  };

  return (
    <>
      <svg
        fill="currentColor"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        onClick={() => {
          clickHandler();
        }}
        style={{rotate: isCollapsed?"180deg":"0deg"}}
      >
        <g data-name="Layer 2">
          <g data-name="arrow-ios-forward" >
            <rect width="24px" height="24px" transform="rotate(90 12 12)" opacity="0" />
            <path d={isCollapsed ? collapsedState : expandedState} />
          </g>
        </g>
      </svg>
    </>
  );
}
