import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../components/const/componentsContants";

export function BaggageIcon({iconSize,activeColor,disableColor,isChecked}:IIconProps) {
    iconSize = iconSize??DEFAULT_ICON_SIZE;
    activeColor = activeColor??"currentColor";
    disableColor = disableColor??"currentColor";

    let  color = isChecked? activeColor:disableColor;
    return (
        <svg
            fill={color}
            version="1.1"
            id="Capa_1"
            width={iconSize} height={iconSize}
            viewBox="0 0 265.702 265.702"
        >
            <g>
                <g>
                    <path d="M258.533,49.847H232.21v138.831h-39.441H53.31H7.168c-3.958,0-7.168,3.216-7.168,7.169c0,3.958,3.211,7.169,7.168,7.169
			h24.42c-1.228,2.86-1.92,6.006-1.92,9.306c0,13.035,10.608,23.639,23.641,23.639s23.644-10.604,23.644-23.639
			c0-3.3-0.693-6.445-1.923-9.306h96.018c-1.232,2.86-1.923,6.006-1.923,9.306c0,13.035,10.613,23.639,23.644,23.639
			s23.644-10.604,23.644-23.639c0-3.3-0.695-6.445-1.923-9.306h32.058V64.184h11.985c3.958,0,7.169-3.211,7.169-7.168
			S262.491,49.847,258.533,49.847z M62.621,212.322c0,5.129-4.177,9.302-9.307,9.302c-5.126,0-9.304-4.173-9.304-9.302
			s4.177-9.306,9.304-9.306C58.443,203.016,62.621,207.193,62.621,212.322z M202.075,212.322c0,5.129-4.182,9.302-9.306,9.302
			c-5.129,0-9.307-4.173-9.307-9.302s4.178-9.306,9.307-9.306C197.893,203.016,202.075,207.193,202.075,212.322z"/>
                    <path d="M170.666,48.925h-15.518c-0.052-8.884-0.846-17.259-6.768-18.839c-0.648-0.173-1.303-0.345-2.077-0.345H98.97
			c-0.669,0-1.234,0.133-1.806,0.259c-1.612,0.348-2.84,1.232-3.82,2.495c-0.014,0.019-0.023,0.038-0.038,0.056
			c-2.38,3.122-3.11,8.774-3.169,16.375H75.24c-0.859,0-1.683,0.133-2.48,0.333H42.097c-6.308,0-11.444,5.134-11.444,11.446V163.99
			c0,6.31,5.131,11.448,11.444,11.448H72.76c0.798,0.196,1.626,0.336,2.48,0.336h95.43c0.858,0,1.685-0.14,2.478-0.336h30.663
			c6.31,0,11.448-5.134,11.448-11.448V60.7c0-6.308-5.134-11.446-11.448-11.446h-30.663
			C172.345,49.058,171.524,48.925,170.666,48.925z M200.917,63.596v97.495h-19.686V63.596H200.917z M44.983,161.091V63.596h19.686
			v97.495H44.983z M166.894,63.596v97.495v0.336H79.006v-0.336V63.596v-0.334h87.888V63.596z M140.395,44.079
			c0.168,1.787,0.308,3.631,0.378,4.847h-36.266c0.072-1.234,0.21-3.073,0.378-4.847H140.395z"/>
                </g>
            </g>
        </svg>)
}