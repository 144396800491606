import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {t} from "i18next";
import {EMAIL_FIELD, FIELD_PASSWORD_CONFIRM, PASSWORD_FIELD} from "../../../constants/FieldsConstants";
import {AuthApi} from "../../../api/AuthApi";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {useAppSelector} from "../../../redux/hooks";
import {AuthService} from "../../../services/AuthService";


export function ChangePasswordPage() {
    const authState = useAppSelector(state => state.auth);

    const initialized = useRef(false);

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [searchParams] = useSearchParams();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const navigate = useNavigate();
    const [recoveryError, setRecoveryError] = useState("");
    const [confirmPassError, setConfirmPassError] = useState("");
    const [passError, setPassError] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [isPassChanged, setIsPassChanged] = useState(false);

    const [complete, setComplete] = useState(false);

    const {changePassword, getUserActiveState} = AuthApi();

    const {logout} = AuthService();

    const redirectToHeadPage = () => {
        setTimeout(() => {
            navigate("/")
        }, 3000)
    }
    const changeHandler = (inputField: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setPassError("");
        setConfirmPassError("");
        if (inputField === PASSWORD_FIELD) {
            setPassword(event.target.value);
        }
        if (inputField === FIELD_PASSWORD_CONFIRM) {
            setConfirmPassword(event.target.value);
        }
    }

    const parseQueryString = async () => {
        const params = Object.fromEntries(searchParams.entries());

        const token = params.token;
        const email = params.email;

        setEmail(email);
        setToken(token);

        if (email === undefined || email === "" || token === undefined || token === "") {
            navigate('/404');
        }
    }

    const checkUser = async () => {
        let checkResult = await getUserActiveState(email, token);
        switch (checkResult) {
            case true:
                if(authState.isValidUser) {
                    navigate('/cabinet/profile');
                }
                else {
                    navigate('/sign-in?email=' + email);
                }
                break;
            case false:
                setComplete(true);
                break;
            default:
                navigate('/404');
                break;
        }
    }

    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setLoadingSpinner(true);

        if (password.length <= 7) {
            setPassError(t("recoveryPage.pass-too-short"));
            setLoadingSpinner(false);
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPassError(t("recoveryPage.confirm-err"))
        }

        if (password === undefined || confirmPassError === undefined) {
            setLoadingSpinner(false)
            return;
        }

        await logout();

        const result = await changePassword({
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            link: token
        })

        if (result) {
            setIsPassChanged(true)
            redirectToHeadPage();
        } else {
            setRecoveryError(t("recoveryPage.token-err"))
            redirectToHeadPage();
        }
    }

    useEffect(() => {
        parseQueryString();
    }, []);

    useEffect(() => {
        if(email !== "" && token !== "") {
            checkUser();
        }
    }, [email, token]);


    return (
        <>
            {
                complete
                    ? (
                        <form className="login-form">
                            <div className="login-form-header">Назначение пароля</div>

                            <div className="login-form-body">

                                <MdTextField
                                    type="password"
                                    label={t("password")}
                                    value={password}
                                    invalid={passError.length !== 0}
                                    errorMessage={passError}
                                    onChange={(e) => {
                                        changeHandler(PASSWORD_FIELD, e);
                                    }}
                                />

                                <MdTextField
                                    type="password"
                                    label={t("commonText.confirmPassword")}
                                    value={confirmPassword}
                                    invalid={confirmPassError.length !== 0}
                                    errorMessage={confirmPassError}
                                    onChange={(event) => {
                                        changeHandler(FIELD_PASSWORD_CONFIRM, event);
                                    }}
                                />

                                <div className="login-form-error-message">
                                    {recoveryError && <ErrorMessage error={recoveryError}/>}
                                    {isPassChanged && <ErrorMessage error={t("recoveryPage.pass-changed")}/>}
                                </div>

                                {
                                    !loadingSpinner
                                        ? (
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "14px",
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                    }
                                                }}
                                                onClick={(event) => submitHandler(event)}
                                            >
                                                Применить
                                            </MdButton>
                                        )
                                        : (
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "20px 0 0 0 "
                                            }}>
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </div>
                                        )
                                }
                            </div>
                        </form>
                    )
                    : (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "70px",
                        }}>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </div>
                    )
            }
        </>
    );
}
