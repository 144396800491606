import React, {CSSProperties, useEffect} from "react";
import './style.scss'

interface IMdAlertProps {
    type?: "primary" | "secondary" | "success" | "danger" | "warning" | "info";
    icon?: React.ReactNode;
    message?: React.ReactNode | string;
    button?: React.ReactNode;
    style?: { [selector: string]: CSSProperties }
}

export function MdAlert({ type = "info", icon = null, message = "", button = null, style = {} }: IMdAlertProps) {


    useEffect(() => { }, []);


    return (
        <div
            className={"md-alert" + " --alert-" + type}
            style={ style["root"] }>
            <i
                className="md-alert-icon"
                style={ style["icon"] }
            >
                { icon }
            </i>
            <span
                className="md-alert-message"
                style={ style["message"] }
            >
                {message}
            </span>
            <span
                className="md-alert-button"
                style={ style["button"] }
            >
                {button}
            </span>
        </div>
    );
}