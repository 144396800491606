interface ErrorMessageProps {
  error: string;
}

export function ErrorMessage({ error }: ErrorMessageProps) {
  return (
    <>
      <p className="text-danger text-center">{error}</p>
    </>
  );
}
