import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICompanyInfo} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";
import {ICompanyRole, ICompanyUserRoles} from "../../../../interfaces/requestsInterfaces/reportRequests";
import {UserRoles} from "../../../../enums/UserRole";
import {DateService} from "../../../../services/DateService";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {CompanyIcon} from "../../../../icons/CompanyIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import "./style.scss"
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompany";


interface EmployeeAddProps {
    addHandler: (
        name: string,
        lastName: string,
        patronymic: string,
        position: string,
        roles: ICompanyRole[],
        dateOfBurn: string,
        email: string,
        gender: boolean
    ) => void;
    //companies: ICompany[];
    companyRoles: ICompanyUserRoles[];
    removeCompanyHandler: (
        companyCode: string,
    ) => void;
    addCompanyRoleHandler: (
        companyCode: string,
        companyName: string,
        roleName: string,
    ) => void;
    removeCompanyRoleHandler: (
        companyCode: string,
        companyName: string,
        roleName: string,
    ) => void;
    openCompanySelectionModal: () => void;
    pending: boolean;
    error: boolean;
}


export function EmployeeAddComponent(props: EmployeeAddProps) {

    const {
        addHandler,
        //companies,
        companyRoles,
        removeCompanyHandler,
        addCompanyRoleHandler,
        removeCompanyRoleHandler,
        pending,
        error
    } = props;

    const {t} = useTranslation();

    const {dateToStringForDatePicker} = DateService();


    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [gender, setGender] = useState(true);
    const [email, setEmail] = useState("");
    const [position, setPosition] = useState("");
    const [dateOfBurn, setDateOfBurn] = useState(dateToStringForDatePicker(new Date()));


    const rolesCheck = companyRoles.length == 0 || companyRoles.some(x => x.roles.length == 0);


    const onSubmit = () => {
        addHandler(
            name,
            lastName,
            patronymic,
            position,
            companyRoles.map(company => {
                return company.roles.map(role => {
                    return {
                        clientCode: company.clientCode,
                        roleName :
                        role
                    } as ICompanyRole
                }).flat()
            }).flat(),
            dateOfBurn,
            email,
            gender
        );
    }


    useEffect(() => {

    }, []);


    return (
        <div className="add-employee-form" style={{ maxWidth: "700px"}}>

            <div className="add-employee-form-sections">

                <div className="add-employee-form-section" style={{marginTop: "0px"}}>
                    <span className="add-employee-form-section-title">ФИО</span>
                    <div className="add-employee-form-section-content">

                        <div className="add-employee-form-fields-group">

                            <div className="add-employee-form-input-field">
                                <input
                                    style={{width: "170px", fontSize: "12px", outline: "none", boxShadow: "none"}}
                                    type="text"
                                    placeholder="Фамилия"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="add-employee-form-input-field">
                                <input
                                    style={{width: "170px", fontSize: "12px", outline: "none", boxShadow: "none"}}
                                    type="text"
                                    placeholder="Имя"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="add-employee-form-input-field">
                                <input
                                    style={{width: "170px", fontSize: "12px", outline: "none", boxShadow: "none"}}
                                    type="text"
                                    placeholder="Отчество"
                                    value={patronymic}
                                    onChange={(e) => {
                                        setPatronymic(e.target.value);
                                    }}
                                />
                            </div>

                        </div>

                    </div>
                </div>

                <div className="send-documents-form-section" style={{marginTop: "10px"}}>
                    <div className="add-employee-form-section-content">
                        <div className="add-employee-form-fields-group">
                            <div>
                                <span className="send-documents-form-section-title">Дата рождения</span>
                                <div className="send-documents-form-section-content">
                                        <span className="input-group">
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    style={{
                                                        width: "170px",
                                                        fontSize: "12px",
                                                        outline: "none",
                                                        boxShadow: "none"
                                                    }}
                                                    type="date"
                                                    value={dateOfBurn}
                                                    onChange={(e) => {
                                                        setDateOfBurn(e.target.value);
                                                    }}
                                                />
                                                {/* <ErrorMessage error={""} /> */}
                                            </div>
                                        </span>
                                </div>
                            </div>

                            <div>
                                <span className="send-documents-form-section-title">Email</span>
                                <div className="send-documents-form-section-content">
                                    <div className="input-group" style={{marginTop: "0px"}}>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder="example@domain.com"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="send-documents-form-section" style={{marginTop: "10px"}}>
                    <span className="send-documents-form-section-title">Должность</span>
                    <div className="send-documents-form-section-content">
                        <div className="input-group" style={{marginTop: "0px"}}>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    style={{
                                        width: "170px",
                                        fontSize: "12px",
                                        outline: "none",
                                        boxShadow: "none"
                                    }}
                                    type="text"
                                    placeholder="Наименование"
                                    value={position}
                                    onChange={(e) => {
                                        setPosition(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="send-documents-form-section" style={{marginTop: "10px"}}>
                    <span className="send-documents-form-section-title">Компания</span>
                    <div className="send-documents-form-section-content">

                        <div className="company-list">
                            {
                                companyRoles.map((company, index) =>
                                    <div className="company-list-item" key={index}>
                                        <div className="company-list-item-info">
                                            <span className="company-icon">
                                                <CompanyIcon
                                                    width={"12px"}
                                                    height={"12px"}
                                                    style={{
                                                        "path": {
                                                            fill: "#777E90"
                                                        }
                                                    }
                                                    }
                                                />
                                            </span>
                                            <span className="company-name">
                                                {company.companyFullName}
                                            </span>
                                            <span className="remove-company">
                                                <MdButton
                                                    icon={
                                                        <CrossIcon
                                                            width="14px"
                                                            height="14px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#000000"
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    style={{
                                                        "button": {
                                                            width: "16px",
                                                            height: "16px",
                                                            padding: "0",
                                                            border: "none",
                                                            borderRadius: "4px",
                                                            backgroundColor: "transparent",
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                        },
                                                        "icon": {
                                                            margin: 0
                                                        }
                                                    }}
                                                    onClick={()=>removeCompanyHandler(company.clientCode)}
                                                />
                                            </span>
                                        </div>
                                        <div className="company-list-item-roles">
                                            {
                                                Object.values(UserRoles).map((role, index) => {
                                                    const hasRole = company.roles.includes(role);
                                                    return (
                                                        <div key={index}>
                                                            <MdToggleButton
                                                                buttonStyle={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "1px 10px 0 10px",
                                                                    borderRadius: "12px",
                                                                    height: "24px",
                                                                    fontSize: "10px",
                                                                    color: (hasRole ? "#74B100" : "#C1C1C1"),
                                                                    border: (hasRole ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                }}
                                                                inkStyle={{
                                                                    backgroundColor: (hasRole ? "#74B100" : "#C1C1C1"),
                                                                    opacity: 0.1
                                                                }}
                                                                checked={hasRole}
                                                                onChange={(state) => {
                                                                    if(state) {
                                                                        addCompanyRoleHandler(company.clientCode, company.companyFullName, role);
                                                                    }
                                                                    else {
                                                                        removeCompanyRoleHandler(company.clientCode, company.companyFullName, role);
                                                                    }
                                                                }}
                                                            >
                                                                <div style={{display: "flex", gap: "5px"}}>
                                                                    <span>{role}</span>
                                                                </div>
                                                            </MdToggleButton>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <MdButton
                            type="submit"
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(253 253 253)",
                                    color: "rgb(175 175 175)",
                                    border: "1px solid rgb(175 175 175)",
                                    borderStyle: "dashed",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={() => props.openCompanySelectionModal()}
                        >
                            <span>Добавить</span>
                        </MdButton>

                        {/*<MdMenu*/}
                        {/*    behaviour="none"*/}
                        {/*    header="ООО ГУДИС"*/}
                        {/*    icon={*/}
                        {/*        <CompanyIcon*/}
                        {/*            width={"12px"}*/}
                        {/*            height={"12px"}*/}
                        {/*            style={{*/}
                        {/*                "path": {*/}
                        {/*                    fill: "#777E90"*/}
                        {/*                }*/}
                        {/*            }*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    style={{*/}
                        {/*        "root": {*/}
                        {/*            width: "300px",*/}
                        {/*            zIndex: 111111111*/}
                        {/*        },*/}
                        {/*        "label": {*/}
                        {/*            fontSize: "12px",*/}
                        {/*            color: "#161A1F "*/}
                        {/*        },*/}
                        {/*        "arrow": {*/}
                        {/*            display: "none"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div className="roles-list">*/}
                        {/*        <MdSwitch />*/}
                        {/*    </div>*/}
                        {/*</MdMenu>*/}

                    </div>
                </div>

                <div className="send-documents-form-section" style={{marginTop: "10px"}}>
                    <span className="send-documents-form-section-title">Пол</span>
                    <div className="send-documents-form-section-content">
                        <div className="send-documents-form-format-list">
                            <MdToggleButton
                                buttonStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1px 10px 0 10px",
                                    borderRadius: "12px",
                                    height: "24px",
                                    fontSize: "11px",
                                    color: (gender ? "#74B100" : "#C1C1C1"),
                                    border: (gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (gender ? "#74B100" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={gender}
                                onClick={(e) => {
                                    if (gender) {
                                        e.preventDefault();
                                        return;
                                    }
                                    setGender(true)
                                }
                                }
                            >
                                <div style={{display: "flex", gap: "5px"}}>
                                    <span>Мужчина</span>
                                </div>
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1px 10px 0 10px",
                                    borderRadius: "12px",
                                    height: "24px",
                                    fontSize: "11px",
                                    color: (!gender ? "#74B100" : "#C1C1C1"),
                                    border: (!gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (!gender ? "#74B100" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={!gender}
                                onClick={(e) => {
                                    if (!gender) {
                                        e.preventDefault();
                                        return;
                                    }
                                    setGender(false)
                                }
                                }
                            >
                                <div style={{display: "flex", gap: "5px"}}>
                                    <span>Женщина</span>
                                </div>
                            </MdToggleButton>

                        </div>
                    </div>
                </div>

            </div>

            {(error) && (
                <div className="error-message">
                    Сотрудник не добавлен, попробуйте повторить запрос позже.
                </div>
            )}

            {
                !pending
                    ? (
                        <MdButton
                            type="submit"
                            style={{
                                "button": {
                                    margin: "20px auto",
                                    width: "200px",
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={rolesCheck}
                            onClick={onSubmit}
                        >
                            {`${t("employeePage.add")}`}
                        </MdButton>
                    )
                    : (
                        <CircleSpinnerIcon
                            width="20px"
                            height="20px"
                            style={{
                                "svg": {
                                    margin: "20px auto 15px auto",
                                }
                            }}
                        />
                    )
            }


        </div>
    );
}
