import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { CompanyAccInfoComponent } from "./companyAccInfo";
import { CompanySalesInfoBarComponent } from "./companySalesInfoBar";
import { AuthorizedUsersInfoComponent } from "./authorizedUsersInfo";
import { ICLientSale } from "../../../interfaces/companyInfoInterfaces/IClientSale";
import { CompanyFeesInfoComponent } from "./companyFeesInfo";
import {
  AVIA_SERVICE_RESPONSE,
  HOTEL_SERVICE_RESPONSE,
  TRAIN_SERVICE_RESPONSE,
} from "../../../constants/ServiceCostants";
import { useTranslation } from "react-i18next";
import { MAIN_BACKGROUND_COLOR } from "../../../constants/PageConstants";
import {
  setCompanyStatisticPeriod,
  setEndCompanyInfoDate,
  setStartCompanyInfoDate,
} from "../../../redux/CompanyInfoSlice";
import { CompanyService } from "../../../services/CompanyService";
import { useCookies } from "react-cookie";
import { ACCESS_TOKEN } from "../../../constants/FieldsConstants";
import { DateService } from "../../../services/DateService";
import { useState } from "react";
import {ColorCardComponent} from "../../commonComponents/colorCardComponent";

export function HomeCabinetPage() {
  const { t } = useTranslation();

  //redux
  const companyInfoState = useAppSelector((state) => state.companyInfo);
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { getClientInfoInBackground, getDatePeriodClientInfo } = CompanyService();
  const { dateToStringForDatePicker } = DateService();
  const [cookies] = useCookies([ACCESS_TOKEN]);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  //hooks

  //handlers
  const statsPeriodHandler = async (period: string) => {
    if (period === companyInfoState.companyStatisticPeriod) return;

    setIsLoadingStats(true);

    dispatch(setCompanyStatisticPeriod(period));
    const reportPeriod = getDatePeriodClientInfo(period);
    dispatch(setStartCompanyInfoDate(dateToStringForDatePicker(reportPeriod[0])));
    dispatch(setEndCompanyInfoDate(dateToStringForDatePicker(reportPeriod[1])));

    await getClientInfoInBackground(authState.gkClientCodes, authState.currentClientCode, cookies[ACCESS_TOKEN], period);
    setIsLoadingStats(false);
  };

  const selectLastEyarSales = (clientSales: ICLientSale[]) => {
    let templateArray: (string | number | Object)[][] = [
      [`${t("accInfoPage.date")}`, `${t("accInfoPage.totalSumRub")}`, { role: "annotation" }],
    ];

    let dataArray: (string | number)[][];
    if (clientSales !== undefined && clientSales.length > 0) {
      dataArray = clientSales.map((sale) => [
        sale.monthNum + "-" + sale.year,
        sale.totalSum,
        sale.operCount,
      ]);
    } else {
      dataArray = [["NoData", 0, 0]];
    }
    return templateArray.concat(dataArray);
  };
  return (
    <>


      {/* <div className="d-flex flex-row gap-1">
        <ColorCardComponent id="blue" />
        <ColorCardComponent id="lightBlue" />
        <ColorCardComponent id="lightgreen" />
        <ColorCardComponent id="green" />
      </div> */}
      {/* <div
        className="d-inline-flex flex-row flex-wrap"
        //style={{ width: "100%" }}
      > */}
      <CompanyAccInfoComponent
        agreementNo={companyInfoState.agreementNo}
        conpanyLimit={0}
        companyDuty={companyInfoState.deptSum}
        agreementFrom={companyInfoState.agreementFrom}
        agreementTill={companyInfoState.agreementTill}
        deptType={companyInfoState.deptType}
        deptSum={companyInfoState.deptSum}
      />
      {/* </div> */}
      <CompanyFeesInfoComponent
        isLoading={isLoadingStats}
        currentPeriod={companyInfoState.companyStatisticPeriod}
        currentPeriodHandler={statsPeriodHandler}
        aviaTicketSale={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.totalSum
        }
        aviaTicketSaleCount={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        aviaTicketExcange={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        aviaTicketExcangeCount={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        aviaTicketReturn={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        aviaTicketReturnCount={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === AVIA_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        trainTicketSale={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        trainTicketSaleCount={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        trainTicketExcange={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        trainTicketExcangeCount={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        trainTicketReturn={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        trainTicketReturnCount={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === TRAIN_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        hotelTicketSale={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        hotelTicketSaleCount={
          companyInfoState.clientStructureSales?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        hotelTicketExcange={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        hotelTicketExcangeCount={
          companyInfoState.clientStructureExchange?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
        hotelTicketReturn={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.totalSum ?? 0
        }
        hotelTicketReturnCount={
          companyInfoState.clientStructureRefound?.find(
            (x) => x.serviceKind === HOTEL_SERVICE_RESPONSE
          )?.operCount ?? 0
        }
      />
      <div className="container-fluid p-0" style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}>
        <AuthorizedUsersInfoComponent autorizedUsers={companyInfoState.allwedPersons} />
      </div>
      <div className="filters-container"
           //style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}
      >
        <CompanySalesInfoBarComponent
          companySalesBarData={selectLastEyarSales(companyInfoState.clientSales)}
        />
      </div>
    </>
  );
}
