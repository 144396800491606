import { DATE_FORMAT_YYYYMMDD } from "../constants/ServiceCostants";
import {useTranslation} from "react-i18next";

export function DateService() {
  const {t} = useTranslation();

  const getCustomDate = (date: Date, separator: string = "/") => {
    return date.getDate() + separator + date.getMonth() + separator + date.getFullYear();
  };

  const getCustomTime = (date: Date, separator: string = ":") => {
    return (date.getHours()<10?"0"+date.getHours():date.getHours())
        + separator
        + (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes());
  };

  const getTravelTime = (departureTime: Date, arrivedTime: Date) => {
    let travelTime: string = "В пути: ";
    let travelPeriod = arrivedTime.getTime() - departureTime.getTime();

    //calculate days
    if (travelPeriod / (1000 * 3600 * 24) > 0) {
      let travelDays = Math.trunc(travelPeriod / (1000 * 3600 * 24));
      if (travelDays > 1) {
        travelTime += travelDays + t("orderPage.shortDay");
        travelPeriod -= 1000 * 3600 * 24 * travelDays;
      } else if(travelDays === 1){
        travelTime += "1"+t("orderPage.shortDay");
        travelPeriod -= 1000 * 3600 * 24;
      }
    }

    //calculate hours
    if (travelPeriod / (1000 * 3600) > 0) {
      let travelHors = Math.trunc(travelPeriod / (1000 * 3600));
      if (travelHors > 1) {
        travelTime += " " + travelHors + t("orderPage.shortHour");
        travelPeriod -= 1000 * 3600 * travelHors;
      } else if (travelHors === 1) {
        travelTime += " 1"+t("orderPage.shortHour");
        travelPeriod -= 1000 * 3600;
      }
    }

    //calculate minutes
    if (travelPeriod / (1000 * 60) > 0) {
      let travelMinutes = Math.trunc(travelPeriod / (1000 * 60));
      if (travelMinutes > 1) {
        travelTime += " " + travelMinutes + t("orderPage.shortMinutes");

        //travelPeriod -= 1000 * 60 * travelMinutes;
      } else {
        travelTime += " 1"+t("orderPage.shortMinutes");
        //travelPeriod -= 1000;
      }
    }

   // travelTime += "; ";

    return travelTime;
  };

  //Convery Date to string for datePicker
  //!  month has indexes 00..11 (00 - Jan)
  const dateToStringForDatePicker = (date: Date): string => {
    let result: string = date.getFullYear().toString() + "-";
    if (date.getMonth() + 1 < 10) {
      result += "0" + (date.getMonth() + 1) + "-";
    } else {
      result += date.getMonth() + 1 + "-";
    }
    if (date.getDate() < 10) {
      result += "0" + date.getDate();
    } else {
      result += date.getDate();
    }
    return result;
  };

  //Convery string from datePicker to Date
  //!  month has indexes 00..11 (00 - Jan)
  const stringToDateFromDatePicker = (dateAsString: string): Date => {
    let array = dateAsString.split("-").map(Number);
    return new Date(array[0], array[1] - 1, array[2]);
  };

  const dateFromDatePickerToCustomFormat = (
    date: string,
    dateFormat: string
  ) => {

    switch (dateFormat) {
      case DATE_FORMAT_YYYYMMDD: {
        let array = date.split("-");
        return array[0] + array[1] + array[2];
      }

      default:
        return "";
    }
  };

  const dateToCustomFormat = (
    date: Date,
    dateFormat: string
  ) => {

    switch (dateFormat) {
      case DATE_FORMAT_YYYYMMDD: {

        return date.getFullYear().toString()
          + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1).toString())
          + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
      }

      default:
        return "";
    }
  };

  //validate Date length from dataPicker (10) /input date or not
  // const isValidDateLengthFromDataPicker (date:string)=>{
  //  return date.
  // }
  //TODO need to implement
  //validate that arrived date more then departure
  const isTimeSpanCorrect = (
    dateStart: Date,
    dateEnd: Date,
    minDateSpanMilliSec?: number
  ) => {
    minDateSpanMilliSec = minDateSpanMilliSec ?? 0;
    return dateEnd.getTime() - dateStart.getTime() >= minDateSpanMilliSec
  };

  return {
    getCustomDate,
    getCustomTime,
    getTravelTime,
    isTimeSpanCorrect,
    dateFromDatePickerToCustomFormat,
    dateToStringForDatePicker,
    stringToDateFromDatePicker,
    dateToCustomFormat
  };
}
