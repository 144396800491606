import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatementIncome } from '../interfaces/reportInterfaces/IStatementIncome';
import { IStatementSale } from '../interfaces/reportInterfaces/IStatementSale';
import { ReportsApi } from "../api/ReportsApi";
import { IClientStructureRequest, ICommonReportRequest, IGetCustomerBuhDocsRequest } from "../interfaces/requestsInterfaces/reportRequests";
import { GetStatementOpenBalanceResponse } from "../responces/accountInfoResponses/GetStatementOpenBalanceResponse";
import { GetStatementCloseBalanceResponce } from '../responces/accountInfoResponses/GetStatementCloseBalanceResponse';
import { RootState } from "./store";
import { IFalconResponse } from '../interfaces/IFalconGenericResponce';
import {
  ICustomerAdditionalService,
  ICustomerAirCompany,
  ICustomerFlight,
  ICustomerTicketOrder,
  ICustomerOpeningMonthlyBalance,
  IAccountantDocument, ICustomerRegister, ICustomerEmployee, IDocumentFilters, IFlightFilters
} from '../interfaces/reportInterfaces/customerInterfaces';
import { CustomerApi } from "../api/CustomerApi";
import { request } from "http";
import { IClientStructure } from "../interfaces/companyInfoInterfaces/IClientStructure";
import { CompanyApi } from "../api/CompanyApi";
import { IReadyToDownloadDoc } from '../interfaces/reportInterfaces/accountantPageInterfaces';

interface ReportState {
  startReportDate: string;
  endReportDate: string;
  statementSales: IStatementSale[];
  statementIncomes: IStatementIncome[];
  statementOpenBalance: number;
  statementCloseBalance: number;

  flightFilters: IFlightFilters;
  customerFlights: ICustomerFlight[];
  customerTicketOrders: ICustomerTicketOrder[];
  customerAirCompanies: ICustomerAirCompany[];
  customerAdditionalServices: ICustomerAdditionalService[];
  customerOpeningMonthlyBalance: ICustomerOpeningMonthlyBalance[];

  reportClientStructureSales: IClientStructure[];
  reportClientStructureExchange: IClientStructure[];
  reportClientStructureRefound: IClientStructure[];

  //buhDocs Page
  documentFilters: IDocumentFilters;
  customerBuhDocs: IAccountantDocument[];
  availableEdmBuhDocs: IReadyToDownloadDoc[],
  sentToEdmBuhDocs: IAccountantDocument[];

  //Registers Page
  customerRegisterNames: string[];
  customerRegisters: ICustomerRegister[];

  customerEmployees: ICustomerEmployee[];
}

const initialState: ReportState = {
  startReportDate: "",
  endReportDate: "",

  statementSales: [],
  statementIncomes: [],
  statementCloseBalance: 0,
  statementOpenBalance: 0,

  flightFilters: {
    departDate: undefined,
    fromCity: undefined,
    toCity: undefined,
  },
  customerFlights: [],
  customerTicketOrders: [],
  customerAirCompanies: [],
  customerAdditionalServices: [],
  customerOpeningMonthlyBalance: [],

  //
  reportClientStructureSales: [],
  reportClientStructureExchange: [],
  reportClientStructureRefound: [],

  documentFilters: {
    startDate: undefined,
    endDate: undefined,
    docNumber: undefined,
    direction: undefined,
    clientName: undefined,
    company: undefined,
  },
  customerBuhDocs: [],
  availableEdmBuhDocs: [],
  sentToEdmBuhDocs: [],

  customerRegisterNames: [],
  customerRegisters: [],

  customerEmployees: [],
};

const {
  getStatemetSales,
  getClientsStructure,
  getStatemetIncomes,
  getStatemetOpenBalance,
  getStatemetCloseBalance,
} = ReportsApi();

const {
  getCustomerFlights,
  getCustomerAirCompanies,
  getCustomerTicketOrders,
  getCustomerAdditionalServices,
  getCustomerBuhDocs
} = CustomerApi();

const { getClientStructure } = CompanyApi();

//statement data - reportApi
export const addAvailableEdmBuhDocsThunk = createAsyncThunk(
    "reports/addAvailableEdmBuhDocsThunk",
    async (docs: IReadyToDownloadDoc[], thunkAPI) => {
      return docs;
    }
);

export const getStatementSalesThunk = createAsyncThunk(
  "reports/getStatementSales",
  async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getStatemetSales(request);
    return responce;
    //return responce.media[0];
  }
);

export const getStatementIncomesThunk = createAsyncThunk(
  "reports/getStatementIncomes",
  async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getStatemetIncomes(request);
    return responce;
    //return responce.media[0];
  }
);

export const getStatementOpenBalanceThunk = createAsyncThunk(
  "reports/getStatementOpenBalance",
  async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getStatemetOpenBalance(request);
    return responce;
    //return responce.media[0];
  }
);

export const getStatementCloseBalanceThunk = createAsyncThunk(
  "reports/getStatementCloseBalance",
  async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getStatemetCloseBalance(request);
    return responce;
    //return responce.media[0];
  }
);

//customer data - customer Api
export const getCustomerFlightsThunk = createAsyncThunk(
  "reports/getCustomerFlights", async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getCustomerFlights(request);
    return responce;
  });

export const getCustomerAirCompaniesThunk = createAsyncThunk(
  "reports/getCustomerAirCompanies", async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getCustomerAirCompanies(request);
    return responce;
  });

export const getCustomerTicketOrdersThunk = createAsyncThunk(
  "reports/getCustomerTicketOrders", async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getCustomerTicketOrders(request);
    return responce;
  });

export const getCustomerAdditionalServicesThunk = createAsyncThunk(
  "reports/getCustomerAdditionalServices", async (request: ICommonReportRequest, thunkAPI) => {
    const responce = await getCustomerAdditionalServices(request);
    return responce;
  });

//reports client structire
export const getReportClientStructureSalesThunk = createAsyncThunk("reports/getReportClientStructureSales",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)
    return response
  }
)

export const getReportClientStructureExchangeThunk = createAsyncThunk("reports/getReportClientStructureExchange",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)
    return response
  }
)

export const getReportClientStructureRefoundThunk = createAsyncThunk("reports/getReportClientStructureRefound",
  async (request: IClientStructureRequest, thunkAPI) => {
    const response = await getClientStructure(request)
    return response
  }
)

export const getCustomerBuhDocsThunk = createAsyncThunk("reports/getCustomerBuhDocs",
  async (request: IGetCustomerBuhDocsRequest, thunkApi) => {
    const response = await getCustomerBuhDocs(request)
    return response.data;
  })

export const reportsSlice = createSlice({
  name: "reports", initialState,
  reducers: {
    setStatementSales: (state, action: PayloadAction<IStatementSale[]>) => {
      state.statementSales = action.payload;
    },
    setStatementIncome: (state, action: PayloadAction<IStatementIncome[]>) => {
      state.statementIncomes = action.payload;
    },
    setStatementOpenBalance: (state, action: PayloadAction<number>) => {
      state.statementOpenBalance = action.payload;
    },
    setStatementCloseBalance: (state, action: PayloadAction<number>) => {
      state.statementCloseBalance = action.payload;
    },

    setFlightFilters: (state, action: PayloadAction<IFlightFilters>) => {
      state.flightFilters = action.payload;
    },
    setCustomerFlights: (state, action: PayloadAction<ICustomerFlight[]>) => {
      state.customerFlights = action.payload;
    },
    setCustomerTicketOrders: (state, action: PayloadAction<ICustomerTicketOrder[]>) => {
      state.customerTicketOrders = action.payload;
    },

    setCustomerAirCompanies: (state, action: PayloadAction<ICustomerAirCompany[]>) => {
      state.customerAirCompanies = action.payload;
    },

    setCustomerAdditionalServices: (state, action: PayloadAction<ICustomerAdditionalService[]>) => {
      state.customerAdditionalServices = action.payload;
    },

    setCustomerOpeningMonthlyBalance: (state, action: PayloadAction<ICustomerOpeningMonthlyBalance[]>) => {
      state.customerOpeningMonthlyBalance = action.payload;
    },

    setReportClientStructureSales: (state, action: PayloadAction<IClientStructure[]>) => {
      state.reportClientStructureSales = action.payload;
    },

    setReportClientStructureExchange: (state, action: PayloadAction<IClientStructure[]>) => {
      state.reportClientStructureExchange = action.payload;
    },

    setReportClientStructureRefound: (state, action: PayloadAction<IClientStructure[]>) => {
      state.reportClientStructureRefound = action.payload;
    },

    setDocumentFilters: (state, action: PayloadAction<IDocumentFilters>) => {
      state.documentFilters = action.payload;
    },
    setCustomerBuhDocs: (state, action: PayloadAction<IAccountantDocument[]>) => {
      state.customerBuhDocs = action.payload;
    },
    setAvailableEdmBuhDocs: (state, action: PayloadAction<IReadyToDownloadDoc[]>) => {
      state.availableEdmBuhDocs = action.payload;
    },
    setSentToEdmBuhDocs: (state, action: PayloadAction<IAccountantDocument[]>) => {
      state.sentToEdmBuhDocs = action.payload;
    },
    setStartReportDate: (state, action: PayloadAction<string>) => {
      state.startReportDate = action.payload;
    },
    setEndReportDate: (state, action: PayloadAction<string>) => {
      state.endReportDate = action.payload;
    },
    resetReports: (state) => {
      state.customerBuhDocs = [];
      state.availableEdmBuhDocs = [];
      state.sentToEdmBuhDocs = [];
    },

    resetDocumentFilters: (state) => {
      state.documentFilters.startDate = undefined;
      state.documentFilters.endDate = undefined;
      state.documentFilters.docNumber = undefined;
      state.documentFilters.direction = undefined;
      state.documentFilters.clientName = undefined;
      state.documentFilters.company = undefined;
    },

    setCustomerRegisterNames: (state, action: PayloadAction<string[]>) => {
      state.customerRegisterNames = action.payload;
    },
    setCustomerRegisters: (state, action: PayloadAction<ICustomerRegister[]>) => {
      state.customerRegisters = action.payload;
    },

    setCustomerEmployees: (state, action: PayloadAction<ICustomerEmployee[]>) => {
      state.customerEmployees = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAvailableEdmBuhDocsThunk.fulfilled, (state, action: PayloadAction<IReadyToDownloadDoc[]>) => {
        state.availableEdmBuhDocs = [...state.availableEdmBuhDocs, ...action.payload]
      })
      .addCase(getStatementSalesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IStatementSale>>) => {
        state.statementSales = action.payload.media
      })
      .addCase(getStatementIncomesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IStatementIncome>>) => {
        state.statementIncomes = action.payload.media
      })
      .addCase(getStatementOpenBalanceThunk.fulfilled, (state, action: PayloadAction<GetStatementOpenBalanceResponse>) => {
        state.statementOpenBalance = action.payload.media[0].openBalance
      })
      .addCase(getStatementCloseBalanceThunk.fulfilled, (state, action: PayloadAction<GetStatementCloseBalanceResponce>) => {
        state.statementCloseBalance = action.payload.media[0].closeBalance
      })
      .addCase(getCustomerFlightsThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<ICustomerFlight>>) => {
        state.customerFlights = action.payload.media
      })
      .addCase(getCustomerAirCompaniesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<ICustomerAirCompany>>) => {
        state.customerAirCompanies = action.payload.media
      })
      .addCase(getCustomerTicketOrdersThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<ICustomerTicketOrder>>) => {
        state.customerTicketOrders = action.payload.media
      })
      .addCase(getCustomerAdditionalServicesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<ICustomerAdditionalService>>) => {
        state.customerAdditionalServices = action.payload.media
      })
      .addCase(
        getReportClientStructureSalesThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
          state.reportClientStructureSales = action.payload.media
        })
      .addCase(
        getReportClientStructureExchangeThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
          state.reportClientStructureExchange = action.payload.media
        })
      .addCase(
        getReportClientStructureRefoundThunk.fulfilled, (state, action: PayloadAction<IFalconResponse<IClientStructure>>) => {
          state.reportClientStructureRefound = action.payload.media
        })
      .addCase(
        getCustomerBuhDocsThunk.fulfilled, (state, action: PayloadAction<IAccountantDocument[]>) => {
          state.customerBuhDocs = action.payload
        }
      )
  }
})

export const {
  setStatementCloseBalance,
  setStatementOpenBalance,
  setStatementIncome,
  setStatementSales,

  setCustomerAdditionalServices,
  setFlightFilters,
  setCustomerFlights,
  setCustomerAirCompanies,
  setCustomerOpeningMonthlyBalance,
  setCustomerTicketOrders,

  setReportClientStructureSales,
  setReportClientStructureExchange,
  setReportClientStructureRefound,

  setDocumentFilters,
  setCustomerBuhDocs,
  setAvailableEdmBuhDocs,
  setSentToEdmBuhDocs,
  setStartReportDate,
  setEndReportDate,
  resetReports,
  resetDocumentFilters,

  setCustomerRegisterNames,
  setCustomerRegisters,

  setCustomerEmployees,
} = reportsSlice.actions;

export const reportsState = (state: RootState): ReportState => {
  return {

    startReportDate: state.reports.startReportDate,
    endReportDate: state.reports.endReportDate,

    statementSales: state.reports.statementSales,
    statementIncomes: state.reports.statementIncomes,
    statementOpenBalance: state.reports.statementOpenBalance,
    statementCloseBalance: state.reports.statementCloseBalance,

    flightFilters: state.reports.flightFilters,
    customerFlights: state.reports.customerFlights,
    customerTicketOrders: state.reports.customerTicketOrders,
    customerAirCompanies: state.reports.customerAirCompanies,
    customerAdditionalServices: state.reports.customerAdditionalServices,
    customerOpeningMonthlyBalance: state.reports.customerOpeningMonthlyBalance,

    reportClientStructureExchange: state.reports.reportClientStructureExchange,
    reportClientStructureRefound: state.reports.reportClientStructureRefound,
    reportClientStructureSales: state.reports.reportClientStructureSales,

    documentFilters: state.reports.documentFilters,
    customerBuhDocs: state.reports.customerBuhDocs,
    availableEdmBuhDocs: state.reports.availableEdmBuhDocs,
    sentToEdmBuhDocs: state.reports.sentToEdmBuhDocs,

    customerRegisterNames: state.reports.customerRegisterNames,
    customerRegisters: state.reports.customerRegisters,

    customerEmployees: state.reports.customerEmployees,
  }
}

export default reportsSlice.reducer;
