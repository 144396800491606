export const CURRENT_NODE_ENVIROMENT = process.env.NODE_ENV;
export const CURRENT_REACT_ENVIROMENT = process.env.REACT_APP_ENV;

export const ENVIROMENT_PRODUCTION = "production";
export const ENVIROMENT_DEVELOPMENT = "development";
export const ENVIROMENT_STAGING = "staging";
export const ENVIROMENT_TEST = "test";


//Auth
export const ASP_ROLE_NAME_CLAIM =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const ASP_GK_CODE_CLAIM = "GcCode";

export const TTS_ADMIN_ROLE = "TtsAdmin";


export const JWT_NAME_CLAIM = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";


//Date
//! add implementation
export const PERIOD_CURRENT_MONTH = "currentMonth";
export const PERIOD_LAST_MONTH = "lastMonth";
export const PERIOD_CURRENT_QUARTER = "currentQuarter";
export const PERIOD_LAST_QUARTER = "lastQuarter";
export const PERIOD_LAST_TWELVE_MONTH = "lastTwelveMonth";

export const DATE_FORMAT_YYYYMMDD = "YYYYMMDD";
export const DATE_FORMAT_ISO = "isoDateFormat"
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-DD-MM";
export const DATE_LENGTH_FROM_DATA_PICKER = 10;

//Response type
export const AVIA_SERVICE_RESPONSE = "Авиа деятельность";
export const TRAIN_SERVICE_RESPONSE = "Жд деятельность";
export const HOTEL_SERVICE_RESPONSE = "Гостиница";
export const TRANSFERL_SERVICE_RESPONSE = "Трансфер";

//Sort
export const SORT_BY_ASC = "sortByAsc";
export const SORT_BY_DESC = "sortByDesc";
export const SORT_NOT_SORTED = "notSorted";
export const SORT_AS_STRING = "sortAsString";
export const SORT_AS_NUMBER = "sortAsNumber";
export const SORT_AS_DATE = "sortAsDate";

//Order service filtering
export const SORT_BY_MIN_PRICE = "sortByMinPrice";
export const SORT_BY_MAX_PRICE = "sortBYMaxPrice"





//local and session storage
export const AVIA_VARIANTS = "aviaVariants";
export const TRAIN_VARIANTS = "trainVariants";
export const TRAVEL_VARIANTS = "travelVariants";
export const FILTERED_TRAVEL_VARIANTS = "filteredTravelVariants";
