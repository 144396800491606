import axios from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BASE_ROUTE_AUTH_SERVICE,
    ROUTE_GET_INFO_ABOUT_USER,
    ROUTE_GET_USER_ACTIVE_STATE,
    ROUTE_CHANGE_PASS,
    ROUTE_RECOVERY_PASS,
    BASE_URL, ROUTE_GET_CUSTOMER_EMPLOYEE_ROLES, ROUTE_GET_USER_ROLES,
} from "../constants/routeConstants/ApiRouteConstants";
import {IUserResponse} from "../responces/IUserResponse";
import {IChangePassInterface} from "../interfaces/requestsInterfaces/IChangePassInterface";
import {ICompanyUserRoles, IGetCustomerEmployeeRolesRequest} from "../interfaces/requestsInterfaces/reportRequests";
import {IGetUserRolesRequest} from "../Requests/IGetUserRolesRequest";

export function AuthApi() {

    const getInfoAboutUser = async () => {
        const result = await axiosInstance.get<IUserResponse>(
            BASE_ROUTE_AUTH_SERVICE + ROUTE_GET_INFO_ABOUT_USER
        );
        return result.data;
    };

    const getUserActiveState = async ( email: string, token: string ) => {
        try {
            const result = await axiosInstance.get<boolean | null>(
                ROUTE_GET_USER_ACTIVE_STATE, {params: { email, token }}
            );
            return result.data == true;
        } catch (error) {
            return null;
        }
    };

    async function changePassword(recoveryPassData: IChangePassInterface) {
        try {
            const response = await axiosInstance.put(ROUTE_CHANGE_PASS, recoveryPassData);
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    async function recoveryPassword(email: string) {
        try {
            const response = await axios.put(BASE_URL + ROUTE_RECOVERY_PASS, email, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data === true;
        } catch (error) {
            return null;
        }
    }


    const getUserRoles = async (request: IGetUserRolesRequest) => {
        const result = await axiosInstance.get<ICompanyUserRoles[]>(
            ROUTE_GET_USER_ROLES,
            {
                headers: {
                    //Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    email: request.email
                },
            }
        );
        return result.data;
    };

    // route: /searchClients (WPF)
    // const getInfoAboutCompanyByClientCode = async (clientCode: string) => {
    //   const result = await axiosInstance.get<SearchClientByCodeResponce>(
    //     ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE +f
    //       "?" +
    //       CLIENT_CODE +
    //       "=" +
    //       clientCode
    //   );

    //   return result.data;
    // };

    //const getInfo;
    return {getInfoAboutUser, getUserActiveState, changePassword, recoveryPassword, getUserRoles};
}
