import { axiosInstance } from './AxiosInstance';
import { IFalconResponse } from "../interfaces/IFalconGenericResponce";
import { ROUTE_FIND_AVIA_VARIANTS, ROUTE_FIND_TRAIN_VARIANTS } from "../constants/routeConstants/ApiRouteConstants";
import { IDirectionRequest, ISearchTripVariants } from "../interfaces/requestsInterfaces/orderInterfaces";
import { ITrainVariant } from "../interfaces/orderInterfaces/TrainOrderInterfaces";
import axios from 'axios';
import { CityElement, ICityCatalogResponce } from '../interfaces/orderInterfaces/orderResponses';
import {IAviaVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";

export function OrderTickethApi() {

  const searchAviaFlights = async (request: ISearchTripVariants<IDirectionRequest>) => {
    const result = await axiosInstance.post<IFalconResponse<IAviaVariant>>(ROUTE_FIND_AVIA_VARIANTS, request);
    return result;
  };

  const searchTrains = async (request: ISearchTripVariants<IDirectionRequest>) => {
    const result = await axiosInstance.post<IFalconResponse<ITrainVariant>>(ROUTE_FIND_TRAIN_VARIANTS, request);
    return result;
  };

  const searchCities = async (partNameString: string) => {
    const result = await axiosInstance.get<CityElement[]>("/api/autocomplete/searchcities", { params: { text: partNameString } });
    return result.data;
  }

  return { searchAviaFlights, searchTrains, searchCities };
}
