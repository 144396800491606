import {useState} from "react";

type StatisticTabItemProps = {
    tabName: string;
    tabTitle: string;
    isActiveTab: boolean;
    isRequired: boolean;
    changeTab: () => void;
    changeCheckbox: () => void;
};

export function StatisticTabItemComponent(props: StatisticTabItemProps) {
    const [isRequiredTab, setIsRequiredTab] = useState(props.isRequired);

    const changeTabStatusHandler = () => {
        props.changeCheckbox();
        setIsRequiredTab(!isRequiredTab);
    };
    const tabColor = props.isActiveTab ? "#c9f76f" : "white";
    return (

        <li className="nav-item" key={StatisticTabItemComponent.name + props.tabName}>
            <div className=""
                 style={{
                     backgroundColor: tabColor,
                     color: "black",
                     border: "1px solid lightGray",
                     borderRadius: "5px",
                     padding: "8px",
                     fontSize: "13px",
                     fontFamily: "roboto"
                 }}>
        <span
            aria-current="page"
            className={isRequiredTab ? "user-select-none" : " user-select-none  text-muted  "}
            onClick={() => {
                props.changeTab();
            }}
        >
          {props.tabTitle}
            &nbsp;
        </span>
                <input type="checkbox" checked={props.isRequired} onChange={changeTabStatusHandler}
                style ={{color:"black", backgroundColor:"green"}}
                ></input>
            </div>
        </li>

    );
}
